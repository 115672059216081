define('stock/routes/quick_actions/move_to', ['exports', 'stock/routes/authorize'], function (exports, _stockRoutesAuthorize) {
  exports['default'] = _stockRoutesAuthorize['default'].extend({
    model: function model(_ref) {
      var location_id = _ref.location_id;

      return this.store.peekRecord('location', location_id);
    },

    setupController: function setupController(controller, destination) {
      controller.set('destination', destination);
      controller.on();
    },

    resetController: function resetController(controller) {
      controller.off();
    }
  });
});