define("stock/services/printer-service", ["exports", "ember", "stock/utils/helpers", "stock/services/api-base-service"], function (exports, _ember, _stockUtilsHelpers, _stockServicesApiBaseService) {
  exports["default"] = _stockServicesApiBaseService["default"].extend({
    store: _ember["default"].inject.service(),
    session: _ember["default"].inject.service(),

    allAvailablePrinters: function allAvailablePrinters() {
      return this.get("store").peekAll("printer").sortBy("name").map(function (printer) {
        return printer.getProperties("name", "id");
      });
    },

    printInventoryLabel: function printInventoryLabel(item) {
      var tag = arguments.length <= 1 || arguments[1] === undefined ? "stock" : arguments[1];

      var id = (0, _stockUtilsHelpers.toID)(item);
      return this.GET("/packages/" + id + "/print_inventory_label", { tag: tag });
    },

    updateUserDefaultPrinter: function updateUserDefaultPrinter(printerId) {
      var _this = this;

      var defaultPrinterUser = this.__getStockPrintersUsers().get("firstObject");
      this.PUT("/printers_users/" + defaultPrinterUser.id, {
        printers_users: {
          printer_id: printerId
        }
      }).then(function (data) {
        _this.get("store").pushPayload(data);
      });
    },

    addDefaultPrinter: function addDefaultPrinter(printer, userId, tag) {
      var _this2 = this;

      var id = printer ? (0, _stockUtilsHelpers.toID)(printer) : null;
      this.POST("/printers_users", {
        printers_users: {
          printer_id: id,
          user_id: userId || this.get("session.currentUser.id"),
          tag: tag || "stock"
        }
      }).then(function (data) {
        return _this2.get("store").pushPayload(data);
      });
    },

    getDefaultPrinter: function getDefaultPrinter() {
      return this.__getStockPrintersUsers().get("firstObject.printer");
    },

    getDefaultPrinterForUser: function getDefaultPrinterForUser(userId, tag) {
      tag = tag || "stock";
      var printers_user = this.get("store").peekAll("printers_user").find(function (row) {
        return row.get("tag") === tag && row.get("userId") === +userId;
      });

      return printers_user && printers_user.get("printer");
    },

    __getStockPrintersUsers: function __getStockPrintersUsers() {
      return this.get("store").peekAll("printers_user").filterBy("tag", "stock");
    }
  });
});