define("stock/components/request-inline-input", ["exports", "ember", "stock/components/numeric-inline-input", "stock/utils/ajax-promise"], function (exports, _ember, _stockComponentsNumericInlineInput, _stockUtilsAjaxPromise) {
  var getOwner = _ember["default"].getOwner;
  exports["default"] = _stockComponentsNumericInlineInput["default"].extend({
    getRequestParams: function getRequestParams() {
      return { quantity: this.get("value") || "" };
    },

    isEmptyQty: function isEmptyQty(requestParams) {
      var parsedQty = parseInt(requestParams["quantity"], 10);
      if (parsedQty === 0 || Number.isNaN(parsedQty)) {
        _ember["default"].$(this.element).removeClass("numeric-inline-input");
        this.set("value", this.get("previousValue"));
        return false;
      }
      return true;
    },

    focusOut: function focusOut() {
      var _this = this;

      var val = this.attrs.value.value;
      var regexPattern = /^\d+$/;
      if (val && val.toString().search(regexPattern) !== 0) {
        this.set("value", val.replace(/\D/g, ""));
      }
      var request = this.get("request");
      var url = "/goodcity_requests/" + request.get("id");
      var requestParams = this.getRequestParams();
      if (!this.isEmptyQty(requestParams)) {
        return false;
      }

      _ember["default"].$(this.element).removeClass("numeric-inline-input");
      if (requestParams["quantity"].toString() !== this.get("previousValue").toString()) {
        var loadingView = getOwner(this).lookup("component:loading").append();
        new _stockUtilsAjaxPromise["default"](url, "PUT", this.get("session.authToken"), {
          goodcity_request: requestParams
        }).then(function (data) {
          _this.get("store").pushPayload(data);
        })["finally"](function () {
          loadingView.destroy();
        });
      }
    }
  });
});