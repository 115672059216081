define("stock/controllers/order/request_purpose", ["exports", "ember", "stock/utils/ajax-promise"], function (exports, _ember, _stockUtilsAjaxPromise) {
  var getOwner = _ember["default"].getOwner;
  exports["default"] = _ember["default"].Controller.extend({
    queryParams: ["userId"],
    messageBox: _ember["default"].inject.service(),
    orderId: null,
    peopleCount: null,
    description: "",
    user: _ember["default"].computed.alias("model.orderUserOrganisation.user"),
    order: _ember["default"].computed.alias("model.orderUserOrganisation.order"),
    districts: _ember["default"].computed(function () {
      return this.get("model.districts").sortBy("name");
    }),

    peopleHelped: _ember["default"].computed("order", {
      get: function get() {
        return this.get("order.peopleHelped") || "";
      },
      set: function set(key, value) {
        this.set("order.peopleHelped", value);
        return value;
      }
    }),

    selectedDistrict: _ember["default"].computed("order.districtId", {
      get: function get() {
        var districtId = this.get("order.districtId");
        return districtId ? this.store.peekRecord("district", districtId) : null;
      },
      set: function set(key, value) {
        var districtId = value.id;
        return districtId ? this.store.peekRecord("district", districtId) : null;
      }
    }),

    actions: {
      clearDescription: function clearDescription() {
        this.set("order.purposeDescription", "");
      },

      deleteOrder: function deleteOrder() {
        var _this = this;

        this.get("messageBox").custom("Cancel this Request?", "Not Now", null, "Cancel Request", function () {
          return _this.send("removeOrder");
        });
      },

      removeOrder: function removeOrder() {
        var _this2 = this;

        var loadingView = getOwner(this).lookup("component:loading").append();

        this.get("model.order").destroyRecord().then(function () {
          _this2.transitionToRoute("app_menu_list");
        })["finally"](function () {
          return loadingView.destroy();
        });
      },

      createOrderWithRequestPuropose: function createOrderWithRequestPuropose() {
        var _this3 = this;

        var user = this.get("user");

        var user_organisation_id = undefined;
        if (user && user.get("organisationsUsers").length) {
          user_organisation_id = user.get("organisationsUsers.firstObject.organisationId");
        }

        var orderParams = {
          organisation_id: user_organisation_id,
          purpose_description: this.get("order.purposeDescription"),
          people_helped: Number(this.get("peopleHelped")),
          district_id: this.get("selectedDistrict.id")
        };

        var orderId = this.get("order.id");
        var url = "/orders/" + orderId;

        var loadingView = getOwner(this).lookup("component:loading").append();

        var isOrganisationPuropose = false;

        new _stockUtilsAjaxPromise["default"](url, "PUT", this.get("session.authToken"), {
          order: orderParams
        }).then(function (data) {
          _this3.get("store").pushPayload(data);
          var orderId = data.designation.id;
          var purpose_ids = data.orders_purposes.filterBy("order_id", orderId).getEach("purpose_id");
          isOrganisationPuropose = purpose_ids.get("length") === 1 && purpose_ids.indexOf(1) >= 0;
          if (isOrganisationPuropose) {
            _this3.transitionToRoute("order.goods_details", orderId);
          } else {
            _this3.transitionToRoute("order.client_information", orderId);
          }
        })["finally"](function () {
          return loadingView.destroy();
        });
      }
    }
  });
});