define("stock/routes/orders/index", ["exports", "stock/routes/authorize", "ember", "stock/utils/ajax-promise", "lodash", "stock/services/filter-service"], function (exports, _stockRoutesAuthorize, _ember, _stockUtilsAjaxPromise, _lodash, _stockServicesFilterService) {
  exports["default"] = _stockRoutesAuthorize["default"].extend({
    filterService: _ember["default"].inject.service(),
    utilityMethods: _ember["default"].inject.service(),
    userService: _ember["default"].inject.service(),

    /* jshint ignore:start */
    model: function model(params, transition) {
      var data;
      return regeneratorRuntime.async(function model$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            if (this.session.get("currentUser")) {
              context$1$0.next = 5;
              break;
            }

            context$1$0.next = 3;
            return regeneratorRuntime.awrap(this.get("userService").currentUser());

          case 3:
            data = context$1$0.sent;

            this.store.pushPayload(data);

          case 5:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    setupController: function setupController(controller) {
      var model = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];
      return regeneratorRuntime.async(function setupController$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return regeneratorRuntime.awrap(this._super(controller, model));

          case 2:
            controller.on();

          case 3:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },
    /* jshint ignore:end */

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.off();
      }
    }
  });
});
//jshint ignore:line

// @TODO: Move this user api call into the session service
// Checking the user, if needed, should probably be in AuthorizeRoute