define("stock/components/inline-select-list", ["exports", "ember", "stock/components/select-list"], function (exports, _ember, _stockComponentsSelectList) {
  var getOwner = _ember["default"].getOwner;
  exports["default"] = _stockComponentsSelectList["default"].extend({
    layoutName: "components/select-list",
    store: _ember["default"].inject.service(),

    selectedValue: _ember["default"].computed("name", "item.grade", function () {
      var name = this.get("name");
      var val = this.get("item." + name);

      if (this.get("isPrimitive")) {
        return { id: val, name: val };
      }
      return val;
    }),

    change: function change() {
      var _this = this;

      var item = this.get("item");
      var key = this.get("name");
      var val = this.get("selectedValue");

      if (this.get("isPrimitive")) {
        item.set(key, val.id);
      } else {
        item.set(key, val);
      }

      if (this.get("autosave")) {
        (function () {
          var loadingView = getOwner(_this).lookup("component:loading").append();
          item.save()["finally"](function () {
            loadingView.destroy();
          });
        })();
      }
    }
  });
});