define("stock/services/logger", ["exports", "ember", "stock/config/environment"], function (exports, _ember, _stockConfigEnvironment) {
  exports["default"] = _ember["default"].Service.extend({
    session: _ember["default"].inject.service(),
    rollbar: _ember["default"].inject.service(),

    notifyErrorCollector: function notifyErrorCollector(reason) {
      var currentUser = this.get("session.currentUser");
      var userName = currentUser.get("fullName");
      var userId = currentUser.get("id");
      var error = reason instanceof Error || typeof reason !== "object" ? reason : JSON.stringify(reason);
      var environment = _stockConfigEnvironment["default"].environment;
      this.set('rollbar.currentUser', currentUser);
      this.get('rollbar').error(error, { id: userId, username: userName, environment: environment });
    },

    error: function error(reason) {
      if (reason.status === 0) return;
      console.info(reason);
      this.notifyErrorCollector(reason);
    }
  });
});