define("stock/components/offer-tag", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    classNames: "",
    isPackageOffer: true,

    actions: {
      removeOffer: function removeOffer(offer) {
        this.get("removeOffer")(offer);
      }
    }
  });
});