define('stock/controllers/quick_actions/move_to', ['exports', 'stock/mixins/embed_scanner', 'stock/mixins/move_actions', 'stock/mixins/async', 'ember', 'lodash'], function (exports, _stockMixinsEmbed_scanner, _stockMixinsMove_actions, _stockMixinsAsync, _ember, _lodash) {

  /**
   * @module Controllers/quick_actions/StockActionsController
   * @augments ember/Controller
   */
  exports['default'] = _ember['default'].Controller.extend(_stockMixinsAsync['default'], _stockMixinsEmbed_scanner['default'], _stockMixinsMove_actions['default'], {
    // ----------------------
    // Dependencies
    // ----------------------

    store: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),
    barcodeService: _ember['default'].inject.service(),
    packageService: _ember['default'].inject.service(),

    // ----------------------
    // Properties
    // ----------------------

    moveInProgress: _ember['default'].computed.alias('readyToMove'),

    // ----------------------
    // Methods
    // ----------------------

    showScannerError: function showScannerError(text) {
      this.set('scannerError', text);
      if (text) {
        // We clear it after a few seconds
        _ember['default'].run.debounce(this, this.showScannerError, '', 3000);
      }
    },

    triggerMove: function triggerMove(pkg) {
      var sourceLocation, locations, validSources, restoreScanner;
      return regeneratorRuntime.async(function triggerMove$(context$1$0) {
        var _this = this;

        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            if (!this.get('moveInProgress')) {
              context$1$0.next = 2;
              break;
            }

            return context$1$0.abrupt('return');

          case 2:
            sourceLocation = null;
            locations = pkg.get('locations');

            if (!(locations.get('length') === 1 && locations.get('firstObject.id') === this.get('destination.id'))) {
              context$1$0.next = 6;
              break;
            }

            return context$1$0.abrupt('return', this.showScannerError(this.get('i18n').t('quick_action_errors.all_packages_at_destination')));

          case 6:
            validSources = locations.filter(function (loc) {
              return loc.get('id') !== _this.get('destination.id');
            });

            if (!(validSources.get('length') === 0)) {
              context$1$0.next = 9;
              break;
            }

            return context$1$0.abrupt('return', this.showScannerError(this.get('i18n').t('quick_action_errors.no_package_for_move')));

          case 9:

            if (validSources.get('length') === 1) {
              // There is only one location, we pick it without prompting the user
              sourceLocation = validSources.get('firstObject');
            }

            restoreScanner = sourceLocation === null && this.get('isScanning');

            if (restoreScanner) {
              this.stopScanning();
            }

            this.send('beginMove', pkg, sourceLocation, this.get('destination'), function () {
              if (restoreScanner) {
                _this.startScanning();
              }
            });

          case 13:
          case 'end':
            return context$1$0.stop();
        }
      }, null, this);
    },

    // ----------------------
    // Lifecycle
    // ----------------------

    on: function on() {
      this.set('scannerPreviewId', 'stocktake-scanner-preview-' + _lodash['default'].uniqueId());
    },

    off: function off() {
      this.set('active', false);
      this.stopScanning();
    },

    onBadInventoryNumber: function onBadInventoryNumber(num) {
      this.showScannerError(this.get('i18n').t('stocktakes.unknown_inventory_number', {
        code: num
      }));
    },

    onBarcodeScanned: function onBarcodeScanned(inventoryNumber) {
      var pkg;
      return regeneratorRuntime.async(function onBarcodeScanned$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            if (!(!inventoryNumber || this.get('moveInProgress'))) {
              context$1$0.next = 2;
              break;
            }

            return context$1$0.abrupt('return');

          case 2:
            context$1$0.next = 4;
            return regeneratorRuntime.awrap(this.get('packageService').findPackageByInventoryNumber(inventoryNumber));

          case 4:
            pkg = context$1$0.sent;

            if (pkg) {
              context$1$0.next = 7;
              break;
            }

            return context$1$0.abrupt('return', this.onBadInventoryNumber(inventoryNumber));

          case 7:
            return context$1$0.abrupt('return', this.triggerMove(pkg));

          case 8:
          case 'end':
            return context$1$0.stop();
        }
      }, null, this);
    },

    afterMove: function afterMove(pkg) {
      return regeneratorRuntime.async(function afterMove$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            if (!(this.get('autoPublish') && !pkg.get('allowWebPublish'))) {
              context$1$0.next = 4;
              break;
            }

            pkg.set('allowWebPublish', true);
            context$1$0.next = 4;
            return regeneratorRuntime.awrap(pkg.save());

          case 4:
          case 'end':
            return context$1$0.stop();
        }
      }, null, this);
    },

    // ----------------------
    // Actions
    // ----------------------

    actions: {
      pickPackage: function pickPackage() {
        var pkg;
        return regeneratorRuntime.async(function pickPackage$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              if (!this.get('moveInProgress')) {
                context$1$0.next = 2;
                break;
              }

              return context$1$0.abrupt('return');

            case 2:

              this.stopScanning();

              context$1$0.next = 5;
              return regeneratorRuntime.awrap(this.get('packageService').userPickPackage());

            case 5:
              pkg = context$1$0.sent;

              if (pkg) {
                context$1$0.next = 8;
                break;
              }

              return context$1$0.abrupt('return');

            case 8:

              this.triggerMove(pkg);

            case 9:
            case 'end':
              return context$1$0.stop();
          }
        }, null, this);
      }
    }
  });
});

// There is only one location, and it is the destination

// There's none of this package anywhere