define('stock/models/stockit_contact', ['exports', 'ember', 'ember-data/model', 'ember-data/attr'], function (exports, _ember, _emberDataModel, _emberDataAttr) {
  exports['default'] = _emberDataModel['default'].extend({
    firstName: (0, _emberDataAttr['default'])('string'),
    lastName: (0, _emberDataAttr['default'])('string'),
    phoneNumber: (0, _emberDataAttr['default'])('string'),
    mobilePhoneNumber: (0, _emberDataAttr['default'])('string'),

    displayNumber: _ember['default'].computed.or("mobilePhoneNumber", "phoneNumber"),

    fullName: _ember['default'].computed("firstName", "lastName", function () {
      return this.get('firstName') + ' ' + this.get('lastName');
    })
  });
});