define("stock/helpers/noop", ["exports", "ember-composable-helpers/helpers/noop"], function (exports, _emberComposableHelpersHelpersNoop) {
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function get() {
      return _emberComposableHelpersHelpersNoop["default"];
    }
  });
  Object.defineProperty(exports, "noop", {
    enumerable: true,
    get: function get() {
      return _emberComposableHelpersHelpersNoop.noop;
    }
  });
});