define("stock/components/reactive-view", ["exports", "ember"], function (exports, _ember) {

  /**
   * Re-renders it's content on model change
   *
   * @module Components/reactive-view
   * @augments ember/Component
   * @property {any} model a value to listen to
   *
   * @example
   *
   * {{#reactive-view model=something}}
   *    {{complex-component-to-rerender}}
   * {{/reactive-view}}
   *
   */
  exports["default"] = _ember["default"].Component.extend({
    render: false,

    _model: null,
    model: _ember["default"].computed("_model", {
      get: function get(key) {
        return this.get("_model");
      },
      set: function set(key, value) {
        this.set("_model", value);
        this.triggerRender();
        return value;
      }
    }),

    show: function show() {
      this.set("render", true);
    },

    hide: function hide() {
      this.set("render", false);
    },

    triggerRender: function triggerRender() {
      this.hide();
      _ember["default"].run.debounce(this, this.show, 200);
    }
  });
});