define("stock/components/package-summary-block", ["exports", "ember", "stock/mixins/item_actions"], function (exports, _ember, _stockMixinsItem_actions) {

  var ObjectPromiseProxy = _ember["default"].ObjectProxy.extend(_ember["default"].PromiseProxyMixin);

  exports["default"] = _ember["default"].Component.extend(_stockMixinsItem_actions["default"], {
    classNames: "",
    parentId: "epsilon",
    isRedirectable: true,
    disableLink: _ember["default"].computed.not("isRedirectable"),
    locationService: _ember["default"].inject.service(),
    packageService: _ember["default"].inject.service(),
    store: _ember["default"].inject.service(),

    "package": _ember["default"].computed("model", function () {
      return this.get("store").peekRecord("item", this.get("pkg.id"));
    }),

    fetchAddedQuantity: _ember["default"].computed("model", function () {
      var entityId = this.get("model.id");
      var pkgId = this.get("item.id");
      var promise = this.get("packageService").fetchAddedQuantity(entityId, pkgId);
      return ObjectPromiseProxy.create({
        promise: promise
      });
    }),

    addedQuantityCount: _ember["default"].computed.reads("fetchAddedQuantity.added_quantity").readOnly(),

    compositeParentId: _ember["default"].computed("parentId", function () {
      var parentId = this.get("parentId");
      return "parent-" + parentId;
    }),

    favouriteImage: _ember["default"].computed("model", function () {
      return this.get("model.favouriteImage.thumbImageUrl");
    })
  });
});