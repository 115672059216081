define("stock/helpers/delayed-action", ["exports", "ember"], function (exports, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; })();

  /**
   * @module Helpers/delayed-actions
   * @description Invoke action with with a specified delay (default=120ms)
   * @property {function} action the action to be executed after some delay
   * @example
   *
   * <button {{action (delayed-action (action 'doSomething') 200)}}>
   *  Submit
   * </button>
   *
   *
   * <button {{action (delayed-action (action 'doSomething'))}}>
   *  Submit
   * </button>
   */
  exports["default"] = _ember["default"].Helper.helper(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2);

    var action = _ref2[0];
    var _ref2$1 = _ref2[1];
    var delay = _ref2$1 === undefined ? 120 : _ref2$1;

    return function callee$1$0() {
      return regeneratorRuntime.async(function callee$1$0$(context$2$0) {
        var _this = this;

        while (1) switch (context$2$0.prev = context$2$0.next) {
          case 0:
            return context$2$0.abrupt("return", setTimeout(function callee$2$0() {
              return regeneratorRuntime.async(function callee$2$0$(context$3$0) {
                while (1) switch (context$3$0.prev = context$3$0.next) {
                  case 0:
                    context$3$0.next = 2;
                    return regeneratorRuntime.awrap(action());

                  case 2:
                  case "end":
                    return context$3$0.stop();
                }
              }, null, _this);
            }, delay));

          case 1:
          case "end":
            return context$2$0.stop();
        }
      }, null, this);
    };
  });
});