define("stock/mixins/async", ["exports", "ember", "lodash"], function (exports, _ember, _lodash) {
  var getOwner = _ember["default"].getOwner;

  var getString = function getString(obj, path) {
    var val = _lodash["default"].get(obj, path);
    return val && _lodash["default"].isString(val) ? val : null;
  };

  /**
   * AsyncMixin
   *
   * @description utilities to run asynchronous tasks with spinner and error management features
   * @mixin AsyncMixin
   *
   **/

  /**
   * @enum {number}
   * @readonly
   * @memberof AsyncMixin
   * @static
   */
  var ERROR_STRATEGIES = {
    /** Will ignore errors */
    IGNORE: 1,
    /** Will display the error message in a modal */
    MODAL: 2,
    /** Will let the error go through */
    RAISE: 3,
    /** Will push the error to rollbar */
    ROLLBAR: 4
  };

  exports.ERROR_STRATEGIES = ERROR_STRATEGIES;
  /**
   * Presets of configs for runTask()
   *
   * @enum {number}
   * @readonly
   * @memberof AsyncMixin
   * @static
   */
  var ASYNC_BEHAVIOURS = {
    DISCREET: {
      showSpinner: false,
      errorStrategy: ERROR_STRATEGIES.ROLLBAR
    },
    LOUD: {
      showSpinner: true,
      errorStrategy: ERROR_STRATEGIES.MODAL
    },
    SILENT_DEPENDENCY: {
      showSpinner: false,
      errorStrategy: ERROR_STRATEGIES.MODAL
    }
  };

  exports.ASYNC_BEHAVIOURS = ASYNC_BEHAVIOURS;
  exports["default"] = _ember["default"].Mixin.create({
    logger: _ember["default"].inject.service(),
    messageBox: _ember["default"].inject.service(),
    i18n: _ember["default"].inject.service(),

    ERROR_STRATEGIES: ERROR_STRATEGIES,

    // ----------------------
    // Private Helpers
    // ----------------------

    __tasksCount: 0,
    __loadingView: null,
    __modalActive: false,

    __incrementTaskCount: function __incrementTaskCount() {
      var step = arguments.length <= 0 || arguments[0] === undefined ? 1 : arguments[0];

      var count = this.get("__tasksCount") + step;

      this.set("__tasksCount", _lodash["default"].clamp(count, 0, Infinity));

      if (this.get("hasRunningTasks")) {
        this.showLoadingSpinner();
      } else {
        this.hideLoadingSpinner();
      }
    },

    __run: function __run(task) {
      var res = typeof task === "function" ? task() : task;
      return _ember["default"].RSVP.resolve(res);
    },

    __handleError: function __handleError(err) {
      var errorStrategy = arguments.length <= 1 || arguments[1] === undefined ? ERROR_STRATEGIES.RAISE : arguments[1];

      if (errorStrategy === ERROR_STRATEGIES.RAISE) {
        return _ember["default"].RSVP.reject(err);
      }

      if (errorStrategy === ERROR_STRATEGIES.MODAL) {
        return this.showErrorPopup(err);
      }

      if (errorStrategy === ERROR_STRATEGIES.ROLLBAR) {
        var errData = this.__toErrorSummary(err);
        this.get("logger").notifyErrorCollector(errData);
        console.error(errData);
        return;
      }
    },

    __toErrorSummary: function __toErrorSummary(error) {
      if (_lodash["default"].isString(error)) {
        return { message: error, details: {} };
      }

      return {
        message: this.__toErrorMessage(error),
        details: _lodash["default"].isError(error) ? { stack: error.stack } : error
      };
    },

    __toErrorMessage: function __toErrorMessage(reason) {
      var defaultMessage = this.get("i18n").t("unexpected_error");

      if (reason && reason.responseJSON) {
        reason = reason.responseJSON;
      }

      if (_lodash["default"].isString(reason)) {
        return reason;
      }

      return getString(reason, "error") || getString(reason, "errors[0].message") || getString(reason, "errors[0].detail.message") || getString(reason, "errors[0].title") || getString(reason, "errors[0]") || getString(reason, "message") || defaultMessage;
    },

    // ----------------------
    // Mixin computed props
    // ----------------------

    hasRunningTasks: _ember["default"].computed("__tasksCount", function () {
      return this.get("__tasksCount") > 0;
    }),

    // ----------------------
    // Mixin api
    // ----------------------

    /**
     * Runs the asynchronous task, showing and hiding loading spinners accordingly
     *
     * @memberof AsyncMixin
     * @instance
     * @param {Promise|Function} task the job to run
     * @param {number} [opts|errorStrategy] an indicator of how to handle the error
     */
    runTask: function runTask(task) {
      var opts = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

      var _opts, errorStrategy, _opts$showSpinner, showSpinner;

      return regeneratorRuntime.async(function runTask$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            if (_lodash["default"].isNumber(opts)) {
              opts = { errorStrategy: opts };
            }

            _opts = opts;
            errorStrategy = _opts.errorStrategy;
            _opts$showSpinner = _opts.showSpinner;
            showSpinner = _opts$showSpinner === undefined ? true : _opts$showSpinner;

            this.__incrementTaskCount(showSpinner ? 1 : 0);
            context$1$0.prev = 6;
            context$1$0.next = 9;
            return regeneratorRuntime.awrap(this.__run(task));

          case 9:
            return context$1$0.abrupt("return", context$1$0.sent);

          case 12:
            context$1$0.prev = 12;
            context$1$0.t0 = context$1$0["catch"](6);
            return context$1$0.abrupt("return", this.__handleError(context$1$0.t0, errorStrategy));

          case 15:
            context$1$0.prev = 15;

            this.__incrementTaskCount(showSpinner ? -1 : 0);
            return context$1$0.finish(15);

          case 18:
          case "end":
            return context$1$0.stop();
        }
      }, null, this, [[6, 12, 15, 18]]);
    },

    showLoadingSpinner: function showLoadingSpinner() {
      var _this = this;

      _ember["default"].run(function () {
        if (!_this.__loadingView && !_ember["default"].testing) {
          _this.__loadingView = getOwner(_this).factoryFor("component:loading").create().append();
        }
      });
    },

    hideLoadingSpinner: function hideLoadingSpinner() {
      var _this2 = this;

      _ember["default"].run(function () {
        if (_this2.__loadingView && !_ember["default"].testing) {
          _this2.__loadingView.destroy();
          _this2.__loadingView = null;
        }
      });
    },

    showErrorPopup: function showErrorPopup(reason) {
      var _this3 = this;

      this.get("logger").error(reason);

      if (this.get("__modalActive")) {
        return;
      }

      this.set("__modalActive", true);
      this.get("messageBox").alert(this.__toErrorMessage(reason), function () {
        _this3.set("__modalActive", false);
      });
    },

    tryTranslate: function tryTranslate(str) {
      var props = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

      var i18n = this.get("i18n");
      return i18n.exists(str) ? i18n.t(str, props) : str;
    },

    modalAlert: function modalAlert(key) {
      var props = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

      var deferred = _ember["default"].RSVP.defer();
      var text = this.tryTranslate(key, props);

      this.get("messageBox").alert(text, function () {
        deferred.resolve(null);
      });

      return deferred.promise;
    },

    modalConfirm: function modalConfirm(bodyText) {
      var confirmText = arguments.length <= 1 || arguments[1] === undefined ? "confirm" : arguments[1];
      var cb = arguments.length <= 2 || arguments[2] === undefined ? _lodash["default"].noop : arguments[2];

      var deferred = _ember["default"].RSVP.defer();

      var onConfirm = function onConfirm() {
        cb();
        deferred.resolve(true);
      };

      var onCancel = function onCancel() {
        return deferred.resolve(false);
      };

      this.get("messageBox").custom(this.tryTranslate(bodyText), this.tryTranslate(confirmText), onConfirm, this.tryTranslate("cancel"), onCancel);

      return deferred.promise;
    }
  });
});