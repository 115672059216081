define("stock/models/stocktake", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (exports, _emberDataModel, _emberDataAttr, _emberDataRelationships) {
  exports["default"] = _emberDataModel["default"].extend({
    createdAt: (0, _emberDataAttr["default"])("date"),
    updatedAt: (0, _emberDataAttr["default"])("date"),
    name: (0, _emberDataAttr["default"])("string"),
    state: (0, _emberDataAttr["default"])("string"),
    comment: (0, _emberDataAttr["default"])("string"),
    gains: (0, _emberDataAttr["default"])("number"),
    losses: (0, _emberDataAttr["default"])("number"),
    counts: (0, _emberDataAttr["default"])("number"),
    warnings: (0, _emberDataAttr["default"])("number"),
    locationId: (0, _emberDataAttr["default"])("number"),
    location: (0, _emberDataRelationships.belongsTo)("location", { async: false }),
    stocktakeRevisions: (0, _emberDataRelationships.hasMany)("stocktake_revisions", { async: false }),
    revisions: Ember.computed.alias("stocktakeRevisions"),
    createdBy: (0, _emberDataRelationships.belongsTo)("user", { async: true }),

    isOpen: Ember.computed.equal("state", "open"),
    isClosed: Ember.computed.equal("state", "closed"),
    isProcessing: Ember.computed.equal("state", "processing"),
    isAwaitingProcess: Ember.computed.equal("state", "awaiting_process"),

    dirtyRevisions: Ember.computed.filterBy("revisions", "dirty", true),
    cleanRevisions: Ember.computed.filterBy("revisions", "dirty", false),
    revisionsWithWarnings: Ember.computed.filterBy("revisions", "warning"),
    gainRevisions: Ember.computed.filterBy("revisions", "isGain"),
    lossRevisions: Ember.computed.filterBy("revisions", "isLoss"),

    cannotCommit: Ember.computed.not("canCommit"),
    canCommit: Ember.computed("revisions.[]", "revisions.@each.hasDirtyAttributes", "dirtyRevisions.length", "isOpen", function () {
      return this.get("isOpen") && this.get("dirtyRevisions.length") === 0 && !this.get("revisions").findBy("hasDirtyAttributes");
    })
  });
});