define("stock/routes/users/charity_position", ["exports", "stock/routes/authorize", "ember"], function (exports, _stockRoutesAuthorize, _ember) {
  exports["default"] = _stockRoutesAuthorize["default"].extend({
    queryParams: {
      id: {
        refreshModel: true
      },
      organisationId: {
        refreshModel: true
      }
    },
    organisationsUserService: _ember["default"].inject.service(),

    model: function model(params) {
      return regeneratorRuntime.async(function model$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            if (!params.id) {
              context$1$0.next = 9;
              break;
            }

            context$1$0.t0 = this.store.peekRecord("organisations_user", params.id);

            if (context$1$0.t0) {
              context$1$0.next = 6;
              break;
            }

            context$1$0.next = 5;
            return regeneratorRuntime.awrap(this.store.findRecord("organisations_user", params.id, {
              reload: true
            }));

          case 5:
            context$1$0.t0 = context$1$0.sent;

          case 6:
            return context$1$0.abrupt("return", context$1$0.t0);

          case 9:
            return context$1$0.abrupt("return", this.store.createRecord("organisations_user", {
              position: "",
              preferredContactNumber: "",
              organisationId: "",
              user_id: "",
              status: ""
            }));

          case 10:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    beforeModel: function beforeModel(transition) {
      var userId;
      return regeneratorRuntime.async(function beforeModel$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            userId = transition.params["users.charity_position"].user_id;

            this.set("user_id", userId);
            context$1$0.t0 = this.store.peekRecord("user", userId);

            if (context$1$0.t0) {
              context$1$0.next = 6;
              break;
            }

            context$1$0.next = 6;
            return regeneratorRuntime.awrap(this.store.findRecord("user", userId, {
              reload: true
            }));

          case 6:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    afterModel: function afterModel(_model, transition) {
      var organisationId;
      return regeneratorRuntime.async(function afterModel$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            organisationId = transition.queryParams["organisationId"];

            if (!organisationId) {
              context$1$0.next = 7;
              break;
            }

            this.set("organisation_id", organisationId);
            context$1$0.t0 = this.store.peekRecord("organisation", organisationId);

            if (context$1$0.t0) {
              context$1$0.next = 7;
              break;
            }

            context$1$0.next = 7;
            return regeneratorRuntime.awrap(this.store.findRecord("organisation", organisationId, {
              reload: true
            }));

          case 7:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set("user_id", this.get("user_id"));
      this.initializeOrganisation(controller, model);
      this.initializeStatus(controller, model);
    },

    initializeOrganisation: function initializeOrganisation(controller, model) {
      if (this.get("organisation_id")) {
        var data = model.get("organisation") || this.store.peekRecord("organisation", this.get("organisation_id"));
        controller.set("organisation", data);

        if (!model.get("organisation.id")) {
          model.set("organisation", data);
        }
      }
    },

    initializeStatus: function initializeStatus(controller, model) {
      var data, selected;
      return regeneratorRuntime.async(function initializeStatus$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return regeneratorRuntime.awrap(this.get("organisationsUserService").getAllStatus());

          case 2:
            data = context$1$0.sent;

            controller.set("allStatus", data);

            if (model.get("status")) {
              context$1$0.next = 7;
              break;
            }

            controller.set("selectedStatus", data[0]);
            return context$1$0.abrupt("return");

          case 7:
            selected = data.find(function (d) {
              return d.name.toLowerCase() === model.get("status");
            });

            controller.set("selectedStatus", selected);

          case 9:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    }
  });
});