define('stock/controllers/app_menu_list', ['exports', 'ember', 'stock/config/environment', 'stock/utils/ajax-promise', 'stock/mixins/storage-type', 'stock/mixins/organisation'], function (exports, _ember, _stockConfigEnvironment, _stockUtilsAjaxPromise, _stockMixinsStorageType, _stockMixinsOrganisation) {
  var getOwner = _ember['default'].getOwner;
  exports['default'] = _ember['default'].Controller.extend(_stockMixinsStorageType['default'], _stockMixinsOrganisation['default'], {
    application: _ember['default'].inject.controller(),
    isMobileApp: _stockConfigEnvironment['default'].cordova.enabled,
    settings: _ember['default'].inject.service(),
    packageService: _ember['default'].inject.service(),
    locationService: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),
    packageTypeService: _ember['default'].inject.service(),
    stockAppVersion: _ember['default'].computed(function () {
      return _stockConfigEnvironment['default'].cordova.enabled ? _stockConfigEnvironment['default'].APP.VERSION : null;
    }),

    getCurrentUser: _ember['default'].computed(function () {
      var store = this.get('store');
      var currentUser = store.peekAll('user_profile').get('firstObject') || null;
      return currentUser;
    }).volatile(),

    orderParams: function orderParams() {
      var orderParams = {};
      orderParams.booking_type_id = this.store.peekAll('bookingType').filterBy('identifier', 'appointment').get('firstObject.id');
      orderParams.submitted_by_id = this.get('getCurrentUser.id');
      orderParams.state = 'draft';
      orderParams.detail_type = 'GoodCity';
      return orderParams;
    },

    actions: {
      logMeOut: function logMeOut() {
        this.get('application').send('logMeOut');
      },

      createInventory: function createInventory(storageTypeName) {
        var code;
        return regeneratorRuntime.async(function createInventory$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              context$1$0.next = 2;
              return regeneratorRuntime.awrap(this.get('packageTypeService').userPickPackageType({
                storageType: storageTypeName
              }));

            case 2:
              code = context$1$0.sent;

              if (code) {
                context$1$0.next = 5;
                break;
              }

              return context$1$0.abrupt('return');

            case 5:

              this.replaceRoute('items.new', {
                queryParams: { codeId: code.get('id'), storageType: storageTypeName }
              });

            case 6:
            case 'end':
              return context$1$0.stop();
          }
        }, null, this);
      },

      quickMove: function quickMove() {
        var location;
        return regeneratorRuntime.async(function quickMove$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              context$1$0.next = 2;
              return regeneratorRuntime.awrap(this.get('locationService').userPickLocation({
                headerText: this.get('i18n').t('select_location.pick_to_location')
              }));

            case 2:
              location = context$1$0.sent;

              if (location) {
                context$1$0.next = 5;
                break;
              }

              return context$1$0.abrupt('return');

            case 5:
              // action cancelled

              this.replaceRoute('quick_actions.move_to', location);

            case 6:
            case 'end':
              return context$1$0.stop();
          }
        }, null, this);
      },

      createHkOrder: function createHkOrder() {
        var _this = this;

        var loadingView = getOwner(this).lookup('component:loading').append();

        new _stockUtilsAjaxPromise['default']('/orders', 'POST', this.get('session.authToken'), {
          order: this.orderParams()
        }).then(function (data) {
          var orderId = data.designation.id;
          _this.store.pushPayload(data);
          _this.transitionToRoute('order.search_users', orderId);
        })['finally'](function () {
          return loadingView.destroy();
        });
      }
    }
  });
});