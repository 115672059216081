define('stock/components/select_appointment_date', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].TextField.extend({
    tagName: 'input',
    classNames: 'pickadate',
    attributeBindings: ["name", "type", "value", "id", 'required', 'pattern', 'placeholder'],

    currentMinutes: function currentMinutes() {
      var currentTime = new Date();
      var hours = currentTime.getHours();
      var minutes = currentTime.getMinutes() > 30 ? 30 : 0;
      var total_mins = hours * 60 + minutes;
      return total_mins > 900 ? 900 : total_mins;
    },

    _currentDay: function _currentDay() {
      var currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      return currentDate;
    },

    _getValidDate: function _getValidDate(selectedDate) {
      var today = new Date();
      var currentDate = new Date();
      var selected = new Date(selectedDate);
      currentDate.setHours(0, 0, 0, 0);
      selected.setHours(0, 0, 0, 0);
      return currentDate > selected ? today : selectedDate;
    },

    _setTimeSlots: function _setTimeSlots(date) {
      var selectedDate = new Date(date);
      var currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      selectedDate.setHours(0, 0, 0, 0);

      if (selectedDate.getTime() === currentDate.getTime()) {
        var total_mins = this.currentMinutes();

        // disabled all previous options
        _ember['default'].$(".time_selector select option[value=" + total_mins + "]").prevAll().each(function () {
          _ember['default'].$(this).addClass("hidden");
          this.disabled = true;
        });

        // disable current option
        var current_option = _ember['default'].$(".time_selector select option[value=" + total_mins + "]");
        if (current_option.length > 0) {
          current_option.addClass("hidden");
          current_option[0].disabled = true;
        }
      } else {
        // Enable all select options
        _ember['default'].$(".time_selector select option").each(function () {
          _ember['default'].$(this).removeClass("hidden");
          this.disabled = false;
        });
      }
    },

    didInsertElement: function didInsertElement() {
      var _this = this;
      var date = new Date();
      var setting = false;

      // Allow GGV booking for next 6 months span.
      var maxDate = new Date();
      maxDate.setMonth(maxDate.getMonth() + 6);

      var list = this.get('available');
      var available_count = 0,
          available_array = [true];

      if (list) {
        available_count = list['appointment_calendar_dates'].length;
        var dates = list['appointment_calendar_dates'].rejectBy('isClosed', true).getEach('date');

        for (var i = dates.length - 1; i >= 0; i--) {
          date = new Date(dates[i]);
          var date_array = [];
          date_array.push(date.getFullYear());
          date_array.push(date.getMonth());
          date_array.push(date.getDate());
          available_array.push(date_array);
        }

        var firstDateArray = available_array.get("lastObject");
        var firstDate = new Date(firstDateArray[0], firstDateArray[1], firstDateArray[2]);
        var isTodayListed = _this._currentDay().getTime() === firstDate.getTime();
        if (_this.currentMinutes() === 900 && isTodayListed) {
          available_array.pop();
        }
      }

      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        _ember['default'].$('.pickadate').pickadate({
          format: 'ddd mmm d',
          monthsFull: moment.months(),
          monthsShort: moment.monthsShort(),
          weekdaysShort: moment.weekdaysShort(),
          disable: available_array,
          clear: false,
          today: false,
          close: false,
          min: available_array[available_array.length - 1],
          max: available_array[1],

          onClose: function onClose() {
            var _this2 = this;

            _ember['default'].$(document.activeElement).blur();

            if (setting) {
              return;
            }

            var date = this.get('select') && this.get('select').obj;
            _this.set("selection", date);
            _ember['default'].$('.time_selector select').val('');
            removeHighlight(".date_selector input");

            setting = true;
            _ember['default'].run.next(function () {
              if (date) {
                _this2.set('select', new Date(date), { format: 'ddd mmm d' });
              }
              setting = false;
            });

            if (date) {
              _this._setTimeSlots(date);
            }
          },

          onStart: function onStart() {
            var date = _this.get('selection');
            if (date) {
              date = _this._getValidDate(date);
              this.set('select', new Date(date), { format: 'ddd mmm d' });
              _this._setTimeSlots(date);
            }
          }
        });

        validateForm();
        validateInputs();
      });

      function validateForm() {
        _ember['default'].$('.a.appointment').click(function () {
          var date = checkInput(_ember['default'].$('.date_selector input'));
          var time = checkInput(_ember['default'].$('.time_selector select'));
          return date && time;
        });
      }

      function validateInputs() {
        _ember['default'].$('.date_selector input').focus(function () {
          return removeHighlight(this);
        });
        _ember['default'].$('.time_selector select').focus(function () {
          return removeHighlight(this);
        });
      }

      function checkInput(element) {
        var parent = _ember['default'].$(element).parent();
        var value = _ember['default'].$(element).val();

        if (!value || value.length === 0) {
          parent.addClass('has-error');
          return false;
        } else {
          parent.removeClass('has-error');
          return true;
        }
      }

      function removeHighlight(element) {
        var parent = _ember['default'].$(element).parent();
        parent.removeClass('has-error');
      }
    }

  });
});