define("stock/controllers/users/charity_position", ["exports", "ember", "stock/mixins/async", "stock/mixins/organisation"], function (exports, _ember, _stockMixinsAsync, _stockMixinsOrganisation) {
  exports["default"] = _ember["default"].Controller.extend(_stockMixinsAsync["default"], _stockMixinsOrganisation["default"], {
    organisationsUserService: _ember["default"].inject.service(),
    i18n: _ember["default"].inject.service(),

    isInvalidPreferredNumber: _ember["default"].computed("model.preferredContactNumber", function () {
      return !this.get("model.preferredContactNumber") || this.get("model.preferredContactNumber").length < 8;
    }),

    isInvalidOrganisation: _ember["default"].computed("model.organisation", function () {
      return !this.get("model.organisation.nameEn");
    }),

    actions: {
      /**
       * Open's the organisation overlay.
       * On change of organisation, a check is made
       * if organisations_users record already exists for the user, then depending on that URL is changed
       * which in-turn loads a model.
       */
      searchOrganisation: function searchOrganisation() {
        var organisation, organisationUser;
        return regeneratorRuntime.async(function searchOrganisation$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              context$1$0.next = 2;
              return regeneratorRuntime.awrap(this.organisationLookup());

            case 2:
              organisation = context$1$0.sent;

              this.set("organisation", organisation);

              organisationUser = this.get("organisationsUserService").getOrganisationUser(organisation.get("id"), this.get("user_id"));

              if (organisationUser) {
                this.transitionToRoute("/users/" + this.get("user_id") + "/charity_position?id=" + organisationUser.get("id"));
              } else {
                this.transitionToRoute("/users/" + this.get("user_id") + "/charity_position?organisationId=" + organisation.get("id"));
              }

            case 6:
            case "end":
              return context$1$0.stop();
          }
        }, null, this);
      },

      onStatusChange: function onStatusChange(status) {
        this.set("selectedStatus", status);
      },

      /**
       * Performs save or update operation
       */
      save: function save() {
        return regeneratorRuntime.async(function save$(context$1$0) {
          var _this2 = this;

          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              this.set("model.user", this.get("store").peekRecord("user", this.get("user_id")));
              this.set("model.status", this.get("selectedStatus.name"));
              context$1$0.prev = 2;
              context$1$0.next = 5;
              return regeneratorRuntime.awrap(this.runTask(function callee$1$0() {
                return regeneratorRuntime.async(function callee$1$0$(context$2$0) {
                  var _this = this;

                  while (1) switch (context$2$0.prev = context$2$0.next) {
                    case 0:
                      context$2$0.next = 2;
                      return regeneratorRuntime.awrap(this.get("model").save());

                    case 2:
                      _ember["default"].run.later(function () {
                        _this.replaceRoute("users.details", _this.get("user_id"));
                      }, 250);

                    case 3:
                    case "end":
                      return context$2$0.stop();
                  }
                }, null, _this2);
              }, _stockMixinsAsync.ERROR_STRATEGIES.RAISE));

            case 5:
              context$1$0.next = 10;
              break;

            case 7:
              context$1$0.prev = 7;
              context$1$0.t0 = context$1$0["catch"](2);
              throw context$1$0.t0;

            case 10:
            case "end":
              return context$1$0.stop();
          }
        }, null, this, [[2, 7]]);
      },

      cancel: function cancel() {
        var confirmed;
        return regeneratorRuntime.async(function cancel$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              context$1$0.next = 2;
              return regeneratorRuntime.awrap(this.modalConfirm(this.get("i18n").t("users.charity_position.cancel_warning")));

            case 2:
              confirmed = context$1$0.sent;

              if (confirmed) {
                context$1$0.next = 5;
                break;
              }

              return context$1$0.abrupt("return");

            case 5:

              this.replaceRoute("users.details", this.get("user_id"));

            case 6:
            case "end":
              return context$1$0.stop();
          }
        }, null, this);
      }
    }
  });
});