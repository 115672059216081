define("stock/controllers/search_code", ["exports", "ember", "ember-i18n", "stock/utils/ajax-promise", "stock/mixins/async", "stock/controllers/goodcity_controller"], function (exports, _ember, _emberI18n, _stockUtilsAjaxPromise, _stockMixinsAsync, _stockControllersGoodcity_controller) {
  exports["default"] = _stockControllersGoodcity_controller["default"].extend(_stockMixinsAsync["default"], {
    queryParams: ["backToNewItem", "orderId", "changeCode", "reqId"],
    reqId: null,
    backToNewItem: false,
    changeCode: false,
    orderId: null,
    filter: "",
    searchText: "",
    fetchMoreResult: true,
    searchPlaceholder: (0, _emberI18n.translationMacro)("search.placeholder"),
    i18n: _ember["default"].inject.service(),

    allPackageTypes: _ember["default"].computed("fetchMoreResult", function () {
      return this.store.peekAll("code").filterBy("visibleInSelects", true);
    }),

    hasSearchText: _ember["default"].computed("searchText", function () {
      return _ember["default"].$.trim(this.get("searchText")).length;
    }),

    hasFilter: _ember["default"].computed("filter", function () {
      return _ember["default"].$.trim(this.get("filter")).length;
    }),

    onSearchTextChange: _ember["default"].observer("searchText", function () {
      // wait before applying the filter
      _ember["default"].run.debounce(this, this.applyFilter, 500);
    }),

    applyFilter: function applyFilter() {
      this.set("filter", this.get("searchText"));
      this.set("fetchMoreResult", true);
    },

    filteredResults: _ember["default"].computed("filter", "fetchMoreResult", "allPackageTypes.[]", function () {
      var filter = _ember["default"].$.trim(this.get("filter").toLowerCase());
      var types = [];
      var matchFilter = function matchFilter(value) {
        return (value || "").toLowerCase().indexOf(filter) !== -1;
      };

      if (filter.length > 0) {
        this.get("allPackageTypes").forEach(function (type) {
          if (matchFilter(type.get("name")) || matchFilter(type.get("otherTerms"))) {
            types.push(type);
          }
        });
        _ember["default"].run.later(this, this.highlight);
      } else {
        types = types.concat(this.get("allPackageTypes").toArray());
        this.clearHiglight();
      }

      return types.sortBy("name").uniq();
    }),

    highlight: function highlight() {
      var string = _ember["default"].$.trim(this.get("filter").toLowerCase());
      this.clearHiglight();
      _ember["default"].$(".codes_results li div").each(function () {
        var text = _ember["default"].$(this).text();
        if (text.toLowerCase().indexOf(string.toLowerCase()) > -1) {
          var matchStart = text.toLowerCase().indexOf("" + string.toLowerCase() + "");
          var matchEnd = matchStart + string.length - 1;
          var beforeMatch = text.slice(0, matchStart);
          var matchText = text.slice(matchStart, matchEnd + 1);
          var afterMatch = text.slice(matchEnd + 1);
          _ember["default"].$(this).html(beforeMatch + "<em>" + matchText + "</em>" + afterMatch);
        }
      });
    },

    clearHiglight: function clearHiglight() {
      _ember["default"].$("em").replaceWith(function () {
        return this.innerHTML;
      });
    },

    updateRequestCode: function updateRequestCode(packageType, requestId) {
      var _this = this;

      var url = "/goodcity_requests/" + requestId;

      return this.runTask(function callee$1$0() {
        var data;
        return regeneratorRuntime.async(function callee$1$0$(context$2$0) {
          while (1) switch (context$2$0.prev = context$2$0.next) {
            case 0:
              context$2$0.next = 2;
              return regeneratorRuntime.awrap(new _stockUtilsAjaxPromise["default"](url, "PUT", this.get("session.authToken"), {
                goodcity_request: {
                  package_type_id: packageType.id
                }
              }));

            case 2:
              data = context$2$0.sent;

              this.get("store").pushPayload(data);

            case 4:
            case "end":
              return context$2$0.stop();
          }
        }, null, _this);
      }, _stockMixinsAsync.ERROR_STRATEGIES.MODAL);
    },

    actions: {
      clearSearch: function clearSearch(isCancelled) {
        this.set("filter", "");
        this.set("searchText", "");
        this.set("fetchMoreResult", true);
        if (!isCancelled) {
          _ember["default"].$("#searchText").focus();
        }
      },

      cancelSearch: function cancelSearch() {
        _ember["default"].$("#searchText").blur();
        this.send("clearSearch", true);
        if (this.get("backToNewItem")) {
          this.replaceRoute("items.new");
        } else {
          window.history.back();
        }
      },

      assignItemLabel: function assignItemLabel(type) {
        if (type) {
          this.replaceRoute("items.new", { queryParams: { codeId: type.id } });
        }
      }
    }
  });
});