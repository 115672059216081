define("stock/components/publish-item", ["exports", "ember", "stock/utils/ajax-promise"], function (exports, _ember, _stockUtilsAjaxPromise) {
  var getOwner = _ember["default"].getOwner;
  exports["default"] = _ember["default"].Component.extend({

    displayUserPrompt: false,
    store: _ember["default"].inject.service(),

    item: null,
    published: _ember["default"].computed.alias("item.allowWebPublish"),

    actions: {
      displayPublishOverlay: function displayPublishOverlay() {
        this.set("displayUserPrompt", true);
      },

      setPublishStatus: function setPublishStatus() {
        var _this = this;

        var item = this.get("item");
        var loadingView = getOwner(this).lookup('component:loading').append();
        var url = "/packages/" + item.get('id');

        new _stockUtilsAjaxPromise["default"](url, "PUT", this.get('session.authToken'), { "package": { allow_web_publish: item.toggleProperty("allowWebPublish") } }).then(function (data) {
          _this.get("store").pushPayload(data);
        })["finally"](function () {
          loadingView.destroy();
        });
      }
    }
  });
});