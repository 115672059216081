define("stock/components/camera-detect", ["exports", "ember", "stock/utils/ajax-promise", "stock/mixins/async"], function (exports, _ember, _stockUtilsAjaxPromise, _stockMixinsAsync) {
  exports["default"] = _ember["default"].Component.extend(_stockMixinsAsync["default"], {
    packageService: _ember["default"].inject.service(),
    store: _ember["default"].inject.service(),
    dataUri: null,

    videoStream: "",
    useFrontCamera: true,

    constraints: {
      video: {
        width: {
          min: 1280,
          ideal: 1920,
          max: 2560
        },
        height: {
          min: 720,
          ideal: 1080,
          max: 1440
        }
      }
    },

    initializeCamera: function initializeCamera() {
      var cameraType, vStream;
      return regeneratorRuntime.async(function initializeCamera$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            this.stopVideoStream();
            cameraType = this.get("useFrontCamera") ? "user" : "environment";

            this.set("constraints.video.facingMode", cameraType);

            context$1$0.next = 5;
            return regeneratorRuntime.awrap(navigator.mediaDevices.getUserMedia(this.get("constraints")));

          case 5:
            vStream = context$1$0.sent;

            this.set("videoStream", vStream);
            this.set("video.srcObject", this.get("videoStream"));

          case 8:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    stopVideoStream: function stopVideoStream() {
      var videoStream = this.get("videoStream");
      if (videoStream) {
        videoStream.getTracks().forEach(function (track) {
          track.stop();
        });
      }
    },

    displayWebcam: _ember["default"].computed.alias("packageService.openImageOverlay"),

    didRender: function didRender() {
      if (this.get("packageService.openImageOverlay")) {
        var _video = this.element.children[0];
        _video.play();
        this.set("video", _video);
        this.initializeCamera();
      }
    },

    actions: {
      snap: function snap() {
        var canvas = document.getElementById("canvas");
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        canvas.getContext("2d").drawImage(video, 0, 0);
        canvas.toDataURL("image/jpeg");
        this.send("didSnap", canvas.toDataURL("image/jpeg"));
      },

      changeCamera: function changeCamera() {
        this.toggleProperty("useFrontCamera");
        this.initializeCamera();
      },

      didSnap: function didSnap(dataUri) {
        return regeneratorRuntime.async(function didSnap$(context$1$0) {
          var _this = this;

          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              this.set("packageService.openImageOverlay", false);
              this.runTask(function callee$1$0() {
                var signature, image;
                return regeneratorRuntime.async(function callee$1$0$(context$2$0) {
                  while (1) switch (context$2$0.prev = context$2$0.next) {
                    case 0:
                      context$2$0.next = 2;
                      return regeneratorRuntime.awrap(this.get("packageService").generateSignature());

                    case 2:
                      signature = context$2$0.sent;

                      signature.file = dataUri;
                      context$2$0.next = 6;
                      return regeneratorRuntime.awrap(this.get("packageService").uploadToCloudinary(signature));

                    case 6:
                      image = context$2$0.sent;

                      this.get("getImageCallback")(image);
                      this.stopVideoStream();

                    case 9:
                    case "end":
                      return context$2$0.stop();
                  }
                }, null, _this);
              });

            case 2:
            case "end":
              return context$1$0.stop();
          }
        }, null, this);
      },

      closeOverlay: function closeOverlay() {
        this.stopVideoStream();
        this.set("packageService.openImageOverlay", false);
      }
    }
  });
});