define("stock/components/print-label", ["exports", "ember", "stock/mixins/async"], function (exports, _ember, _stockMixinsAsync) {
  exports["default"] = _ember["default"].Component.extend(_stockMixinsAsync["default"], {
    item: null,
    messageBox: _ember["default"].inject.service(),
    printerService: _ember["default"].inject.service(),

    actions: {
      printBarcode: function printBarcode() {
        var _this = this;

        this.runTask(function () {
          _this.get("printerService").printInventoryLabel(_this.get("item")).then(function () {
            var element = _ember["default"].$("#printer_message_" + _this.get("item.id")).clone();
            element.prependTo(".printer_message_block");
            _this.sendAction("closeList");
            _ember["default"].run.debounce(_this, _this.hidePrinterMessage, 500);
          });
        }, _stockMixinsAsync.ERROR_STRATEGIES.MODAL);
      }
    },

    hidePrinterMessage: function hidePrinterMessage() {
      _ember["default"].$(".printer_message_block").fadeOut(3000);
      _ember["default"].run.debounce(this, this.removePrinterMessage, 2500);
    },

    removePrinterMessage: function removePrinterMessage() {
      _ember["default"].$(".printer_message_block").empty();
      _ember["default"].$(".printer_message_block").addClass("visible");
    }
  });
});