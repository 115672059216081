define("stock/components/mentionable-message-textbox", ["exports", "ember", "npm:tributejs", "stock/utils/ajax-promise"], function (exports, _ember, _npmTributejs, _stockUtilsAjaxPromise) {

  var users = null;
  var selectedUsers = [];
  var remoteSearch = function remoteSearch(_ref, authToken, cb) {
    var roles = _ref.roles;
    var messageable_id = _ref.messageable_id;
    var messageable_type = _ref.messageable_type;
    var is_private = _ref.is_private;

    new _stockUtilsAjaxPromise["default"]("/mentionable_users", "GET", authToken, {
      roles: roles.join(),
      messageable_id: messageable_id,
      messageable_type: messageable_type,
      is_private: is_private
    }).then(function (data) {
      var images = data.images;
      users = data.users.map(function (user) {
        return {
          name: user.first_name + " " + user.last_name,
          id: user.id,
          image: images.find(function (img) {
            return img.id === user.image_id;
          })
        };
      });
      users.sort(function (u1, u2) {
        return u1.name.toLowerCase().localeCompare(u2.name.toLowerCase());
      });
      return cb(users);
    });
  };

  exports["default"] = _ember["default"].Component.extend({
    tagName: "p",
    contentEditable: true,
    attributeBindings: ["disabled", "value", "setBody"],
    classNames: "message-bar mentionable",
    disabled: false,

    autoScroll: function autoScroll() {
      window.scrollTo(0, document.body.scrollHeight);
    },

    didDestroyElement: function didDestroyElement() {
      _ember["default"].$("body").css({ "overflow-x": "hidden" });
      users = null;
    },

    valueObserver: (function () {
      _ember["default"].run.once(this, "processValue");
    }).observes("value"),

    processValue: function processValue() {
      // scroll to bottom if message typed and restrict if blank message is sent
      if (!this.get("value")) {
        this.autoScroll();
        this.element.innerText = "";
      }
    },

    didInsertElement: function didInsertElement() {
      var _this, roles, messageable_id, messageable_type, is_private, tribute;

      return regeneratorRuntime.async(function didInsertElement$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            _ember["default"].$("body").css({ "overflow-x": "unset" });
            _this = this;
            roles = this.roles;
            messageable_id = this.messageableId;
            messageable_type = this.messageableType;
            is_private = this.isPrivate;
            tribute = new _npmTributejs["default"]({
              values: function values(text, cb) {
                if (!users) {
                  return remoteSearch({ roles: roles, messageable_id: messageable_id, messageable_type: messageable_type, is_private: is_private }, _this.get("session.authToken"), function (users) {
                    return cb(users);
                  });
                }
                return cb(users);
              },
              menuItemTemplate: function menuItemTemplate(item) {
                if (item.original.image) {
                  var id = item.original.image.cloudinary_id;
                  id = id.substring(id.indexOf("/") + 1);

                  return "<div class='item'><img class='mentionedImage' src=" + $.cloudinary.url(id) + "></img> " + item.original.name + "</div>";
                } else {
                  return "<div class='item'><img class='mentionedImage' src=\"assets/images/user.svg\"></img> " + item.original.name + "</div>";
                }
              },
              selectTemplate: function selectTemplate(item) {
                if (typeof item === "undefined") return null;

                selectedUsers.push(item.original);
                return "<span class='mentioned' contenteditable=\"false\">@" + item.original.name + "</span>";
              },
              selectClass: "highlight",
              lookup: "name",
              fillAttr: "name",
              noMatchTemplate: function noMatchTemplate() {
                return null;
              },
              menuContainer: document.getElementsByClassName(this.get("containerClass"))[0]
            });

            tribute.attach(_ember["default"].$(this.element));

            this.element.addEventListener("input", function () {
              var parsedText = this.innerText;
              selectedUsers.forEach(function (user) {
                parsedText = parsedText.replace(new RegExp("@" + user.name, "g"), "[:" + user.id + "]");
              });

              _this.setMessageContext({
                parsedText: parsedText,
                displayText: this.innerText
              });
            });

            // Handle copy and paste operation for contenteditable
            $(document).on("copy", "[contenteditable]", function (e) {
              e = e.originalEvent;
              var selectedText = window.getSelection();
              var range = selectedText.getRangeAt(0);
              var selectedTextReplacement = range.toString();
              e.clipboardData.setData("text/plain", selectedTextReplacement);
              e.preventDefault();
            });

            $(document).on("paste", "[contenteditable]", function (e) {
              e.preventDefault();

              if (window.clipboardData) {
                var content = window.clipboardData.getData("Text");
                if (window.getSelection) {
                  var selObj = window.getSelection();
                  var selRange = selObj.getRangeAt(0);
                  selRange.deleteContents();
                  selRange.insertNode(document.createTextNode(content));
                }
              } else if (e.originalEvent.clipboardData) {
                content = (e.originalEvent || e).clipboardData.getData("text/plain");
                document.execCommand("insertText", false, content);
              }
            });

            // Adding event listeners for opening and closing of menu
            // https://github.com/zurb/tribute#events
            document.querySelector(".mentionable").addEventListener("tribute-active-true", function (e) {
              _this.setMentionsActive(true);
            });

            document.querySelector(".mentionable").addEventListener("tribute-active-false", function (e) {
              _this.setMentionsActive(false);
            });

          case 13:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    }
  });
});