define('stock/controllers/application', ['exports', 'ember', 'stock/config/environment'], function (exports, _ember, _stockConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend({
    subscription: _ember['default'].inject.service(),
    cordova: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),
    designationService: _ember['default'].inject.service(),
    locationService: _ember['default'].inject.service(),
    packageService: _ember['default'].inject.service(),
    packageTypeService: _ember['default'].inject.service(),
    organisationService: _ember['default'].inject.service(),
    offerService: _ember['default'].inject.service(),
    messagesUtil: _ember['default'].inject.service('messages'),
    app_id: _stockConfigEnvironment['default'].APP.ANDROID_APP_ID,
    ios_app_id: _stockConfigEnvironment['default'].APP.APPLE_APP_ID,
    appTitle: _stockConfigEnvironment['default'].APP.TITLE,
    bannerImage: _stockConfigEnvironment['default'].APP.BANNER_IMAGE,
    bannerReopenDays: _stockConfigEnvironment['default'].BANNER_REOPEN_DAYS,
    isMobileApp: _stockConfigEnvironment['default'].cordova.enabled,
    notifications: _ember['default'].inject.controller(),

    initSubscription: _ember['default'].on('init', function () {
      if (_stockConfigEnvironment['default'].disableNotifications) return;

      this.get('subscription').wire();
      if (this.get('isMobileApp') && cordova.platformId === 'android') {
        // jshint ignore:line
        this.redirectToItem();
      }
    }),

    redirectToItem: function redirectToItem() {
      var _this = this;

      universalLinks && universalLinks.subscribe('redirectToItem', function (eventData) {
        // jshint ignore:line
        _this.transitionToRoute(eventData.path);
      });
    },

    actions: {
      logMeOut: function logMeOut() {
        var _this2 = this;

        this.session.clear(); // this should be first since it updates isLoggedIn status
        this.get('subscription').unwire();
        this.get('notifications').send('unloadNotifications');
        this.get('session').unloadSessionData();
        this.session.clearCache();
        _stockConfigEnvironment['default'].APP.USER_DATA_TYPES.map(function (key) {
          return _this2.get('store').unloadAll(key);
        });
        this.transitionToRoute('login');
      }
    }
  });
});