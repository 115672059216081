define("stock/mixins/navigation_aware", ["exports", "ember", "lodash"], function (exports, _ember, _lodash) {

  /**
   * Adds navigation awareness to component/services
   *
   * It provides:
   *    - A `currentRoute` property
   *    - Support for an `onNavigation` callback
   */
  exports["default"] = _ember["default"].Mixin.create({
    currentRoute: _ember["default"].computed.alias("router.currentRouteName"),

    routeObserver: _ember["default"].observer("currentRoute", function () {
      var handler = this.getWithDefault("onNavigation", _lodash["default"].noop);
      handler.call(this, this.get("currentRoute"));
    })
  });
});