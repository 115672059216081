define("stock/routes/users/contact_details", ["exports", "stock/routes/authorize"], function (exports, _stockRoutesAuthorize) {
  exports["default"] = _stockRoutesAuthorize["default"].extend({
    model: function model(params) {
      return Ember.RSVP.hash({
        district: this.store.query("district", {}),
        user: this.loadIfAbsent("user", params.user_id)
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var phoneNumber = model.user.get("mobile") && model.user.get("mobile").slice(4);
      controller.set("mobileNumber", phoneNumber);
      controller.set("mobileInputError", false);
      controller.set("mobileValidationError", false);
    }
  });
});