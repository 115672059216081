define("stock/controllers/items/edit_images", ["exports", "ember", "ember-i18n", "stock/config/environment", "stock/utils/ajax-promise"], function (exports, _ember, _emberI18n, _stockConfigEnvironment, _stockUtilsAjaxPromise) {
  var getOwner = _ember["default"].getOwner;
  exports["default"] = _ember["default"].Controller.extend({
    item: _ember["default"].computed.alias("model"),
    session: _ember["default"].inject.service(),
    store: _ember["default"].inject.service(),
    packageService: _ember["default"].inject.service(),
    messageBox: _ember["default"].inject.service(),
    i18n: _ember["default"].inject.service(),
    cordova: _ember["default"].inject.service(),
    isMobileApp: _stockConfigEnvironment["default"].cordova.enabled,

    itemId: null,
    noImage: _ember["default"].computed.empty("item.images"),
    previewImage: null,
    addPhotoLabel: (0, _emberI18n.translationMacro)("edit_images.add_photo"),
    isReady: false,
    isExpanded: false,
    backBtnVisible: true,
    loadingPercentage: (0, _emberI18n.translationMacro)("edit_images.image_uploading"),
    uploadedFileDate: null,

    initActionSheet: function initActionSheet(onSuccess) {
      return window.plugins.actionsheet.show({
        buttonLabels: [this.locale("edit_images.upload").string, this.locale("edit_images.camera").string, this.locale("edit_images.cancel").string]
      }, function (buttonIndex) {
        if (buttonIndex === 1) {
          navigator.camera.getPicture(onSuccess, null, {
            quality: 40,
            destinationType: navigator.camera.DestinationType.DATA_URL,
            sourceType: navigator.camera.PictureSourceType.PHOTOLIBRARY
          });
        }
        if (buttonIndex === 2) {
          navigator.camera.getPicture(onSuccess, null, {
            correctOrientation: true,
            quality: 40,
            destinationType: navigator.camera.DestinationType.DATA_URL,
            sourceType: navigator.camera.PictureSourceType.CAMERA
          });
        }
        if (buttonIndex === 3) {
          window.plugins.actionsheet.hide();
        }
      });
    },

    previewMatchesFavourite: _ember["default"].computed("previewImage", "favouriteImage", function () {
      return this.get("previewImage") === this.get("favouriteImage");
    }),

    images: _ember["default"].computed("item.images.[]", function () {
      //The reason for sorting is because by default it's ordered by favourite
      //then id order. If another image is made favourite then deleted the first image
      //by id order is made favourite which can be second image in list which seems random.

      //Sort by id ascending except place new images id = 0 at end
      return (this.get("item.images") || _ember["default"].A()).toArray().sort(function (a, b) {
        if (a && b) {
          a = parseInt(a.get("id"), 10);
          b = parseInt(b.get("id"), 10);
          if (a === 0) {
            return 1;
          }
          if (b === 0) {
            return -1;
          }
          return a - b;
        }
      });
    }),

    favouriteImage: _ember["default"].computed("item.images.@each.favourite", function () {
      return this.get("images").filterBy("favourite").get("firstObject");
    }),

    initPreviewImage: _ember["default"].on("init", _ember["default"].observer("model", "model.images.[]", function () {
      var image = this.get("item.favouriteImage") || this.get("item.images.firstObject");
      if (image) {
        this.send("setPreview", image);
      }
    })),

    isImageOnLongTermStorage: _ember["default"].computed("previewImage", function () {
      return this.get("previewImage.isImageOnLongTermStorage");
    }),

    //css related
    previewImageBgCss: _ember["default"].computed("previewImage", "isExpanded", "previewImage.angle", {
      get: function get() {
        var _this = this;

        var css = this.get("instructionBoxCss");
        if (!this.get("previewImage")) {
          return css;
        }

        var imgTag = new Image();
        imgTag.onload = function () {
          var newCSS = new _ember["default"].String.htmlSafe(css + "background-image:url(" + _this.get("previewImage.imageUrl") + ");" + "background-size: " + (_this.get("isExpanded") ? "contain" : "cover") + ";");
          _this.set("previewImageBgCss", newCSS);
        };
        imgTag.src = this.get("previewImage.imageUrl");

        return new _ember["default"].String.htmlSafe(css + "background-image:url('assets/images/image_loading.gif');" + "background-size: 'inherit';");
      },

      set: function set(key, value) {
        return value;
      }
    }),

    instructionBoxCss: _ember["default"].computed("previewImage", "isExpanded", function () {
      var height = _ember["default"].$(window).height() * 0.6;
      return new _ember["default"].String.htmlSafe("min-height:" + height + "px;");
    }),

    thumbImageCss: _ember["default"].computed(function () {
      var imgWidth = Math.min(120, _ember["default"].$(window).width() / 4 - 14);
      return new _ember["default"].String.htmlSafe("width:" + imgWidth + "px; height:" + imgWidth + "px;");
    }),

    locale: function locale(str) {
      return this.get("i18n").t(str);
    },

    removeImage: function removeImage(controller, item) {
      var _this2 = this;

      var img = item.get("images.firstObject");
      var loadingView = getOwner(controller).lookup("component:loading").append();
      img.deleteRecord();
      img.save().then(function (i) {
        i.unloadRecord();
        _this2.reloadItem();
        controller.transitionToRoute("items.edit_images", item);
      })["finally"](function () {
        return loadingView.destroy();
      });
    },

    reloadItem: function reloadItem() {
      var store = this.get("store");
      store.pushPayload(store.findRecord("item", this.get("item.id")));
    },

    confirmRemoveLastImage: function confirmRemoveLastImage() {
      var _this3 = this;

      var item = this.get("item");
      this.get("messageBox").custom(this.locale("edit_images.last_image_with_item"), this.locale("edit_images.remove_image"), function () {
        return _this3.removeImage(_this3, item);
      }, "Cancel");
    },

    actions: {
      next: function next() {
        this.transitionToRoute("items.detail", this.get("item.id"));
      },

      setPreview: function setPreview(image) {
        this.get("item.images").setEach("selected", false);
        image.set("selected", true);
        this.set("previewImage", image);
      },

      setFavourite: function setFavourite() {
        var _this4 = this;

        this.get("item.images").setEach("favourite", false);
        var currentImage = this.get("previewImage");
        currentImage.set("favourite", true);

        new _stockUtilsAjaxPromise["default"]("/images/" + currentImage.get("id"), "PUT", this.get("session.authToken"), { image: { favourite: true } }).then(function (data) {
          return _this4.get("store").pushPayload(data);
        })["catch"](function (error) {
          _this4.get("item.images").forEach(function (img) {
            return img.rollbackAttributes();
          });
          throw error;
        });
      },

      deleteImage: function deleteImage() {
        var _this5 = this;

        if (this.get("item.images.length") === 1) {
          this.confirmRemoveLastImage();
          return;
        } else {
          this.get("messageBox").confirm(this.get("i18n").t("edit_images.delete_confirm"), function () {
            var loadingView = getOwner(_this5).lookup("component:loading").append();
            var img = _this5.get("previewImage");
            img.deleteRecord();
            img.save().then(function (i) {
              i.unloadRecord();
              _this5.reloadItem();
              _this5.initPreviewImage();
              if (!_this5.get("favouriteImage")) {
                _this5.send("setFavourite");
              }
            })["catch"](function (error) {
              img.rollbackAttributes();
              throw error;
            })["finally"](function () {
              return loadingView.destroy();
            });
          });
        }
      },

      expandImage: function expandImage() {
        var value = this.get("isExpanded");
        this.set("isExpanded", !value);
      },

      //file upload
      triggerUpload: function triggerUpload() {
        // For Cordova application
        if (_stockConfigEnvironment["default"].cordova.enabled) {
          var onSuccess = (function () {
            return function (path) {
              console.log(path);
              var dataURL = "data:image/jpg;base64," + path;

              _ember["default"].$("input[type='file']").fileupload("option", "formData").file = dataURL;
              _ember["default"].$("input[type='file']").fileupload("add", {
                files: [dataURL]
              });
            };
          })(this);

          this.initActionSheet(onSuccess);
        } else {
          // For web application
          if (navigator.userAgent.match(/iemobile/i)) {
            //don't know why but on windows phone need to click twice in quick succession
            //for dialog to appear
            _ember["default"].$("#photo-list input[type='file']").click().click();
          } else {
            _ember["default"].$("#photo-list input[type='file']").trigger("click");
          }
        }
      },

      uploadReady: function uploadReady() {
        this.set("isReady", true);
      },

      uploadStart: function uploadStart(e, data) {
        this.set("uploadedFileDate", data);
        _ember["default"].$(".loading-image-indicator").show();
      },

      cancelUpload: function cancelUpload() {
        if (this.get("uploadedFileDate")) {
          this.get("uploadedFileDate").abort();
        }
      },

      uploadProgress: function uploadProgress(e, data) {
        e.target.disabled = true; // disable image-selection
        var progress = parseInt(data.loaded / data.total * 100, 10) || 0;
        this.set("addPhotoLabel", progress + "%");
        this.set("loadingPercentage", this.get("i18n").t("edit_images.image_uploading") + progress + "%");
      },

      uploadComplete: function uploadComplete(e) {
        e.target.disabled = false; // enable image-selection
        this.set("uploadedFileDate", null);
        _ember["default"].$(".loading-image-indicator.hide_image_loading").hide();
        this.set("addPhotoLabel", this.get("i18n").t("edit_images.add_photo"));
        this.set("loadingPercentage", this.get("i18n").t("edit_images.image_uploading"));
      },

      uploadSuccess: function uploadSuccess(e, data) {
        var _this6 = this;

        var identifier = data.result.version + "/" + data.result.public_id + "." + data.result.format;
        var item = this.get("item");
        var favourite = item.get("images.length") === 0;
        var imageAttributes = {
          cloudinary_id: identifier,
          imageable_id: this.get("item.id"),
          imageable_type: "Package",
          favourite: favourite
        };

        new _stockUtilsAjaxPromise["default"]("/images", "POST", this.get("session.authToken"), {
          image: imageAttributes
        }).then(function (data) {
          _this6.get("store").pushPayload(data);
          _this6.send("setPreview", _this6.get("store").peekRecord("image", data.image.id));
        });
      },

      openImageOverlay: function openImageOverlay() {
        this.set("packageService.openImageOverlay", true);
      },

      saveImageURI: function saveImageURI(image) {
        var _this7 = this;

        image.set("imageableId", this.get("item.id"));
        image.set("imageableType", "Package");
        image.save().then(function (data) {
          _this7.send("setPreview", _this7.get("store").peekRecord("image", data.id));
        });
      },

      rotateImageRight: function rotateImageRight() {
        var angle = this.get("previewImage.angle");
        angle = (angle + 90) % 360;
        this.send("rotateImage", angle);
      },

      rotateImageLeft: function rotateImageLeft() {
        var angle = this.get("previewImage.angle");
        angle = (angle ? angle - 90 : 270) % 360;
        this.send("rotateImage", angle);
      },

      rotateImage: function rotateImage(angle) {
        var image = this.get("previewImage");
        image.set("angle", angle);
        _ember["default"].run.debounce(this, this.saveImageRotation, image, 400);
      }
    },

    saveImageRotation: function saveImageRotation(image) {
      var _this8 = this;

      new _stockUtilsAjaxPromise["default"]("/images/" + image.get("id"), "PUT", this.get("session.authToken"), { image: { angle: image.get("angle") } }).then(function (data) {
        return _this8.get("store").pushPayload(data);
      });
    }
  });
});