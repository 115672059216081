define("stock/routes/orders/detail", ["exports", "stock/routes/authorize", "ember"], function (exports, _stockRoutesAuthorize, _ember) {
  exports["default"] = _stockRoutesAuthorize["default"].extend({
    designationService: _ember["default"].inject.service(),
    currentRouteName: null,

    model: function model(_ref) {
      var order_id = _ref.order_id;

      return this.get("store").findRecord("designation", order_id);
    },

    afterModel: function afterModel() {
      return regeneratorRuntime.async(function afterModel$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return regeneratorRuntime.awrap(this.store.query("cancellation_reason", { "for": "order" }));

          case 2:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    }
  });
});