define("stock/components/request-description-textarea", ["exports", "ember", "stock/components/gc-textarea", "stock/utils/ajax-promise"], function (exports, _ember, _stockComponentsGcTextarea, _stockUtilsAjaxPromise) {
  var getOwner = _ember["default"].getOwner;
  exports["default"] = _stockComponentsGcTextarea["default"].extend({
    previousValue: "",
    store: _ember["default"].inject.service(),
    request: null,

    focusIn: function focusIn() {
      _ember["default"].$(this.element).addClass("item-description-textarea");
    },
    focusOut: function focusOut() {
      var _this = this;

      var request = this.get("request");
      var url = "/goodcity_requests/" + request.get("id");
      var key = this.get("name");
      var value = this.attrs.value.value || "";
      var requestParams = {};
      requestParams[key] = (this.get("value") || "").trim();

      if (requestParams[key].toString() !== this.get("previousValue").toString().trim()) {
        var loadingView = getOwner(this).lookup("component:loading").append();
        new _stockUtilsAjaxPromise["default"](url, "PUT", this.get("session.authToken"), {
          goodcity_request: requestParams
        }).then(function (data) {
          _this.get("store").pushPayload(data);
        })["finally"](function () {
          loadingView.destroy();
        });
      }
      this.element.value = value.trim();
      _ember["default"].$(this.element).removeClass("item-description-textarea");
    }
  });
});