define("stock/models/goodcity_request", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (exports, _emberDataModel, _emberDataAttr, _emberDataRelationships) {
  exports["default"] = _emberDataModel["default"].extend({
    quantity: (0, _emberDataAttr["default"])("string"),
    description: (0, _emberDataAttr["default"])("string"),
    itemSpecifics: (0, _emberDataAttr["default"])("string"),
    designationId: (0, _emberDataAttr["default"])("string"),
    codeId: (0, _emberDataAttr["default"])("string"),
    code: (0, _emberDataRelationships.belongsTo)("code", { async: false }),
    designation: (0, _emberDataRelationships.belongsTo)("designation", { async: false })
  });
});