define("stock/app", ["exports", "ember", "stock/resolver", "ember-load-initializers", "stock/config/environment"], function (exports, _ember, _stockResolver, _emberLoadInitializers, _stockConfigEnvironment) {

  var App = undefined;

  App = _ember["default"].Application.extend({
    modulePrefix: _stockConfigEnvironment["default"].modulePrefix,
    podModulePrefix: _stockConfigEnvironment["default"].podModulePrefix,
    Resolver: _stockResolver["default"]
  });

  (0, _emberLoadInitializers["default"])(App, _stockConfigEnvironment["default"].modulePrefix);

  exports["default"] = App;
});