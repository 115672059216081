define('stock/models/order_transport', ['exports', 'ember-data/model', 'ember-data/attr', 'ember', 'ember-data/relationships'], function (exports, _emberDataModel, _emberDataAttr, _ember, _emberDataRelationships) {
  exports['default'] = _emberDataModel['default'].extend({
    i18n: _ember['default'].inject.service(),
    gogovanOrderId: (0, _emberDataAttr['default'])('number'),
    gogovanTransportId: (0, _emberDataAttr['default'])('number'),
    timeslot: (0, _emberDataAttr['default'])('string'),
    transportType: (0, _emberDataAttr['default'])('string'),
    vehicleType: (0, _emberDataAttr['default'])('string'),
    scheduledAt: (0, _emberDataAttr['default'])('string'),
    orderId: (0, _emberDataAttr['default'])('string'),
    contact: (0, _emberDataRelationships.belongsTo)('contact', { async: false }),
    designation: (0, _emberDataRelationships.belongsTo)('designation', { async: false }),
    needEnglish: (0, _emberDataAttr['default'])("boolean"),
    needCart: (0, _emberDataAttr['default'])("boolean"),
    needCarry: (0, _emberDataAttr['default'])("boolean"),
    gogovanTransport: (0, _emberDataRelationships.belongsTo)('gogovan_transport', { async: false }),

    scheduledDate: _ember['default'].computed('scheduledAt', function () {
      return moment(this.get('scheduledAt')).format("D MMMM YYYY");
    }),

    type: _ember['default'].computed('transportType', function () {
      var type = this.get('transportType');
      if (type === "ggv") {
        return type.toUpperCase();
      } else if (type === "self") {
        return type.charAt(0).toUpperCase() + type.slice(1);
      } else {
        return "";
      }
    })
  });
});