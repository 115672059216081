define("stock/routes/order_filters", ["exports", "stock/routes/authorize"], function (exports, _stockRoutesAuthorize) {
  exports["default"] = _stockRoutesAuthorize["default"].extend({
    deactivate: function deactivate() {
      var controller = this.controllerFor("orderFilters");
      var queryParams = controller.get("queryParams");
      queryParams.forEach(function (queryParam) {
        return controller.set(queryParam, null);
      });
    }
  });
});