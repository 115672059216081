define("stock/services/location-service", ["exports", "ember", "stock/services/api-base-service", "stock/mixins/navigation_aware", "lodash", "stock/utils/helpers"], function (exports, _ember, _stockServicesApiBaseService, _stockMixinsNavigation_aware, _lodash, _stockUtilsHelpers) {

  /**
   * Location Service
   *
   * @module Services/LocationService
   * @augments Services/ApiBaseService
   * @description Find and pick locations
   *
   */
  exports["default"] = _stockServicesApiBaseService["default"].extend(_stockMixinsNavigation_aware["default"], {
    store: _ember["default"].inject.service(),
    i18n: _ember["default"].inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.set("openLocationSearch", false);
    },

    onNavigation: function onNavigation() {
      this.getWithDefault("onLocationSelected", _lodash["default"].noop)(null);
    },

    /**
     * Moves a package to the specified location
     *
     * @param {Package} pkg the package to move
     * @param {object} opts the move properties
     * @param {Location|string} opts.from the source location or its id
     * @param {Location|string} opts.to the destination location or its id
     * @param {quantity} opts.to the quantity to move
     * @returns {Promise<Model>}
     */
    movePackage: function movePackage(pkg) {
      var opts = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];
      var from, to, quantity, payload;
      return regeneratorRuntime.async(function movePackage$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            from = opts.from;
            to = opts.to;
            quantity = opts.quantity;
            context$1$0.next = 5;
            return regeneratorRuntime.awrap(this.PUT("/packages/" + pkg.get("id") + "/move", {
              quantity: quantity,
              from: (0, _stockUtilsHelpers.toID)(from),
              to: (0, _stockUtilsHelpers.toID)(to)
            }));

          case 5:
            payload = context$1$0.sent;

            this.get("store").pushPayload(payload);

            return context$1$0.abrupt("return", this.get("store").peekRecord("item", pkg.get("id")));

          case 8:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    /**
     * Triggers the location selection popup, and resolves the promise
     * once a location has been selected.
     *
     * null is returned if the user closes the UI
     *
     * @param {object} opts searcg iotuibs
     * @returns {Promise<Model>}
     */
    userPickLocation: function userPickLocation() {
      var _this = this;

      var opts = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

      var deferred = _ember["default"].RSVP.defer();

      _ember["default"].run(function () {
        _this.set("locationSearchOptions", opts);
        _this.set("openLocationSearch", true);
        _this.set("onLocationSelected", function (location) {
          _this.set("onLocationSelected", _lodash["default"].noop);
          _this.set("openLocationSearch", false);
          deferred.resolve(location || null);
        });
      });

      return deferred.promise;
    }
  });
});