define("stock/controllers/manage_account", ["exports", "ember", "stock/mixins/async", "stock/utils/ajax-promise", "stock/mixins/grades_option"], function (exports, _ember, _stockMixinsAsync, _stockUtilsAjaxPromise, _stockMixinsGrades_option) {
  exports["default"] = _ember["default"].Controller.extend(_stockMixinsAsync["default"], _stockMixinsGrades_option["default"], {
    user: _ember["default"].computed.alias("model.user"),
    userService: _ember["default"].inject.service(),
    printerService: _ember["default"].inject.service(),
    selectedAdminPrinterId: "",
    selectedStockPrinterId: "",
    showDeleteAccountConfirmationPopup: false,
    application: _ember["default"].inject.controller(),

    getUserMobile: function getUserMobile() {
      return this.get("userService").getUserMobileWithCode(this.get("user.mobile"));
    },

    printers: _ember["default"].computed(function () {
      return this.get("printerService").allAvailablePrinters();
    }),

    selectedAdminPrinterDisplay: _ember["default"].computed("model.user.id", "selectedAdminPrinterId", function () {
      var printerId = this.get("selectedAdminPrinterId");
      return this.get("userService").getPrinterForUser(this.get("user"), printerId, "admin");
    }),

    selectedStockPrinterDisplay: _ember["default"].computed("model.user.id", "selectedStockPrinterId", function () {
      var printerId = this.get("selectedStockPrinterId");
      return this.get("userService").getPrinterForUser(this.get("user"), printerId, "stock");
    }),

    hideValidationErrors: function hideValidationErrors(target) {
      this.set(target.id + "InputError", false);
      this.set(target.id + "ValidationError", false);
    },

    actions: {
      onDeleteAccount: function onDeleteAccount() {
        this.set("showDeleteAccountConfirmationPopup", true);
      },

      cancelAccountDeletion: function cancelAccountDeletion() {
        this.set("showDeleteAccountConfirmationPopup", false);
      },

      confirmDeleteAccount: function confirmDeleteAccount() {
        var _this = this;

        this.runTask(function callee$1$0() {
          var userId, data;
          return regeneratorRuntime.async(function callee$1$0$(context$2$0) {
            while (1) switch (context$2$0.prev = context$2$0.next) {
              case 0:
                userId = this.get("user.id");
                context$2$0.next = 3;
                return regeneratorRuntime.awrap(new _stockUtilsAjaxPromise["default"]("/users/" + userId, "DELETE", this.get("session.authToken")));

              case 3:
                data = context$2$0.sent;

                this.get("application").send("logMeOut");
                this.set("showDeleteAccountConfirmationPopup", false);

              case 6:
              case "end":
                return context$2$0.stop();
            }
          }, null, _this);
        }, _stockMixinsAsync.ERROR_STRATEGIES.MODAL);
      },

      updateUserDetails: function updateUserDetails(e) {
        var _this2 = this;

        var value = e.target.value.trim();
        var isValid = undefined;

        if (Object.keys(this.get("user").changedAttributes()).length === 0) {
          this.hideValidationErrors(e.target);
          return;
        }

        switch (e.target.id) {
          case "firstName":
            isValid = Boolean(value);
            break;
          case "lastName":
            isValid = Boolean(value);
            break;
          case "email":
            isValid = this.get("userService").checkUserEmailValidity(value, this.get("mobileNumber"));
            break;
        }

        if (isValid) {
          this.runTask(function callee$1$0() {
            var user;
            return regeneratorRuntime.async(function callee$1$0$(context$2$0) {
              while (1) switch (context$2$0.prev = context$2$0.next) {
                case 0:
                  user = this.get("user");

                  user.set(e.target.id, value);

                  context$2$0.prev = 2;
                  context$2$0.next = 5;
                  return regeneratorRuntime.awrap(user.save());

                case 5:
                  context$2$0.next = 11;
                  break;

                case 7:
                  context$2$0.prev = 7;
                  context$2$0.t0 = context$2$0["catch"](2);

                  this.get("user").rollbackAttributes();
                  throw context$2$0.t0;

                case 11:
                case "end":
                  return context$2$0.stop();
              }
            }, null, _this2, [[2, 7]]);
          }, _stockMixinsAsync.ERROR_STRATEGIES.MODAL);
          this.hideValidationErrors(e.target);
        } else {
          this.get("user").rollbackAttributes();
          _ember["default"].$("#" + e.target.id).focus();

          e.target.value ? this.set(e.target.id + "ValidationError", true) : this.set(e.target.id + "InputError", true);
        }
      },

      changeAdminPrinter: function changeAdminPrinter(value) {
        var printerId = value.id;
        this.set("selectedAdminPrinterId", printerId);

        printerId = this.get("selectedAdminPrinterId");
        this.get("printerService").addDefaultPrinter(printerId, this.get("user.id"), "admin");
      },

      changeStockPrinter: function changeStockPrinter(value) {
        var printerId = value.id;
        this.set("selectedStockPrinterId", printerId);

        printerId = this.get("selectedStockPrinterId");
        this.get("printerService").addDefaultPrinter(printerId, this.get("user.id"), "stock");
      }
    }
  });
});