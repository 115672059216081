define("stock/components/donation-field", ["exports", "ember", "stock/components/barcode-scanner"], function (exports, _ember, _stockComponentsBarcodeScanner) {
  exports["default"] = _stockComponentsBarcodeScanner["default"].extend({
    messageBox: _ember["default"].inject.service(),
    i18n: _ember["default"].inject.service(),

    checkPermissionAndScan: function checkPermissionAndScan() {
      var _this = this;
      var permissions = window.cordova.plugins.permissions;
      var permissionError = function permissionError() {
        var error_message = _this.get("i18n").t("camera_scan.permission_error");
        _this.get("messageBox").alert(error_message);
      };
      var permissionSuccess = function permissionSuccess(status) {
        //after requesting check for permission then, permit to scan
        if (status.hasPermission) {
          _this.scan();
        } else {
          permissionError();
        }
      };
      permissions.hasPermission(permissions.CAMERA, function (status) {
        //check permission here
        if (status.hasPermission) {
          _this.scan();
        } else {
          //request permission here
          permissions.requestPermission(permissions.CAMERA, permissionSuccess, permissionError);
        }
      });
    },

    scan: function scan() {
      var _this2 = this;

      var item = this.get('item');
      var onSuccess = function onSuccess(res) {
        if (!res.cancelled) {
          item.set('caseNumber', res.text);
        }
      };

      var onError = function onError(error) {
        return _this2.get("messageBox").alert("Scanning failed: " + error);
      };
      var options = { "formats": "QR_CODE, CODE_128", "orientation": "portrait" };

      window.cordova.plugins.barcodeScanner.scan(onSuccess, onError, options);
    },

    actions: {
      scanBarcode: function scanBarcode() {
        this.checkPermissionAndScan();
      }
    }
  });
});