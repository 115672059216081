define("stock/controllers/items/detail", ["exports", "ember", "stock/config/environment", "stock/controllers/goodcity_controller", "ember-inflector", "stock/constants/additional-fields", "stock/mixins/search_option", "stock/mixins/fetch_package_detail", "stock/mixins/grades_option", "stock/mixins/move_actions", "stock/mixins/storage-type", "stock/mixins/async", "stock/constants/orders-packages-sorting-options", "stock/mixins/item_actions", "lodash", "stock/mixins/search_resource"], function (exports, _ember, _stockConfigEnvironment, _stockControllersGoodcity_controller, _emberInflector, _stockConstantsAdditionalFields, _stockMixinsSearch_option, _stockMixinsFetch_package_detail, _stockMixinsGrades_option, _stockMixinsMove_actions, _stockMixinsStorageType, _stockMixinsAsync, _stockConstantsOrdersPackagesSortingOptions, _stockMixinsItem_actions, _lodash, _stockMixinsSearch_resource) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  exports["default"] = _stockControllersGoodcity_controller["default"].extend(_stockMixinsSearch_option["default"], _stockMixinsFetch_package_detail["default"], _stockMixinsGrades_option["default"], _stockMixinsMove_actions["default"], _stockMixinsStorageType["default"], _stockMixinsAsync["default"], _stockMixinsItem_actions["default"], _stockMixinsSearch_resource["default"], {
    enableFooterActions: true,
    isMobileApp: _stockConfigEnvironment["default"].cordova.enabled,
    backLinkPath: "",
    searchText: null,
    previousValue: "",
    openAddItemOverlay: false,
    addableItem: null,
    subformDataObject: null,
    item: _ember["default"].computed.alias("model"),
    queryParams: ["showDispatchOverlay"],
    showDispatchOverlay: false,
    autoDisplayOverlay: false,
    subformDetailService: _ember["default"].inject.service(),
    application: _ember["default"].inject.controller(),
    messageBox: _ember["default"].inject.service(),
    setDropdownOption: _ember["default"].inject.service(),
    designationService: _ember["default"].inject.service(),
    offerService: _ember["default"].inject.service(),
    packageTypeService: _ember["default"].inject.service(),
    packageService: _ember["default"].inject.service(),
    settings: _ember["default"].inject.service(),
    locationService: _ember["default"].inject.service(),
    settings: _ember["default"].inject.service(),
    session: _ember["default"].inject.service(),
    utilityMethods: _ember["default"].inject.service(),
    displayScanner: false,
    callOrderObserver: false,
    hideDetailsLink: true,
    displayItemOptions: false,
    valuationIsFocused: false,
    selectedDescriptionLanguage: "en",
    fields: _stockConstantsAdditionalFields["default"],
    fixedDropdownArr: ["frequencyId", "voltageId", "compTestStatusId", "testStatusId"],
    currentRoute: _ember["default"].computed.alias("application.currentPath"),
    pkg: _ember["default"].computed.alias("model"),
    allowItemActions: _ember["default"].computed.alias("settings.allowItemActions"),

    // Filter fields
    sortingColumn: _lodash["default"].cloneDeep(_stockConstantsOrdersPackagesSortingOptions.ORDERS_PACKAGES_SORTING_OPTIONS)[0],
    dropDownItems: _lodash["default"].cloneDeep(_stockConstantsOrdersPackagesSortingOptions.ORDERS_PACKAGES_SORTING_OPTIONS),
    states: _lodash["default"].cloneDeep(_stockConstantsOrdersPackagesSortingOptions.ORDERS_PACKAGES_STATES),

    onStatesChange: _ember["default"].observer("states.@each.enabled", function () {
      this.reloadResults();
    }),

    filteredStates: _ember["default"].computed("states.@each.enabled", function () {
      return _lodash["default"].filter(this.get("states"), ["enabled", true]).map(function (_ref) {
        var state = _ref.state;
        return state;
      });
    }),

    showPieces: _ember["default"].computed("item.code.allowPieces", "isBoxOrPallet", function () {
      return this.get("item.code.allowPieces") && !this.get("isBoxOrPallet");
    }),

    sortActionsBy: ["id:desc"],
    sortedItemActions: _ember["default"].computed.sort("model.itemActions", "sortActionsBy"),

    isItemDetailPresent: function isItemDetailPresent() {
      return !!this.get("item.detail.length");
    },

    packageSetName: _ember["default"].computed("model.code", "model.isPartOfSet", function () {
      return this.get("model.packageSet.code.name");
    }),

    showSetList: _ember["default"].computed("_showSetList", "model.isPartOfSet", {
      get: function get() {
        return this.get("_showSetList") && this.get("model.isPartOfSet");
      },
      set: function set(k, value) {
        this.set("_showSetList", value);
        return value;
      }
    }),

    disableBoxPalletItemAddition: _ember["default"].computed("model", "model.onHandQuantity", function () {
      return this.get("settings.disableBoxPalletItemAddition") || !this.get("model.onHandQuantity");
    }),

    displayFields: _ember["default"].computed("model.code", function () {
      var subform = this.get("model.code.subform");
      return this.returnDisplayFields(subform);
    }),

    selectedCountry: _ember["default"].computed("item.detail", function () {
      var country = this.get("item.detail.country");
      if (country) {
        return {
          id: country.id,
          nameEn: country.get("nameEn")
        };
      }
    }).volatile(),

    watchModel: _ember["default"].observer("model.id", "active", function () {
      var _this = this;

      // Temporary fix
      // The image-zoom carousel sometimes has issues handling switches between two of the same page.
      // Since the controller instance is shared between pages, we turn off the carousel on change
      // and re-enable it once the new model has loaded
      this.set("showImages", false);
      if (this.get("active")) {
        _ember["default"].run.scheduleOnce("afterRender", function () {
          _this.set("showImages", _this.get("active"));
        });
      }
    }),

    returnSelectedValues: function returnSelectedValues(selectedValues) {
      var _this2 = this;

      var dataObj = _extends({}, this.get("subformDataObject"));
      Object.keys(selectedValues).map(function (data, index) {
        if (_this2.get("fixedDropdownArr").indexOf(data) >= 0) {
          var subformColumn = "" + data.substring(0, data.length - 2);
          dataObj[subformColumn] = {
            id: _this2.get("item.detail." + data),
            tag: _this2.get("item.detail." + subformColumn + ".labelEn")
          };
        } else {
          dataObj[data] = {
            id: index + 1,
            tag: selectedValues[data]
          };
        }
      });
      return _extends({}, dataObj);
    },

    selectedValuesDisplay: _ember["default"].computed("item.detail", "subformDataObject", {
      get: function get(key) {
        if (!this.get("showAdditionalFields")) {
          return false;
        }
        var selectedValues = this.get("item.detail.data");
        var returnData = this.returnSelectedValues(selectedValues);
        this.set("subformDataObject", _extends({}, returnData));
        return this.get("subformDataObject");
      },
      set: function set(key, value) {
        this.set("subformDataObject", null);
        return value;
      }
    }),

    allowPublish: _ember["default"].computed("model.isSingletonItem", "model.availableQuantity", function () {
      var qty = this.get("model.availableQuantity");
      if (this.get("settings.onlyPublishSingletons")) {
        return qty === 1;
      }
      return qty > 0;
    }),

    showAdditionalFields: _ember["default"].computed("model.code", function () {
      return !!this.get("item.detail.data") && this.get("subformDetailService").isSubformAvailable(this.get("model.code.subform"));
    }),

    tabName: _ember["default"].computed("currentRoute", function () {
      return this.get("currentRoute").split(".").get("lastObject");
    }),

    selectInfoTab: _ember["default"].computed("tabName", function () {
      return ["storage_detail", "storage_content", "info"].indexOf(this.get("tabName")) > -1;
    }),

    listTabSelected: _ember["default"].computed("tabName", function () {
      return ["storage_content", "info"].indexOf(this.get("tabName")) > -1;
    }),

    isBoxOrPallet: _ember["default"].computed("item", function () {
      return ["Box", "Pallet"].indexOf(this.get("item.storageTypeName")) > -1;
    }),

    //Added to avoid gain in box or pallet if the quantity is present in dispatched , designated or available quantity
    isGainInvalidInBoxOrPallet: _ember["default"].computed("model.onHandQuantity", "model.dispatchedQuantity", "isBoxOrPallet", function () {
      return this.get("isBoxOrPallet") && (!!this.get("model.onHandQuantity") || !!this.get("model.dispatchedQuantity"));
    }),

    conditions: _ember["default"].computed(function () {
      return this.get("store").peekAll("donor_condition");
    }),

    itemMarkedMissing: _ember["default"].observer("item.inventoryNumber", "item.state", function () {
      var _this3 = this;

      if ((!this.get("item.inventoryNumber") || this.get("item.state") === "missing") && this.get("target").currentPath.indexOf("items.detail") > -1) {
        this.get("messageBox").alert(this.get("i18n").t("item_details.not_inventorized_or_missing"), function () {
          _this3.transitionToRoute("items.index", {
            queryParams: { searchInput: "" }
          });
        });
      }
    }),

    performDispatch: _ember["default"].observer("showDispatchOverlay", function () {
      _ember["default"].run.debounce(this, this.updateAutoDisplayOverlay, 100);
    }),

    updateAutoDisplayOverlay: function updateAutoDisplayOverlay() {
      if (this.get("showDispatchOverlay")) {
        this.toggleProperty("autoDisplayOverlay");
      }
    },

    updatePackageOffers: function updatePackageOffers(offerIds) {
      this.runTask(this.get("packageService").updatePackage(this.get("item.id"), {
        "package": {
          offer_ids: offerIds
        }
      }));
    },

    associatedPackageTypes: _ember["default"].computed("item", function () {
      return this.get("packageService").allChildPackageTypes(this.get("item"));
    }),

    deleteAndAssignNew: function deleteAndAssignNew(packageType) {
      var item, type, detailId;
      return regeneratorRuntime.async(function deleteAndAssignNew$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            item = this.get("item");
            type = item.get("detailType");
            detailId = item.get("detailId");

            if (!type) {
              context$1$0.next = 6;
              break;
            }

            context$1$0.next = 6;
            return regeneratorRuntime.awrap(this.runTask(this.get("subformDetailService").deleteDetailType(type, detailId)));

          case 6:
            return context$1$0.abrupt("return", this.assignNew(packageType, {
              deleteDetailId: !this.isSubformPackage(packageType)
            }));

          case 7:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    warnAndAssignNew: function warnAndAssignNew(pkgType) {
      var _this4 = this;

      var existingPkgType = this.get("item.code");
      var packageName = existingPkgType.get("name");
      var newPackageName = pkgType.get("name");
      var translation = this.get("i18n");

      this.get("messageBox").custom(translation.t("items.new.subform.delete_subform_waring", {
        newPackageName: newPackageName,
        packageName: packageName
      }), translation.t("not_now"), null, translation.t("continue"), function () {
        _this4.deleteAndAssignNew(pkgType);
      });
    },

    isSubformPackage: function isSubformPackage(packageType) {
      return ["computer", "computer_accessory", "electrical", "medical"].indexOf(packageType.get("subform")) >= 0;
    },

    hasExistingPackageSubform: function hasExistingPackageSubform() {
      var code = this.get("item.code");
      return this.isSubformPackage(code);
    },

    assignNew: function assignNew(type) {
      var _ref2,
          _ref2$deleteDetailId,
          deleteDetailId,
          item,
          store,
          packageParams,
          args$1$0 = arguments;

      return regeneratorRuntime.async(function assignNew$(context$1$0) {
        var _this5 = this;

        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            _ref2 = args$1$0.length <= 1 || args$1$0[1] === undefined ? {} : args$1$0[1];
            _ref2$deleteDetailId = _ref2.deleteDetailId;
            deleteDetailId = _ref2$deleteDetailId === undefined ? false : _ref2$deleteDetailId;
            item = this.get("item");
            store = this.get("store");
            packageParams = {
              package_type_id: type.get("id")
            };

            if (!this.isSamePackage(type) || !item.get("detailId") && this.isSubformPackage(type)) {
              packageParams.detail_type = _lodash["default"].capitalize(type.get("subform"));
            }

            if (deleteDetailId) {
              packageParams.detail_id = null;
            }

            context$1$0.next = 10;
            return regeneratorRuntime.awrap(this.runTask(function callee$1$0() {
              return regeneratorRuntime.async(function callee$1$0$(context$2$0) {
                while (1) switch (context$2$0.prev = context$2$0.next) {
                  case 0:
                    context$2$0.next = 2;
                    return regeneratorRuntime.awrap(this.get("packageService").updatePackage(item.id, { "package": packageParams }, { reloadDeps: true }));

                  case 2:
                  case "end":
                    return context$2$0.stop();
                }
              }, null, _this5);
            }, _stockMixinsAsync.ERROR_STRATEGIES.MODAL));

          case 10:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    isSamePackage: function isSamePackage(type) {
      var existingPkgTypeSubform = this.get("item.code.subform");
      return type.get("subform") == existingPkgTypeSubform;
    },

    /**
     *
     * @param {Object} item - Model to persist
     * Perform an update action on item. It does a rollback of the item
     * if there is an error encountered
     */
    saveItem: function saveItem(item) {
      var _this6 = this;

      this.runTask(function callee$1$0() {
        return regeneratorRuntime.async(function callee$1$0$(context$2$0) {
          while (1) switch (context$2$0.prev = context$2$0.next) {
            case 0:
              context$2$0.prev = 0;
              context$2$0.next = 3;
              return regeneratorRuntime.awrap(item.save());

            case 3:
              context$2$0.next = 9;
              break;

            case 5:
              context$2$0.prev = 5;
              context$2$0.t0 = context$2$0["catch"](0);

              item.rollbackAttributes();
              throw context$2$0.t0;

            case 9:
            case "end":
              return context$2$0.stop();
          }
        }, null, _this6, [[0, 5]]);
      }, _stockMixinsAsync.ERROR_STRATEGIES.MODAL);
    },

    itemValueHkDollar: _ember["default"].computed("item", "valueHkDollar", "item.isBoxOrPallet", function () {
      if (this.get("item.isBoxOrPallet")) {
        return false;
      }

      return this.get("valueHkDollar");
    }),

    isInvalidValuation: _ember["default"].computed("itemValueHkDollar", function () {
      var value = this.get("itemValueHkDollar");
      return value === "" || value === null;
    }),

    getOrdersPackagesStates: function getOrdersPackagesStates() {
      var utilities = this.get("utilityMethods");
      var state = utilities.stringifyArray(this.get("filteredStates"));
      return {
        state: state
      };
    },

    getSortQuery: function getSortQuery() {
      var sortOptions = this.get("sortingColumn");
      return {
        sort_column: sortOptions["column_alias"],
        is_desc: /desc/.test(sortOptions["sort"])
      };
    },

    applySortOn: function applySortOn(options) {
      this.set("sortingColumn", options);
      this.reloadResults();
    },

    actions: {
      applySortOn: function applySortOn(options) {
        this.set("sortingColumn", options);
        this.reloadResults();
      },

      clearSearch: function clearSearch() {
        this.set("searchText", "");
      },

      loadOrdersPackages: function loadOrdersPackages(pageNo) {
        var params;
        return regeneratorRuntime.async(function loadOrdersPackages$(context$1$0) {
          var _this7 = this;

          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              params = this.trimQuery(_lodash["default"].merge({ search_by_package_id: this.get("item.id") }, this.getSearchQuery(), this.getPaginationQuery(pageNo), this.getOrdersPackagesStates(), this.getSortQuery()));
              return context$1$0.abrupt("return", this.get("store").query("orders_package", params).then(function (ordersPkgs) {
                _this7.set("ordersPkgLength", ordersPkgs.meta.orders_packages_count);
                return ordersPkgs;
              }));

            case 2:
            case "end":
              return context$1$0.stop();
          }
        }, null, this);
      },

      updatePackage: function updatePackage(field, value) {
        this.runTask(this.get("packageService").updatePackage(this.get("item.id"), {
          "package": _defineProperty({}, field, value)
        }));
      },

      setPkgDescriptionLang: function setPkgDescriptionLang(langauge) {
        this.set("selectedDescriptionLanguage", langauge);
      },

      /**
       * @param {String} name | Name of the field to update
       * @param {any} value | Value of the field
       * @param {boolean} [isRequired=false] | false by default
       * @param {function} cb | Callback to invoke
       */
      updateAttribute: function updateAttribute(name, value) {
        var isRequired = arguments.length <= 2 || arguments[2] === undefined ? false : arguments[2];
        var cb = arguments.length <= 3 || arguments[3] === undefined ? _lodash["default"].noop : arguments[3];
        var item;
        return regeneratorRuntime.async(function updateAttribute$(context$1$0) {
          var _this9 = this;

          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              item = this.get("item");

              if (!(!value && isRequired)) {
                context$1$0.next = 3;
                break;
              }

              return context$1$0.abrupt("return");

            case 3:
              if (!item.changedAttributes()[name]) {
                context$1$0.next = 6;
                break;
              }

              context$1$0.next = 6;
              return regeneratorRuntime.awrap((function callee$1$0() {
                var params;
                return regeneratorRuntime.async(function callee$1$0$(context$2$0) {
                  var _this8 = this;

                  while (1) switch (context$2$0.prev = context$2$0.next) {
                    case 0:
                      params = { "package": _defineProperty({}, _lodash["default"].snakeCase(name), value) };
                      context$2$0.next = 3;
                      return regeneratorRuntime.awrap(this.runTask(function callee$2$0() {
                        return regeneratorRuntime.async(function callee$2$0$(context$3$0) {
                          while (1) switch (context$3$0.prev = context$3$0.next) {
                            case 0:
                              context$3$0.next = 2;
                              return regeneratorRuntime.awrap(this.get("packageService").updatePackage(item, params));

                            case 2:
                            case "end":
                              return context$3$0.stop();
                          }
                        }, null, _this8);
                      }, _stockMixinsAsync.ERROR_STRATEGIES.MODAL));

                    case 3:
                    case "end":
                      return context$2$0.stop();
                  }
                }, null, _this9);
              })());

            case 6:
              cb();

            case 7:
            case "end":
              return context$1$0.stop();
          }
        }, null, this);
      },

      setShowDescSuggestion: function setShowDescSuggestion(val) {
        this.set("showDescriptionSuggestion", val);
      },

      /**
       * Add Offer to Package
       */
      addOffer: function addOffer() {
        var offer, offerIds;
        return regeneratorRuntime.async(function addOffer$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              context$1$0.next = 2;
              return regeneratorRuntime.awrap(this.get("offerService").getOffer());

            case 2:
              offer = context$1$0.sent;
              offerIds = [].concat(_toConsumableArray(this.get("item.offersPackages").getEach("offerId")), [+offer.id]).uniq();

              this.updatePackageOffers(offerIds);

            case 5:
            case "end":
              return context$1$0.stop();
          }
        }, null, this);
      },

      onGradeChange: function onGradeChange(_ref3) {
        var id = _ref3.id;
        var name = _ref3.name;

        this.set("selectedGrade", { id: id, name: name });
        this.set("defaultValueHkDollar", null);
        this.send("calculateItemValuation");
      },

      onConditionChange: function onConditionChange(_ref4) {
        var id = _ref4.id;
        var name = _ref4.name;

        this.set("defaultCondition", { id: id, name: name });
        this.set("defaultValueHkDollar", null);
        this.send("calculateItemValuation");
      },

      calculateItemValuation: function calculateItemValuation() {
        var item, itemValuation;
        return regeneratorRuntime.async(function calculateItemValuation$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              item = this.get("item");
              context$1$0.next = 3;
              return regeneratorRuntime.awrap(this.get("packageService").getItemValuation({
                donorConditionId: this.get("defaultCondition.id") || item.get("donorCondition.id"),
                grade: this.get("selectedGrade.id") || item.get("grade"),
                packageTypeId: item.get("code.id")
              }));

            case 3:
              itemValuation = context$1$0.sent;

              this.set("defaultValueHkDollar", Number(itemValuation.value_hk_dollar));

            case 5:
            case "end":
              return context$1$0.stop();
          }
        }, null, this);
      },

      /**
       * Remove offer from Package
       * @param {Offer} to be dissociate from Package
       */
      removeOffer: function removeOffer(offer) {
        var offerPackage = this.get("item.offersPackages").findBy("offerId", +offer.get("id"));
        if (offerPackage) {
          this.runTask(offerPackage.destroyRecord());
        }
      },

      /**
       * Removes a package from its set
       * @param {Package} pkg the package to unlink
       */
      unlinkFromSet: function unlinkFromSet(pkg) {
        var _this10 = this;

        this.runTask(function callee$1$0() {
          return regeneratorRuntime.async(function callee$1$0$(context$2$0) {
            while (1) switch (context$2$0.prev = context$2$0.next) {
              case 0:
                context$2$0.next = 2;
                return regeneratorRuntime.awrap(this.get("packageService").removeFromSet(pkg));

              case 2:

                if (pkg.get("id") === this.get("model.id")) {
                  this.set("showSetList", false);
                }

              case 3:
              case "end":
                return context$2$0.stop();
            }
          }, null, _this10);
        }, _stockMixinsAsync.ERROR_STRATEGIES.MODAL);
      },

      makeCurrentPackageASet: function makeCurrentPackageASet() {
        var allowedPackageTypes, code;
        return regeneratorRuntime.async(function makeCurrentPackageASet$(context$1$0) {
          var _this11 = this;

          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              if (!this.get("model.isPartOfSet")) {
                context$1$0.next = 2;
                break;
              }

              return context$1$0.abrupt("return");

            case 2:
              allowedPackageTypes = this.get("packageTypeService").parentsOf(this.get("model.code"));
              context$1$0.next = 5;
              return regeneratorRuntime.awrap(this.get("packageTypeService").userPickPackageType({
                storageType: "Package",
                subsetPackageTypes: allowedPackageTypes,
                headerText: this.get("i18n").t("items.select_set_type")
              }));

            case 5:
              code = context$1$0.sent;
              context$1$0.next = 8;
              return regeneratorRuntime.awrap(this.runTask(function callee$1$0() {
                return regeneratorRuntime.async(function callee$1$0$(context$2$0) {
                  while (1) switch (context$2$0.prev = context$2$0.next) {
                    case 0:
                      context$2$0.next = 2;
                      return regeneratorRuntime.awrap(this.get("packageService").initializeSetOf(this.get("model"), code));

                    case 2:
                    case "end":
                      return context$2$0.stop();
                  }
                }, null, _this11);
              }, _stockMixinsAsync.ERROR_STRATEGIES.MODAL));

            case 8:
              return context$1$0.abrupt("return", this.send("addItemToCurrentSet"));

            case 9:
            case "end":
              return context$1$0.stop();
          }
        }, null, this);
      },

      addItemToCurrentSet: function addItemToCurrentSet() {
        var packageSet, pkg;
        return regeneratorRuntime.async(function addItemToCurrentSet$(context$1$0) {
          var _this12 = this;

          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              context$1$0.next = 2;
              return regeneratorRuntime.awrap(this.runTask(this.get("packageService").initializeSetOf(this.get("model"))));

            case 2:
              packageSet = this.get("model.packageSet");
              context$1$0.next = 5;
              return regeneratorRuntime.awrap(this.get("packageService").userPickPackage({
                parentCode: packageSet.get("code.code"),
                storageTypeName: "Package" // we don't add boxes to sets
              }));

            case 5:
              pkg = context$1$0.sent;

              if (!(!pkg || pkg.get("packageSetId") === packageSet.get("id"))) {
                context$1$0.next = 8;
                break;
              }

              return context$1$0.abrupt("return");

            case 8:
              return context$1$0.abrupt("return", this.runTask(function callee$1$0() {
                return regeneratorRuntime.async(function callee$1$0$(context$2$0) {
                  while (1) switch (context$2$0.prev = context$2$0.next) {
                    case 0:
                      context$2$0.next = 2;
                      return regeneratorRuntime.awrap(this.get("packageService").addToSet(pkg, packageSet));

                    case 2:
                    case "end":
                      return context$2$0.stop();
                  }
                }, null, _this12);
              }, _stockMixinsAsync.ERROR_STRATEGIES.MODAL));

            case 9:
            case "end":
              return context$1$0.stop();
          }
        }, null, this);
      },

      /**
       * Called after a property is changed to push the updated
       * record to the API
       */
      persistModel: function persistModel() {
        this.updateRecord(this.get("model"));
      },

      /**
       * Switch to another item of the same set
       *
       * @param {Item} it the newly selected item
       */
      selectItem: function selectItem(it) {
        this.replaceRoute(this.get("currentRoute"), it);
      },

      /**
       * Display/Hide the set list
       */
      toggleSetList: function toggleSetList() {
        this.toggleProperty("showSetList");
      },

      /**
       * Fetches all the assoicated packages to a box/pallet
       */
      fetchContainedPackages: function fetchContainedPackages() {
        var _this13 = this;

        var page = arguments.length <= 0 || arguments[0] === undefined ? 1 : arguments[0];

        return this.get("packageService").fetchContainedPackages(this.get("item.id"), {
          page: page,
          per_page: 10
        }).then(function (data) {
          _this13.set("containerQuantity", data.containerQuantity);
          return data.containedPackages;
        });
      },

      /**
       * The callback to be invoked when an item is removed from
       * box / pallet
       */
      reloadItemsInContainer: function reloadItemsInContainer() {
        this.reloadResults();
      },

      updatePackageType: function updatePackageType() {
        var pkgType;
        return regeneratorRuntime.async(function updatePackageType$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              pkgType = undefined;

              if (!this.get("model.isPartOfSet")) {
                context$1$0.next = 7;
                break;
              }

              context$1$0.next = 4;
              return regeneratorRuntime.awrap(this.get("packageTypeService").userPickPackageType({
                subsetPackageTypes: this.get("model.packageSet.code").defaultChildPackagesList()
              }));

            case 4:
              pkgType = context$1$0.sent;
              context$1$0.next = 10;
              break;

            case 7:
              context$1$0.next = 9;
              return regeneratorRuntime.awrap(this.get("packageTypeService").userPickPackageType({
                storageType: this.get("model.storageTypeName")
              }));

            case 9:
              pkgType = context$1$0.sent;

            case 10:

              if (this.hasExistingPackageSubform() && !this.isSamePackage(pkgType)) {
                this.warnAndAssignNew(pkgType);
              } else {
                this.assignNew(pkgType);
              }

            case 11:
            case "end":
              return context$1$0.stop();
          }
        }, null, this);
      },

      fetchParentContainers: function fetchParentContainers() {
        var pageNo = arguments.length <= 0 || arguments[0] === undefined ? 1 : arguments[0];

        return this.get("packageService").fetchParentContainers(this.get("item.id"), {
          page: pageNo,
          per_page: 10
        }).then(function (data) {
          return data;
        });
      },

      openItemsSearch: function openItemsSearch() {
        this.set("openPackageSearch", true);
        this.set("parentCode", this.get("item.code.code"));
      },

      updateContainer: function updateContainer(pkg, quantity) {
        return regeneratorRuntime.async(function updateContainer$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              if (quantity) {
                context$1$0.next = 2;
                break;
              }

              return context$1$0.abrupt("return");

            case 2:

              this.reloadResults();

            case 3:
            case "end":
              return context$1$0.stop();
          }
        }, null, this);
      },

      setScannedSearchText: function setScannedSearchText(searchedText) {
        this.set("searchText", searchedText);
        this.send("openItemsSearch");
      },

      /**
       * Applies the original item valuation when it was loaded.
       * It is like resetting to the value when item was displayed
       */
      applyDefaultItemValuation: function applyDefaultItemValuation() {
        var item;
        return regeneratorRuntime.async(function applyDefaultItemValuation$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              item = this.get("item");

              item.set("valueHkDollar", this.get("defaultValueHkDollar"));
              this.set("valueHkDollar", this.get("defaultValueHkDollar"));
              context$1$0.next = 5;
              return regeneratorRuntime.awrap(this.saveItem(item));

            case 5:
            case "end":
              return context$1$0.stop();
          }
        }, null, this);
      },

      /**
       *  Add the default suggested description for selected language
       * @param {string} language - Language EN | Zh-TW
       */
      addDefaultDescriptionFor: function addDefaultDescriptionFor(language) {
        var item = this.get("item");
        var description = "";
        var name = "";
        if (language === "en") {
          description = this.get("item.code.descriptionEn");
          name = "notes";
        } else {
          description = this.get("item.code.descriptionZhTw");
          name = "notesZhTw";
        }
        item.set(name, description);
        this.send("updateAttribute", name, description);
      },

      openAddItemOverlay: function openAddItemOverlay(item) {
        this.set("openAddItemOverlay", true);
        this.set("addableItem", item);
      },

      /**
       * Switches to the specified tab by navigating to the correct subroute
       *
       * @param {String} tabName The tab we wish to open
       */
      openTab: function openTab(tabName) {
        this.clearMoveParams();
        this.replaceRoute("items.detail." + tabName);
      },

      setCountryValue: function setCountryValue(value) {
        var config = {
          value: value.id,
          name: "country_id",
          previousValue: this.get("previousValue")
        };
        this.send("updateFields", config);
      },

      setExpiryDate: function setExpiryDate(value) {
        this.set("item.expiryDate", value.toString());
        this.runTask(this.get("item").save());
      },

      clearExpiryDate: function clearExpiryDate() {
        // Need to refer pickadate since this action is out of the
        // calendar-input component
        var picker = _ember["default"].$(".pickadate").pickadate().pickadate("picker");
        picker.clear();
        this.set("item.expiryDate", null);
        this.runTask(this.get("item").save());
      },

      updateFields: function updateFields(config) {
        var detailType = _lodash["default"].snakeCase(this.get("item.detailType")).toLowerCase();
        var apiEndpoint = (0, _emberInflector.pluralize)(detailType);
        var detailId = this.get("item.detail.id");
        var url = "/" + apiEndpoint + "/" + detailId;
        var snakeCaseKey = _lodash["default"].snakeCase(config.name);
        var packageDetailParams = _defineProperty({}, snakeCaseKey, config.value);
        var paramsObj = {
          detailType: detailType,
          url: url,
          snakeCaseKey: snakeCaseKey,
          packageDetailParams: packageDetailParams
        };
        return this.get("subformDetailService").updateRequest(paramsObj, config.previousValue);
      },

      openDropDown: function openDropDown() {
        var country = this.get("item.detail.country");
        if (country) {
          this.set("previousValue", country.id);
        }
      },

      onSearch: function onSearch(field, searchText) {
        this.onSearchCountry(field, searchText);
      },

      onSaleableChange: function onSaleableChange(_ref5) {
        var id = _ref5.id;
        var item, saleable;
        return regeneratorRuntime.async(function onSaleableChange$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              item = this.get("item");
              saleable = _lodash["default"].filter(this.get("saleableOptions"), ["name", id])[0].value;

              item.set("saleable", saleable);
              context$1$0.next = 5;
              return regeneratorRuntime.awrap(this.saveItem(item));

            case 5:
            case "end":
              return context$1$0.stop();
          }
        }, null, this);
      },

      toggleItemOptions: function toggleItemOptions() {
        this.toggleProperty("displayItemOptions");
      },

      closeItemOptions: function closeItemOptions() {
        this.set("displayItemOptions", false);
      },

      triggerItemAction: function triggerItemAction(pkg, actionName) {
        this.toggleProperty("displayItemOptions");
        this.send("beginAction", pkg, actionName);
      }
    }
  });
});