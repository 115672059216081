define("stock/controllers/search_module", ["exports", "ember", "lodash", "ember-infinity/mixins/route", "stock/controllers/goodcity_controller"], function (exports, _ember, _lodash, _emberInfinityMixinsRoute, _stockControllersGoodcity_controller) {
  exports["default"] = _stockControllersGoodcity_controller["default"].extend(_emberInfinityMixinsRoute["default"], {
    filterService: _ember["default"].inject.service(),
    utilityMethods: _ember["default"].inject.service(),
    getCurrentUser: _ember["default"].computed(function () {
      var store = this.get("store");
      var currentUser = store.peekAll("user_profile").get("firstObject") || null;
      return currentUser;
    }).volatile(),

    sanitizeString: function sanitizeString(str) {
      // these are the special characters '.,)(@_-' that are allowed for search
      // '\.' => will allow '.'
      // '\(' => will allow '('
      // '\@' => will allow '@'
      // '\)' => will allow ')'
      str = str.replace(/[^a-z0-9áéíóúñü \.,\)\(@_-]/gim, "");
      return str.trim();
    },

    searchText: _ember["default"].computed("searchInput", {
      get: function get() {
        return this.get("searchInput") || "";
      },

      set: function set(key, value) {
        return this.sanitizeString(value);
      }
    }),

    i18n: _ember["default"].inject.service(),
    store: _ember["default"].inject.service(),
    isLoading: false,
    hasNoResults: false,
    itemSetId: null,
    unloadAll: false,
    minSearchTextLength: 0,
    searchInput: null,
    toDesignateItem: null,
    excludeAssociations: true,
    requestOptions: {},
    modelPath: "filteredResults",

    hasSearchText: _ember["default"].computed("searchText", function () {
      return !!this.get("searchText");
    }),

    canSearch: _ember["default"].computed("searchText", function () {
      return this.get("searchText").length > this.get("minSearchTextLength");
    }),

    onSearchTextChange: _ember["default"].observer("searchText", function () {
      // wait before applying the filter
      if (this.get("canSearch")) {
        this.set("itemSetId", null);
        _ember["default"].run.debounce(this, this.applyFilter, 500);
      } else {
        this.set(this.get("modelPath"), []);
      }
    }),

    buildQueryParamMap: function buildQueryParamMap() {
      var queryParamDefinitions = {
        itemId: "itemSetId",
        toDesignateItem: "toDesignateItem",
        shallow: "excludeAssociations"
      };

      if (this.get("hasSearchText")) {
        queryParamDefinitions.searchText = "searchText";
      }

      for (var key in this.requestOptions) {
        queryParamDefinitions[key] = "requestOptions." + key;
      }
      return queryParamDefinitions;
    },

    onFilterChange: function onFilterChange() {
      _ember["default"].run.debounce(this, this.reloadResults, 500);
    },

    reloadResults: function reloadResults() {
      this.applyFilter({ force: true });
    },

    beforeSearch: function beforeSearch() {
      var _this = this;

      if (this.get("unloadAll")) {
        _lodash["default"].each(["designation", "item", "location", "code"], function (m) {
          _this.get("store").unloadAll(m);
        });
      }
    },

    afterSearch: function afterSearch() {
      // To be implemented
    },

    createFilterParams: function createFilterParams() {
      // Default params, to be overriden in subclass
      return {
        perPage: 25,
        startingPage: 1,
        modelPath: this.get("modelPath"),
        stockRequest: true
      };
    },

    applyFilter: function applyFilter() {
      var _this2 = this;

      var opts = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];
      var _opts$force = opts.force;
      var force = _opts$force === undefined ? false : _opts$force;

      var modelPath = this.get("modelPath");
      var searchText = this.get("searchText");
      if (force || this.get("canSearch")) {
        this.set("isLoading", true);
        this.set("hasNoResults", false);
        this.set("forceLoading", force);
        this.beforeSearch();

        this.infinityModel(this.get("searchModelName"), this.createFilterParams(), this.buildQueryParamMap()).then(function (data) {
          return _ember["default"].RSVP.resolve(_this2.afterSearch(data)).then(function () {
            return data;
          });
        }).then(function (data) {
          if (force || _this2.get("searchText") === data.meta.search) {
            _this2.set(modelPath, data);
            _this2.set("hasNoResults", data.get("length") === 0);
          }
        })["finally"](function () {
          return _this2.set("isLoading", false);
        });
      }
      this.set(modelPath, []);
    },

    afterInfinityModel: function afterInfinityModel(records) {
      var searchText = this.get("searchText");
      if (this.get("forceLoading")) {
        return;
      }
      if (searchText.length === 0 || searchText !== records.meta.search) {
        records.replaceContent(0, 25, []);
      }
    },

    actions: {
      clearSearch: function clearSearch() {
        this.set("searchText", "");
      }
    }
  });
});