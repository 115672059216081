define("stock/helpers/matches", ["exports", "ember"], function (exports, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; })();

  /**
   * A regex match helper
   *
   * Example:
   *
   * {{#if (matches myString '^(foo|bar)$')}}
   *   It matches !
   * {{/if}}
   *
   * @param {String} text the text to test
   * @param {String} regex the regex to test the text against
   * @returns {Boolean}
   */
  exports["default"] = _ember["default"].Helper.helper(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2);

    var text = _ref2[0];
    var regex = _ref2[1];

    return new RegExp(regex).test(text);
  });
});