define("stock/mixins/item_actions", ["exports", "ember", "stock/mixins/async", "lodash", "stock/constants/item-actions"], function (exports, _ember, _stockMixinsAsync, _lodash, _stockConstantsItemActions) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  /**
   * Adds the following properties:
   *
   * - readyForAction (computed, true if other proerties are valid)
   * - actionQty
   * - actionTarget (the package to move)
   * - actionFrom
   * - actionName
   *
   * Adds the following actions:
   *
   * - beginAction(package, from, action)
   * - completeAction()
   * - cancelAction()
   */

  var TASK = {
    UNPACK: "unpack"
  };

  exports["default"] = _ember["default"].Mixin.create(_stockMixinsAsync["default"], {
    locationService: _ember["default"].inject.service(),
    packageService: _ember["default"].inject.service(),
    settings: _ember["default"].inject.service(),
    actionComment: "",
    i18n: _ember["default"].inject.service(),

    selectedProcessingDestination: "",

    processingDestinations: _ember["default"].computed(function () {
      var list = this.get("store").peekAll("processing_destination").map(function (item) {
        return { id: item.get("id"), name: item.get("name") };
      });

      list.unshift({ id: null, name: "None" });
      return list;
    }),

    itemActions: _ember["default"].computed(function () {
      var _this = this;

      return _stockConstantsItemActions.ITEM_ACTIONS.map(function (action) {
        return _extends({}, action, {
          displayName: _this.get("i18n").t("items.actions." + action.name.toLowerCase()).string
        });
      });
    }),

    verifyGainAction: function verifyGainAction(actionName) {
      var currentAction = _lodash["default"].find(this.get("itemActions"), ["name", actionName]);
      return currentAction && !currentAction.loss;
    },

    isValidQuantity: _ember["default"].computed("actionQty", function () {
      var value = this.get("actionQty");
      return value > 0 && value <= this.get("maxQuantity");
    }),

    resolveActionFromLocation: function resolveActionFromLocation(pkg) {
      var showAllLocations = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];
      var presetLocations;
      return regeneratorRuntime.async(function resolveActionFromLocation$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return regeneratorRuntime.awrap(showAllLocations ? this.get("store").query("location", {}) : pkg.get("packagesLocations").mapBy("location"));

          case 2:
            presetLocations = context$1$0.sent;

            if (!(presetLocations.get("length") > 1)) {
              context$1$0.next = 5;
              break;
            }

            return context$1$0.abrupt("return", this.get("locationService").userPickLocation({
              headerText: this.get("i18n").t("select_location." + this.get("actionName").toLowerCase() + "_from"),
              presetLocations: presetLocations
            }));

          case 5:
            return context$1$0.abrupt("return", presetLocations.get("firstObject"));

          case 6:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    quantityAtLocation: function quantityAtLocation(source) {
      var pkg = this.get("actionTarget");

      if (!pkg) {
        return 0;
      }

      var pkgLoc = pkg.get("packagesLocations").findBy("location", source);

      return pkgLoc ? pkgLoc.get("quantity") : 0;
    },

    validActionParams: function validActionParams() {
      var target = this.get("actionTarget");
      var from = this.get("actionFrom");
      var actionName = this.get("actionName");
      var qty = this.get("actionQty");

      if (this.get("isGainAction")) {
        return target && from && actionName;
      } else {
        return target && from && actionName && qty > 0;
      }
    },

    clearActionParams: function clearActionParams() {
      this.set("readyForAction", false);
      this.set("actionQty", 0);
      this.set("actionTarget", null);
      this.set("actionFrom", null);
      this.set("actionName", null);
      this.set("actionIcon", null);
    },

    /**
     * Unpacks the item from box / pallet
     * Updates the onHandBoxedQuantity / onHandPalletizedQuantity in store
     *
     * @param EmberObject container
     * @param EmberObject item
     * @param Integer location_id
     * @param Integer quantity
     * @param function callback
     */
    unpack: function unpack(container, item, location_id, quantity, callback) {
      var params;
      return regeneratorRuntime.async(function unpack$(context$1$0) {
        var _this2 = this;

        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            if (item) {
              context$1$0.next = 2;
              break;
            }

            throw new Error(this.get("i18n").t("box_pallet.bad_item"));

          case 2:
            params = {
              item_id: item.id,
              location_id: location_id,
              task: TASK.UNPACK,
              quantity: quantity
            };

            // 1. Make API request to remove item from the container
            this.get("packageService").addRemoveItem(container.id, params).then(function callee$1$0() {
              return regeneratorRuntime.async(function callee$1$0$(context$2$0) {
                while (1) switch (context$2$0.prev = context$2$0.next) {
                  case 0:
                    this.get("packageService");

                    // 2. Reload the model to sync all location and quantity data with API
                    context$2$0.next = 3;
                    return regeneratorRuntime.awrap(item.reload());

                  case 3:

                    // 3. Invoke callback with parameters
                    // item - The package record which is removed from the box
                    // container - The package record from which the item is removed
                    if (callback) {
                      callback(item, container);
                    }

                  case 4:
                  case "end":
                    return context$2$0.stop();
                }
              }, null, _this2);
            });

          case 4:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    actions: {
      beginAction: function beginAction(pkg, actionName) {
        var isGainAction, from, quantity;
        return regeneratorRuntime.async(function beginAction$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              isGainAction = this.verifyGainAction(actionName);

              this.set("actionName", actionName);
              this.set("isGainAction", isGainAction);

              context$1$0.next = 5;
              return regeneratorRuntime.awrap(this.resolveActionFromLocation(pkg, isGainAction));

            case 5:
              from = context$1$0.sent;

              if (!(!pkg || !actionName || !from)) {
                context$1$0.next = 8;
                break;
              }

              return context$1$0.abrupt("return", this.send("cancelAction"));

            case 8:

              this.set("actionTarget", pkg);
              this.set("actionFrom", from);
              this.set("actionComment", "");

              quantity = this.quantityAtLocation(from);

              if (isGainAction) {
                if (pkg.get("isBoxPallet")) {
                  this.set("maxQuantity", 1);
                  this.set("actionQty", 1);
                } else {
                  this.set("maxQuantity", 99999);
                }
              } else {
                this.set("maxQuantity", quantity);
                this.set("actionQty", quantity);
              }

              this.set("actionIcon", _lodash["default"].filter(_stockConstantsItemActions.ITEM_ACTIONS, {
                name: actionName
              })[0].icon);

              if (this.validActionParams()) {
                this.set("readyForAction", true);
              } else {
                this.send("cancelAction");
              }

            case 15:
            case "end":
              return context$1$0.stop();
          }
        }, null, this);
      },

      setProcessingDestination: function setProcessingDestination(destination) {
        this.set("selectedProcessingDestination", destination);
      },

      completeAction: function completeAction() {
        var _this3 = this;

        this.runTask(function () {
          return _this3.get("packageService").peformActionOnPackage(_this3.get("actionTarget"), {
            from: _this3.get("actionFrom"),
            actionName: _this3.get("actionName").toLowerCase(),
            quantity: _this3.get("actionQty"),
            comment: _this3.get("actionComment"),
            processing_destination_id: _this3.get("selectedProcessingDestination.id")
          });
        }, _stockMixinsAsync.ERROR_STRATEGIES.MODAL)["finally"](function () {
          _this3.clearActionParams();
        });
      },

      cancelAction: function cancelAction() {
        return regeneratorRuntime.async(function cancelAction$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              this.clearActionParams();

            case 1:
            case "end":
              return context$1$0.stop();
          }
        }, null, this);
      },

      selectLocationAction: function selectLocationAction() {
        return regeneratorRuntime.async(function selectLocationAction$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              this.send("beginAction", this.get("actionTarget"), this.get("actionName"));

            case 1:
            case "end":
              return context$1$0.stop();
          }
        }, null, this);
      }
    }
  });
});