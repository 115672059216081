define("stock/controllers/users/contact_details", ["exports", "ember", "stock/mixins/async", "stock/mixins/grades_option"], function (exports, _ember, _stockMixinsAsync, _stockMixinsGrades_option) {
  exports["default"] = _ember["default"].Controller.extend(_stockMixinsAsync["default"], _stockMixinsGrades_option["default"], {
    user: _ember["default"].computed.alias("model.user"),
    userService: _ember["default"].inject.service(),

    hideValidationErrors: function hideValidationErrors(target) {
      this.set(target.id + "InputError", false);
      this.set(target.id + "ValidationError", false);
    },

    districts: _ember["default"].computed(function () {
      return this.get("store").peekAll("district").sortBy("name");
    }),

    getUpdatedDistrictParams: function getUpdatedDistrictParams() {
      var address_attributes = {
        district_id: this.get("user.associatedDistrict.id") || null
      };
      return { address_attributes: address_attributes };
    },

    actions: {
      updateUserDetails: function updateUserDetails(e) {
        var _this = this;

        var value = e.target.value.trim();
        var isValid = undefined;

        if (Object.keys(this.get("user").changedAttributes()).length === 0) {
          this.hideValidationErrors(e.target);
          return;
        }

        switch (e.target.id) {
          case "firstName":
            isValid = Boolean(value);
            break;
          case "lastName":
            isValid = Boolean(value);
            break;
          case "email":
            isValid = this.get("userService").checkUserEmailValidity(value, this.get("mobileNumber"));
            break;
          case "mobile":
            isValid = this.get("userService").checkUserMobileValidity(value, this.get("user.email"));
            break;
        }

        if (isValid) {
          this.runTask(function callee$1$0() {
            var user, phoneNumber;
            return regeneratorRuntime.async(function callee$1$0$(context$2$0) {
              while (1) switch (context$2$0.prev = context$2$0.next) {
                case 0:
                  user = this.get("user");

                  value = e.target.id == "mobile" && value ? "+852" + value : value;
                  user.set(e.target.id, value);
                  context$2$0.prev = 3;
                  context$2$0.next = 6;
                  return regeneratorRuntime.awrap(user.save());

                case 6:
                  context$2$0.next = 14;
                  break;

                case 8:
                  context$2$0.prev = 8;
                  context$2$0.t0 = context$2$0["catch"](3);

                  this.get("user").rollbackAttributes();
                  phoneNumber = this.get("user.mobile") && this.get("user.mobile").slice(4);

                  this.set("mobileNumber", phoneNumber);
                  throw context$2$0.t0;

                case 14:
                case "end":
                  return context$2$0.stop();
              }
            }, null, _this, [[3, 8]]);
          }, _stockMixinsAsync.ERROR_STRATEGIES.MODAL);
          this.hideValidationErrors(e.target);
        } else {
          this.get("user").rollbackAttributes();
          _ember["default"].$("#" + e.target.id).focus();

          e.target.value ? this.set(e.target.id + "ValidationError", true) : this.set(e.target.id + "InputError", true);
        }
      },

      updateMobile: function updateMobile(e) {
        var value = e.target.value.trim();
        var mobileCode = value ? "+852" : "";
        this.set("user.mobile", mobileCode + this.get("mobileNumber"));
        this.send("updateUserDetails", e);
      },

      changeDistrict: function changeDistrict() {
        var _this2 = this;

        return this.runTask(function callee$1$0() {
          var id, data;
          return regeneratorRuntime.async(function callee$1$0$(context$2$0) {
            while (1) switch (context$2$0.prev = context$2$0.next) {
              case 0:
                id = this.get("user.id");
                context$2$0.next = 3;
                return regeneratorRuntime.awrap(this.get("userService").editUser(id, {
                  user: this.getUpdatedDistrictParams()
                }));

              case 3:
                data = context$2$0.sent;

                this.get("store").pushPayload(data);

              case 5:
              case "end":
                return context$2$0.stop();
            }
          }, null, _this2);
        }, _stockMixinsAsync.ERROR_STRATEGIES.MODAL);
      }
    }
  });
});