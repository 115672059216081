define("stock/models/version", ["exports", "ember-data", "ember-data/relationships"], function (exports, _emberData, _emberDataRelationships) {
  var attr = _emberData["default"].attr;

  exports["default"] = _emberData["default"].Model.extend({
    event: attr("string"),
    itemType: attr("string"),
    itemId: attr("number"),
    whodunnit: attr("string"),
    whodunnitName: attr("string"),
    state: attr("string"),
    createdAt: attr("date"),
    objectChanges: attr(),
    item: (0, _emberDataRelationships.belongsTo)("item", {
      async: false
    })
  });
});