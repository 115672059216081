define("stock/routes/order/goods_details", ["exports", "stock/routes/order/order_user_organisation"], function (exports, _stockRoutesOrderOrder_user_organisation) {
  exports["default"] = _stockRoutesOrderOrder_user_organisation["default"].extend({
    model: function model(params) {
      return this.loadIfAbsent("designation", params.order_id);
    },

    setupController: function setupController(controller, model) {
      var user = model.get("createdBy");
      var organisation = user.get("organisationsUsers.firstObject.organisation");
      var orderUserOrganisation = {
        orderUserOrganisation: { user: user, organisation: organisation, order: model }
      };
      controller.set("model", orderUserOrganisation);
      this.setupGoods(controller, model);
    },

    setupGoods: function setupGoods(controller, model) {
      var goodcityRequests = [];
      if (!model.get("goodcityRequests").length) {
        goodcityRequests.push({
          description: null,
          quantity: 1,
          code: null
        });
      } else {
        model.get("goodcityRequests").map(function (gr) {
          goodcityRequests.push({
            id: gr.get("id"),
            description: gr.get("description"),
            quantity: gr.get("quantity"),
            code: gr.get("code")
          });
        });
      }
      controller.set("goodcityRequests", goodcityRequests);
    },

    deactivate: function deactivate() {
      this.controllerFor("application").set("showSidebar", true);
    }
  });
});