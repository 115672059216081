define('stock/mixins/embed_scanner', ['exports', 'ember', 'stock/mixins/async', 'lodash'], function (exports, _ember, _stockMixinsAsync, _lodash) {
  exports['default'] = _ember['default'].Mixin.create(_stockMixinsAsync['default'], {
    barcodeService: _ember['default'].inject.service(),

    // ----------------------
    // Scanner
    // ----------------------

    canScan: _ember['default'].computed(function () {
      return this.get('barcodeService').enabled();
    }),

    cannotScan: _ember['default'].computed.not('canScan'),

    /**
     * Counts a package by scanning a barcode
     */
    startScanning: function startScanning() {
      var _this = this;

      if (this.get('cannotScan') || this.get('isScanning')) {
        return;
      }

      this.set('isScanning', true);

      _ember['default'].run.scheduleOnce('afterRender', this, function callee$1$0() {
        var scannerPreviewId, e, scanner;
        return regeneratorRuntime.async(function callee$1$0$(context$2$0) {
          while (1) switch (context$2$0.prev = context$2$0.next) {
            case 0:
              context$2$0.prev = 0;
              scannerPreviewId = this.get('scannerPreviewId');

              if (scannerPreviewId) {
                context$2$0.next = 6;
                break;
              }

              e = new Error('Implementation of EmberScannerMixin should provide a scannerPreviewId');

              console.error(e);
              throw e;

            case 6:
              context$2$0.next = 8;
              return regeneratorRuntime.awrap(this.get('barcodeService').scanMultiple({
                previewElement: document.getElementById(scannerPreviewId),
                onBarcode: (this.onBarcodeScanned || _lodash['default'].noop).bind(this),
                onStop: this.stopScanning.bind(this)
              }));

            case 8:
              scanner = context$2$0.sent;

              this.set('scanner', scanner);
              context$2$0.next = 16;
              break;

            case 12:
              context$2$0.prev = 12;
              context$2$0.t0 = context$2$0['catch'](0);

              this.modalAlert('stocktakes.scanning_failure');
              this.set('isScanning', false);

            case 16:
            case 'end':
              return context$2$0.stop();
          }
        }, null, _this, [[0, 12]]);
      });
    },

    stopScanning: function stopScanning() {
      if (!this.get('isScanning')) {
        return;
      }

      this.set('isScanning', false);
      this.get('scanner').stop();
      this.set('scanner', null);
    },

    actions: {
      toggleScanning: function toggleScanning() {
        if (this.get('isScanning')) {
          this.stopScanning();
        } else {
          this.startScanning();
        }
      },

      stopScanning: function stopScanning() {
        this.stopScanning();
      }
    }
  });
});