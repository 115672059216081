define("stock/components/scan-barcode-button", ["exports", "ember", "stock/mixins/async", "stock/utils/async"], function (exports, _ember, _stockMixinsAsync, _stockUtilsAsync) {
  exports["default"] = _ember["default"].Component.extend(_stockMixinsAsync["default"], {
    messageBox: _ember["default"].inject.service(),
    barcodeService: _ember["default"].inject.service(),
    i18n: _ember["default"].inject.service(),
    record: null,

    checkPermissionAndScan: function checkPermissionAndScan(route) {
      var scanner, allowed;
      return regeneratorRuntime.async(function checkPermissionAndScan$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            scanner = this.get("barcodeService");
            context$1$0.prev = 1;
            context$1$0.next = 4;
            return regeneratorRuntime.awrap(scanner.requestPermission());

          case 4:
            allowed = context$1$0.sent;

            if (allowed) {
              context$1$0.next = 7;
              break;
            }

            return context$1$0.abrupt("return");

          case 7:
            context$1$0.next = 12;
            break;

          case 9:
            context$1$0.prev = 9;
            context$1$0.t0 = context$1$0["catch"](1);
            return context$1$0.abrupt("return", this.modalAlert("camera_scan.permission_error"));

          case 12:
            context$1$0.next = 14;
            return regeneratorRuntime.awrap(this.scan(route));

          case 14:
          case "end":
            return context$1$0.stop();
        }
      }, null, this, [[1, 9]]);
    },

    scan: function scan(route) {
      var code, record, queryParams;
      return regeneratorRuntime.async(function scan$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.prev = 0;
            context$1$0.next = 3;
            return regeneratorRuntime.awrap(this.get("barcodeService").scanOne());

          case 3:
            code = context$1$0.sent;
            record = this.get("record");
            queryParams = {
              queryParams: { searchInput: code }
            };

            if (route) {
              context$1$0.next = 9;
              break;
            }

            this.get("onScanComplete")(code);
            return context$1$0.abrupt("return");

          case 9:

            if (record) {
              this.get("router").transitionTo(route, record, queryParams);
            } else {
              this.get("router").transitionTo(route, queryParams);
            }
            context$1$0.next = 15;
            break;

          case 12:
            context$1$0.prev = 12;
            context$1$0.t0 = context$1$0["catch"](0);

            this.modalAlert("Scanning failed: " + context$1$0.t0);

          case 15:
          case "end":
            return context$1$0.stop();
        }
      }, null, this, [[0, 12]]);
    },

    actions: {
      scanBarcode: (0, _stockUtilsAsync.singleRunner)(function (route) {
        return this.checkPermissionAndScan(route);
      })
    }
  });
});