define("stock/mixins/dispatch_actions", ["exports", "ember", "stock/mixins/async", "lodash"], function (exports, _ember, _stockMixinsAsync, _lodash) {

  /**
   * @mixin DispatchAction
   * @augments ember/Mixin
   * @description
   * <br> Adds the following properties:
   * <br>
   * <br> - readyToDispatch
   * <br> - dispatchOrdersPackage
   * <br> - dispatchLocation
   * <br> - dispatchQty
   * <br> - editableQty
   * <br>
   * <br> Adds the following actions:
   * <br>
   * <br> - beginDispatch(ordersPackage, location = null)
   * <br> - completeDispatch()
   * <br> - cancelDispatch()
   */
  exports["default"] = _ember["default"].Mixin.create(_stockMixinsAsync["default"], {
    designationService: _ember["default"].inject.service(),
    locationService: _ember["default"].inject.service(),
    settings: _ember["default"].inject.service(),
    i18n: _ember["default"].inject.service(),

    editableQty: _ember["default"].computed.not("settings.allowPartialOperations"),

    clearDispatchParams: function clearDispatchParams() {
      this.set("readyToDispatch", false);
      this.set("dispatchableQuantity", 0);
      this.set("dispatchOrdersPackage", null);
      this.set("dispatchLocation", null);
      this.set("dispatchQty", 0);
    },

    computeDispatchQuantities: function computeDispatchQuantities() {
      var ordPkg = this.get("dispatchOrdersPackage");
      var loc = this.get("dispatchLocation");

      if (!loc || !ordPkg) {
        this.set("dispatchableQuantity", 0);
        this.set("dispatchQty", 0);
        return;
      }

      var pkg = ordPkg.get("item");
      var pkgLocation = pkg.get("packagesLocations").find(function (k) {
        return +k.get("locationId") === +loc.id;
      });
      var maxQuantity = pkgLocation.get("quantity");

      this.set("dispatchableQuantity", maxQuantity);
      this.set("dispatchQty", ordPkg.get("undispatchedQty"));

      if (this.get("dispatchQty") > maxQuantity) {
        this.set("dispatchQty", maxQuantity);
      }
    },

    resolveLocation: function resolveLocation(ordersPackage, location) {
      var pkg, presetLocations;
      return regeneratorRuntime.async(function resolveLocation$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            if (!location) {
              context$1$0.next = 2;
              break;
            }

            return context$1$0.abrupt("return", location);

          case 2:
            pkg = ordersPackage.get("item");
            presetLocations = pkg.get("packagesLocations").mapBy("location");

            if (!(presetLocations.get("length") > 1)) {
              context$1$0.next = 6;
              break;
            }

            return context$1$0.abrupt("return", this.get("locationService").userPickLocation({
              headerText: this.get("i18n").t("select_location.dispatch_from"),
              presetLocations: presetLocations
            }));

          case 6:
            return context$1$0.abrupt("return", presetLocations.get("firstObject"));

          case 7:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    dispatchQuantityInvalid: _ember["default"].computed("dispatchQty", "dispatchableQuantity", function () {
      var qty = this.get("dispatchQty");
      var max = this.get("dispatchableQuantity");
      return isNaN(qty) || isNaN(max) || qty > max || qty < 1;
    }),

    onQuantitiesChange: _ember["default"].observer("dispatchLocation", "dispatchOrdersPackage.undispatchedQty", "dispatchOrdersPackage.package.packagesLocations.@each.{quantity}", function () {
      this.computeDispatchQuantities();
    }),

    actions: {
      pickDispatchLocation: function pickDispatchLocation() {
        return regeneratorRuntime.async(function pickDispatchLocation$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              this.send("beginDispatch", this.get("dispatchOrdersPackage"), null);

            case 1:
            case "end":
              return context$1$0.stop();
          }
        }, null, this);
      },

      beginDispatch: function beginDispatch(ordersPackage) {
        var _this = this;

        var location = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];

        this.resolveLocation(ordersPackage, location).then(function (loc) {
          if (loc) {
            _ember["default"].run(function () {
              _this.set("dispatchOrdersPackage", ordersPackage);
              _this.set("dispatchLocation", loc);
              _this.computeDispatchQuantities();
              _this.set("readyToDispatch", true);
            });
          }
        });
      },

      completeDispatch: function completeDispatch() {
        var _this2 = this;

        if (!this.get("readyToDispatch") || this.get("dispatchQuantityInvalid")) return;

        this.runTask(function () {
          var ordPkg = _this2.get("dispatchOrdersPackage");
          var location = _this2.get("dispatchLocation");
          var quantity = _this2.get("dispatchQty");

          return _this2.get("designationService").dispatch(ordPkg, {
            from_location: location,
            quantity: quantity
          });
        }, _stockMixinsAsync.ERROR_STRATEGIES.MODAL)["finally"](function () {
          _this2.clearDispatchParams();
        });
      },

      cancelDispatch: function cancelDispatch() {
        return regeneratorRuntime.async(function cancelDispatch$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              this.clearDispatchParams();

            case 1:
            case "end":
              return context$1$0.stop();
          }
        }, null, this);
      }
    }
  });
});