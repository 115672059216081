define("stock/controllers/index", ["exports", "ember", "stock/services/filter-service"], function (exports, _ember, _stockServicesFilterService) {
  exports["default"] = _ember["default"].Controller.extend({
    application: _ember["default"].inject.controller(),
    filterService: _ember["default"].inject.service(),

    actions: {
      logMeOut: function logMeOut() {
        this.get("application").send("logMeOut");
      },

      goToOrder: function goToOrder(states) {
        var priority = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];

        var filterService = this.get("filterService");
        var stateFilter = [states];
        var APPOINTMENT = _stockServicesFilterService.TYPE_FILTERS.APPOINTMENT;
        var ONLINE_ORDER = _stockServicesFilterService.TYPE_FILTERS.ONLINE_ORDER;

        if (priority) {
          stateFilter.unshift(_stockServicesFilterService.STATE_FILTERS.PRIORITY);
        }
        filterService.clearFilters();
        filterService.set("orderStateFilters", stateFilter);
        filterService.set("orderTypeFilters", [APPOINTMENT, ONLINE_ORDER]);
        this.transitionToRoute("orders");
      }
    }
  });
});