define('stock/services/utility_methods', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({

    arrayExists: function arrayExists(arr) {
      return arr && arr.length;
    },

    exists: function exists(obj) {
      return obj;
    },

    stringifyArray: function stringifyArray(arr) {
      return Array.isArray(arr) ? arr.toString() : '';
    }
  });
});