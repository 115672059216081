define("stock/components/additional-fields-input", ["exports", "ember", "stock/config/environment", "ember-inflector", "lodash"], function (exports, _ember, _stockConfigEnvironment, _emberInflector, _lodash) {
  exports["default"] = _ember["default"].TextField.extend({
    tagName: "input",
    type: "text",
    isMobileApp: _stockConfigEnvironment["default"].cordova.enabled,
    attributeBindings: ["name", "type", "value", "maxlength", "id", "autoFocus", "placeholder", "required", "pattern"],
    store: _ember["default"].inject.service(),
    classNameBindings: ["inlineTextInput"],
    inlineTextInput: false,
    previousValue: "",

    focusOut: function focusOut() {
      if (this.get("disableCallbacks")) return;
      var config = {
        value: this.get("value"),
        name: this.get("name"),
        previousValue: this.get("previousValue")
      };
      this.get("onFocusOut")(config);
      this.set("inlineTextInput", false);
    },

    focusIn: function focusIn() {
      this.addCssStyle();
    },

    addCssStyle: function addCssStyle() {
      this.set("inlineTextInput", true);
      this.set("previousValue", this.get("value") || "");
    },

    click: function click() {
      this.addCssStyle();
    },

    willDestroyElement: function willDestroyElement() {
      this.set("disableCallbacks", true);
    }
  });
});