define("stock/controllers/orders/conversation", ["exports", "ember", "stock/config/environment", "stock/controllers/orders/detail", "stock/mixins/messages_base", "stock/constants/roles"], function (exports, _ember, _stockConfigEnvironment, _stockControllersOrdersDetail, _stockMixinsMessages_base, _stockConstantsRoles) {
  exports["default"] = _stockControllersOrdersDetail["default"].extend(_stockMixinsMessages_base["default"], {
    isPrivate: false,
    backLinkPath: "",
    isMobileApp: _stockConfigEnvironment["default"].cordova.enabled,
    itemIdforHistoryRoute: null,
    organisationIdforHistoryRoute: null,
    i18n: _ember["default"].inject.service(),
    model: null,
    noMessage: _ember["default"].computed.empty("messages"),
    isMentionsActive: false,
    messageableType: "Order",
    messageableId: _ember["default"].computed(function () {
      return this.get("order.id");
    }),

    displayChatNote: _ember["default"].computed("noMessage", "disabled", "isMentionsActive", function () {
      return this.get("noMessage") && !this.get("isMentionsActive") && !this.get("disabled");
    }),

    on: function on() {
      this.get("subscription").on("change:message", this, this.markReadAndScroll);
    },

    off: function off() {
      this.get("subscription").off("change:message", this, this.markReadAndScroll);
    },

    roles: _ember["default"].computed(function () {
      var _ROLES$STOCK_APP_ROLES = _stockConstantsRoles.ROLES.STOCK_APP_ROLES;
      var ORDER_ADMINISTRATOR = _ROLES$STOCK_APP_ROLES.ORDER_ADMINISTRATOR;
      var ORDER_FULFILMENT = _ROLES$STOCK_APP_ROLES.ORDER_FULFILMENT;

      return [ORDER_ADMINISTRATOR, ORDER_FULFILMENT];
    }),

    markReadAndScroll: function markReadAndScroll(_ref) {
      var record = _ref.record;

      this.markMessageAsRead(record);
      this.scrollToBottom();
    },

    scrollToBottom: function scrollToBottom() {
      if (!_ember["default"].$(".message-textbar").length) {
        return;
      }

      var scrollOffset = _ember["default"].$(document).height();
      var screenHeight = document.documentElement.clientHeight;
      var pageHeight = document.documentElement.scrollHeight;

      if (pageHeight > screenHeight) {
        _ember["default"].run.later(this, function () {
          window.scrollTo(0, scrollOffset);
        });
      }
    },

    actions: {
      sendMessage: function sendMessage() {
        _ember["default"].$("textarea").trigger("blur");
        var values = this.prepareMessageObject("Order");
        this.createMessage(values);
      }
    }
  });
});