define("stock/utils/time-ranges", ["exports"], function (exports) {
  exports["default"] = Object.defineProperties({}, {
    overdue: {
      get: function get() {
        return {
          before: moment().toDate(),
          after: null
        };
      },
      configurable: true,
      enumerable: true
    },
    today: {
      get: function get() {
        return {
          after: moment().startOf("day").toDate(),
          before: moment().endOf("day").toDate()
        };
      },
      configurable: true,
      enumerable: true
    },
    tomorrow: {
      get: function get() {
        return {
          after: moment().add(1, "days").startOf("day").toDate(),
          before: moment().add(1, "days").endOf("day").toDate()
        };
      },
      configurable: true,
      enumerable: true
    },
    week: {
      get: function get() {
        return {
          after: moment().startOf("week").isoWeekday(2).toDate(),
          before: moment().endOf("week").isoWeekday(2).toDate()
        };
      },
      configurable: true,
      enumerable: true
    },
    next_week: {
      get: function get() {
        return {
          after: moment().add(1, "weeks").startOf("week").isoWeekday(2).toDate(),
          before: moment().add(1, "weeks").endOf("week").isoWeekday(2).toDate()
        };
      },
      configurable: true,
      enumerable: true
    },
    month: {
      get: function get() {
        return {
          after: moment().startOf("month").toDate(),
          before: moment().endOf("month").toDate()
        };
      },
      configurable: true,
      enumerable: true
    },
    next_month: {
      get: function get() {
        return {
          after: moment().add(1, "months").startOf("month").toDate(),
          before: moment().add(1, "months").endOf("month").toDate()
        };
      },
      configurable: true,
      enumerable: true
    }
  });
});