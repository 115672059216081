define("stock/models/user_profile", ["exports", "ember", "ember-data", "stock/models/addressable", "lodash", "ember-data/relationships"], function (exports, _ember, _emberData, _stockModelsAddressable, _lodash, _emberDataRelationships) {

  var attr = _emberData["default"].attr;

  exports["default"] = _stockModelsAddressable["default"].extend({
    firstName: attr("string"),
    lastName: attr("string"),
    email: attr("string"),
    mobile: attr("string"),
    disabled: attr("boolean"),
    maxRoleLevel: attr("number"),
    title: attr("string"),

    userRoles: (0, _emberDataRelationships.hasMany)("userRoles", {
      async: false
    }),

    image: (0, _emberDataRelationships.belongsTo)("image", {
      async: false
    }),

    activeUserRoles: _ember["default"].computed("userRoles.[]", "userRoles.@each.expiresAt", function () {
      return this.get("userRoles").filter(function (userRole) {
        return !userRole.get("expiresAt") || userRole.get("expiresAt") && moment.tz(userRole.get("expiresAt"), "Asia/Hong_Kong").isAfter();
      });
    }),

    activeRoles: _ember["default"].computed("activeUserRoles.[]", function () {
      return this.get("activeUserRoles").map(function (userRole) {
        return userRole.get("role");
      });
    }),

    roles: _ember["default"].computed("userRoles.[]", function () {
      return this.get("userRoles").map(function (userRole) {
        return userRole.get("role");
      });
    }),

    roles: _ember["default"].computed("userRoles.[]", function () {
      return this.get("userRoles").map(function (userRole) {
        return userRole.get("role");
      });
    }),

    roleNames: _ember["default"].computed("roles", function () {
      if (this.get("roles.length")) {
        return this.get("roles").getEach("name");
      }
    }),

    isReviewer: _ember["default"].computed("roleNames", function () {
      if (this.get("roleNames") && this.get("roleNames").length) {
        return this.get("roleNames").indexOf("Reviewer") >= 0;
      }
    }),

    isSupervisor: _ember["default"].computed("roleNames", function () {
      if (this.get("roleNames") && this.get("roleNames").length) {
        return this.get("roleNames").indexOf("Supervisor") >= 0;
      }
    }),

    isOrderFulfilmentUser: _ember["default"].computed("roleNames", function () {
      if (this.get("roleNames") && this.get("roleNames").length) {
        return this.get("roleNames").indexOf("Order fulfilment") >= 0;
      }
    }),

    isOrderAdministrator: _ember["default"].computed("roleNames", function () {
      if (this.get("roleNames") && this.get("roleNames").length) {
        return this.get("roleNames").indexOf("Order administrator") >= 0;
      }
    }),

    canManageAppointments: _ember["default"].computed("roles", function () {
      var roles = this.get("roles");
      return roles.find(function (r) {
        return r.get("permissionNames").indexOf("can_manage_settings") >= 0;
      });
    }),

    canManageUsers: _ember["default"].computed("roles", function () {
      var roles = this.get("roles");
      return roles.find(function (r) {
        return r.get("permissionNames").indexOf("can_read_or_modify_user") >= 0;
      });
    }),

    canManageUserRoles: _ember["default"].computed("roles", function () {
      var roles = this.get("roles");
      return roles.find(function (r) {
        return r.get("permissionNames").indexOf("can_manage_user_roles") >= 0;
      });
    }),

    canManageOrders: _ember["default"].computed("roles", function () {
      var roles = this.get("roles");
      return roles.find(function (r) {
        return r.get("permissionNames").indexOf("can_manage_orders") >= 0;
      });
    }),

    canManageItemMessages: _ember["default"].computed("roles", function () {
      var roles = this.get("roles");
      return roles.find(function (r) {
        return r.get("permissionNames").indexOf("can_manage_package_messages") >= 0;
      });
    }),

    canManageStocktakes: _ember["default"].computed("roles", function () {
      var roles = this.get("roles");
      return roles.find(function (r) {
        return r.get("permissionNames").indexOf("can_manage_stocktakes") >= 0;
      });
    }),

    canManageAccessPasses: _ember["default"].computed("roles", function () {
      var roles = this.get("roles");
      return roles.find(function (r) {
        return r.get("permissionNames").indexOf("can_manage_access_passes") >= 0;
      });
    }),

    canManageStocktakeRevisions: _ember["default"].computed("roles", function () {
      var roles = this.get("roles");
      return roles.find(function (r) {
        return r.get("permissionNames").indexOf("can_manage_stocktake_revisions") >= 0;
      });
    }),

    canManageOrganisationsUsers: _ember["default"].computed("roles", function () {
      var roles = this.get("roles");
      return roles.find(function (r) {
        return r.get("permissionNames").indexOf("can_manage_organisations_users") >= 0;
      });
    }),

    canDisableUsers: _ember["default"].computed("roles", function () {
      var roles = this.get("roles");
      return roles.find(function (r) {
        return r.get("permissionNames").indexOf("can_disable_user") >= 0;
      });
    }),

    canMergeUsers: _ember["default"].computed("roles", function () {
      var roles = this.get("roles");
      return roles.find(function (r) {
        return r.get("permissionNames").indexOf("can_merge_users") >= 0;
      });
    }),

    mobileWithCountryCode: _ember["default"].computed("mobile", function () {
      return this.get("mobile") ? "+852" + this.get("mobile") : "";
    }),

    fullName: _ember["default"].computed("firstName", "lastName", function () {
      return this.get("firstName") + " " + this.get("lastName");
    })
  });
});