define("stock/models/company", ["exports", "ember-data", "ember", "ember-data/relationships"], function (exports, _emberData, _ember, _emberDataRelationships) {

  var attr = _emberData["default"].attr;

  exports["default"] = _emberData["default"].Model.extend({
    name: attr("string"),
    crmId: attr("number"),
    createdById: attr("number"),
    updatedById: attr("number"),

    offers: (0, _emberDataRelationships.hasMany)("offers", {
      async: false
    })
  });
});