define("stock/routes/guest_user_details", ["exports", "stock/routes/authorize"], function (exports, _stockRoutesAuthorize) {
  exports["default"] = _stockRoutesAuthorize["default"].extend({
    userService: Ember.inject.service(),

    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);

      var user = this.get("store").peekRecord("user_profile", this.session.get("currentUser.id"));

      var hasActiveRole = user.get("activeRoles") && user.get("activeRoles").length > 0;
      var hasValidUserProfile = (user.get("firstName") || "").trim().length > 0 && (user.get("lastName") || "").trim().length > 0;

      if (hasActiveRole || hasValidUserProfile) {
        transition.abort();
        this.transitionTo("index");
      }
    },

    model: function model() {
      return this.get("store").peekRecord("user_profile", this.session.get("currentUser.id"));
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var title = model.get("title") || "Mr";

      controller.set("firstName", model.get("firstName"));
      controller.set("lastName", model.get("lastName"));
      controller.set("email", model.get("email"));
      controller.set("selectedTitle", controller.get("titles").findBy("id", title));
      model.set("mobile", controller.getUserMobile());
    }
  });
});