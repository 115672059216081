define("stock/components/goodcity/code-search-overlay", ["exports", "ember", "lodash", "stock/mixins/search_resource", "stock/mixins/async"], function (exports, _ember, _lodash, _stockMixinsSearch_resource, _stockMixinsAsync) {

  /**
   * An overlay that pops up from the bottom of the screen, allowing the user
   * to search and select an order.
   *
   * The popup *does not* do anythinng to the order apart from returning it
   *
   * @property {boolean} open whether the popup is visible or not
   * @property {function} onSelect callback triggered when an order is selected
   */
  exports["default"] = _ember["default"].Component.extend(_stockMixinsSearch_resource["default"], _stockMixinsAsync["default"], {
    store: _ember["default"].inject.service(),
    packageTypeService: _ember["default"].inject.service(),
    filter: "",
    searchText: "",
    fetchMoreResult: true,
    storageType: null,

    init: function init() {
      var _this = this;

      this._super("code-search-overlay");
      this.get("router").addObserver("currentRouteName", function () {
        return _this.closeOverlay();
      });
    },

    recentPackageTypes: _ember["default"].computed("open", "storageType", function () {
      var _this2 = this;

      var userFavourites = this.get("store").peekAll("user_favourite");
      var storageType = this.get("storageType");

      var packageTypeIds = userFavourites.filterBy("favourite_type", "PackageType").getEach("favourite_id").reverse().uniq();
      var pkgTypes = packageTypeIds.map(function (it) {
        return _this2.get("store").peekRecord("code", it);
      }).filter(_lodash["default"].identity);

      if (storageType) {
        return pkgTypes.filterBy("allow" + storageType, true);
      }

      return pkgTypes;
    }),

    allPackageTypes: _ember["default"].computed("open", "subsetPackageTypes", function () {
      if (this.get("subsetPackageTypes")) {
        return this.get("subsetPackageTypes");
      }
      return this.get("store").peekAll("code");
    }),

    filteredPackageTypes: _ember["default"].computed("allPackageTypes", "storageType", function () {
      var pkgTypes = this.get("allPackageTypes");
      var storageType = this.get("storageType");
      if (storageType) {
        return pkgTypes.filterBy("allow" + storageType, true);
      }
      return pkgTypes;
    }),

    hasSearchText: _ember["default"].computed("searchText", function () {
      return _ember["default"].$.trim(this.get("searchText")).length;
    }),

    onSearchTextChange: _ember["default"].observer("searchText", function () {
      // wait before applying the filter
      _ember["default"].run.debounce(this, this.applyFilter, 500);
    }),

    applyFilter: function applyFilter() {
      this.set("filter", this.get("searchText"));
      this.set("fetchMoreResult", true);
    },

    filteredResults: _ember["default"].computed("filter", "fetchMoreResult", "allPackageTypes.[]", function () {
      var packageTypes = this.get("filteredPackageTypes");
      var filter = _ember["default"].$.trim(this.get("filter").toLowerCase());
      var types = [];
      var matchFilter = function matchFilter(value) {
        return (value || "").toLowerCase().indexOf(filter) !== -1;
      };

      if (filter.length > 0) {
        packageTypes.forEach(function (type) {
          if (matchFilter(type.get("code")) || matchFilter(type.get("name")) || matchFilter(type.get("otherTerms"))) {
            types.push(type);
          }
        });
      } else {
        types = types.concat(packageTypes.toArray());
      }

      return types.sortBy("name").uniq();
    }),

    closeOverlay: function closeOverlay() {
      this.setProperties({
        searchText: "",
        filter: "",
        open: false,
        onSelect: null,
        fetchMoreResult: true,
        storageType: null
      });
    },

    actions: {
      closeOverlay: function closeOverlay() {
        this.closeOverlay();
      },

      clearSearch: function clearSearch() {
        this.set("searchText", "");
      },

      selectCode: function selectCode(code) {
        this.getWithDefault("onSelect", _lodash["default"].noop)(code);
        this.closeOverlay();
      }
    }
  });
});