define("stock/routes/organisations/new", ["exports", "ember", "stock/config/environment", "stock/routes/authorize"], function (exports, _ember, _stockConfigEnvironment, _stockRoutesAuthorize) {
  exports["default"] = _stockRoutesAuthorize["default"].extend({
    organisationService: _ember["default"].inject.service(),

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.initializeAttributes();
    },

    initializeOrganisationTypes: function initializeOrganisationTypes() {
      var data;
      return regeneratorRuntime.async(function initializeOrganisationTypes$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return regeneratorRuntime.awrap(this.store.findAll("organisationType"));

          case 2:
            data = context$1$0.sent;

            this.store.pushPayload(data);
            this.controller.set("organisationTypes", data);
            this.controller.set("selectedOrganisationType", data.get("firstObject"));

          case 6:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    // Initialize country to 'China - Hong Kong (Special Administrative Region)'
    initializeCountry: function initializeCountry() {
      var country, countryObj;
      return regeneratorRuntime.async(function initializeCountry$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return regeneratorRuntime.awrap(this.store.query("country", {
              searchText: _stockConfigEnvironment["default"].APP.DEFAULT_COUNTRY
            }));

          case 2:
            country = context$1$0.sent;
            countryObj = country.get("firstObject");

            this.store.pushPayload(country);
            this.controller.set("country", {
              id: countryObj.get("id"),
              nameEn: countryObj.get("nameEn")
            });

          case 6:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    initializeAttributes: function initializeAttributes() {
      return regeneratorRuntime.async(function initializeAttributes$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return regeneratorRuntime.awrap(this.initializeOrganisationTypes());

          case 2:
            context$1$0.next = 4;
            return regeneratorRuntime.awrap(this.initializeCountry());

          case 4:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    }
  });
});