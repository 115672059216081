define('stock/controllers/order/client_information', ['exports', 'ember', 'stock/utils/ajax-promise', 'stock/config/environment', 'ember-concurrency'], function (exports, _ember, _stockUtilsAjaxPromise, _stockConfigEnvironment, _emberConcurrency) {
  var getOwner = _ember['default'].getOwner;
  exports['default'] = _ember['default'].Controller.extend({
    queryParams: ["prevPath"],
    prevPath: null,
    isMobileApp: _stockConfigEnvironment['default'].cordova.enabled,
    i18n: _ember['default'].inject.service(),
    order: _ember['default'].computed.alias("model.orderUserOrganisation.order"),
    beneficiary: _ember['default'].computed.alias("model.beneficiary"),
    purposes: _ember['default'].computed.alias("model.purposes"),

    firstName: _ember['default'].computed("beneficiary", {
      get: function get() {
        return this.returnBeneficaryType('firstName');
      },
      set: function set(key, value) {
        return value;
      }
    }),

    lastName: _ember['default'].computed("beneficiary", {
      get: function get() {
        return this.returnBeneficaryType('lastName');
      },
      set: function set(key, value) {
        return value;
      }
    }),

    identityNumber: _ember['default'].computed("beneficiary", {
      get: function get() {
        return this.returnBeneficaryType('identityNumber');
      },
      set: function set(key, value) {
        return value;
      }
    }),

    returnBeneficaryType: function returnBeneficaryType(type) {
      var beneficiary = this.get('beneficiary');
      return beneficiary && beneficiary.get(type);
    },

    mobilePhone: _ember['default'].computed("beneficiary", {
      get: function get() {
        var beneficiary = this.get('beneficiary');
        var phoneNumber = beneficiary && beneficiary.get('phoneNumber').slice(4);
        return phoneNumber;
      },
      set: function set(key, value) {
        return value;
      }
    }),

    selectedId: _ember['default'].computed('beneficiary.identityTypeId', {
      get: function get() {
        var beneficiary = this.get('beneficiary');
        var selectedId = beneficiary && beneficiary.get('identityTypeId') === 2 ? "abcl" : "hkId";
        return selectedId;
      },
      set: function set(key, value) {
        return value;
      }
    }),

    selectedPurposeId: _ember['default'].computed('order', {
      get: function get() {
        var orderPurpose = this.get('order.ordersPurposes').get('firstObject');
        var prevPath = this.get('prevPath');
        return prevPath === "client_summary" ? "client" : orderPurpose && orderPurpose.get('purpose.identifier') || 'organisation';
      },
      set: function set(key, value) {
        return value;
      }
    }),

    isClientSelected: _ember['default'].computed.equal("selectedPurposeId", "client"),
    isHkIdSelected: _ember['default'].computed.equal("selectedId", "hkId"),

    mobile: _ember['default'].computed('mobilePhone', function () {
      return _stockConfigEnvironment['default'].APP.HK_COUNTRY_CODE + this.get('mobilePhone');
    }),

    titles: _ember['default'].computed(function () {
      var translation = this.get("i18n");
      var mr = translation.t("order.user_title.mr");
      var mrs = translation.t("order.user_title.mrs");
      var miss = translation.t("order.user_title.miss");
      var ms = translation.t("order.user_title.ms");

      return [{ name: mr, id: "Mr" }, { name: mrs, id: "Mrs" }, { name: miss, id: "Miss" }, { name: ms, id: "Ms" }];
    }),

    beneficiaryParams: function beneficiaryParams() {
      var title = _ember['default'].$("select#title option").toArray().filter(function (title) {
        return title.selected === true;
      })[0].value;
      var beneficieryParams = {
        first_name: this.get('firstName'),
        last_name: this.get('lastName'),
        title: title,
        identity_number: this.get('identityNumber'),
        phone_number: this.get('mobile'),
        order_id: this.get('order.id'),
        identity_type_id: this.identityTypeId()
      };
      return beneficieryParams;
    },

    identityTypeId: function identityTypeId() {
      return this.get('selectedId') === 'hkId' ? 1 : 2;
    },

    actionType: function actionType(isOrganisation, beneficiaryId) {
      var actionType = undefined;
      if (isOrganisation && beneficiaryId) {
        actionType = 'DELETE';
      } else if (!isOrganisation && beneficiaryId) {
        actionType = 'PUT';
      } else if (!isOrganisation && !beneficiaryId) {
        actionType = 'POST';
      } else {
        actionType = null;
      }
      return actionType;
    },

    editOrder: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(orderParams, isOrganisation) {
      var orderId, beneficiaryId, store, url, actionType, beneficiary, loadingView, beneficiaryParams, beneficiaryResponse, orderResponse;
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            orderId = this.get('order.id');
            beneficiaryId = this.get('beneficiary.id');
            store = this.store;
            url = beneficiaryId ? "/beneficiaries/" + beneficiaryId : "/beneficiaries";
            actionType = this.actionType(isOrganisation, beneficiaryId);
            beneficiary = beneficiaryId && store.peekRecord('beneficiary', beneficiaryId);
            loadingView = getOwner(this).lookup('component:loading').append();
            beneficiaryParams = ["PUT", "POST"].indexOf(actionType) > -1 ? { beneficiary: this.beneficiaryParams(), order_id: orderId } : {};

            if (!actionType) {
              context$1$0.next = 13;
              break;
            }

            context$1$0.next = 11;
            return new _stockUtilsAjaxPromise['default'](url, actionType, this.get('session.authToken'), beneficiaryParams);

          case 11:
            beneficiaryResponse = context$1$0.sent;

            orderParams['beneficiary_id'] = beneficiaryResponse.beneficiary ? beneficiaryResponse.beneficiary.id : null;

          case 13:
            context$1$0.next = 15;
            return new _stockUtilsAjaxPromise['default']('/orders/' + orderId, 'PUT', this.get('session.authToken'), { order: orderParams });

          case 15:
            orderResponse = context$1$0.sent;

            store.pushPayload(orderResponse);

            if (beneficiary && actionType === "DELETE") {
              store.unloadRecord(beneficiary);
              this.send('redirectToGoodsDetails');
            } else {
              store.pushPayload(beneficiaryResponse);
              this.send('redirectToGoodsDetails');
            }
            loadingView.destroy();

          case 19:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })),

    actions: {
      saveClientDetails: function saveClientDetails() {
        var orderParams = undefined;
        var clientInfo = this.get('selectedPurposeId');
        var purposeId = this.get('purposes').filterBy('identifier', clientInfo).get('firstObject.id');

        var isForOrganisation = clientInfo === 'organisation';
        orderParams = isForOrganisation ? {
          'purpose_ids': [purposeId],
          'beneficiary_id': null
        } : {
          'purpose_ids': [purposeId]
        };
        this.get('editOrder').perform(orderParams, isForOrganisation);
      },

      redirectToGoodsDetails: function redirectToGoodsDetails() {
        var orderId = this.get("order.id");
        var prevPath = this.get("prevPath");
        if (prevPath && prevPath === "client_summary") {
          this.send("redirectToClientSummary");
        } else {
          this.transitionToRoute('order.goods_details', orderId, { queryParams: { fromClientInformation: true } });
        }
      },

      redirectToClientSummary: function redirectToClientSummary() {
        var orderId = this.get("order.id");
        this.transitionToRoute('orders.client_summary', orderId);
      }
    }
  });
});