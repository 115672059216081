define("stock/controllers/order/appointment_details", ["exports", "ember", "stock/utils/ajax-promise", "stock/config/environment"], function (exports, _ember, _stockUtilsAjaxPromise, _stockConfigEnvironment) {
  var getOwner = _ember["default"].getOwner;
  exports["default"] = _ember["default"].Controller.extend({
    order: _ember["default"].computed.alias("model.orderUserOrganisation.order"),
    orderTransport: _ember["default"].computed.alias("model.orderTransport"),
    selectedId: null,
    selectedTimeId: null,
    selectedDate: null,
    timeSlotNotSelected: false,
    isMobileApp: _stockConfigEnvironment["default"].cordova.enabled,

    timeSlots: _ember["default"].computed("selectedDate", function () {
      var selectedDate = this.get("selectedDate");
      if (selectedDate) {
        var timeSlots = this.get("available_dates").appointment_calendar_dates.filter(function (date) {
          return date.date === moment(selectedDate).format("YYYY-MM-DD");
        })[0].slots;
        return timeSlots;
      }
    }),

    orderTransportParams: function orderTransportParams() {
      var orderTransportProperties = {};
      orderTransportProperties.scheduled_at = this.get("selectedTimeId");
      orderTransportProperties.timeslot = this.get("selectedTimeId").substr(11, 5);
      orderTransportProperties.transport_type = this.get("selectedId");
      orderTransportProperties.order_id = this.get("order.id");
      return orderTransportProperties;
    },

    actions: {
      saveTransportDetails: function saveTransportDetails() {
        var isTimeSlotSelected = _ember["default"].$(".time-slots input").toArray().filter(function (radioButton) {
          return radioButton.checked === true;
        }).length;
        if (isTimeSlotSelected) {
          this.set("timeSlotNotSelected", false);
        } else {
          this.set("timeSlotNotSelected", true);
          return false;
        }
        var orderTransport = this.get("orderTransport");

        var url, actionType;

        if (orderTransport) {
          url = "/order_transports/" + orderTransport.get("id");
          actionType = "PUT";
        } else {
          url = "/order_transports";
          actionType = "POST";
        }

        this.send("saveOrUpdateOrderTransport", url, actionType);
      },

      saveOrUpdateOrderTransport: function saveOrUpdateOrderTransport(url, actionType) {
        var _this = this;

        var loadingView = getOwner(this).lookup("component:loading").append();

        new _stockUtilsAjaxPromise["default"](url, actionType, this.get("session.authToken"), {
          order_transport: this.orderTransportParams()
        }).then(function (data) {
          _this.get("store").pushPayload(data);
          loadingView.destroy();
          _this.transitionToRoute("order.confirm_booking", _this.get("order.id"));
        });
      }
    }
  });
});