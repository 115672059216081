define("stock/controllers/goodcity_controller", ["exports", "ember", "rsvp", "lodash"], function (exports, _ember, _rsvp, _lodash) {

  /**
   * @module Controllers/GoodcityController
   * @augments ember/Controller
   * @description Generic controller base. Should hopefully help avoid re-writing code in the future
   *  <br> Has:
   *  <br> - Message box
   *  <br> - Loading spinner
   *  <br> - CRUD methods for controlling records
   */
  exports["default"] = _ember["default"].Controller.extend({
    // ---- Services

    messageBox: _ember["default"].inject.service(),
    i18n: _ember["default"].inject.service(),

    init: function init() {
      this._super();
    },

    // ---- Generic Helpers

    /**
     * @instance
     * @description displays the loading spinner on the screen
     */
    showLoadingSpinner: function showLoadingSpinner() {
      if (_ember["default"].testing) {
        return;
      }
      if (!this.loadingView) {
        this.loadingView = _ember["default"].getOwner(this).lookup("component:loading").append();
      }
    },

    /**
     * @instance
     * @description hides the loading spinner off the screen
     */
    hideLoadingSpinner: function hideLoadingSpinner() {
      if (_ember["default"].testing) {
        return;
      }
      if (this.loadingView) {
        this.loadingView.destroy();
        this.loadingView = null;
      }
    },

    showError: function showError(message, cb) {
      this.get("messageBox").alert(message || this.get("i18n").t("unexpected_error"), cb);
    },

    onError: function onError() {
      var response = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

      var errors = _lodash["default"].map(response.errors, function (err) {
        return _lodash["default"].isString(err) ? err : err.detail;
      });
      this.showError(errors[0]);
    },

    // ---- CRUD

    createRecord: function createRecord(modelName, payload) {
      var _this = this;

      var newRecord = this.get("store").createRecord(modelName, payload);
      this.showLoadingSpinner();
      return newRecord.save()["catch"](function (r) {
        _this.onError(r);
      }).then(function () {
        return _this.hideLoadingSpinner();
      });
    },

    updateRecord: function updateRecord(record) {
      var _this2 = this;

      var updates = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];
      var opts = arguments.length <= 2 || arguments[2] === undefined ? {} : arguments[2];

      _lodash["default"].each(updates, function (v, k) {
        return record.set(k, v);
      });
      this.showLoadingSpinner();
      return record.save().then(function () {
        _lodash["default"].get(opts, "onSuccess", _lodash["default"].noop)();
      })["catch"](function (e) {
        if (!opts.noRollback) {
          record.rollbackAttributes();
        }
        _lodash["default"].get(opts, "onFailure", _lodash["default"].noop)(e);
        _this2.onError(e);
      })["finally"](function () {
        return _this2.hideLoadingSpinner();
      });
    },

    deleteRecords: function deleteRecords(records) {
      var _this3 = this;

      this.showLoadingSpinner();
      return _rsvp["default"].all(records.map(function (r) {
        return r.destroyRecord();
      }))["catch"](function (r) {
        return _this3.onError(r);
      })["finally"](function () {
        return _this3.hideLoadingSpinner();
      });
    },

    back: function back() {
      history.back();
    }
  });
});