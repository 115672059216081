define("stock/services/organisation-service", ["exports", "ember", "lodash", "stock/services/api-base-service", "stock/utils/helpers", "stock/mixins/navigation_aware"], function (exports, _ember, _lodash, _stockServicesApiBaseService, _stockUtilsHelpers, _stockMixinsNavigation_aware) {
  exports["default"] = _stockServicesApiBaseService["default"].extend(_stockMixinsNavigation_aware["default"], {
    store: _ember["default"].inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.set("openOrganisationSearch", false);
    },

    onNavigation: function onNavigation() {
      this.getWithDefault("onOrganisationSelected", _lodash["default"].noop)(null);
    },

    getOrganisationOrders: function getOrganisationOrders(order) {
      var opts = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];
      var store, pagination, id, data;
      return regeneratorRuntime.async(function getOrganisationOrders$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            store = this.get("store");
            pagination = _lodash["default"].pick(opts, ["page", "per_page"]);
            id = (0, _stockUtilsHelpers.toID)(order);
            context$1$0.next = 5;
            return regeneratorRuntime.awrap(this.GET("/organisations/" + id + "/orders", pagination));

          case 5:
            data = context$1$0.sent;

            store.pushPayload(data);
            return context$1$0.abrupt("return", store.peekRecord("organisation", id).get("designations"));

          case 8:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    /**
     * Triggers an organisation search overlay
     * @returns {Promise<Model>}
     */
    userPickOrganisation: function userPickOrganisation() {
      var _this = this;

      return new Promise(function (resolve, reject) {
        _ember["default"].run(function () {
          _this.set("openOrganisationSearch", true);
          _this.set("onOrganisationSelect", function (organisation) {
            _this.set("onOrganisationSelect", _lodash["default"].noop);
            _this.set("openOrganisationSearch", false);
            resolve(organisation || null);
          });
        });
      });
    },

    create: function create(params) {
      return this.POST("/organisations", { organisation: params });
    },

    update: function update(id, params) {
      return this.PUT("/organisations/" + id, { organisation: params });
    }
  });
});