define("stock/services/api-base-service", ["exports", "ember", "lodash", "stock/utils/ajax-promise"], function (exports, _ember, _lodash, _stockUtilsAjaxPromise) {

  /**
   * @module Services/ApiBaseService
   * @augments ember/Service
   * @description Base service class providing handy methods for API based services
   */

  function urlWithParams(url, params) {
    if (!params) {
      return url;
    }
    var paramStr = _lodash["default"].map(params, function (value, key) {
      return key + "=" + value;
    }).join("&");
    var separator = /\?/.test(url) ? "&" : "?";
    return "" + url + separator + paramStr;
  }

  exports["default"] = _ember["default"].Service.extend({
    // ----- Services -----
    session: _ember["default"].inject.service(),

    // ----- Utilities -----
    _request: function _request(url, options, authorizedRequest) {
      var action = options.action;
      var body = options.body;

      var language = this.get("session.language");
      return new _stockUtilsAjaxPromise["default"](url, action, authorizedRequest ? this.get("session.authToken") : null, body, null, language);
    },

    // ----- CRUD ACTIONS -----

    /**
     * @param {string} url the endpoint to fetch
     * @param {object} [options]
     * @param {boolean} [options.authorizedRequest] auth token presence (default=true)
     * @returns {Promise<any>}
     */
    GET: function GET(url) {
      var opts = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];
      var _opts$authorizedRequest = opts.authorizedRequest;
      var authorizedRequest = _opts$authorizedRequest === undefined ? true : _opts$authorizedRequest;

      return this._request(urlWithParams(url, opts), {
        action: "GET"
      }, authorizedRequest);
    },

    /**
     * @param {string} url the endpoint to post to
     * @param {object} body the post payload
     * @param {object} [options]
     * @param {boolean} [options.authorizedRequest] auth token presence (default=true)
     * @returns {Promise<any>}
     */
    POST: function POST(url, body) {
      var opts = arguments.length <= 2 || arguments[2] === undefined ? {} : arguments[2];
      var _opts$authorizedRequest2 = opts.authorizedRequest;
      var authorizedRequest = _opts$authorizedRequest2 === undefined ? true : _opts$authorizedRequest2;

      return this._request(url, {
        action: "POST",
        body: body
      }, authorizedRequest);
    },

    /**
     * @param {string} url the endpoint to put to
     * @param {object} body the data payload
     * @param {object} [options]
     * @param {boolean} [options.authorizedRequest] auth token presence (default=true)
     * @returns {Promise<any>}
     */
    PUT: function PUT(url, body) {
      var opts = arguments.length <= 2 || arguments[2] === undefined ? {} : arguments[2];
      var _opts$authorizedRequest3 = opts.authorizedRequest;
      var authorizedRequest = _opts$authorizedRequest3 === undefined ? true : _opts$authorizedRequest3;

      return this._request(url, {
        action: "PUT",
        body: body
      }, authorizedRequest);
    },

    /**
     * @param {string} url the endpoint to delete
     * @param {object} [options]
     * @param {boolean} [options.authorizedRequest] auth token presence (default=true)
     * @returns {Promise<any>}
     */
    DELETE: function DELETE(url) {
      var opts = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];
      var _opts$authorizedRequest4 = opts.authorizedRequest;
      var authorizedRequest = _opts$authorizedRequest4 === undefined ? true : _opts$authorizedRequest4;

      return this._request(url, {
        action: "DELETE"
      }, authorizedRequest);
    }
  });
});