define("stock/services/package-service", ["exports", "lodash", "stock/services/api-base-service", "stock/utils/helpers", "stock/mixins/navigation_aware", "npm:axios", "stock/config/environment"], function (exports, _lodash, _stockServicesApiBaseService, _stockUtilsHelpers, _stockMixinsNavigation_aware, _npmAxios, _stockConfigEnvironment) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  exports["default"] = _stockServicesApiBaseService["default"].extend(_stockMixinsNavigation_aware["default"], {
    store: Ember.inject.service(),
    i18n: Ember.inject.service(),
    packageTypeService: Ember.inject.service(),
    CLOUD_URL: _stockConfigEnvironment["default"].APP.CLOUD_URL,

    init: function init() {
      this._super.apply(this, arguments);
      this.set("openPackageSearch", false);
      this.set("packageSearchOptions", {});
      this.set("openImageOverlay", false);
    },

    uploadToCloudinary: function uploadToCloudinary(params) {
      var image, identifier, newUploadedImage;
      return regeneratorRuntime.async(function uploadToCloudinary$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return regeneratorRuntime.awrap(_npmAxios["default"].post(this.CLOUD_URL, params));

          case 2:
            image = context$1$0.sent;
            identifier = image.data.version + "/" + image.data.public_id + "." + image.data.format;
            newUploadedImage = this.get("store").createRecord("image", {
              cloudinaryId: identifier,
              favourite: true
            });
            return context$1$0.abrupt("return", newUploadedImage);

          case 6:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    generateSignature: function generateSignature() {
      return this.GET("/images/generate_signature");
    },

    generateInventoryNumber: function generateInventoryNumber() {
      return this.POST("/inventory_numbers");
    },

    printBarcode: function printBarcode(pkgParams) {
      return this.POST("/packages/print_barcode", pkgParams);
    },

    removeInventoryNumber: function removeInventoryNumber(code) {
      return this.PUT("/inventory_numbers/remove_number", code);
    },

    createPackage: function createPackage(pkgParams) {
      var _this = this;

      return this.POST("/packages", pkgParams).then(function (data) {
        _this.get("store").pushPayload(data);
        _this.fetchUserFavourites();
        return data;
      });
    },

    findPackageByInventoryNumber: function findPackageByInventoryNumber(inventoryNum) {
      var payload;
      return regeneratorRuntime.async(function findPackageByInventoryNumber$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return regeneratorRuntime.awrap(this.GET("/packages", {
              inventory_number: inventoryNum
            }));

          case 2:
            payload = context$1$0.sent;

            this.get("store").pushPayload(payload);

            return context$1$0.abrupt("return", this.get("store").peekAll("item").findBy("inventoryNumber", inventoryNum));

          case 5:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    loadSubform: function loadSubform(detailType, detailId) {
      return this.get("store").findRecord(_lodash["default"].snakeCase(detailType).toLowerCase(), detailId);
    },

    getPackageVersions: function getPackageVersions(pkg) {
      var store, id, data;
      return regeneratorRuntime.async(function getPackageVersions$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            store = this.get("store");
            id = (0, _stockUtilsHelpers.toID)(pkg);
            context$1$0.next = 4;
            return regeneratorRuntime.awrap(this.GET("/packages/" + id + "/versions"));

          case 4:
            data = context$1$0.sent;

            store.pushPayload(data);
            return context$1$0.abrupt("return", _lodash["default"].get(data, "versions", []).map(function (it) {
              return store.peekRecord("version", it.id);
            }));

          case 7:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    updatePackage: function updatePackage(pkg, pkgParams) {
      var opts = arguments.length <= 2 || arguments[2] === undefined ? {} : arguments[2];

      var _opts$reloadDeps, reloadDeps, pkgId, payload, _$get, detail_type, detail_id;

      return regeneratorRuntime.async(function updatePackage$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            _opts$reloadDeps = opts.reloadDeps;
            reloadDeps = _opts$reloadDeps === undefined ? false : _opts$reloadDeps;
            pkgId = (0, _stockUtilsHelpers.toID)(pkg);
            context$1$0.next = 5;
            return regeneratorRuntime.awrap(this.PUT("/packages/" + pkgId, pkgParams));

          case 5:
            payload = context$1$0.sent;
            context$1$0.next = 8;
            return regeneratorRuntime.awrap(this.fetchUserFavourites());

          case 8:
            if (!reloadDeps) {
              context$1$0.next = 15;
              break;
            }

            _$get = _lodash["default"].get(payload, "item", {});
            detail_type = _$get.detail_type;
            detail_id = _$get.detail_id;

            if (!(detail_id && detail_type)) {
              context$1$0.next = 15;
              break;
            }

            context$1$0.next = 15;
            return regeneratorRuntime.awrap(this.loadSubform(detail_type, detail_id));

          case 15:

            this.get("store").pushPayload(payload);
            return context$1$0.abrupt("return", this.get("store").peekRecord("item", pkgId));

          case 17:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    fetchUserFavourites: function fetchUserFavourites() {
      var store, userFavourites;
      return regeneratorRuntime.async(function fetchUserFavourites$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            store = this.get("store");
            context$1$0.next = 3;
            return regeneratorRuntime.awrap(store.findAll("user_favourite"));

          case 3:
            userFavourites = context$1$0.sent;

            store.pushPayload(userFavourites);

          case 5:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    getCloudinaryImage: function getCloudinaryImage(imageId) {
      return this.get("store").peekAll("image").filterBy("cloudinaryId", imageId).get("firstObject");
    },

    getItemValuation: function getItemValuation(_ref) {
      var donor_condition_id = _ref.donorConditionId;
      var package_type_id = _ref.packageTypeId;
      var grade = _ref.grade;

      return this.GET("/packages/package_valuation", {
        donor_condition_id: donor_condition_id,
        package_type_id: package_type_id,
        grade: grade
      });
    },

    openItemsSearch: function openItemsSearch(item) {
      var _this2 = this;

      Ember.run(function () {
        _this2.set("openItemSearch", true);
        _this2.set("entity", item);
      });
    },

    addRemoveItem: function addRemoveItem(pkgId, params) {
      return this.PUT("/packages/" + pkgId + "/add_remove_item", params);
    },

    fetchContainedPackages: function fetchContainedPackages(boxPalletId, opts) {
      var pagination, data, containerQuantity, containedPackages;
      return regeneratorRuntime.async(function fetchContainedPackages$(context$1$0) {
        var _this3 = this;

        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            pagination = _lodash["default"].pick(opts, ["page", "per_page"]);
            context$1$0.next = 3;
            return regeneratorRuntime.awrap(this.GET("/packages/" + boxPalletId + "/contained_packages", pagination));

          case 3:
            data = context$1$0.sent;

            this.get("store").pushPayload(data);
            containerQuantity = data.meta.total_count;
            context$1$0.next = 8;
            return regeneratorRuntime.awrap(Promise.all(data.items.map(function callee$1$0(item) {
              var quantity;
              return regeneratorRuntime.async(function callee$1$0$(context$2$0) {
                while (1) switch (context$2$0.prev = context$2$0.next) {
                  case 0:
                    context$2$0.next = 2;
                    return regeneratorRuntime.awrap(this.fetchAddedQuantity(boxPalletId, item.id));

                  case 2:
                    quantity = context$2$0.sent;
                    return context$2$0.abrupt("return", _extends({}, item, {
                      addedQuantity: quantity.added_quantity,
                      isDeleted: false
                    }));

                  case 4:
                  case "end":
                    return context$2$0.stop();
                }
              }, null, _this3);
            })));

          case 8:
            containedPackages = context$1$0.sent;
            return context$1$0.abrupt("return", { containedPackages: containedPackages, containerQuantity: containerQuantity });

          case 10:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    fetchParentContainers: function fetchParentContainers(pkg) {
      var opts = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];
      var store, pagination, data;
      return regeneratorRuntime.async(function fetchParentContainers$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            store = this.get("store");
            pagination = _lodash["default"].pick(opts, ["page", "per_page"]);
            context$1$0.next = 4;
            return regeneratorRuntime.awrap(this.GET("/packages/" + (0, _stockUtilsHelpers.toID)(pkg) + "/parent_containers", pagination));

          case 4:
            data = context$1$0.sent;

            store.pushPayload(data);

            return context$1$0.abrupt("return", _lodash["default"].get(data, "items", []).map(function (it) {
              return store.peekRecord("item", it.id);
            }));

          case 7:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    fetchAddedQuantity: function fetchAddedQuantity(entityId, pkgID) {
      return this.GET("/packages/" + pkgID + "/fetch_added_quantity", {
        entity_id: entityId
      });
    },

    allChildPackageTypes: function allChildPackageTypes(item) {
      var all_package_types = this.getAssociatedPkgTypes(item, "defaultChildPackages").concat(this.getAssociatedPkgTypes(item, "otherChildPackages"));
      return all_package_types.getEach("id");
    },

    getAssociatedPkgTypes: function getAssociatedPkgTypes(item, type) {
      var defaultChildPackageTypes = item.get("code").get(type);
      return this._getPackageTypes(defaultChildPackageTypes);
    },

    _getPackageTypes: function _getPackageTypes(types) {
      var packageTypeNames = (types || "").split(",");
      var packagesTypes = [];
      var allPackageTypes = this.get("store").peekAll("code");
      packageTypeNames.map(function (type) {
        allPackageTypes.filter(function (pkgType) {
          return pkgType.get("code") === type ? packagesTypes.push(pkgType) : "";
        });
      });
      return packagesTypes;
    },

    /**
     * Removes a package from its set
     *
     * @param {Package} pkg the package to unlink
     * @returns {Promise<Model>}
     */
    removeFromSet: function removeFromSet(pkg) {
      var id, packageSet, lastPkg;
      return regeneratorRuntime.async(function removeFromSet$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            if (pkg.get("packageSetId")) {
              context$1$0.next = 2;
              break;
            }

            return context$1$0.abrupt("return", pkg);

          case 2:
            id = pkg.get("id");
            packageSet = pkg.get("packageSet");
            context$1$0.next = 6;
            return regeneratorRuntime.awrap(this.updatePackage(id, { "package": { package_set_id: null } }));

          case 6:

            packageSet.get("packageIds").removeObject(Number(id));

            if (packageSet.get("packageIds.length") === 1) {
              lastPkg = packageSet.get("items.firstObject");

              lastPkg.set("packageSet", null);
              lastPkg.set("packageSetId", null);
              packageSet.set("packageIds", []);
            }

            return context$1$0.abrupt("return", this.get("store").peekRecord("item", id));

          case 9:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    /**
     * Creates a set for the package if non-existent
     *
     * @param {Package} pkg the package to add to set
     * @param {PackageType} [pkgType] the package type of the set. If not provided, will request for it
     * @returns {Promise<PackageSet>} the existing newly created package set
     */
    initializeSetOf: function initializeSetOf(pkg, pkgType) {
      var code, payload, setId;
      return regeneratorRuntime.async(function initializeSetOf$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            if (!pkg.get("packageSet")) {
              context$1$0.next = 2;
              break;
            }

            return context$1$0.abrupt("return", pkg.get("packageSet"));

          case 2:
            if (!pkg.get("isBoxPallet")) {
              context$1$0.next = 4;
              break;
            }

            throw new Error(this.get("i18n").t("items.no_box_in_set"));

          case 4:
            context$1$0.t0 = pkgType;

            if (context$1$0.t0) {
              context$1$0.next = 9;
              break;
            }

            context$1$0.next = 8;
            return regeneratorRuntime.awrap(this.get("packageTypeService").userPickPackageType({
              storageType: "Package",
              headerText: this.get("i18n").t("items.select_set_type"),
              subsetPackageTypes: this.get("packageTypeService").parentsOf(pkg.get("code"))
            }));

          case 8:
            context$1$0.t0 = context$1$0.sent;

          case 9:
            code = context$1$0.t0;
            context$1$0.next = 12;
            return regeneratorRuntime.awrap(this.POST("/package_sets", {
              package_set: {
                package_type_id: code.get("id"),
                description: code.get("name")
              }
            }));

          case 12:
            payload = context$1$0.sent;
            setId = _lodash["default"].get(payload, "package_set.id");

            this.get("store").pushPayload(payload);

            context$1$0.next = 17;
            return regeneratorRuntime.awrap(this.updatePackage(pkg, { "package": { package_set_id: setId } }));

          case 17:
            return context$1$0.abrupt("return", this.get("store").peekRecord("package_set", setId));

          case 18:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    /**
     * Adds a package to a set
     *
     * @param {Package} pkg the package to add to set
     * @param {PackageSet} packageSet the package set to add to
     * @returns {Promise<Model>}
     */
    addToSet: function addToSet(pkg, packageSet) {
      var id, payload;
      return regeneratorRuntime.async(function addToSet$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            if (!pkg.get("packageSetId")) {
              context$1$0.next = 2;
              break;
            }

            throw new Error(this.get("i18n").t("item.already_in_set"));

          case 2:
            id = pkg.get("id");
            context$1$0.next = 5;
            return regeneratorRuntime.awrap(this.PUT("/packages/" + id, {
              "package": {
                package_set_id: packageSet.get("id")
              }
            }));

          case 5:
            payload = context$1$0.sent;

            this.get("store").pushPayload(payload);
            return context$1$0.abrupt("return", this.get("store").peekRecord("item", id));

          case 8:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    /**
     * Performs action on a package from the specified location
     *
     * @param {Package} pkg the package to move
     * @param {object} opts the move properties
     * @param {Location|string} opts.from the source location or its id
     * @param {number} opts.quantity the quantity to move
     * @param {string} opts.comment the comment of package action
     * @returns {Promise<Model>}
     */
    peformActionOnPackage: function peformActionOnPackage(pkg) {
      var opts = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];
      var from, actionName, quantity, comment, processing_destination_id, payload;
      return regeneratorRuntime.async(function peformActionOnPackage$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            from = opts.from;
            actionName = opts.actionName;
            quantity = opts.quantity;
            comment = opts.comment;
            processing_destination_id = opts.processing_destination_id;
            context$1$0.next = 7;
            return regeneratorRuntime.awrap(this.PUT("/packages/" + pkg.get("id") + "/actions/" + opts.actionName, {
              quantity: quantity,
              processing_destination_id: processing_destination_id,
              from: (0, _stockUtilsHelpers.toID)(from),
              description: comment
            }));

          case 7:
            payload = context$1$0.sent;

            this.get("store").pushPayload(payload);

            return context$1$0.abrupt("return", this.get("store").peekRecord("item", pkg.get("id")));

          case 10:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    /**
     * Splits a package into 2 separate packages
     *
     * @param {Package} pkg the package to split
     * @param {number} quantity the quantity to split off from the original
     * @returns {Promise<Package>}
     */
    splitPackage: function splitPackage(pkg, quantity) {
      var payload;
      return regeneratorRuntime.async(function splitPackage$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return regeneratorRuntime.awrap(this.PUT("/items/" + (0, _stockUtilsHelpers.toID)(pkg) + "/split_item", {
              "package": { quantity: quantity }
            }));

          case 2:
            payload = context$1$0.sent;

            this.get("store").pushPayload(payload);

            return context$1$0.abrupt("return", this.get("store").peekRecord("item", (0, _stockUtilsHelpers.toID)(pkg)));

          case 5:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    /**
     * Triggers the package selection popup, and resolves the promise
     * once a package has been selected.
     *
     * null is returned if the user closes the UI
     *
     * @param {object} opts search options
     * @returns {Promise<Model>}
     */
    userPickPackage: function userPickPackage() {
      var _this4 = this;

      var opts = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

      var deferred = Ember.RSVP.defer();

      Ember.run(function () {
        _this4.set("packageSearchOptions", opts);
        _this4.set("openPackageSearch", true);
        _this4.set("onPackageSelected", function (pkg) {
          _this4.set("onPackageSelected", _lodash["default"].noop);
          _this4.set("openPackageSearch", false);
          deferred.resolve(pkg || null);
        });
      });

      return deferred.promise;
    }
  });
});