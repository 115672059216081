define("stock/controllers/orders/active_items", ["exports", "stock/controllers/orders/detail", "stock/constants/orders-packages-sorting-options", "stock/mixins/search_resource", "lodash"], function (exports, _stockControllersOrdersDetail, _stockConstantsOrdersPackagesSortingOptions, _stockMixinsSearch_resource, _lodash) {
  exports["default"] = _stockControllersOrdersDetail["default"].extend(_stockMixinsSearch_resource["default"], {
    // ----- Services -----
    goodcityRequestService: Ember.inject.service(),
    packageService: Ember.inject.service(),
    packageTypeService: Ember.inject.service(),

    // ----- Arguments -----
    dropDownItems: _lodash["default"].cloneDeep(_stockConstantsOrdersPackagesSortingOptions.ORDERS_PACKAGES_SORTING_OPTIONS),
    states: _lodash["default"].cloneDeep(_stockConstantsOrdersPackagesSortingOptions.ORDERS_PACKAGES_STATES),
    displayDropDownItems: false,
    sortingColumn: _lodash["default"].cloneDeep(_stockConstantsOrdersPackagesSortingOptions.ORDERS_PACKAGES_SORTING_OPTIONS)[0],
    autoLoad: true,
    /*
     * @type {Number}, perPage in response
     */
    perPage: 25,
    ordersPkgLength: 0,
    sortProperties: ["id"],

    // ----- Computed Properties -----
    sortedGcRequests: Ember.computed.sort("model.goodcityRequests", "sortProperties"),

    filteredStates: Ember.computed("states.@each.enabled", function () {
      return _lodash["default"].filter(this.get("states"), ["enabled", true]).map(function (_ref) {
        var state = _ref.state;
        return state;
      });
    }),

    onStatesChange: Ember.observer("states.@each.enabled", function () {
      this.reloadResults();
    }),

    // ----- Helpers -----
    getStates: function getStates() {
      var utilities = this.get("utilityMethods");
      var state = utilities.stringifyArray(this.get("filteredStates"));
      return {
        state: state
      };
    },

    getSortQuery: function getSortQuery() {
      var sortOptions = this.get("sortingColumn");
      return {
        sort_column: sortOptions["column_alias"],
        is_desc: /desc/.test(sortOptions["sort"])
      };
    },

    // ----- Actions -----
    actions: {
      loadOrdersPackages: function loadOrdersPackages(pageNo) {
        var _this = this;

        var params = this.trimQuery(_lodash["default"].merge({ order_id: this.get("orderId") }, this.getSearchQuery(), this.getPaginationQuery(pageNo), this.getStates(), this.getSortQuery()));
        return this.get("store").query("orders_package", params).then(function (ordersPkgs) {
          _this.set("ordersPkgLength", ordersPkgs.meta.orders_packages_count);
          return ordersPkgs;
        });
      },

      applySortOn: function applySortOn(options) {
        this.set("sortingColumn", options);
        this.reloadResults();
      },

      addRequest: function addRequest() {
        var pgkType;
        return regeneratorRuntime.async(function addRequest$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              context$1$0.next = 2;
              return regeneratorRuntime.awrap(this.get("packageTypeService").userPickPackageType());

            case 2:
              pgkType = context$1$0.sent;

              if (!pgkType) {
                context$1$0.next = 6;
                break;
              }

              context$1$0.next = 6;
              return regeneratorRuntime.awrap(this.get("goodcityRequestService").createGcRequest({
                package_type_id: pgkType.get("id"),
                quantity: 1,
                order_id: this.get("order.id")
              }));

            case 6:
            case "end":
              return context$1$0.stop();
          }
        }, null, this);
      },

      clearSearch: function clearSearch() {
        this.set("searchText", "");
      }
    }
  });
});