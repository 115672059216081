define("stock/adapters/offer", ["exports", "stock/adapters/application", "stock/config/environment", "ember-inflector"], function (exports, _stockAdaptersApplication, _stockConfigEnvironment, _emberInflector) {
  exports["default"] = _stockAdaptersApplication["default"].extend({
    urlForQuery: function urlForQuery(_ref, modelName) {
      var slug = _ref.slug;
      var _config$APP = _stockConfigEnvironment["default"].APP;
      var NAMESPACE = _config$APP.NAMESPACE;
      var API_HOST_URL = _config$APP.API_HOST_URL;

      if (slug) {
        return API_HOST_URL + "/" + NAMESPACE + "/" + (0, _emberInflector.pluralize)(modelName) + "/" + slug;
      }
      return this._super.apply(this, arguments);
    }
  });
});