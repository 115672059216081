define('stock/models/purpose', ['exports', 'ember', 'ember-data/model', 'ember-data/attr'], function (exports, _ember, _emberDataModel, _emberDataAttr) {

  var TRANSLATION_KEYS = {
    'trade': 'order.for_charity_sale',
    'organisation': 'order.for_our_charity',
    'client': 'order.for_client'
  };

  exports['default'] = _emberDataModel['default'].extend({
    i18n: _ember['default'].inject.service(),

    nameEn: (0, _emberDataAttr['default'])('string'),
    nameZhTw: (0, _emberDataAttr['default'])('string'),
    identifier: (0, _emberDataAttr['default'])('string'),

    description: _ember['default'].computed('nameEn', function () {
      var name = this.get('nameEn');
      return name && this.get('i18n').t(TRANSLATION_KEYS[name.toLowerCase()]);
    })
  });
});