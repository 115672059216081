define("stock/mixins/organisation", ["exports", "ember", "stock/mixins/async"], function (exports, _ember, _stockMixinsAsync) {
  exports["default"] = _ember["default"].Mixin.create(_stockMixinsAsync["default"], {
    organisationService: _ember["default"].inject.service(),
    /**
     * Open's the organisation overlay.
     * Returns the selected organisation record
     * @returns {Object}
     */
    organisationLookup: function organisationLookup() {
      var organisation;
      return regeneratorRuntime.async(function organisationLookup$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return regeneratorRuntime.awrap(this.get("organisationService").userPickOrganisation());

          case 2:
            organisation = context$1$0.sent;
            return context$1$0.abrupt("return", organisation);

          case 4:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    actions: {
      /**
       * Open's the organisation overlay.
       * Once the organisation is selected, navigates to the organisation detail screen
       */
      withOrganisationNavigation: function withOrganisationNavigation() {
        var organisation;
        return regeneratorRuntime.async(function withOrganisationNavigation$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              context$1$0.next = 2;
              return regeneratorRuntime.awrap(this.organisationLookup());

            case 2:
              organisation = context$1$0.sent;
              return context$1$0.abrupt("return", this.replaceRoute("organisations.detail", organisation.id));

            case 4:
            case "end":
              return context$1$0.stop();
          }
        }, null, this);
      }
    }
  });
});