define("stock/mixins/search_resource", ["exports", "ember", "lodash"], function (exports, _ember, _lodash) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  /**
   * SearchMixin
   *
   * @mixin SearchMixin
   * @property {Number} `perPage`: number of results in response. <br>
   * @property {Boolean} `autoLoad`: to query on model as soon we land on the page
   * @description utilities to implement searching
   *
   * <br> Requirements:
   * <br>  - This controllers sets and unsets `displayResults`
   * <br>  - call `on()` and `off()` from calling page route for auto initial load of the records and cleaning the record.
   * <br>    Example:
   * <br>     setupController() { controller.on() },
   * <br>     resetController(controller) { controller.off() }
   **/
  exports["default"] = _ember["default"].Mixin.create({
    // ----- Services -----
    filterService: _ember["default"].inject.service(),
    utilityMethods: _ember["default"].inject.service(),

    // ----- Arguments -----
    minSearchTextLength: 2,
    searchText: "",
    displayResults: false,
    countryArray: [],
    searchProps: {
      shallow: true
    },

    // ----- Observers -----
    on: function on() {
      this.showResults(); // Upon opening the page, we populate with results
      this.get("filterService").on("change", this, this.reloadResults);
    },

    off: function off() {
      this.get("filterService").off("change", this, this.reloadResults);
    },

    onSearchTextChange: _ember["default"].observer("searchText", function () {
      if (this.isValidTextLength()) {
        this.reloadResults();
      }
    }),

    // ----- Helpers ------
    isValidTextLength: function isValidTextLength() {
      var searchTextLength = (this.get("searchText") || "").length;
      return searchTextLength > this.get("minSearchTextLength") || searchTextLength === 0;
    },

    sanitizeString: function sanitizeString(str) {
      // these are the special characters '.,)(@_-' that are allowed for search
      // '\.' => will allow '.'
      // '\(' => will allow '('
      // '\@' => will allow '@'
      // '\)' => will allow ')'
      str = str.replace(/[^a-z0-9áéíóúñü \.,\)\(@_-]/gim, "");
      return str.trim();
    },

    /**
     * Hide existing result and make request
     *
     * @instance
     * @memberof SearchMixin
     **/
    reloadResults: function reloadResults() {
      this.hideResults();
      _ember["default"].run.debounce(this, this.showResults, 500);
    },

    /**
     * Hide existing results
     *
     * @instance
     * @memberof SearchMixin
     **/
    hideResults: function hideResults() {
      var _this = this;

      _ember["default"].run(function () {
        _this.set("displayResults", false);
      });
    },

    /**
     * Triggers the initial fetch request and shows the results
     *
     * @instance
     * @memberof SearchMixin
     **/
    showResults: function showResults() {
      var _this2 = this;

      _ember["default"].run(function () {
        _this2.set("displayResults", true);
      });
    },

    /**
     * Returns pagination query Object with per_page and pageNo
     *
     * @instance
     * @memberof SearchMixin
     * @param {pageNo} pageNo to query
     * @returns {object} the pagination query parameters
     **/
    getPaginationQuery: function getPaginationQuery(pageNo) {
      return {
        per_page: this.get("perPage"),
        page: pageNo
      };
    },

    /**
     * Returns searchText to be query
     *
     * @instance
     * @memberof SearchMixin
     * @returns {object} the search parameters
     **/
    getSearchQuery: function getSearchQuery() {
      return _extends({
        searchText: this.get("searchText")
      }, this.get("searchProps"));
    },

    /**
     * Remove undefined values from query Object
     *
     * @instance
     * @memberof SearchMixin
     * @param {object} query the query object
     * @returns {object} the trimmed object
     **/
    trimQuery: function trimQuery(query) {
      // Remove any undefined values
      return _lodash["default"].pickBy(query, _lodash["default"].identity);
    }
  });
});