define("stock/routes/orders/items", ["exports", "stock/routes/orders/get_order"], function (exports, _stockRoutesOrdersGet_order) {
  exports["default"] = _stockRoutesOrdersGet_order["default"].extend({
    queryParams: {
      searchInput: ""
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      if (this.paramsFor("orders.items").searchInput) {
        controller.set("searchText", this.paramsFor("orders.items").searchInput);
      }
    },

    resetController: function resetController(controller) {
      controller.hideResults();
    }
  });
});