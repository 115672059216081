define("stock/utils/ajax-promise", ["exports", "ember", "stock/config/environment"], function (exports, _ember, _stockConfigEnvironment) {

  var defaultHeaders = {
    "X-GOODCITY-APP-NAME": _stockConfigEnvironment["default"].APP.NAME,
    "X-GOODCITY-APP-VERSION": _stockConfigEnvironment["default"].APP.VERSION,
    "X-GOODCITY-APP-SHA": _stockConfigEnvironment["default"].APP.SHA
  };

  function _read(data) {
    if (typeof data == "function") {
      return data();
    }
    return data;
  }

  function getServerPath(namespace) {
    var host = _stockConfigEnvironment["default"].APP.API_HOST_URL + "/";
    return Number(namespace) == 2 ? "" + host + _stockConfigEnvironment["default"].APP.NAMESPACE_V2 : "" + host + _stockConfigEnvironment["default"].APP.NAMESPACE;
  }
  function AjaxPromise(url, type, authToken, data, args) {
    var language = arguments.length <= 5 || arguments[5] === undefined ? "en" : arguments[5];

    var _ref = args ? args : "";

    var version = _ref.version;

    return new _ember["default"].RSVP.Promise(function (resolve, reject) {
      var headers = _ember["default"].$.extend({}, _read(defaultHeaders), {
        "Accept-Language": language
      });

      if (authToken) {
        headers["Authorization"] = "Bearer " + authToken;
      }

      _ember["default"].$.ajax(_ember["default"].$.extend({}, {
        type: type,
        dataType: "json",
        data: data,
        language: language,
        url: url.indexOf("http") === -1 ? getServerPath(version) + url : url,
        headers: headers,
        success: function success(data) {
          _ember["default"].run(function () {
            resolve(data);
          });
        },
        error: function error(jqXHR) {
          jqXHR.url = url;
          _ember["default"].run(function () {
            reject(jqXHR);
          });
        }
      }, args));
    });
  }

  AjaxPromise.setDefaultHeaders = function (headers) {
    defaultHeaders = headers;
  };

  exports["default"] = AjaxPromise;
});