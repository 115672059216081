define("stock/routes/users/details", ["exports", "stock/routes/authorize"], function (exports, _stockRoutesAuthorize) {
  exports["default"] = _stockRoutesAuthorize["default"].extend({
    model: function model(params) {
      return this.loadIfAbsent("user", params.user_id);
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set("searchUser", false);
    }
  });
});