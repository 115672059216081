define("stock/models/user", ["exports", "ember-data/attr", "ember", "ember-data/relationships", "stock/models/addressable"], function (exports, _emberDataAttr, _ember, _emberDataRelationships, _stockModelsAddressable) {
  exports["default"] = _stockModelsAddressable["default"].extend({
    firstName: (0, _emberDataAttr["default"])("string"),
    lastName: (0, _emberDataAttr["default"])("string"),
    mobile: (0, _emberDataAttr["default"])("string"),
    createdAt: (0, _emberDataAttr["default"])("date"),
    email: (0, _emberDataAttr["default"])("string"),
    organisations_users_ids: (0, _emberDataAttr["default"])(),
    lastConnected: (0, _emberDataAttr["default"])("date"),
    lastDisconnected: (0, _emberDataAttr["default"])("date"),
    i18n: _ember["default"].inject.service(),
    userRoleIds: (0, _emberDataAttr["default"])(""),
    title: (0, _emberDataAttr["default"])("string"),
    preferredLanguage: (0, _emberDataAttr["default"])("string"),
    isEmailVerified: (0, _emberDataAttr["default"])("boolean"),
    isMobileVerified: (0, _emberDataAttr["default"])("boolean"),
    disabled: (0, _emberDataAttr["default"])("boolean"),

    printersUsers: (0, _emberDataRelationships.hasMany)("printersUsers", { async: false }),

    image: (0, _emberDataRelationships.belongsTo)("image", {
      async: false
    }),

    address: (0, _emberDataRelationships.belongsTo)("address", {
      async: false
    }),

    associatedDistrict: _ember["default"].computed("address", function () {
      return this.get("address.district");
    }),

    userRoles: (0, _emberDataRelationships.hasMany)("userRoles", {
      async: false
    }),

    activeUserRoles: _ember["default"].computed("userRoles.[]", "userRoles.@each.expiresAt", function () {
      return this.get("userRoles").filter(function (userRole) {
        return !userRole.get("expiresAt") || userRole.get("expiresAt") && moment.tz(userRole.get("expiresAt"), "Asia/Hong_Kong").isAfter();
      });
    }),

    activeRoles: _ember["default"].computed("activeUserRoles.[]", function () {
      return this.get("activeUserRoles").map(function (userRole) {
        return userRole.get("role");
      });
    }),

    roles: _ember["default"].computed("userRoles.[]", function () {
      return this.get("userRoles").map(function (userRole) {
        return userRole.get("role");
      });
    }),

    fullName: _ember["default"].computed("firstName", "lastName", function () {
      // To avoid the undefined undefined in case record is not loaded yet
      var firstName = this.get("firstName");
      var lastName = this.get("lastName");

      return (firstName ? firstName : "") + " " + (lastName ? lastName : "");
    }),

    fullNameWithUnknown: _ember["default"].computed("firstName", "lastName", function () {
      // Replace undefined with <unknown>
      var firstName = this.get("firstName");
      var lastName = this.get("lastName");

      return (firstName ? firstName : "<unknown>") + " " + (lastName ? lastName : "<unknown>");
    }),

    shortMobile: _ember["default"].computed("mobile", function () {
      // Remove HK country code for easier readability on display
      var mobile = this.get("mobile");
      return "" + (mobile ? mobile.replace("+852", "") : "");
    }),

    organisations: _ember["default"].computed("organisations_users_ids", function () {
      var _this = this;

      var ids = this.get("organisationsUsers").map(function (record) {
        return record.get("organisationId");
      });
      return ids.map(function (id) {
        return _this.store.peekRecord("organisation", id);
      });
    }),

    organisationsUsers: (0, _emberDataRelationships.hasMany)("organisationsUsers", {
      async: false
    }),

    position: _ember["default"].computed("organisationsUsers.[]", function () {
      var organisationsUsers = this.get("organisationsUsers");
      return organisationsUsers.length ? organisationsUsers.get("firstObject.position") : "";
    }),

    preferredContactNumber: _ember["default"].computed("organisationsUsers.[]", function () {
      var organisationsUser = this.get("organisationsUsers.firstObject");
      return organisationsUser ? organisationsUser.get("preferredContactNumber") : "";
    }),

    onlineStatusLabel: _ember["default"].computed("lastConnected", "lastDisconnected", function () {
      var i18n = this.get("i18n");
      if (!this.get("lastConnected") && !this.get("lastDisconnected")) {
        return i18n.t("online_status.not_connected");
      } else if (this.get("lastDisconnected") > this.get("lastConnected")) {
        return false;
      } else if (this.get("lastDisconnected") < this.get("lastConnected")) {
        return i18n.t("online_status.online");
      }
    })
  });
});