define('stock/components/message-box-click-button', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    btn1Click: false,
    btn2Click: false,
    toggleEnter: true,

    keyUp: function keyUp(e) {
      var pressEnter = this.toggleProperty('toggleEnter');
      var messageBox = _ember['default'].$('#messageBox')[0];

      if (e.which === 13 && messageBox && pressEnter) {
        if (this.get('btn1Click')) {
          this.clickBtn1();
        } else if (this.get('btn2Click')) {
          //btn2 related code
        } else {
            this.clickBtn1();
          }
      }
    },

    clickBtn1: function clickBtn1() {
      _ember['default'].$('#messageBox #btn1').each(function (message) {
        console.log(message);
        var messageText = _ember['default'].$('#messageBox p#messageBoxText').text().trim().toLowerCase();
        var btn1Text = _ember['default'].$('#messageBox #btn1').text().toLowerCase();
        if ((btn1Text.indexOf('okay') >= 0 || btn1Text.indexOf('ok') >= 0) && messageText.indexOf('invalid sms code') >= 0) {
          _ember['default'].run.later(function () {
            _ember['default'].$('#messageBox #btn1').click();
          }, 250);
        }
      });
    }
  });
});