define("stock/constants/action-icons", ["exports"], function (exports) {
  var ACTIONS_ICONS = [{
    name: "Process",
    icon: "random"
  }, {
    name: "Recycle",
    icon: "recycle"
  }, {
    name: "Trash",
    icon: "dumpster"
  }, {
    name: "Gain",
    icon: "folder-plus"
  }, {
    name: "Loss",
    icon: "folder-minus"
  }, {
    name: "Edited",
    icon: "pencil-alt"
  }, {
    name: "Inventory",
    icon: "tag"
  }, {
    name: "Pack",
    icon: "cube"
  }, {
    name: "designated",
    icon: "shopping-basket"
  }, {
    name: "Dispatch",
    icon: "paper-plane"
  }, {
    name: "Undispatch",
    icon: "reply"
  }];
  exports.ACTIONS_ICONS = ACTIONS_ICONS;
});