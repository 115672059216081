define("stock/routes/my_notifications", ["exports", "stock/routes/authorize"], function (exports, _stockRoutesAuthorize) {
  exports["default"] = _stockRoutesAuthorize["default"].extend({
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.on();
    },

    resetController: function resetController(controller, isExiting) {
      this._super.apply(this, arguments);

      if (isExiting) {
        controller.set("notifications", []);
      }

      controller.off();
    }
  });
});