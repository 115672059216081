define("stock/components/goodcity/orders-package-block", ["exports", "ember", "lodash", "stock/mixins/async", "stock/mixins/dispatch_actions", "stock/constants/states"], function (exports, _ember, _lodash, _stockMixinsAsync, _stockMixinsDispatch_actions, _stockConstantsStates) {

  var ACTIONS_SETTINGS = {
    dispatch: {
      icon: "paper-plane",
      customAction: "beginDispatch"
    },
    cancel: {
      icon: "times-circle"
    },
    undispatch: {
      icon: "reply",
      params: ["location_id"]
    },
    redesignate: {
      icon: "shopping-basket",
      customAction: "beginDesignation"
    },
    edit_quantity: {
      icon: "edit",
      customAction: "beginDesignation"
    }
  };

  var CANCELLED = {};

  /**
   * UI Component representing an orders_package
   *
   *  --------------------------------------------------------
   *  | op_state  |   order_code         order_state |    <  |
   *  |           |                                  |  <    |
   *  | qty       |   organisation                   |    <  |
   *  --------------------------------------------------------
   *
   * @module Components/goodcity/orders-package-block
   * @augments ember/Component
   * @param {Model} orderPkg the orders_package to display
   * @description
   * <br> It provides a drawer from the right side which is populated
   * <br> with the actions provided by the backend
   * <br>
   * <br> Clicking on an action will run it on the API side
   *
   * @example
   *  {{goodcity/orders-package-block orderPkg=orderPkg }}
   */
  exports["default"] = _ember["default"].Component.extend(_stockMixinsAsync["default"], _stockMixinsDispatch_actions["default"], {
    store: _ember["default"].inject.service(),
    messageBox: _ember["default"].inject.service(),
    i18n: _ember["default"].inject.service(),
    designationService: _ember["default"].inject.service(),
    locationService: _ember["default"].inject.service(),
    settings: _ember["default"].inject.service(),
    actionRunner: _ember["default"].computed.alias("designationService"),

    editableQty: _ember["default"].computed.alias("settings.allowPartialOperations"),
    drawerOpened: false,

    init: function init() {
      this._super.apply(this, arguments);
      this.set("paramGetters", {
        order_id: "selectOrder",
        location_id: "selectLocation",
        quantity: "selectQuantity"
      });
    },

    // --- PARAMS RESOLUTION

    /**
     * Prompts the user to select an order, and returns its id
     *
     * CANCELLED is returned if the user closes the UI
     *
     * @private
     * @returns {Promise<String>}
     */
    selectOrder: function selectOrder() {
      var order;
      return regeneratorRuntime.async(function selectOrder$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return regeneratorRuntime.awrap(this.get("designationService").userPickOrder({
              state: _stockConstantsStates.ACTIVE_ORDER_STATES.join(",")
            }));

          case 2:
            order = context$1$0.sent;
            return context$1$0.abrupt("return", order ? order.get("id") : CANCELLED);

          case 4:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    /**
     * Prompts the user to select a location, and returns its id
     *
     * CANCELLED is returned if the user closes the UI
     *
     * @private
     * @returns {Promise<String>}
     */
    selectLocation: function selectLocation() {
      var location;
      return regeneratorRuntime.async(function selectLocation$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return regeneratorRuntime.awrap(this.get("locationService").userPickLocation({
              headerText: this.get("i18n").t("select_location.pick_to_location")
            }));

          case 2:
            location = context$1$0.sent;
            return context$1$0.abrupt("return", location ? location.get("id") : CANCELLED);

          case 4:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    /**
     * Given an actionn name, returns an object with all the required properties
     * to successfully
     *
     * CANCELLED if the user did not provide the
     *
     * @private
     * @memberof OrdersPackageBlock
     * @param {string} actionName the action being run
     * @returns the options to send to the api
     */
    fulfillParams: function fulfillParams(actionName) {
      var paramNames, params, _iteratorNormalCompletion, _didIteratorError, _iteratorError, _iterator, _step, _name, getterName, val;

      return regeneratorRuntime.async(function fulfillParams$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            paramNames = _lodash["default"].get(ACTIONS_SETTINGS, actionName + ".params", []);
            params = {};
            _iteratorNormalCompletion = true;
            _didIteratorError = false;
            _iteratorError = undefined;
            context$1$0.prev = 5;
            _iterator = paramNames[Symbol.iterator]();

          case 7:
            if (_iteratorNormalCompletion = (_step = _iterator.next()).done) {
              context$1$0.next = 19;
              break;
            }

            _name = _step.value;
            getterName = this.getWithDefault("paramGetters." + _name, "");
            context$1$0.next = 12;
            return regeneratorRuntime.awrap(this[getterName]());

          case 12:
            val = context$1$0.sent;

            if (!(val === CANCELLED)) {
              context$1$0.next = 15;
              break;
            }

            return context$1$0.abrupt("return", CANCELLED);

          case 15:
            params[_name] = val;

          case 16:
            _iteratorNormalCompletion = true;
            context$1$0.next = 7;
            break;

          case 19:
            context$1$0.next = 25;
            break;

          case 21:
            context$1$0.prev = 21;
            context$1$0.t0 = context$1$0["catch"](5);
            _didIteratorError = true;
            _iteratorError = context$1$0.t0;

          case 25:
            context$1$0.prev = 25;
            context$1$0.prev = 26;

            if (!_iteratorNormalCompletion && _iterator["return"]) {
              _iterator["return"]();
            }

          case 28:
            context$1$0.prev = 28;

            if (!_didIteratorError) {
              context$1$0.next = 31;
              break;
            }

            throw _iteratorError;

          case 31:
            return context$1$0.finish(28);

          case 32:
            return context$1$0.finish(25);

          case 33:
            return context$1$0.abrupt("return", params);

          case 34:
          case "end":
            return context$1$0.stop();
        }
      }, null, this, [[5, 21, 25, 33], [26,, 28, 32]]);
    },

    // --- EXECUTION

    onError: function onError(reason) {
      var defaultMessage = this.get("i18n").t("unexpected_error");
      var message = _lodash["default"].get(reason, "responseJSON.errors[0].message", defaultMessage);
      this.get("messageBox").alert(message);
    },

    /**
     * Runs the desired action
     *
     * @memberof OrdersPackageBlock
     * @private
     * @param {string} actionName the action to run
     * @returns {Promise<Package>} the updated package
     */
    runAction: function runAction(actionName) {
      var ordersPkg, customAction, params, itemId;
      return regeneratorRuntime.async(function runAction$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            ordersPkg = this.get("orderPkg");
            customAction = _lodash["default"].get(ACTIONS_SETTINGS, actionName + ".customAction", null);

            if (!customAction) {
              context$1$0.next = 5;
              break;
            }

            this.send("closeDrawer");
            return context$1$0.abrupt("return", this.send(customAction, ordersPkg));

          case 5:
            context$1$0.next = 7;
            return regeneratorRuntime.awrap(this.fulfillParams(actionName));

          case 7:
            params = context$1$0.sent;
            itemId = ordersPkg.get("itemId");

            if (!(params === CANCELLED)) {
              context$1$0.next = 11;
              break;
            }

            return context$1$0.abrupt("return");

          case 11:
            context$1$0.prev = 11;

            this.send("closeDrawer");

            context$1$0.next = 15;
            return regeneratorRuntime.awrap(this.runTask(this.get("actionRunner").execAction(ordersPkg, actionName, params)));

          case 15:
            return context$1$0.abrupt("return", this.get("store").findRecord("item", itemId, { reload: true }));

          case 18:
            context$1$0.prev = 18;
            context$1$0.t0 = context$1$0["catch"](11);

            this.onError(context$1$0.t0);

          case 21:
          case "end":
            return context$1$0.stop();
        }
      }, null, this, [[11, 18]]);
    },

    // --- MENU

    labelFor: function labelFor(actionName) {
      return this.get("i18n").t("orders_package.actions." + actionName);
    },

    iconFor: function iconFor(actionName) {
      return _lodash["default"].get(ACTIONS_SETTINGS, actionName + ".icon", []);
    },

    actionList: _ember["default"].computed("orderPkg.id", "orderPkg.allowedActions.@each", "orderPkg.allowedActions.[]", function () {
      var _this = this;

      var filter = function filter(_ref) {
        var name = _ref.name;

        if (_this.get("actionsFilter")) {
          return _this.get("actionsFilter").includes(name);
        }
        return true;
      };

      return this.getWithDefault("orderPkg.allowedActions", []).filter(filter).map(function (_ref2) {
        var name = _ref2.name;
        var enabled = _ref2.enabled;
        return {
          label: _this.labelFor(name),
          icon: _this.iconFor(name),
          enabled: name === "redesignate" ? true : enabled,
          trigger: _this.runAction.bind(_this, name)
        };
      });
    }),

    actions: {
      closeDrawer: function closeDrawer() {
        var _this2 = this;

        _ember["default"].run(function () {
          return _this2.set("drawerOpened", false);
        });
      },

      redirectToOrderDetail: function redirectToOrderDetail(orderId) {
        this.router.transitionTo("orders.active_items", orderId);
      },

      redirectToItemDetails: function redirectToItemDetails(itemId) {
        this.router.transitionTo("items.detail", itemId);
      },

      redirectToDetails: function redirectToDetails() {
        if (this.get("packageView")) {
          this.send("redirectToItemDetails", this.get("orderPkg.item.id"));
        } else {
          this.send("redirectToOrderDetail", this.get("orderPkg.designation.id"));
        }
      },

      beginDesignation: function beginDesignation(orderPkg) {
        this.get("designationService").beginDesignation({
          pkg: orderPkg.get("item"),
          order: orderPkg.get("designation"),
          isEditOperation: true,
          allowOrderChange: true
        });
      }
    }
  });
});