define("stock/routes/users/add_user", ["exports", "stock/routes/authorize"], function (exports, _stockRoutesAuthorize) {
  exports["default"] = _stockRoutesAuthorize["default"].extend({
    queryParams: {
      organisationId: null
    },

    model: function model() {
      var districts = this.store.query("district", {});
      return Ember.RSVP.hash({
        districts: districts
      });
    }
  });
});