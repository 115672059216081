define('stock/components/validate-appointment', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    didInsertElement: function didInsertElement() {
      this._super();

      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        validateInputs();
        validateForm();
        validateCheckCheckBoxInput();
      });

      function validateForm() {
        _ember['default'].$('.appointment').click(function () {
          _ember['default'].$.each(['.pickadate'], function (i, input) {
            checkInput(_ember['default'].$(input));
          });
          checkCheckBoxInput(_ember['default'].$('.checkbox input')[0]);

          if (_ember['default'].$('.form__control--error').length > 0) {
            return false;
          }
        });
      }

      function validateInputs() {
        _ember['default'].$('.pickadate').focusout(function () {
          _ember['default'].run.later(function () {
            return checkInput(this);
          }, 100);
        });

        _ember['default'].$('.pickadate').focus(function () {
          return removeHighlight(this);
        });
      }

      function validateCheckCheckBoxInput() {
        _ember['default'].$('.checkbox input').click(function () {
          return checkCheckBoxInput(this);
        });
      }

      function checkInput(element) {
        var parent = _ember['default'].$(element).parent();
        var value = _ember['default'].$(element).val();

        if (value === undefined || value.length === 0) {
          parent.addClass('form__control--error');
        } else {
          parent.removeClass('form__control--error');
        }
      }

      function checkCheckBoxInput(element) {
        var parent = _ember['default'].$(element).parent();
        if (!element.checked) {
          parent.addClass('form__control--error');
        } else {
          parent.removeClass('form__control--error');
        }
      }

      function removeHighlight(element) {
        var parent = _ember['default'].$(element).parent();
        parent.removeClass('form__control--error');
      }
    }
  });
});