define('stock/helpers/searchable-select-highlight-match', ['exports', 'ember-searchable-select/helpers/searchable-select-highlight-match'], function (exports, _emberSearchableSelectHelpersSearchableSelectHighlightMatch) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberSearchableSelectHelpersSearchableSelectHighlightMatch['default'];
    }
  });
  Object.defineProperty(exports, 'searchableSelectHighlightMatch', {
    enumerable: true,
    get: function get() {
      return _emberSearchableSelectHelpersSearchableSelectHighlightMatch.searchableSelectHighlightMatch;
    }
  });
});