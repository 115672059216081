define("stock/routes/items/new", ["exports", "stock/routes/authorize", "ember", "stock/mixins/grades_option"], function (exports, _stockRoutesAuthorize, _ember, _stockMixinsGrades_option) {
  exports["default"] = _stockRoutesAuthorize["default"].extend(_stockMixinsGrades_option["default"], {
    inventoryNumber: "",
    newItemRequest: "",
    packageService: _ember["default"].inject.service(),
    printerService: _ember["default"].inject.service(),
    session: _ember["default"].inject.service(),
    store: _ember["default"].inject.service(),

    queryParams: {
      codeId: {
        replace: true
      },
      locationId: {
        replace: true
      },
      scanLocationName: {
        replace: true
      },
      storageType: {
        replace: true
      }
    },

    beforeModel: function beforeModel(_ref) {
      var _ref$queryParams = _ref.queryParams;
      var queryParams = _ref$queryParams === undefined ? {} : _ref$queryParams;

      this._super.apply(this, arguments);

      var hasCodeId = !!queryParams.codeId;

      if (!hasCodeId) {
        this.replaceWith("search_code");
      }
    },

    isSubformAllowed: function isSubformAllowed(selectedSubform) {
      return ["computer", "electrical", "computer_accessory", "medical"].indexOf(selectedSubform) >= 0;
    },

    afterModel: function afterModel() {
      var reload, storageType;
      return regeneratorRuntime.async(function afterModel$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            reload = true;
            storageType = this.paramsFor("items.new").storageType;
            context$1$0.next = 4;
            return regeneratorRuntime.awrap(this.store.findAll("location", { reload: reload }));

          case 4:
            if (!(["Box", "Pallet"].indexOf(storageType) < 0)) {
              context$1$0.next = 7;
              break;
            }

            context$1$0.next = 7;
            return regeneratorRuntime.awrap(_ember["default"].RSVP.all([this.store.findAll("restriction", { reload: reload }), this.store.findAll("donor_condition", { reload: reload })]));

          case 7:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    setupPrinterId: function setupPrinterId(controller) {
      var defaultPrinter = this.get("printerService").getDefaultPrinter();
      if (defaultPrinter) {
        controller.set("selectedPrinterId", defaultPrinter.id);
      } else {
        var allAvailablePrinters = this.get("printerService").allAvailablePrinters();
        if (allAvailablePrinters.length) {
          var firstPrinterId = allAvailablePrinters[0].id;
          this.get("printerService").addDefaultPrinter(firstPrinterId);
          controller.set("selectedPrinterId", firstPrinterId);
        }
      }
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var storageType = this.paramsFor("items.new").storageType;
      var isBoxPallet = ["Box", "Pallet"].indexOf(storageType) > -1;
      this.initializeController();
      this.initializeAttributes(isBoxPallet, controller);
      if (!isBoxPallet) {
        this.manageSubformDetails();
      }
      this.setUpPackageImage();
      this.setupPrinterId(controller);
    },

    initializeController: function initializeController() {
      var controller;
      return regeneratorRuntime.async(function initializeController$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            controller = this.controller;

            controller.set("showAdditionalFields", false);

            if (controller.get("inventoryNumber")) {
              context$1$0.next = 5;
              break;
            }

            context$1$0.next = 5;
            return regeneratorRuntime.awrap(controller.send("autoGenerateInventoryNumber"));

          case 5:
            controller.set("invalidLocation", false);
            controller.set("invalidScanResult", false);
            controller.set("labels", 1);
            controller.set("offersLists", []);

          case 9:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    manageSubformDetails: function manageSubformDetails() {
      var controller, store, codeId, selected, selectedSubform, details;
      return regeneratorRuntime.async(function manageSubformDetails$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            controller = this.controller;
            store = this.store;
            codeId = controller.get("codeId");

            if (codeId) {
              context$1$0.next = 5;
              break;
            }

            return context$1$0.abrupt("return");

          case 5:
            selected = store.peekRecord("code", codeId);

            if (selected) {
              context$1$0.next = 8;
              break;
            }

            return context$1$0.abrupt("return");

          case 8:
            selectedSubform = selected.get("subform");

            if (selectedSubform) {
              context$1$0.next = 11;
              break;
            }

            return context$1$0.abrupt("return");

          case 11:
            if (!this.isSubformAllowed(selectedSubform)) {
              context$1$0.next = 17;
              break;
            }

            controller.set("showAdditionalFields", true);
            context$1$0.next = 15;
            return regeneratorRuntime.awrap(store.query(selectedSubform, {
              distinct: "brand"
            }));

          case 15:
            details = context$1$0.sent;

            controller.set("packageDetails", details);

          case 17:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    setUpPackageImage: function setUpPackageImage() {
      var controller, imageKey, image;
      return regeneratorRuntime.async(function setUpPackageImage$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            controller = this.controller;
            imageKey = controller.get("imageKeys");

            if (!imageKey) {
              context$1$0.next = 10;
              break;
            }

            image = this.get("packageService").getCloudinaryImage(imageKey);
            context$1$0.next = 6;
            return regeneratorRuntime.awrap(image || this.store.createRecord("image", {
              cloudinaryId: imageKey,
              favourite: true
            }));

          case 6:
            image = context$1$0.sent;

            controller.set("newUploadedImage", image);
            context$1$0.next = 11;
            break;

          case 10:
            controller.set("newUploadedImage", null);

          case 11:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    getDefaultCondition: function getDefaultCondition() {
      var conditions = this.get("store").peekAll("donor_condition");
      return conditions.filterBy("name", "Lightly Used").get("firstObject") || conditions.get("firstObject");
    },

    initializeAttributes: function initializeAttributes(isBoxPallet, controller) {
      return regeneratorRuntime.async(function initializeAttributes$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            this.set("newItemRequest", false);
            controller.set("quantity", 1);
            controller.set("caseNumber", "");
            controller.set("length", null);
            controller.set("width", null);
            controller.set("height", null);
            controller.set("weight", null);
            controller.set("pieces", null);
            controller.set("expiry_date", null);
            controller.set("imageKeys", "");
            controller.set("defaultCondition", null);
            controller.set("valueHkDollar", null);
            controller.set("defaultValueHkDollar", null);
            controller.set("restrictionId", null);
            controller.set("saleableId", null);
            controller.set("selectedGrade", { id: null });

            if (!isBoxPallet) {
              this.assignPackageAttribute(controller);
            }

          case 17:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    assignPackageAttribute: function assignPackageAttribute(controller) {
      var defaultValue;
      return regeneratorRuntime.async(function assignPackageAttribute$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            controller.set("restrictionId", this.get("restrictionOptions").get("firstObject"));
            controller.set("saleableId", this.get("saleableOptions").get("firstObject"));
            controller.set("selectedGrade", {
              name: "B",
              id: "B"
            });
            context$1$0.next = 5;
            return regeneratorRuntime.awrap(this.get("packageService").getItemValuation({
              donorConditionId: this.getDefaultCondition().id,
              grade: controller.get("selectedGrade").id,
              packageTypeId: controller.get("codeId")
            }));

          case 5:
            defaultValue = context$1$0.sent;

            controller.set("defaultCondition", this.getDefaultCondition());
            controller.set("valueHkDollar", +defaultValue.value_hk_dollar);
            controller.set("defaultValueHkDollar", +defaultValue.value_hk_dollar);

          case 9:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    }
  });
});