define("stock/models/orders_package", ["exports", "ember", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (exports, _ember, _emberDataModel, _emberDataAttr, _emberDataRelationships) {
  exports["default"] = _emberDataModel["default"].extend({
    packageId: (0, _emberDataAttr["default"])("number"),
    orderId: (0, _emberDataAttr["default"])("number"),
    itemId: (0, _emberDataAttr["default"])("number"),
    designationId: (0, _emberDataAttr["default"])("number"),
    quantity: (0, _emberDataAttr["default"])("number"),
    dispatchedQuantity: (0, _emberDataAttr["default"])("number"),
    shippingNumber: (0, _emberDataAttr["default"])("number"),
    sentOn: (0, _emberDataAttr["default"])("date"),
    state: (0, _emberDataAttr["default"])("string"),
    allowedActions: (0, _emberDataAttr["default"])(),
    updatedById: (0, _emberDataAttr["default"])("number"),
    createdAt: (0, _emberDataAttr["default"])("date"),

    updatedBy: (0, _emberDataRelationships.belongsTo)("user", { async: false }),
    item: (0, _emberDataRelationships.belongsTo)("item", { async: false }),
    designation: (0, _emberDataRelationships.belongsTo)("designation", { async: true }),
    isDispatched: _ember["default"].computed.bool("sentOn"),

    isRequested: _ember["default"].computed.equal("state", "requested"),
    isDesignated: _ember["default"].computed.equal("state", "designated"),
    isCancelled: _ember["default"].computed.equal("state", "cancelled"),

    isSingleQuantity: _ember["default"].computed.equal("quantity", 1),

    absoluteQuantity: _ember["default"].computed.alias("quantity"),

    undispatchedQty: _ember["default"].computed("quantity", "dispatchedQuantity", function () {
      return this.get("quantity") - this.get("dispatchedQuantity");
    }),

    orderCode: _ember["default"].computed("designation", function () {
      return this.get("designation.code");
    }),

    locationSubscript: _ember["default"].computed("quantity", function () {
      return Number(this.get("quantity")) >= 0 ? "To" : "From";
    }),

    arrow: _ember["default"].computed("quantity", function () {
      return Number(this.get("quantity")) >= 0 ? "arrow-up" : "arrow-down";
    }),

    partiallyDispatched: _ember["default"].computed("undispatchedQty", "dispatchedQuantity", function () {
      return this.get("undispatchedQty") > 0 && this.get("dispatchedQuantity") > 0;
    })
  });
});