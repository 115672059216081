define("stock/components/add-request", ["exports", "ember", "stock/mixins/async"], function (exports, _ember, _stockMixinsAsync) {
  exports["default"] = _ember["default"].Component.extend(_stockMixinsAsync["default"], {
    layoutName: null,
    isGCRequest: null,

    store: _ember["default"].inject.service(),
    goodcityRequestService: _ember["default"].inject.service(),
    packageTypeService: _ember["default"].inject.service(),
    packageService: _ember["default"].inject.service(),
    messageBox: _ember["default"].inject.service(),
    i18n: _ember["default"].inject.service(),
    request: null,
    requestType: _ember["default"].computed.alias("request.code"),
    num: null,
    order: null,

    packageTypeName: _ember["default"].computed("request.code", function () {
      return this.get("requestType") ? this.get("requestType.code") + "-" + this.get("requestType.name") : "";
    }),

    onInit: _ember["default"].on("init", function () {
      if (this.get("isGCRequest")) {
        this.set("layoutName", "components/appointment-add-request");
      } else {
        this.set("layoutName", "components/order-add-request");
      }
    }),

    hasError: _ember["default"].computed("requestType", "request.quantity", function () {
      return !this.get("requestType") || ! +this.get("request.quantity");
    }),

    actions: {
      deleteRequest: function deleteRequest(reqId) {
        var _this = this;

        var i18n = this.get("i18n");
        var orderCode = this.get("order.code");
        this.get("messageBox").custom(i18n.t("order.request.remove_req", { orderCode: orderCode }), i18n.t("order.request.remove"), function () {
          return _this.send("removeRequest", reqId);
        }, i18n.t("not_now"));
      },

      removeRequest: function removeRequest(id, index) {
        var req;
        return regeneratorRuntime.async(function removeRequest$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              if (!id) {
                context$1$0.next = 5;
                break;
              }

              req = this.get("store").peekRecord("goodcity_request", id);
              context$1$0.next = 4;
              return regeneratorRuntime.awrap(this.runTask(this.get("goodcityRequestService").deleteRequest(id)));

            case 4:
              this.get("store").unloadRecord(req);

            case 5:
              this.get("onRemoveRequest")(id, index);

            case 6:
            case "end":
              return context$1$0.stop();
          }
        }, null, this);
      },

      assingPackageType: function assingPackageType() {
        var packageType;
        return regeneratorRuntime.async(function assingPackageType$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              context$1$0.next = 2;
              return regeneratorRuntime.awrap(this.get("packageTypeService").userPickPackageType());

            case 2:
              packageType = context$1$0.sent;

              this.set("request.code", packageType);

            case 4:
            case "end":
              return context$1$0.stop();
          }
        }, null, this);
      },

      searchPackageType: function searchPackageType(reqId, orderId) {
        this.get("router").replaceWith("order.search_code", orderId, {
          queryParams: { reqId: reqId }
        });
      },

      //Fix: Too deeply nested component(3 levels) failing randomly(Known issue with Ember)
      //Remove when Ember is upgraded to >= 3.0
      updateErrorMessage: function updateErrorMessage() {
        return false;
      }
    }
  });
});