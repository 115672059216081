define("stock/components/editable-line", ["exports", "ember", "lodash", "stock/mixins/async"], function (exports, _ember, _lodash, _stockMixinsAsync) {
  exports["default"] = _ember["default"].Component.extend(_stockMixinsAsync["default"], {
    tagName: "div",

    attributeBindings: ["value", "name", "id", "placeholder", "maxlength", "required", "pattern"],

    init: function init() {
      this._super.apply(this, arguments);
      this.set("editing", false);
    },

    didInsertElement: function didInsertElement() {
      var _this = this;

      var ENTER = 13;
      this.textarea().on("keyup keypress", function (e) {
        var keyCode = e.keyCode || e.which;
        if (keyCode === ENTER) {
          e.preventDefault();
          _this.send("stopEditing");
          return false;
        }
      });
    },

    willDestroyElement: function willDestroyElement() {
      var model = this.get("model");
      var key = this.get("key");

      if (!model || !key) {
        return;
      }

      var chagedAttributes = this.get("model").changedAttributes();
      var changes = chagedAttributes[key];
      if (changes) {
        // Rollback before leaving
        this.model.set(key, changes[0]);
      }
    },

    textarea: function textarea() {
      return $(this.element).find("textarea");
    },

    disabled: _ember["default"].computed.not("editing"),

    actions: {
      stopEditing: function stopEditing() {
        if (!this.get("editing") || !this.get("value")) {
          return;
        }

        this.set("editing", false);
        if (this.get("autosave") && this.get("model")) {
          var changed = _lodash["default"].keys(this.get("model").changedAttributes()).length > 0;

          if (changed) {
            this.runTask(this.get("model").save(), this.ERROR_STRATEGIES.MODAL);
          }
        }
      },

      startEditing: function startEditing() {
        this.set("editing", true);
        _ember["default"].run.scheduleOnce("afterRender", this, function () {
          this.textarea().focus();
        });
      },

      toggleEdit: function toggleEdit() {
        this.send(this.get("editing") ? "stopEditing" : "startEditing");
      }
    }
  });
});