define("stock/utils/async", ["exports", "ember"], function (exports, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; })();

  exports.stagerred = stagerred;
  exports.wait = wait;
  exports.deferredFunc = deferredFunc;
  exports.queued = queued;
  exports.singleRunner = singleRunner;
  exports.chain = chain;

  /**
   * Returns a promise that will resolve the passed value after a certain delay
   *
   * @export
   * @param {any} result
   * @param {number} [delay=300]
   * @returns {Promise<any>}
   */

  function stagerred(result) {
    var delay = arguments.length <= 1 || arguments[1] === undefined ? 300 : arguments[1];

    var deferred = _ember["default"].RSVP.defer();

    _ember["default"].run.later(function () {
      deferred.resolve(result);
    }, delay);

    return deferred.promise;
  }

  /**
   * Returns a promise that will resolve after the specified time (ms)
   *
   * @export
   * @param {number} [delay=300]
   * @returns {Promise<null>}
   */

  function wait() {
    var delay = arguments.length <= 0 || arguments[0] === undefined ? 300 : arguments[0];

    delay = delay > 0 ? delay : 0;
    return stagerred(null, delay);
  }

  /**
   * Returns a promise and a trigger to start the job
   *
   * @export
   * @param {Function} func the job to execute
   * @param {any} scope the scope to bind the function to
   * @param {...any} args arguments
   * @returns {array} [promise, job]
   */

  function deferredFunc(func, scope) {
    for (var _len = arguments.length, args = Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
      args[_key - 2] = arguments[_key];
    }

    var _this = this;

    var deferred = _ember["default"].RSVP.defer();

    var trigger = function trigger() {
      var res;
      return regeneratorRuntime.async(function trigger$(context$2$0) {
        while (1) switch (context$2$0.prev = context$2$0.next) {
          case 0:
            context$2$0.prev = 0;
            context$2$0.next = 3;
            return regeneratorRuntime.awrap(func.apply(scope, args));

          case 3:
            res = context$2$0.sent;

            deferred.resolve(res);
            context$2$0.next = 10;
            break;

          case 7:
            context$2$0.prev = 7;
            context$2$0.t0 = context$2$0["catch"](0);

            deferred.reject(context$2$0.t0);

          case 10:
          case "end":
            return context$2$0.stop();
        }
      }, null, _this, [[0, 7]]);
    };

    return [deferred.promise, trigger];
  }

  /**
   * Returns a function that cannot run in parallel, any subsequent call will be queued
   *
   * @export
   * @param {function} func
   * @returns {function} a queued version of func
   */

  function queued(func) {
    var _this2 = this;

    var queue = [];

    var next = function next() {
      return regeneratorRuntime.async(function next$(context$2$0) {
        while (1) switch (context$2$0.prev = context$2$0.next) {
          case 0:
            if (!queue.length) {
              context$2$0.next = 5;
              break;
            }

            context$2$0.next = 3;
            return regeneratorRuntime.awrap(queue[0]());

          case 3:
            queue.shift();
            next();

          case 5:
          case "end":
            return context$2$0.stop();
        }
      }, null, _this2);
    };

    return function () {
      for (var _len2 = arguments.length, args = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        args[_key2] = arguments[_key2];
      }

      var _deferredFunc = deferredFunc.apply(undefined, [func, this].concat(args));

      var _deferredFunc2 = _slicedToArray(_deferredFunc, 2);

      var promise = _deferredFunc2[0];
      var trigger = _deferredFunc2[1];

      queue.push(trigger);

      if (queue.length === 1) {
        next(); // start
      }

      return promise;
    };
  }

  /**
   * Prevents a function from being called multiple times, e.g if a user spam clicks a button
   * Once the first call of the function is finished, it can be called again
   *
   * @export
   * @param {function} func the original function to be called
   * @returns {Promise<any>}
   */

  function singleRunner(func) {
    var promise = null;

    return function () {
      if (promise) {
        // A previous call is still running
        return promise;
      }

      for (var _len3 = arguments.length, args = Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
        args[_key3] = arguments[_key3];
      }

      promise = _ember["default"].RSVP.resolve(func.apply(this, args));

      return promise.then(function (res) {
        promise = null;
        return res;
      })["catch"](function (err) {
        promise = null;
        return _ember["default"].RSVP.reject(err);
      });
    };
  }

  /**
   * runs the operations in succession
   *
   * @export
   * @param {Function[]} [actions=[]]
   */

  function chain() {
    var actions = arguments.length <= 0 || arguments[0] === undefined ? [] : arguments[0];

    var _iteratorNormalCompletion, _didIteratorError, _iteratorError, _iterator, _step, act;

    return regeneratorRuntime.async(function chain$(context$1$0) {
      while (1) switch (context$1$0.prev = context$1$0.next) {
        case 0:
          _iteratorNormalCompletion = true;
          _didIteratorError = false;
          _iteratorError = undefined;
          context$1$0.prev = 3;
          _iterator = actions[Symbol.iterator]();

        case 5:
          if (_iteratorNormalCompletion = (_step = _iterator.next()).done) {
            context$1$0.next = 12;
            break;
          }

          act = _step.value;
          context$1$0.next = 9;
          return regeneratorRuntime.awrap(act());

        case 9:
          _iteratorNormalCompletion = true;
          context$1$0.next = 5;
          break;

        case 12:
          context$1$0.next = 18;
          break;

        case 14:
          context$1$0.prev = 14;
          context$1$0.t0 = context$1$0["catch"](3);
          _didIteratorError = true;
          _iteratorError = context$1$0.t0;

        case 18:
          context$1$0.prev = 18;
          context$1$0.prev = 19;

          if (!_iteratorNormalCompletion && _iterator["return"]) {
            _iterator["return"]();
          }

        case 21:
          context$1$0.prev = 21;

          if (!_didIteratorError) {
            context$1$0.next = 24;
            break;
          }

          throw _iteratorError;

        case 24:
          return context$1$0.finish(21);

        case 25:
          return context$1$0.finish(18);

        case 26:
        case "end":
          return context$1$0.stop();
      }
    }, null, this, [[3, 14, 18, 26], [19,, 21, 25]]);
  }

  /**
   * Runs the operations with a delay between each action
   *
   * @param {Function[]} [actions=[]]
   * @param {number} [delay=300]
   * @returns
   */
  chain.stagerred = function () {
    var _this3 = this;

    var actions = arguments.length <= 0 || arguments[0] === undefined ? [] : arguments[0];
    var delay = arguments.length <= 1 || arguments[1] === undefined ? 200 : arguments[1];

    return chain(actions.map(function (act) {
      return function callee$2$0() {
        return regeneratorRuntime.async(function callee$2$0$(context$3$0) {
          while (1) switch (context$3$0.prev = context$3$0.next) {
            case 0:
              context$3$0.next = 2;
              return regeneratorRuntime.awrap(act());

            case 2:
              context$3$0.next = 4;
              return regeneratorRuntime.awrap(wait(delay));

            case 4:
            case "end":
              return context$3$0.stop();
          }
        }, null, _this3);
      };
    }));
  };
});