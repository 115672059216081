define("stock/components/numeric-inline-input", ["exports", "ember", "stock/utils/ajax-promise", "stock/constants/regex"], function (exports, _ember, _stockUtilsAjaxPromise, _stockConstantsRegex) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; })();

  var getOwner = _ember["default"].getOwner;
  exports["default"] = _ember["default"].TextField.extend({
    store: _ember["default"].inject.service(),
    previousValue: "",
    tagName: "input",
    type: "text",
    attributeBindings: ["name", "type", "disabled", "value", "maxlength", "id", "autoFocus", "placeholder", "required", "pattern"],
    classNameBindings: ["class"],

    didInsertElement: function didInsertElement() {
      if (this.attrs.autoFocus) {
        this.$().focus();
      }
    },

    whichKey: function whichKey(e, key) {
      var keyList = [13, 8, 9, 39, 46];
      return e.ctrlKey && key === 86 || keyList.indexOf(key) >= 0 || key >= 35 && key <= 37 || this.get("acceptFloat") && key === 190 || key >= 48 && key <= 57 || key >= 96 && key <= 105;
    },

    keyDown: function keyDown(e) {
      var key = e.charCode || e.keyCode || 0;
      this.set("currentKey", key);

      // allow ctrl+v, enter, backspace, tab, delete, numbers, keypad numbers
      // home, end only.
      var keyPressed = this.whichKey(e, key);
      return keyPressed;
    },

    focusOut: function focusOut() {
      var _this = this;

      this.onFocusOut && setTimeout(function () {
        return _this.onFocusOut();
      }, 150);
      _ember["default"].$(this.element).removeClass("numeric-inline-input");
      var val = this.get("value");

      var _ref = this.get("acceptFloat") ? [_stockConstantsRegex.regex.FLOAT_REGEX, _stockConstantsRegex.regex.NON_DIGIT_FLOAT_REGEX] : [_stockConstantsRegex.regex.INT_REGEX, _stockConstantsRegex.regex.NON_DIGIT_REGEX];

      var _ref2 = _slicedToArray(_ref, 2);

      var regexPattern = _ref2[0];
      var replacePattern = _ref2[1];

      if (val && val.toString().search(regexPattern) !== 0) {
        val = val.toString().replace(replacePattern, "");
      }
      val = isNaN(val) ? null : val;
      if (this.get("isMaxLengthRequired") && val.length < this.get("maxlength")) {
        this.set("value", this.get("previousValue"));
        return false;
      }
      if (val === null) {
        this.set("value", "");
        return;
      }
      if (val !== "") {
        this.set("value", +(+val).toFixed((this.get("maxlength") || 6) - 2));
      }
      if (isNaN(val)) {
        this.set("value", "");
        return false;
      }

      if (this.shouldUpdate()) {
        this.get("onSettingInput")(this.get("name"), val);
      }
    },

    shouldUpdate: function shouldUpdate() {
      var newValue = this.get("value");
      var oldValue = this.get("previousValue");
      var dummy = Math.random();
      (newValue === "" || newValue === null) && (newValue = dummy);
      (oldValue === "" || oldValue === null) && (oldValue = dummy);
      return Math.abs(newValue - oldValue);
    },

    focusIn: function focusIn() {
      this.onFocusIn && this.onFocusIn();
      this.addCssStyle();
    },

    addCssStyle: function addCssStyle() {
      _ember["default"].$(this.element).addClass("numeric-inline-input");
      this.set("previousValue", this.get("value"));
    },

    click: function click() {
      this.addCssStyle();
    },

    willDestroyElement: function willDestroyElement() {
      this.destroy();
    }
  });
});