define("stock/constants/states", ["exports"], function (exports) {
  var ACTIVE_ORDER_STATES = ["submitted", "processing", "awaiting_dispatch", "dispatching"];

  exports.ACTIVE_ORDER_STATES = ACTIVE_ORDER_STATES;
  var ORGANISATION_STATUS = {
    pending: "pending",
    approved: "approved",
    expired: "expired",
    denied: "denied"
  };
  exports.ORGANISATION_STATUS = ORGANISATION_STATUS;
});