define('stock/models/designation', ['exports', 'ember', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _ember, _emberDataModel, _emberDataAttr, _emberDataRelationships) {
  exports['default'] = _emberDataModel['default'].extend({
    messageableName: 'Order',
    i18n: _ember['default'].inject.service(),
    utilityMethods: _ember['default'].inject.service(),

    state: (0, _emberDataAttr['default'])('string'),
    createdAt: (0, _emberDataAttr['default'])('date'),
    recentlyUsedAt: (0, _emberDataAttr['default'])('date'),
    submittedAt: (0, _emberDataAttr['default'])('date'),
    submittedById: (0, _emberDataAttr['default'])('number'),
    createdById: (0, _emberDataAttr['default'])('number'),
    processedAt: (0, _emberDataAttr['default'])('date'),
    processedById: (0, _emberDataAttr['default'])('number'),
    cancelledAt: (0, _emberDataAttr['default'])('date'),
    cancelledById: (0, _emberDataAttr['default'])('number'),
    processCompletedAt: (0, _emberDataAttr['default'])('date'),
    processCompletedById: (0, _emberDataAttr['default'])('number'),
    closedAt: (0, _emberDataAttr['default'])('date'),
    closedById: (0, _emberDataAttr['default'])('number'),
    dispatchStartedAt: (0, _emberDataAttr['default'])('date'),
    dispatchStartedBy: (0, _emberDataAttr['default'])('number'),
    code: (0, _emberDataAttr['default'])('string'),
    activity: (0, _emberDataAttr['default'])('string'),
    description: (0, _emberDataAttr['default'])('string'),
    detailType: (0, _emberDataAttr['default'])('string'),
    detailId: (0, _emberDataAttr['default'])('number'),
    purposeDescription: (0, _emberDataAttr['default'])('string'),
    organisationId: (0, _emberDataAttr['default'])('number'),
    beneficiaryId: (0, _emberDataAttr['default'])('number'),
    staffNote: (0, _emberDataAttr['default'])('string'),
    cancelReason: (0, _emberDataAttr['default'])('string'),
    countryName: (0, _emberDataAttr['default'])('string'),
    countryId: (0, _emberDataAttr['default'])('number'),
    shipmentDate: (0, _emberDataAttr['default'])('date'),
    addressId: (0, _emberDataAttr['default'])('number'),
    address: (0, _emberDataRelationships.belongsTo)('address', { async: false }),
    cancellationReason: (0, _emberDataRelationships.belongsTo)('cancellation_reason', { async: false }),
    beneficiary: (0, _emberDataRelationships.belongsTo)('beneficiary', { async: false }),
    stockitContact: (0, _emberDataRelationships.belongsTo)('stockit_contact', { async: false }),
    organisation: (0, _emberDataRelationships.belongsTo)('organisation', { async: false }),
    organisation: (0, _emberDataRelationships.belongsTo)('organisation', { async: true }),
    localOrder: (0, _emberDataRelationships.belongsTo)('local_order', { async: false }),
    items: (0, _emberDataRelationships.hasMany)('item', { async: true }),
    goodcityRequests: (0, _emberDataRelationships.hasMany)('goodcity_request', { async: false }),
    ordersPackages: (0, _emberDataRelationships.hasMany)('ordersPackages', { async: false }),
    messages: (0, _emberDataRelationships.hasMany)('message', { async: true }),
    orderTransport: (0, _emberDataRelationships.belongsTo)('orderTransport', { async: false }),
    ordersPurposes: (0, _emberDataRelationships.hasMany)('ordersPurpose', { async: false }),
    submittedBy: (0, _emberDataRelationships.belongsTo)('user', { async: false }),
    bookingType: (0, _emberDataRelationships.belongsTo)('booking_type', { async: false }),
    bookingTypeId: (0, _emberDataAttr['default'])('number'),
    createdBy: (0, _emberDataRelationships.belongsTo)('user', { async: true }),
    peopleHelped: (0, _emberDataAttr['default'])('number'),
    districtId: (0, _emberDataAttr['default'])('number'),
    district: (0, _emberDataRelationships.belongsTo)('district', { async: false }),
    ordersProcessChecklists: (0, _emberDataRelationships.hasMany)('ordersProcessChecklists', { async: false }),
    ordersProcessChecklistIds: (0, _emberDataAttr['default'])(),

    clientIdType: _ember['default'].computed('beneficiary', 'beneficiary.identityType', function () {
      return this.get('beneficiary.identityType.name');
    }),

    clientIdNumber: _ember['default'].computed.alias('beneficiary.identityNumber'),
    clientName: _ember['default'].computed.alias('beneficiary.fullName'),
    clientPhone: _ember['default'].computed.alias('beneficiary.phoneNumber'),

    isEditAllowed: _ember['default'].computed('state', function () {
      return !(this.get('isCancelled') || this.get('isClosed'));
    }),

    isLocalOrder: _ember['default'].computed('detailType', function () {
      return ['LocalOrder', 'StockitLocalOrder'].indexOf(this.get('detailType')) > -1;
    }),

    isGoodCityOrder: _ember['default'].computed.equal('detailType', 'GoodCity'),

    isShipmentOrder: _ember['default'].computed.equal('detailType', 'Shipment'),

    isStockitLocalOrder: _ember['default'].computed.equal('detailType', 'StockitLocalOrder'),

    isCarryOutOrder: _ember['default'].computed.equal('detailType', 'CarryOut'),

    isAppointment: _ember['default'].computed('bookingType', function () {
      var bookingType = this.get('bookingType');
      return bookingType && bookingType.get('isAppointment');
    }),

    isOnlineOrder: _ember['default'].computed('bookingType', function () {
      var bookingType = this.get('bookingType');
      return bookingType && bookingType.get('isOnlineOrder');
    }),

    isDraft: _ember['default'].computed.equal('state', 'draft'),
    isSubmitted: _ember['default'].computed.equal('state', 'submitted'),
    isAwaitingDispatch: _ember['default'].computed.equal('state', 'awaiting_dispatch'),
    isDispatching: _ember['default'].computed.equal('state', 'dispatching'),
    isClosed: _ember['default'].computed.equal('state', 'closed'),
    isProcessing: _ember['default'].computed.equal('state', 'processing'),
    isCancelled: _ember['default'].computed.equal('state', 'cancelled'),

    dispatchedItems: _ember['default'].computed('items.@each.sentOn', function () {
      return this.get('items').rejectBy('sentOn', null);
    }),

    capitalizedState: _ember['default'].computed('state', function () {
      return this.get('state').capitalize();
    }),

    purposeName: _ember['default'].computed('ordersPurposes.[]', function () {
      return this.get('ordersPurposes.firstObject.purpose.description');
    }),

    purposeId: _ember['default'].computed('ordersPurposes.[]', function () {
      return this.get('ordersPurposes.firstObject.purpose.nameEn');
    }),

    stateIcon: _ember['default'].computed('state', function () {
      var state = this.get('state');
      switch (state) {
        case 'awaiting_dispatch':
        case 'scheduled':
          return 'clock';
        case 'processing':
          return 'list';
        case 'submitted':
          return 'envelope';
        case 'dispatching':
          return 'paper-plane';
        case 'cancelled':
          return 'thumbs-down';
        case 'closed':
          return 'lock';
        default:
          return '';
      }
    }),

    appointmentTransportLabel: _ember['default'].computed('orderTransport.transportType', function () {
      var i18n = this.get('i18n');
      return this.get('orderTransport.transportType') === 'self' ? i18n.t('order.appointment.self_vehicle') : i18n.t('order.appointment.hire_vehicle');
    }),

    stateText: _ember['default'].computed('orderTransport', 'bookingType', 'detailType', function () {
      if (this.get('isAppointment')) {
        return 'appointment';
      } else if (this.get('isOnlineOrder')) {
        return 'online_order';
      } else if (this.get('isShipmentOrder')) {
        return 'shipment';
      } else if (this.get('isStockitLocalOrder')) {
        return 'stockit_local_order';
      } else if (this.get('isCarryOutOrder')) {
        return 'carry_out';
      }
    }),

    appointmentTime: _ember['default'].computed('orderTransport.scheduledAt', 'orderTransport.timeslot', function () {
      var orderTransport = this.get('orderTransport');
      if (orderTransport) {
        return moment(orderTransport.get('scheduledAt')).format('dddd MMMM Do') + ', ' + orderTransport.get('timeslot');
      } else {
        return '';
      }
    }),

    transportIcon: _ember['default'].computed('isAppointment', 'isOnlineOrder', 'isShipmntOrder', 'isCarryOutOrder', 'isStockitLocalOrder', function () {
      if (this.get('isAppointment') || this.get('isStockitLocalOrder')) {
        return 'warehouse';
      } else if (this.get('isOnlineOrder')) {
        return 'desktop';
      } else if (this.get('isShipmentOrder')) {
        return 'ship';
      } else if (this.get('isCarryOutOrder')) {
        return 'shopping-bag';
      } else {
        return 'question';
      }
    }),

    transportLabel: _ember['default'].computed('stateText', 'orderTransport', function () {
      var key = this.get('stateText') || 'unknown_transport';
      return this.get('i18n').t('order_transports.' + key);
    }),

    transportKey: _ember['default'].computed('orderTransport.transportType', function () {
      var transportType = this.get('orderTransport.transportType');
      switch (transportType) {
        case 'ggv':
          return 'gogovan_transport';
        case 'self':
          return 'collection_transport';
        default:
          return 'unknown_transport';
      }
    }),

    isDelivery: _ember['default'].computed('orderTransport.transportType', function () {
      return this.get('orderTransport.transportType') === 'ggv';
    }),

    ordersPackagesCount: _ember['default'].computed('ordersPackages.[]', 'ordersPackages.@each.quantity', 'ordersPackages.@each.state', function () {
      return this.get('ordersPackages').filterBy('quantity').length;
    }),

    allDispatchedOrdersPackages: _ember['default'].computed('ordersPackages.@each.state', 'ordersPackages.@each.quantity', function () {
      var ordersPackages = this.get('quantityOrdersPackages');
      return this.get('utilityMethods').arrayExists(ordersPackages) && ordersPackages.filterBy('isDispatched', false).length === 0;
    }),

    allDesignatedOrdersPackages: _ember['default'].computed('ordersPackages.@each.state', 'ordersPackages.@each.quantity', function () {
      var ordersPackages = this.get('quantityOrdersPackages');
      return this.get('utilityMethods').arrayExists(ordersPackages) && ordersPackages.filterBy('isDispatched', true).length === 0;
    }),

    quantityOrdersPackages: _ember['default'].computed('ordersPackages.@each.state', 'ordersPackages.@each.quantity', function () {
      return this.get('ordersPackages').filterBy('quantity');
    }),

    allItemsDispatched: _ember['default'].computed('items.@each.isDispatched', function () {
      var items = this.get('items');
      return items.get('length') > 0 && items.filterBy('isDispatched', false).length === 0;
    }),

    designatedOrdersPackages: _ember['default'].computed('ordersPackages.@each.state', function () {
      return this.get('ordersPackages').filterBy('state', 'designated');
    }),

    dispatchedOrdersPackages: _ember['default'].computed('ordersPackages.@each.state', function () {
      return this.get('ordersPackages').filterBy('state', 'dispatched');
    }),

    cancelledOrdersPackages: _ember['default'].computed('ordersPackages.@each.state', function () {
      return this.get('ordersPackages').filterBy('state', 'cancelled');
    }),

    designatedItems: _ember['default'].computed('items.@each.sentOn', function () {
      return this.get('items').filterBy('sentOn', null);
    }),

    // unread order messages
    unreadMessagesCount: _ember['default'].computed('messages.@each.state', function () {
      return this.get('messages').filterBy('state', 'unread').length;
    }),

    unreadUserMessagesCount: _ember['default'].computed('messages.@each.state', function () {
      return this.get('messages').filterBy('isPrivate', false).filterBy('state', 'unread').length;
    }),

    unreadStaffMessagesCount: _ember['default'].computed('messages.@each.state', function () {
      return this.get('messages').filterBy('isPrivate', true).filterBy('state', 'unread').length;
    }),

    hasUnreadUserMessages: _ember['default'].computed('unreadUserMessagesCount', function () {
      return this.get('unreadUserMessagesCount') > 0;
    }),

    hasUnreadStaffMessages: _ember['default'].computed('unreadStaffMessagesCount', function () {
      return this.get('unreadStaffMessagesCount') > 0;
    }),

    hasUnreadMessages: _ember['default'].computed('unreadMessagesCount', function () {
      return this.get('unreadMessagesCount') > 0;
    })
  });
});