define('stock/initializers/offline', ['exports', 'stock/config/environment'], function (exports, _stockConfigEnvironment) {
  exports.initialize = initialize;

  function initialize() {
    var application = arguments[1] || arguments[0];

    // apply config to global Offline
    window.Offline.options = _stockConfigEnvironment['default'].emberOffline;

    application.register('offline:main', window.Offline, { instantiate: false });
    application.inject('service:offline', 'offline', 'offline:main');
  }

  exports['default'] = {
    name: 'offline',
    initialize: initialize
  };
});