define('stock/controllers/orders/order_types', ['exports', 'stock/controllers/orders/detail', 'ember', 'lodash', 'stock/config/environment'], function (exports, _stockControllersOrdersDetail, _ember, _lodash, _stockConfigEnvironment) {
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var EMPTY_OPTION = {
    id: '-1',
    value: null,
    name: 'n/a'
  };

  exports['default'] = _stockControllersOrdersDetail['default'].extend({
    orderService: _ember['default'].inject.service(),
    hkTimeZone: _stockConfigEnvironment['default'].APP.HK_TIME_ZONE,

    stickyNote: {
      showCallToAction: true
    },

    showCallToAction: _ember['default'].computed('model', 'stickyNote.showCallToAction', function () {
      var note = this.get('model.staffNote');
      return !note && this.get('stickyNote.showCallToAction');
    }),

    checklist: _ember['default'].computed('model.bookingTypeId', 'model.ordersProcessChecklists.[]', function () {
      var checklistService = this.get('processingChecklist');
      var order = this.get('model');

      return checklistService.getChecklistForOrder(order).map(function (item) {
        return {
          isChecked: checklistService.itemChecked(order, item),
          item: item
        };
      });
    }),

    scheduledAtStringPlaceholder: _ember['default'].computed('selectedScheduleDate', function () {
      var date = this.get('selectedScheduleDate');
      if (!date) {
        return this.get('i18n').t('order_details.logistics.pick_date');
      }
      return moment.tz(date, this.get('hkTimeZone')).format('D MMMM, YYYY');
    }),

    /**
     * Creates an action that modifies the property of the record passed as argument
     * @ignore
     */
    propertyMutator: function propertyMutator(model, property) {
      var _this = this;

      return function (_ref) {
        var value = _ref.value;

        _this.updateRecord(model, _defineProperty({}, property, value));
      };
    },

    /**
     * Transforms a modelName into a list of options for a <select>
     * @ignore
     */
    buildOptions: function buildOptions(modelName, namer) {
      var opts = arguments.length <= 2 || arguments[2] === undefined ? {} : arguments[2];

      if (_lodash['default'].isString(namer)) {
        (function () {
          var key = namer;
          namer = function (record) {
            return record.get(key);
          };
        })();
      }
      var res = this.store.peekAll(modelName).map(function (it) {
        return {
          id: it.get('id'),
          value: it,
          name: namer(it)
        };
      });

      if (opts.allowEmpty) {
        res.unshift(EMPTY_OPTION);
      }

      return res;
    },

    /*
     * SCHEDULE ROW
     */
    makeScheduleRow: function makeScheduleRow() {
      var order = this.get('model');
      return {
        label: this.get('i18n').t('order_details.logistics.scheduled'),
        text: moment.tz(order.get('orderTransport.scheduledAt'), this.get('hkTimeZone')).format('dddd Do MMMM hh:mm a'),
        action: 'openSchedulePopup',
        icon: 'clock',
        name: 'reschedule'
      };
    },

    /*
     * TYPE ROW
     */
    makeTypeRow: function makeTypeRow() {
      var order = this.get('model');
      var options = this.buildOptions('booking_type', 'displayName');
      return {
        label: this.get('i18n').t('order_details.logistics.type'),
        action: this.propertyMutator(order, 'bookingType'),
        icon: 'tv',
        name: 'type',
        selectable: true,
        value: options.find(function (opt) {
          return opt.id === order.get('bookingType.id');
        }),
        options: options
      };
    },

    /*
     * TRANSPORT TYPE
     */
    makeTransportRow: function makeTransportRow() {
      var _this2 = this;

      var transport = this.get('model.orderTransport');
      var transportType = transport.get('transportType');
      var options = ['self', 'ggv'].map(function (t, i) {
        return {
          id: i,
          value: t,
          name: _this2.get('i18n').t('order_details.logistics.vehicle.' + t)
        };
      });
      return {
        label: this.get('i18n').t('order_details.logistics.transport_type'),
        action: this.propertyMutator(transport, 'transportType'),
        icon: 'file-invoice-dollar',
        name: 'transport_type',
        selectable: true,
        value: options.filterBy('value', transportType).get('firstObject'),
        options: options
      };
    },

    /*
     * DISTRICT
     */
    makeDistrictRow: function makeDistrictRow() {
      var _this3 = this;

      var address = this.get('model.address');
      var options = this.buildOptions('district', 'name', {
        allowEmpty: false
      }).sortBy('name');

      return {
        label: this.get('i18n').t('order_details.logistics.destination'),
        action: function action(_ref2) {
          var id = _ref2.id;

          _this3.updateAddressField({
            name: 'district_id',
            value: id
          });
        },
        icon: '',
        name: 'district',
        selectable: true,
        value: options.find(function (opt) {
          return address && opt.id == address.get('districtId');
        }),
        options: options
      };
    },

    /*
     * ADDRESS Rows
     */
    makeAddressTextRow: function makeAddressTextRow(name) {
      var _this4 = this;

      return {
        label: this.get('i18n').t('order_details.logistics.address_fields.' + name),
        action: function action(evt) {
          _ember['default'].run.debounce(_this4, _this4.updateAddressField, { name: name, value: evt.target.value }, 500);
        },
        icon: '',
        name: name,
        isText: true,
        selectable: false,
        value: this.getWithDefault('model.address.' + name, '')
      };
    },

    /*
     * VEHICLE
     */
    makeVehicleRow: function makeVehicleRow() {
      var orderTransport = this.get('model.orderTransport');
      var options = this.buildOptions('gogovan_transport', 'name', {
        allowEmpty: true
      });
      return {
        label: this.get('i18n').t('order_details.logistics.vehicle_type'),
        action: this.propertyMutator(orderTransport, 'gogovanTransport'),
        icon: '',
        name: 'vehicle',
        selectable: true,
        value: options.find(function (opt) {
          return opt.id === orderTransport.get('gogovanTransport.id');
        }),
        options: options
      };
    },

    logisticDataRows: _ember['default'].computed('model', 'model.orderTransport.scheduledAt', function () {
      return [this.makeScheduleRow(), this.makeTypeRow(), this.makeTransportRow(), this.makeVehicleRow()];
    }),

    addressDataRows: _ember['default'].computed('model', 'model.address.{flat,districtId,street,building,notes}', function () {
      return [this.makeDistrictRow(), this.makeAddressTextRow('street'), this.makeAddressTextRow('building'), this.makeAddressTextRow('flat'), this.makeAddressTextRow('notes')];
    }),

    updateAddressField: function updateAddressField(_ref3) {
      var name = _ref3.name;
      var value = _ref3.value;
      return regeneratorRuntime.async(function updateAddressField$(context$1$0) {
        var _this5 = this;

        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return regeneratorRuntime.awrap(this.runTask(function () {
              return _this5.get('orderService').updateAddress(_this5.get('model'), _defineProperty({}, name, value));
            }));

          case 2:
          case 'end':
            return context$1$0.stop();
        }
      }, null, this);
    },

    actions: {
      toggleCheckbox: function toggleCheckbox(processChecklistRecord) {
        var checklistService = this.get('processingChecklist');
        var order = this.get('model');
        var loadingView = _ember['default'].getOwner(this).lookup('component:loading').append();

        var task = undefined;
        if (checklistService.itemChecked(order, processChecklistRecord)) {
          task = checklistService.uncheckItem(order, processChecklistRecord);
        } else {
          task = checklistService.checkItem(order, processChecklistRecord);
        }

        task['finally'](function () {
          return loadingView.destroy();
        });
      },
      hideNoteCallToAction: function hideNoteCallToAction() {
        this.set('stickyNote.showCallToAction', false);
      },
      showNoteCallToAction: function showNoteCallToAction() {
        this.set('stickyNote.showCallToAction', true);
      },
      onStickyNoteChanged: function onStickyNoteChanged() {
        this.set('stickyNote.showSaveButton', true);
      },
      saveStickyNote: function saveStickyNote() {
        var _this6 = this;

        var order = this.get('model');
        this.updateRecord(order, {}, {
          noRollback: true,
          onSuccess: function onSuccess() {
            _this6.set('stickyNote.showSaveButton', false);
            if (!order.get('staffNote')) {
              _this6.send('showNoteCallToAction');
            }
          }
        });
      }
    }
  });
});