define("stock/controllers/items/new", ["exports", "ember", "stock/utils/ajax-promise", "stock/controllers/goodcity_controller", "stock/config/environment", "stock/constants/additional-fields", "lodash", "ember-i18n", "stock/mixins/search_option", "stock/mixins/fetch_package_detail", "stock/mixins/grades_option", "stock/mixins/async"], function (exports, _ember, _stockUtilsAjaxPromise, _stockControllersGoodcity_controller, _stockConfigEnvironment, _stockConstantsAdditionalFields, _lodash, _emberI18n, _stockMixinsSearch_option, _stockMixinsFetch_package_detail, _stockMixinsGrades_option, _stockMixinsAsync) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var getOwner = _ember["default"].getOwner;
  var A = _ember["default"].A;
  exports["default"] = _stockControllersGoodcity_controller["default"].extend(_stockMixinsSearch_option["default"], _stockMixinsFetch_package_detail["default"], _stockMixinsGrades_option["default"], _stockMixinsAsync["default"], {
    queryParams: ["codeId", "locationId", "scanLocationName", "storageType"],
    codeId: "",
    locationId: "",
    inventoryNumber: "",
    results: "",
    searchText: "",
    dropDownValues: {},
    inputFieldValues: {},
    selected: [],
    countryObj: {},
    countryValue: {},
    scanLocationName: "",
    displayInventoryOptions: false,
    autoGenerateInventory: true,
    inputInventory: false,
    settings: _ember["default"].inject.service(),
    paramsNotCopied: _ember["default"].computed.alias("settings.paramsNotCopied"),
    locationName: _ember["default"].computed.alias("location.displayName"),
    caseNumber: "",
    isSearchCodePreviousRoute: _ember["default"].computed.localStorage(),
    fields: _stockConstantsAdditionalFields["default"],
    weight: "",
    isSelectLocationPreviousRoute: _ember["default"].computed.localStorage(),
    offerService: _ember["default"].inject.service(),
    fixedDropdownArr: ["frequency", "voltage", "compTestStatus", "testStatus"],
    quantity: 1,
    valueHkDollar: "",
    labels: 1,
    length: null,
    width: null,
    height: null,
    invalidLocation: false,
    invalidScanResult: false,
    newUploadedImage: null,
    subFormData: {},
    successfullyDuplicated: false,
    setDropdownOption: _ember["default"].inject.service(),
    showAdditionalFields: false,
    isAllowedToPublish: false,
    shouldDuplicate: false,
    isSaleable: false,
    imageKeys: _ember["default"].computed.localStorage(),
    i18n: _ember["default"].inject.service(),
    session: _ember["default"].inject.service(),
    locationService: _ember["default"].inject.service(),
    packageService: _ember["default"].inject.service(),
    printerService: _ember["default"].inject.service(),
    selectedDescriptionLanguage: "en",
    cancelWarning: (0, _emberI18n.translationMacro)("items.new.cancel_warning"),
    displayFields: _ember["default"].computed("code", function () {
      var subform = this.get("code.subform");
      return this.returnDisplayFields(subform);
    }),
    offersLists: [],
    hasInvalidDescription: _ember["default"].computed("descriptionEn", function () {
      return !this.get("descriptionEn");
    }),
    isBoxOrPallet: _ember["default"].computed("storageType", function () {
      return ["Box", "Pallet"].indexOf(this.get("storageType")) > -1;
    }),

    pageTitle: _ember["default"].computed("storageType", "parentCodeName", function () {
      return this.get("isBoxOrPallet") ? "New " + this.get("storageType") + " - " + this.get("parentCodeName") : "Add - " + this.get("parentCodeName");
    }),

    showPublishItemCheckBox: _ember["default"].computed("quantity", "isBoxOrPallet", function () {
      this.set("isAllowedToPublish", false);
      return +this.get("quantity") === 1;
    }),
    showDuplicateCheckbox: _ember["default"].computed.equal("storageType", "Package"),

    locale: function locale(str) {
      return this.get("i18n").t(str);
    },

    allAvailablePrinters: _ember["default"].computed(function () {
      return this.get("printerService").allAvailablePrinters();
    }),

    selectedPrinterDisplay: _ember["default"].computed("selectedPrinterId", function () {
      var printerId = this.get("selectedPrinterId");
      if (printerId) {
        var printer = this.store.peekRecord("printer", printerId);
        return {
          name: printer.get("name"),
          id: printer.id
        };
      } else {
        return this.get("allAvailablePrinters")[0];
      }
    }),

    canApplyDefaultValuation: _ember["default"].computed("valueHkDollar", function () {
      var defaultValue = Number(this.get("defaultValueHkDollar"));
      var valueHkDollar = Number(this.get("valueHkDollar"));
      return defaultValue !== valueHkDollar;
    }),

    setLocation: _ember["default"].observer("scanLocationName", function () {
      var _this2 = this;

      _ember["default"].run.next(function () {
        var scanInput = _this2.get("scanLocationName");
        if (scanInput) {
          var results = _this2.get("store").peekAll("location").filterBy("displayName", scanInput);
          if (results.length > 0) {
            _this2.set("invalidScanResult", false);
            _this2.set("location", results.get("firstObject"));
          } else {
            _this2.set("invalidScanResult", true);
          }
        } else {
          _this2.set("invalidScanResult", false);
        }
      });
    }),

    validateLocation: _ember["default"].observer("location", function () {
      if (!this.get("location")) {
        this.set("invalidLocation", false);
      }
    }),

    isMobileApp: _stockConfigEnvironment["default"].cordova.enabled,
    messageBox: _ember["default"].inject.service(),

    conditions: _ember["default"].computed(function () {
      return this.get("store").peekAll("donor_condition");
    }),

    fetchDetailAttributes: function fetchDetailAttributes() {
      var detailAttributes = {};
      var attributes = _extends({}, this.get("inputFieldValues"), this.get("dropDownValues"), this.get("countryValue"));
      Object.keys(attributes).forEach(function (key) {
        detailAttributes[_lodash["default"].snakeCase(key)] = attributes[key];
      });
      return detailAttributes;
    },

    clearParams: function clearParams() {
      var _this3 = this;

      if (this.get("displayFields")) {
        var attr = this.get("paramsNotCopied");
        attr.forEach(function (value) {
          return _this3.clearAttribute(value);
        });
      }
    },

    clearAttribute: function clearAttribute(value) {
      if (value.toLowerCase().includes("country")) {
        return this.setProperties({
          countryValue: {},
          selected: []
        });
      }
      var fieldAtributes = this.get("displayFields").find(function (newValue) {
        return newValue.label == value;
      });
      if (!fieldAtributes) {
        return;
      }
      return fieldAtributes.autoComplete ? this.send("setFields", fieldAtributes.name, null) : this.set("inputFieldValues." + fieldAtributes.value, null);
    },

    showPiecesInput: _ember["default"].computed("codeId", "isBoxOrPallet", function () {
      if (this.get("isBoxOrPallet")) {
        return false;
      }

      var codeId = this.get("codeId");
      if (codeId) {
        var selected = this.get("store").peekRecord("code", codeId);
        return selected && selected.get("allowPieces");
      }
    }),

    emptySubformAttributes: _ember["default"].observer("codeId", function () {
      this.clearSubformAttributes();
    }),

    parentCodeName: _ember["default"].computed("codeId", function () {
      var selected = "";
      var codeId = this.get("codeId");
      if (codeId.length) {
        selected = this.get("store").peekRecord("code", codeId);
        return selected && selected.get("name");
      }
      return selected;
    }),

    code: _ember["default"].computed("codeId", function () {
      var selected = "";
      var codeId = this.get("codeId");
      if (codeId.length) {
        selected = this.get("store").peekRecord("code", codeId);
        return selected && selected.defaultChildPackagesList()[0];
      }
      return selected;
    }),

    isInvalidaLabelCount: _ember["default"].computed("labels", function () {
      var labelCount = this.get("labels");
      return Number(labelCount) < 0;
    }),

    isInvalidPrintCount: _ember["default"].computed("labels", function () {
      return this.isValidLabelRange({
        startRange: 0
      });
    }),

    isMultipleCountPrint: _ember["default"].computed("labels", function () {
      return this.isValidLabelRange({
        startRange: 1
      });
    }),

    isValidDimension: _ember["default"].computed("length", "width", "height", function () {
      var length = parseInt(this.get("length"));
      var width = parseInt(this.get("width"));
      var height = parseInt(this.get("height"));

      // Its a validation error if, any one of them doesn't have a value
      // i.e. either all 3 must have value or none of them have any value
      var dimensionsCount = [length, width, height].filter(function (dimension) {
        return !isNaN(dimension) && dimension > 0;
      }).length;

      // Its a validation error if, any of the values are 0
      var hasZero = [length, width, height].any(function (dimension) {
        return dimension == 0;
      });

      return (dimensionsCount == 3 || dimensionsCount == 0) && !hasZero;
    }),

    printLabelCount: _ember["default"].computed("labels", function () {
      return Number(this.get("labels"));
    }),

    isInvalidValuation: _ember["default"].computed("valueHkDollar", "isBoxOrPallet", function () {
      if (this.get("isBoxOrPallet")) {
        return false;
      }

      var value = this.get("valueHkDollar");
      // can be 0
      return value === "" || value === null;
    }),

    location: _ember["default"].computed("codeId", "locationId", {
      get: function get() {
        var location;
        var locationId = this.get("locationId");
        var codeLocation = this.get("code.location");
        if (locationId) {
          this.set("scanLocationName", null);
        }
        if (locationId.length) {
          location = this.get("store").peekRecord("location", locationId);
        } else if (codeLocation) {
          location = codeLocation;
        }
        if (!locationId && location) {
          this.set("locationId", location.get("id"));
        }
        return location;
      },
      set: function set(key, value) {
        return value;
      }
    }),

    initActionSheet: function initActionSheet(onSuccess) {
      return window.plugins.actionsheet.show({
        buttonLabels: [this.locale("edit_images.upload").string, this.locale("edit_images.camera").string, this.locale("edit_images.cancel").string]
      }, function (buttonIndex) {
        if (buttonIndex === 1) {
          navigator.camera.getPicture(onSuccess, null, {
            quality: 40,
            destinationType: navigator.camera.DestinationType.DATA_URL,
            sourceType: navigator.camera.PictureSourceType.PHOTOLIBRARY
          });
        }
        if (buttonIndex === 2) {
          navigator.camera.getPicture(onSuccess, null, {
            correctOrientation: true,
            quality: 40,
            destinationType: navigator.camera.DestinationType.DATA_URL,
            sourceType: navigator.camera.PictureSourceType.CAMERA
          });
        }
        if (buttonIndex === 3) {
          window.plugins.actionsheet.hide();
        }
      });
    },

    packageParams: function packageParams() {
      var locationId = this.get("location.id");
      var quantity = this.get("quantity");
      var detailAttributes = this.fetchDetailAttributes();
      var saleable = this.get("saleableId") ? this.get("saleableId").value : false;

      return {
        quantity: quantity,
        allow_web_publish: this.get("isAllowedToPublish"),
        length: this.get("length"),
        width: this.get("width"),
        height: this.get("height"),
        weight: this.get("weight"),
        pieces: this.get("pieces"),
        inventory_number: this.get("inventoryNumber"),
        case_number: this.get("caseNumber"),
        notes: this.get("descriptionEn"),
        notes_zh_tw: this.get("descriptionZhTw"),
        detail_type: this.get("code.subform"),
        grade: this.get("selectedGrade.id"),
        donor_condition_id: this.get("defaultCondition.id"),
        location_id: locationId,
        package_type_id: this.get("code.id"),
        state_event: "mark_received",
        storage_type: this.get("storageType"),
        expiry_date: this.get("expiry_date"),
        value_hk_dollar: this.get("valueHkDollar"),
        offer_ids: this.get("offersLists").getEach("id"),
        restriction_id: this.get("restrictionId.id"),
        saleable: saleable,
        comment: this.get("comment"),
        detail_attributes: detailAttributes
      };
    },

    clearSubformAttributes: function clearSubformAttributes() {
      this.setProperties({
        inputFieldValues: {},
        dropDownValues: {},
        countryValue: {},
        selected: []
      });
    },

    isValidLabelRange: function isValidLabelRange(_ref) {
      var startRange = _ref.startRange;

      var labelCount = Number(this.get("labels"));
      return _lodash["default"].inRange(labelCount, startRange, 301);
    },

    updateStoreAndSaveImage: function updateStoreAndSaveImage(data) {
      this.get("store").pushPayload(data);
      var image = this.get("newUploadedImage");
      if (image) {
        image.setProperties({
          imageableId: data.item.id,
          imageableType: "Package"
        });
        image.save();
      }
    },

    hasIncompleteConditions: function hasIncompleteConditions() {
      return this.get("quantity").toString().trim().length === 0 || this.get("descriptionEn").trim().length === 0 || !this.get("location") || this.get("inventoryNumber").trim().length === 0 || !this.get("code") || !this.get("isInvalidPrintCount") || this.get("isInvalidaLabelCount") || !this.get("isValidDimension") || this.get("isInvalidValuation") || this.get("hasInvalidDescription");
    },

    checkPermissionAndScan: function checkPermissionAndScan() {
      var _this = this;
      var permissions = window.cordova.plugins.permissions;
      var permissionError = function permissionError() {
        var error_message = _this.get("i18n").t("camera_scan.permission_error");
        _this.get("messageBox").alert(error_message);
      };
      var permissionSuccess = function permissionSuccess(status) {
        //after requesting check for permission then, permit to scan
        if (status.hasPermission) {
          _this.scan();
        } else {
          permissionError();
        }
      };
      permissions.hasPermission(permissions.CAMERA, function (status) {
        //check permission here
        if (status.hasPermission) {
          _this.scan();
        } else {
          //request permission here
          permissions.requestPermission(permissions.CAMERA, permissionSuccess, permissionError);
        }
      });
    },

    scan: function scan() {
      var _this4 = this;

      var onSuccess = function onSuccess(res) {
        if (!res.cancelled) {
          var strippedURL = res.text.substring(res.text.lastIndexOf("=") + 1);
          _this4.set("inventoryNumber", strippedURL);
        }
      };
      var onError = function onError(error) {
        return _this4.get("messageBox").alert("Scanning failed: " + error);
      };
      var options = {
        formats: "QR_CODE, CODE_128",
        orientation: "portrait"
      };
      window.cordova.plugins.barcodeScanner.scan(onSuccess, onError, options);
    },

    printBarcode: function printBarcode(packageId) {
      var _this5 = this;

      var labels = this.get("labels");
      return this.get("packageService").printBarcode({
        package_id: packageId,
        labels: labels,
        tag: "stock"
      })["catch"](function (error) {
        _this5.get("messageBox").alert(error.responseJSON.errors);
      });
    },

    isInValidConditions: function isInValidConditions() {
      var itemConditions = this.hasIncompleteConditions();
      if (itemConditions) {
        if (!this.get("location")) {
          this.set("invalidLocation", true);
        }
        return true;
      } else {
        return false;
      }
    },

    showOfflineError: function showOfflineError() {
      if (!window.navigator.onLine) {
        this.get("messageBox").alert(this.get("i18n").t("offline_error"));
        return false;
      }
    },

    createPackage: function createPackage() {
      var _this6 = this;

      this.set("previousInventoryNumber", this.get("inventoryNumber"));
      this.get("packageService").createPackage({
        "package": this.packageParams()
      }).then(function (data) {
        if (_this6.get("isMultipleCountPrint")) {
          _this6.printBarcode(data.item.id);
        }
        _this6.updateStoreAndSaveImage(data);
        if (_this6.get("shouldDuplicate") && !_this6.get("isBoxOrPallet")) {
          _this6.displayDuplicateParams(data);
        } else {
          _this6.clearAllFields();
          _this6.replaceRoute("items.detail", data.item.id);
        }
      })["catch"](function (response) {
        _this6.showError(response.responseJSON && response.responseJSON.errors[0]);
      })["finally"](function () {
        _this6.hideLoadingSpinner();
      });
    },

    displayDuplicateParams: function displayDuplicateParams(data) {
      var duplicateImage, newUploadedImage;
      return regeneratorRuntime.async(function displayDuplicateParams$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            this.replaceRoute("items.new");
            this.clearParams();
            _ember["default"].$("body").animate({ scrollTop: 0 });
            this.set("quantity", 1);
            this.set("successfullyDuplicated", true);
            context$1$0.next = 7;
            return regeneratorRuntime.awrap(this.send("autoGenerateInventoryNumber"));

          case 7:
            if (this.get("newUploadedImage")) {
              duplicateImage = this.get("newUploadedImage");
              newUploadedImage = this.get("store").createRecord("image", {
                cloudinaryId: duplicateImage.get("cloudinaryId"),
                favourite: true
              });

              this.set("newUploadedImage", newUploadedImage);
              this.set("imageKeys", newUploadedImage);
            }
            _ember["default"].run.later(this, function () {
              this.set("successfullyDuplicated", false);
            }, 4000);

          case 9:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    clearAllFields: function clearAllFields() {
      this.clearSubformAttributes();

      this.setProperties({
        locationId: "",
        comment: "",
        successfullyDuplicated: false,
        inventoryNumber: "",
        offersLists: [],
        descriptionEn: "",
        descriptionZhTw: ""
      });
    },

    actions: {
      pickLocation: function pickLocation() {
        return regeneratorRuntime.async(function pickLocation$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              context$1$0.t0 = this;
              context$1$0.next = 3;
              return regeneratorRuntime.awrap(this.get("locationService").userPickLocation());

            case 3:
              context$1$0.t1 = context$1$0.sent;
              context$1$0.t0.set.call(context$1$0.t0, "location", context$1$0.t1);

            case 5:
            case "end":
              return context$1$0.stop();
          }
        }, null, this);
      },

      openImageOverlay: function openImageOverlay() {
        this.set("packageService.openImageOverlay", true);
      },

      saveImageURI: function saveImageURI(imageObject) {
        this.set("newUploadedImage", imageObject);
      },

      setPkgDescriptionLang: function setPkgDescriptionLang(language) {
        this.set("selectedDescriptionLanguage", language);
      },

      /**
       *  Add the default suggested description for selected language
       * @param {string} language - Language EN | Zh-TW
       */
      addDefaultDescriptionFor: function addDefaultDescriptionFor(language) {
        if (language === "en") {
          var description = this.get("code.descriptionEn");
          this.set("descriptionEn", description);
        } else {
          var description = this.get("code.descriptionZhTw");
          this.set("descriptionZhTw", description);
        }
      },

      /**
       * Change the Grade value and update the item valuation
       * @param {Object} selectedGrade - The selected grade value of item
       * @param {string} selectedGrade.id - ID of the grade
       * @param {string} selectedGrade.name - Name of the grade
       */
      onGradeChange: function onGradeChange(_ref2) {
        var id = _ref2.id;
        var name = _ref2.name;

        this.set("selectedGrade", { id: id, name: name });
        this.set("defaultValueHkDollar", null);
        this.send("calculateItemValuation");
      },

      /**
       * Change the donor condition value and update the item valuation
       * @param {Object} defaultCondition - The selected condition of item
       * @param {string} defaultCondition.id - ID of the condition
       * @param {string} defaultCondition.name - Name of the condition
       */
      onConditionChange: function onConditionChange(_ref3) {
        var id = _ref3.id;
        var name = _ref3.name;

        this.set("defaultCondition", { id: id, name: name });
        this.set("defaultValueHkDollar", null);
        this.send("calculateItemValuation");
      },

      /**
       * Makes an API call to calculate the item valuation based on
       * donor condition, grade and package type.
       * This also sets the default value for item valuation.
       */
      calculateItemValuation: function calculateItemValuation() {
        var itemValuation, defaultValueHkDollar;
        return regeneratorRuntime.async(function calculateItemValuation$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              context$1$0.next = 2;
              return regeneratorRuntime.awrap(this.get("packageService").getItemValuation({
                donorConditionId: this.get("defaultCondition.id"),
                grade: this.get("selectedGrade.id"),
                packageTypeId: this.get("code.id")
              }));

            case 2:
              itemValuation = context$1$0.sent;
              defaultValueHkDollar = this.get("defaultValueHkDollar");

              if (!defaultValueHkDollar) {
                this.set("defaultValueHkDollar", +itemValuation.value_hk_dollar);
              }
              this.set("valueHkDollar", +itemValuation.value_hk_dollar);

            case 6:
            case "end":
              return context$1$0.stop();
          }
        }, null, this);
      },

      removeOffer: function removeOffer(offer) {
        var offersList = this.get("offersLists").filter(function (offer_list) {
          return offer_list.id !== offer.id;
        });
        this.set("offersLists", offersList);
      },

      addOffer: function addOffer() {
        var offer, offers;
        return regeneratorRuntime.async(function addOffer$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              context$1$0.next = 2;
              return regeneratorRuntime.awrap(this.get("offerService").getOffer());

            case 2:
              offer = context$1$0.sent;

              if (offer) {
                context$1$0.next = 5;
                break;
              }

              return context$1$0.abrupt("return");

            case 5:
              offers = _lodash["default"].uniq([].concat(_toConsumableArray(this.get("offersLists")), [offer]));

              this.set("offersLists", offers);

            case 7:
            case "end":
              return context$1$0.stop();
          }
        }, null, this);
      },

      setDefaultItemValuation: function setDefaultItemValuation() {
        this.set("valueHkDollar", this.get("defaultValueHkDollar"));
      },

      //file upload
      triggerUpload: function triggerUpload() {
        // For Cordova application
        if (_stockConfigEnvironment["default"].cordova.enabled) {
          var onSuccess = (function () {
            return function (path) {
              console.log(path);
              var dataURL = "data:image/jpg;base64," + path;

              _ember["default"].$("input[type='file']").fileupload("option", "formData").file = dataURL;
              _ember["default"].$("input[type='file']").fileupload("add", {
                files: [dataURL]
              });
            };
          })(this);

          this.initActionSheet(onSuccess);
        } else {
          // For web application
          if (navigator.userAgent.match(/iemobile/i)) {
            //don't know why but on windows phone need to click twice in quick succession
            //for dialog to appear
            _ember["default"].$("input[type='file']").click().click();
          } else {
            _ember["default"].$("input[type='file']").trigger("click");
          }
        }
      },

      uploadReady: function uploadReady() {
        this.set("isReady", true);
      },

      uploadStart: function uploadStart(e, data) {
        this.send("deleteUnusedImage");
        this.set("uploadedFileDate", data);
        _ember["default"].$(".loading-image-indicator").show();
        this.set("loadingPercentage", "Image Uploading ");
      },

      cancelUpload: function cancelUpload() {
        if (this.get("uploadedFileDate")) {
          this.get("uploadedFileDate").abort();
        }
      },

      uploadProgress: function uploadProgress(e, data) {
        e.target.disabled = true; // disable image-selection
        var progress = parseInt(data.loaded / data.total * 100, 10) || 0;
        this.set("addPhotoLabel", progress + "%");
        this.set("loadingPercentage", "Image Uploading " + progress + "%");
      },

      uploadComplete: function uploadComplete(e) {
        e.target.disabled = false; // enable image-selection
        this.set("uploadedFileDate", null);
        _ember["default"].$(".loading-image-indicator.hide_image_loading").hide();
        this.set("loadingPercentage", "Image Uploading ");
      },

      uploadSuccess: function uploadSuccess(e, data) {
        var identifier = data.result.version + "/" + data.result.public_id + "." + data.result.format;
        var newUploadedImage = this.get("store").createRecord("image", {
          cloudinaryId: identifier,
          favourite: true
        });
        this.set("newUploadedImage", newUploadedImage);
        this.set("imageKeys", identifier);
      },

      clearDescription: function clearDescription() {
        this.set("description", "");
      },

      deleteAutoGeneratedNumber: function deleteAutoGeneratedNumber() {
        var _this7 = this;

        return this.runTask(function () {
          return _this7.get("packageService").removeInventoryNumber({
            code: _this7.get("inventoryNumber")
          });
        });
      },

      deleteUnusedImage: function deleteUnusedImage() {
        if (this.get("imageKeys.length")) {
          new _stockUtilsAjaxPromise["default"]("/images/delete_cloudinary_image", "PUT", this.get("session.authToken"), {
            cloudinary_id: this.get("imageKeys")
          });
          this.set("imageKeys", "");
          this.set("newUploadedImage", null);
        }
      },

      cancelForm: function cancelForm() {
        var _this8 = this;

        var cancel = function cancel() {
          return _this8.runTask(function callee$2$0() {
            return regeneratorRuntime.async(function callee$2$0$(context$3$0) {
              while (1) switch (context$3$0.prev = context$3$0.next) {
                case 0:
                  this.clearAllFields();

                  if (!this.get("inventoryNumber")) {
                    context$3$0.next = 5;
                    break;
                  }

                  context$3$0.next = 4;
                  return regeneratorRuntime.awrap(this.send("deleteAutoGeneratedNumber"));

                case 4:
                  this.set("inventoryNumber", "");

                case 5:
                  this.send("deleteUnusedImage");
                  this.setProperties({
                    locationId: "",
                    comment: "",
                    codeId: "",
                    successfullyDuplicated: false,
                    shouldDuplicate: false,
                    valueHkDollar: null
                  });
                  _ember["default"].run.later(this, function () {
                    this.replaceRoute("/");
                  }, 0);

                case 8:
                case "end":
                  return context$3$0.stop();
              }
            }, null, _this8);
          });
        };

        this.get("messageBox").custom(this.get("cancelWarning"), "Yes", cancel, "No");
      },

      toggleInventoryOptions: function toggleInventoryOptions() {
        this.toggleProperty("displayInventoryOptions");
      },

      editInventoryNumber: function editInventoryNumber() {
        return regeneratorRuntime.async(function editInventoryNumber$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              context$1$0.next = 2;
              return regeneratorRuntime.awrap(this.send("deleteAutoGeneratedNumber"));

            case 2:
              this.set("inventoryNumber", "");
              this.set("inputInventory", true);
              this.set("autoGenerateInventory", false);
              this.set("displayInventoryOptions", false);

            case 6:
            case "end":
              return context$1$0.stop();
          }
        }, null, this);
      },

      autoGenerateInventoryNumber: function autoGenerateInventoryNumber() {
        var _this9 = this;

        this.set("inventoryNumber", "");
        this.set("inputInventory", false);
        this.set("autoGenerateInventory", true);
        this.set("displayInventoryOptions", false);

        this.showLoadingSpinner();
        this.get("packageService").generateInventoryNumber().then(function (data) {
          _this9.set("inventoryNumber", data.inventory_number);
          _this9.hideLoadingSpinner();
        });
      },

      scanInventoryNumber: function scanInventoryNumber() {
        this.set("displayInventoryOptions", false);
        this.set("autoGenerateInventory", false);
        this.set("inputInventory", false);
        this.send("deleteAutoGeneratedNumber");
        this.checkPermissionAndScan();
      },

      onSearch: function onSearch(field, searchText) {
        this.onSearchCountry(field, searchText);
      },

      setCountryValue: function setCountryValue(value) {
        var CountryName = this.get("store").peekRecord("country", value.id).get("nameEn");
        this.set("selected", {
          id: value.id,
          nameEn: CountryName
        });
        this.set("countryValue", {
          country_id: value.id
        });
      },

      setPrinterValue: function setPrinterValue(value) {
        var printerId = value.id;
        this.set("selectedPrinterId", printerId);
        this.get("printerService").updateUserDefaultPrinter(printerId);
      },

      setFields: function setFields(fieldName, value) {
        var dropDownValues = _extends({}, this.get("dropDownValues"));
        if (this.get("fixedDropdownArr").indexOf(fieldName) >= 0) {
          dropDownValues[fieldName + "_id"] = value == null ? "" : value.id;
        } else {
          dropDownValues[fieldName] = value !== null && value.tag ? value.tag.trim() : "";
        }
        this.set("dropDownValues", dropDownValues);
      },

      saveItem: function saveItem() {
        this.showOfflineError();
        this.set("isSearchCodePreviousRoute", false);
        if (this.isInValidConditions()) {
          return false;
        } else {
          this.showLoadingSpinner();
          this.createPackage();
        }
      }
    }
  });
});