define("stock/components/remove-item-from-set", ["exports", "ember", "stock/utils/ajax-promise"], function (exports, _ember, _stockUtilsAjaxPromise) {
  var getOwner = _ember["default"].getOwner;
  exports["default"] = _ember["default"].Component.extend({
    displayUserPrompt: false,
    store: _ember["default"].inject.service(),
    hideDetailsLink: true,

    actions: {
      displayRemoveOverlay: function displayRemoveOverlay() {
        this.set("displayUserPrompt", true);
      },

      removeItemFromSet: function removeItemFromSet() {
        var _this = this;

        var item = this.get("item");
        var loadingView = getOwner(this).lookup('component:loading').append();
        var url = "/items/" + item.get('id') + "/remove_from_set";

        new _stockUtilsAjaxPromise["default"](url, "PUT", this.get('session.authToken')).then(function (data) {
          _this.get("store").pushPayload(data);
        })["finally"](function () {
          loadingView.destroy();
          _this.sendAction("closeList");
        });
      },

      closeItemOptions: function closeItemOptions() {
        this.sendAction("closeList");
      }
    }

  });
});