define("stock/services/package-type-service", ["exports", "ember", "lodash", "stock/utils/cache"], function (exports, _ember, _lodash, _stockUtilsCache) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  // @todo: unify code and pacakge_type under 'package_type'
  var MODEL_NAME = "code";

  exports["default"] = _ember["default"].Service.extend({
    // ----- Services -----
    store: _ember["default"].inject.service(),

    // ----- Methods -----

    init: function init() {
      this.set("openPackageTypeSearch", false);
      this.set("packageTypeSearchOptions", {});
    },

    /**
     * Triggers the code selection popup, and resolves the promise
     * once a package type (or code) has been selected.
     *
     * null is returned if the user closes the UI
     *
     * @param {object} opts search options
     * @returns {Promise<Model>}
     */
    userPickPackageType: function userPickPackageType() {
      var _this = this;

      var opts = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

      var deferred = _ember["default"].RSVP.defer();

      _ember["default"].run(function () {
        _this.set("packageTypeSearchOptions", opts);
        _this.set("openPackageTypeSearch", true);
        _this.set("onPackageTypeSelected", function (code) {
          _this.set("onPackageTypeSelected", _lodash["default"].noop);
          _this.set("openPackageTypeSearch", false);
          deferred.resolve(code || null);
        });
      });

      return deferred.promise;
    },

    parentsOf: function parentsOf(packageType) {
      var _this2 = this;

      var hierarchy = this.get("hierarchy");
      var code = packageType.get("code");

      var node = hierarchy.refs[code];

      return _lodash["default"].uniq(_lodash["default"].flatten([packageType].concat(_toConsumableArray(node.parents.map(function (_ref) {
        var type = _ref.type;
        return type;
      })), _toConsumableArray(node.parents.map(function (_ref2) {
        var type = _ref2.type;
        return _this2.parentsOf(type);
      })))));
    },

    // ----- Data -----

    _all: _ember["default"].computed(function () {
      return this.get("store").peekAll(MODEL_NAME);
    }),

    /*
     * Tree tructure representing the package type hierarchy
     */
    hierarchy: _ember["default"].computed("_all.[]", function () {
      var refs = {};

      var getOrCreateNode = function getOrCreateNode(type) {
        var parent = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];

        var code = type.get("code");

        if (!refs[code]) {
          (function () {
            var node = {};
            refs[code] = node;
            node.type = type;
            node.nodes = type.allChildPackagesList().map(function (t) {
              return getOrCreateNode(t, node);
            });
          })();
        }

        refs[code].parents = refs[code].parents || [];
        if (parent && parent != refs[code] && !_lodash["default"].includes(refs[code].parents.parent)) {
          refs[code].parents.push(parent);
        }

        return refs[code];
      };

      var allNodes = this.get("_all").map(function (type) {
        return getOrCreateNode(type);
      });

      return {
        refs: refs,
        roots: allNodes.filter(function (node) {
          return node.parents.length === 0;
        })
      };
    })
  });
});