define("stock/components/async-validatable-form", ["exports", "ember-cli-html5-validation/components/validatable-form", "stock/mixins/async"], function (exports, _emberCliHtml5ValidationComponentsValidatableForm, _stockMixinsAsync) {
  exports["default"] = _emberCliHtml5ValidationComponentsValidatableForm["default"].extend(_stockMixinsAsync["default"], {
    actions: {
      submit: function submit(eventName) {
        var _this = this;

        if (!this.get("element").checkValidity()) {
          this.scrollToFirstError();
          return false;
        }

        this.runTask(function callee$1$0() {
          return regeneratorRuntime.async(function callee$1$0$(context$2$0) {
            while (1) switch (context$2$0.prev = context$2$0.next) {
              case 0:
                this.get(eventName || "onSubmit")();

              case 1:
              case "end":
                return context$2$0.stop();
            }
          }, null, _this);
        }, _stockMixinsAsync.ERROR_STRATEGIES.MODAL);

        return false;
      }
    }
  });
});