define("stock/services/set-dropdown-option", ["exports", "ember", "lodash"], function (exports, _ember, _lodash) {
  exports["default"] = _ember["default"].Service.extend({
    session: _ember["default"].inject.service(),
    store: _ember["default"].inject.service(),

    setOptions: function setOptions(column, package_details) {
      if (["frequency", "voltage", "testStatus"].indexOf(column) > -1) {
        return this.fetchLookups("electrical_" + _lodash["default"].snakeCase(column));
      } else if (column === "compTestStatus") {
        return this.fetchLookups("comp_test_status");
      } else {
        var _ret = (function () {
          var dataObj = [];
          package_details.map(function (data) {
            if (data.get(column)) {
              dataObj.push({
                id: data.id,
                tag: data.get(column)
              });
            }
          });
          return {
            v: dataObj
          };
        })();

        if (typeof _ret === "object") return _ret.v;
      }
    },

    fetchLookups: function fetchLookups(lookupName) {
      var lookupArray = [];
      var lookupData = this.get("store").peekAll("lookup").filterBy("name", lookupName);
      lookupData.map(function (lookup) {
        var idAndLabel = lookup.getProperties("id", "labelEn");
        lookupArray.push({
          id: idAndLabel.id,
          tag: idAndLabel.labelEn
        });
      });
      return lookupArray;
    }
  });
});