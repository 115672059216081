define("stock/components/gc-textarea", ["exports", "ember", "stock/components/auto-resize-textarea"], function (exports, _ember, _stockComponentsAutoResizeTextarea) {
  exports["default"] = _stockComponentsAutoResizeTextarea["default"].extend({
    previousValue: "",
    store: _ember["default"].inject.service(),
    item: null,

    didInsertElement: function didInsertElement() {
      this.handleSiblings();
    },

    handleSiblings: function handleSiblings() {
      if (this.hasError()) {
        return this.showSiblings();
      }
      this.hideSiblings();
    },

    hasError: function hasError() {
      var value = (this.get("value") || "").trim();
      return !value && this.get("require");
    },

    didRender: function didRender() {
      this.handleSiblings();
    },

    showSiblings: function showSiblings() {
      _ember["default"].$(this.element).siblings().show();
    },

    hideSiblings: function hideSiblings() {
      _ember["default"].$(this.element).siblings().hide();
    },

    keyDown: function keyDown() {
      var value = this.element.value;
      if (value.charCodeAt(value.length - 1) === 10 && event.which === 13) {
        return false;
      }
    },

    focusOut: function focusOut() {
      return regeneratorRuntime.async(function focusOut$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            if (!this.hasError()) {
              context$1$0.next = 3;
              break;
            }

            this.showSiblings();
            return context$1$0.abrupt("return", false);

          case 3:
            this.onFocusOut && this.onFocusOut();

          case 4:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    addCssStyle: function addCssStyle() {
      _ember["default"].$(this.element).addClass("item-description-textarea-withbg");
      this.set("previousValue", this.get("value") || "");
    },

    click: function click() {
      this.addCssStyle();
    },

    focusIn: function focusIn() {
      this.addCssStyle();
      this.get("onFocusIn") && this.get("onFocusIn")();
    }
  });
});