define("stock/controllers/users/stock_roles", ["exports", "ember", "lodash", "stock/constants/roles", "stock/constants/access-types"], function (exports, _ember, _lodash, _stockConstantsRoles, _stockConstantsAccessTypes) {
  exports["default"] = _ember["default"].Controller.extend({
    printerService: _ember["default"].inject.service(),
    apiBaseService: _ember["default"].inject.service(),
    userService: _ember["default"].inject.service(),

    selectedPrinterId: "",
    user: _ember["default"].computed.alias("model.user"),
    activeRoles: _ember["default"].computed.alias("model.user.activeRoles"),

    noStockAppAccess: _ember["default"].computed.equal("stockRoleAccess", _stockConstantsAccessTypes.ACCESS_TYPES.NO_ACCESS),

    printers: _ember["default"].computed(function () {
      return this.get("printerService").allAvailablePrinters();
    }),

    canUpdateRole: _ember["default"].computed("user.id", function () {
      return this.get("userService").canUpdateRole(this.get("user.id"));
    }),

    selectedPrinterDisplay: _ember["default"].computed("model.user.id", "selectedPrinterId", function () {
      var printerId = this.get("selectedPrinterId");
      return this.get("userService").getPrinterForUser(this.get("user"), printerId, "stock");
    }),

    roleError: _ember["default"].computed("noStockAppRole", "stockRoleAccess", function () {
      return this.get("noStockAppRole") && this.get("stockRoleAccess") !== _stockConstantsAccessTypes.ACCESS_TYPES.NO_ACCESS;
    }),

    stockRoleAccess: _ember["default"].computed("user.roles.[]", {
      get: function get() {
        if (this.get("noStockAppRole")) {
          return _stockConstantsAccessTypes.ACCESS_TYPES.NO_ACCESS;
        } else if (this.get("roleExpiryDate")) {
          return _stockConstantsAccessTypes.ACCESS_TYPES.LIMITED_ACCESS;
        } else {
          return _stockConstantsAccessTypes.ACCESS_TYPES.UNLIMITED_ACCESS;
        }
      },
      set: function set(_, value) {
        if (value === _stockConstantsAccessTypes.ACCESS_TYPES.LIMITED_ACCESS) {
          this.set("roleExpiryDate", moment().format("DD/MMM/YYYY"));
        } else {
          this.set("roleExpiryDate", "");
        }

        return value;
      }
    }),

    observeExpiryDate: _ember["default"].observer("roleExpiryDate", function () {
      if (this.get("roleExpiryDate") && this.get("stockRoleAccess") !== _stockConstantsAccessTypes.ACCESS_TYPES.LIMITED_ACCESS) {
        this.set("stockRoleAccess", _stockConstantsAccessTypes.ACCESS_TYPES.LIMITED_ACCESS);
      }
    }),

    roleExpiryDate: _ember["default"].computed("activeRoles.[]", {
      get: function get() {
        var date = this.get("userService").getRoleExpiryDate(this.get("user"), "stock");
        return date ? moment(date).format("DD/MMM/YYYY") : "";
      },
      set: function set(_, value) {
        return value;
      }
    }),

    hasStockAdministratorRole: _ember["default"].computed("activeRoles.[]", {
      get: function get() {
        return this.get("userService").hasRole(this.get("user"), _stockConstantsRoles.ROLES.STOCK_APP_ROLES.STOCK_ADMINISTRATOR);
      },
      set: function set(_, value) {
        return value;
      }
    }),

    hasStockFulfilmentRole: _ember["default"].computed("activeRoles.[]", {
      get: function get() {
        return this.get("userService").hasRole(this.get("user"), _stockConstantsRoles.ROLES.STOCK_APP_ROLES.STOCK_FULFILMENT);
      },
      set: function set(_, value) {
        return value;
      }
    }),

    hasOrderAdministratorRole: _ember["default"].computed("activeRoles.[]", {
      get: function get() {
        return this.get("userService").hasRole(this.get("user"), _stockConstantsRoles.ROLES.STOCK_APP_ROLES.ORDER_ADMINISTRATOR);
      },
      set: function set(_, value) {
        return value;
      }
    }),

    hasOrderFulfilmentRole: _ember["default"].computed("activeRoles.[]", {
      get: function get() {
        return this.get("userService").hasRole(this.get("user"), _stockConstantsRoles.ROLES.STOCK_APP_ROLES.ORDER_FULFILMENT);
      },
      set: function set(_, value) {
        return value;
      }
    }),

    noStockAppRole: _ember["default"].computed("hasStockAdministratorRole", "hasStockFulfilmentRole", "hasOrderAdministratorRole", "hasOrderFulfilmentRole", function () {
      return !this.get("hasStockAdministratorRole") && !this.get("hasStockFulfilmentRole") && !this.get("hasOrderAdministratorRole") && !this.get("hasOrderFulfilmentRole");
    }),

    updateUserRole: function updateUserRole(role, selectedRole, roleExpiryDate) {
      var roleId = this.get("userService").getRoleId(role);
      var userId = this.get("user.id");

      if (selectedRole) {
        this.get("userService").assignRole(userId, roleId, roleExpiryDate);
      } else {
        this.get("userService").deleteUserRole(userId, roleId);
      }
    },

    actions: {
      setPrinterValue: function setPrinterValue(value) {
        var printerId = value.id;
        this.set("selectedPrinterId", printerId);
      },

      cancelForm: function cancelForm() {
        this.set("selectedPrinterId", "");
        this.transitionToRoute("users.details", this.get("user.id"));
      },

      saveUserRoles: function saveUserRoles() {
        var roleExpiryDate = undefined;
        var stockRoleAccess = this.get("stockRoleAccess");
        var userId = this.get("user.id");

        if (stockRoleAccess === _stockConstantsAccessTypes.ACCESS_TYPES.NO_ACCESS) {
          this.get("userService").deleteStockRoles(this.get("user"));
        } else {
          if (this.get("canUpdateRole")) {
            if (stockRoleAccess === _stockConstantsAccessTypes.ACCESS_TYPES.LIMITED_ACCESS) {
              roleExpiryDate = this.get("roleExpiryDate");
            }

            this.updateUserRole(_stockConstantsRoles.ROLES.STOCK_APP_ROLES.STOCK_FULFILMENT, this.get("hasStockFulfilmentRole"), roleExpiryDate);
            this.updateUserRole(_stockConstantsRoles.ROLES.STOCK_APP_ROLES.STOCK_ADMINISTRATOR, this.get("hasStockAdministratorRole"), roleExpiryDate);
            this.updateUserRole(_stockConstantsRoles.ROLES.STOCK_APP_ROLES.ORDER_FULFILMENT, this.get("hasOrderFulfilmentRole"), roleExpiryDate);
            this.updateUserRole(_stockConstantsRoles.ROLES.STOCK_APP_ROLES.ORDER_ADMINISTRATOR, this.get("hasOrderAdministratorRole"), roleExpiryDate);
          }

          var printerId = this.get("selectedPrinterId");
          this.get("printerService").addDefaultPrinter(printerId, userId, "stock");
        }

        this.transitionToRoute("users.details", userId);
      }
    }
  });
});