define("stock/models/booking_type", ["exports", "ember", "ember-data/model", "ember-data/attr"], function (exports, _ember, _emberDataModel, _emberDataAttr) {
  exports["default"] = _emberDataModel["default"].extend({
    i18n: _ember["default"].inject.service(),
    nameEn: (0, _emberDataAttr["default"])("string"),
    nameZh: (0, _emberDataAttr["default"])("string"),
    identifier: (0, _emberDataAttr["default"])("string"),

    isAppointment: _ember["default"].computed("identifier", function () {
      var name = this.get('identifier') && this.get('identifier').toLowerCase();
      return name === "appointment";
    }),

    isOnlineOrder: _ember["default"].computed.not('isAppointment'),

    displayName: _ember["default"].computed('isAppointment', function () {
      return this.get('i18n').t("order_transports." + (this.get('isAppointment') ? 'appointment' : 'online_order'));
    })
  });
});