define('stock/components/loading', ['exports', 'ember', 'stock/templates/loading'], function (exports, _ember, _stockTemplatesLoading) {
  exports['default'] = _ember['default'].Component.extend({
    layout: _stockTemplatesLoading['default'],
    classNames: ["loading-indicator"],
    messageBox: _ember['default'].inject.service(),
    logger: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),
    timer: null,

    didInsertElement: function didInsertElement() {
      var _this = this;

      var timer = _ember['default'].run.later(function () {
        _this.get("logger").error(new Error(_this.get("i18n").t("loading_timeout_error")));
        _this.get("messageBox").alert(_this.get("i18n").t("loading_timeout"), function () {
          _this.destroy();
          window.location.reload();
        });
      }, 3000000);

      this.set("timer", timer);
      _ember['default'].$(document).on("cancel-loading-timer", function () {
        return _ember['default'].run.cancel(timer);
      });
    },

    willDestroyElement: function willDestroyElement() {
      _ember['default'].run.cancel(this.get("timer"));
      _ember['default'].$(document).off("cancel-loading-timer");
    }
  });
});