define("stock/components/calendar-input", ["exports", "ember", "lodash"], function (exports, _ember, _lodash) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  var MIN_DATE = moment().subtract(2, "years").toDate();
  var DEFAULT_PICKADATE_CONFIG = {
    selectMonths: true,
    selectYears: true,
    formatSubmit: "ddd mmm d",
    monthsFull: moment.months(),
    monthsShort: moment.monthsShort(),
    weekdaysShort: moment.weekdaysShort(),
    clear: false,
    today: false,
    close: false,
    min: moment().toDate()
  };

  exports["default"] = _ember["default"].TextField.extend({
    tagName: "input",
    classNames: "pickadate",
    attributeBindings: ["name", "type", "value", "id", "required", "pattern", "placeholder", "disableWeekends"],

    _getValidDate: function _getValidDate(selectedDate) {
      var today = new Date();
      var currentDate = new Date();
      var selected = selectedDate;
      currentDate.setHours(0, 0, 0, 0);
      selected.setHours(0, 0, 0, 0);
      return currentDate > selected ? today : selectedDate;
    },

    isDateEqual: function isDateEqual(date) {
      var selected = this.get("selection");
      selected.setHours(0, 0, 0, 0);
      date.setHours(0, 0, 0, 0);
      return selected.getTime() === date.getTime();
    },

    _cb: function _cb() {
      var onSelect = this.get("onSelect");
      if (onSelect) {
        var date = this.get("selection");
        onSelect(date);
      }
    },

    onClose: function onClose(pickadate) {
      var _this = this;

      _ember["default"].$(document.activeElement).blur();
      if (this.setting) {
        return;
      }
      var date = pickadate.get("select") && pickadate.get("select").obj;

      if (date) {
        this.set("selection", date);
        this.setting = true;
        _ember["default"].run.next(function () {
          _this._cb();
          pickadate.set("select", moment(date).toDate(), {
            format: _this.get("datePickerConfig.formatSubmit")
          });
          _this.setting = false;
        });
      }
    },

    onStart: function onStart(pickadate) {
      var date = this.get("selection");
      if (date) {
        pickadate.set("select", moment(new Date(date)).toDate(), {
          format: this.get("datePickerConfig.formatSubmit")
        });
      }
    },

    initializeConfig: function initializeConfig() {
      var config = _extends({}, DEFAULT_PICKADATE_CONFIG);

      var enablePastDate = this.get("enablePastDate");
      if (enablePastDate) {
        config.min = MIN_DATE;
      }

      var formatSubmit = this.get("formatSubmit");
      if (formatSubmit) {
        config.format = formatSubmit;
      }

      this.set("datePickerConfig", config);
    },

    didInsertElement: function didInsertElement() {
      var component = this;
      this.initializeConfig();

      _ember["default"].run.scheduleOnce("afterRender", this, function () {
        _ember["default"].$(".pickadate").pickadate(_lodash["default"].extend({}, this.get("datePickerConfig"), {
          disable: component.get("disableWeekends") === true ? [1, 2] : [],
          onClose: function onClose() {
            component.onClose(this);
          },
          onOpen: function onOpen() {
            component.onStart(this);
          },
          onStart: function onStart() {
            component.onStart(this);
          }
        }));

        _ember["default"].$(".picker__holder").click(function (e) {
          if (e.target !== this) {
            return;
          }
          _ember["default"].$("[id$=selectedDate]").trigger("blur");
        });
      });
    }
  });
});