define("stock/components/goodcity/add-item-overlay", ["exports", "ember", "lodash", "stock/mixins/async"], function (exports, _ember, _lodash, _stockMixinsAsync) {
  var getOwner = _ember["default"].getOwner;
  exports["default"] = _ember["default"].Component.extend(_stockMixinsAsync["default"], {
    packageService: _ember["default"].inject.service(),
    store: _ember["default"].inject.service(),
    messageBox: _ember["default"].inject.service(),
    i18n: _ember["default"].inject.service(),
    locationQuantities: [],

    pkgLocations: _ember["default"].computed.alias("pkg.packagesLocations"),

    onOpen: _ember["default"].observer("open", function () {
      if (!this.get("open")) {
        return this.set("locationQuantities", []);
      }

      this.set("locationQuantities", this.get("pkgLocations").map(function (pl) {
        return {
          name: pl.get("location.name"),
          packageLocation: pl,
          selectedQuantity: pl.get("quantity")
        };
      }));
    }),

    totalNumberTomove: _ember["default"].computed("locationQuantities.@each.selectedQuantity", function () {
      return this.get("locationQuantities").reduce(function (acc, lq) {
        return acc + Number(lq.selectedQuantity);
      }, 0);
    }),

    resolveAddItemPromises: function resolveAddItemPromises() {
      return regeneratorRuntime.async(function resolveAddItemPromises$(context$1$0) {
        var _this = this;

        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return regeneratorRuntime.awrap(this.runTask(function callee$1$0() {
              var promises;
              return regeneratorRuntime.async(function callee$1$0$(context$2$0) {
                while (1) switch (context$2$0.prev = context$2$0.next) {
                  case 0:
                    promises = this.addItemPromises();
                    context$2$0.next = 3;
                    return regeneratorRuntime.awrap(_ember["default"].RSVP.all(promises));

                  case 3:
                    this.sendAction("onConfirm");

                  case 4:
                  case "end":
                    return context$2$0.stop();
                }
              }, null, _this);
            }, _stockMixinsAsync.ERROR_STRATEGIES.MODAL));

          case 2:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    hasInvalidAddedQuantity: function hasInvalidAddedQuantity() {
      return !!this.get("locationQuantities").find(function (lq) {
        return lq.selectedQuantity < 0 || lq.selectedQuantity > lq.packageLocation.get("quantity");
      });
    },

    addItemPromises: function addItemPromises() {
      var _this2 = this;

      var promises = [];
      this.get("locationQuantities").map(function (lq) {
        var selectedQuantity = lq.selectedQuantity;
        var packageLocation = lq.packageLocation;

        if (selectedQuantity > 0) {
          var params = {
            item_id: _this2.get("pkg").id,
            task: "pack",
            location_id: packageLocation.get("locationId"),
            quantity: selectedQuantity
          };
          promises.push(_this2.get("packageService").addRemoveItem(_this2.get("entity.id"), params));
        }
      });
      return promises;
    },

    actions: {
      moveItemToBox: function moveItemToBox(pkg) {
        var cb = arguments.length <= 1 || arguments[1] === undefined ? _lodash["default"].noop : arguments[1];
        return regeneratorRuntime.async(function moveItemToBox$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              if (pkg) {
                context$1$0.next = 2;
                break;
              }

              return context$1$0.abrupt("return");

            case 2:

              if (this.hasInvalidAddedQuantity()) {
                this.get("messageBox").alert(this.get("i18n").t("box_pallet.invalid_quantity"));
              }

              context$1$0.next = 5;
              return regeneratorRuntime.awrap(this.resolveAddItemPromises());

            case 5:
              cb(pkg, this.get("totalNumberTomove"));
              this.set("open", false);
              return context$1$0.abrupt("return", pkg);

            case 8:
            case "end":
              return context$1$0.stop();
          }
        }, null, this);
      },

      cancelMove: function cancelMove() {
        this.set("open", false);
      }
    }
  });
});