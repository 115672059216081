define("stock/constants/state-events", ["exports"], function (exports) {
  var STATE_EVENTS = {
    SUBMIT: "submit",
    REJECT: "reject"
  };

  exports.STATE_EVENTS = STATE_EVENTS;
  var INTERNATIONAL_ORDERS = {
    SHIPMENT: "Shipment",
    CARRYOUT: "CarryOut"
  };
  exports.INTERNATIONAL_ORDERS = INTERNATIONAL_ORDERS;
});