define("stock/controllers/users/admin_roles", ["exports", "ember", "lodash", "stock/constants/roles", "stock/constants/access-types"], function (exports, _ember, _lodash, _stockConstantsRoles, _stockConstantsAccessTypes) {
  exports["default"] = _ember["default"].Controller.extend({
    printerService: _ember["default"].inject.service(),
    apiBaseService: _ember["default"].inject.service(),
    userService: _ember["default"].inject.service(),

    selectedPrinterId: "",
    user: _ember["default"].computed.alias("model.user"),
    activeRoles: _ember["default"].computed.alias("model.user.activeRoles"),

    noAdminAppAccess: _ember["default"].computed.equal("adminRoleAccess", _stockConstantsAccessTypes.ACCESS_TYPES.NO_ACCESS),

    printers: _ember["default"].computed(function () {
      return this.get("printerService").allAvailablePrinters();
    }),

    canUpdateRole: _ember["default"].computed("user.id", function () {
      return this.get("userService").canUpdateRole(this.get("user.id"));
    }),

    selectedPrinterDisplay: _ember["default"].computed("model.user.id", "selectedPrinterId", function () {
      var printerId = this.get("selectedPrinterId");
      return this.get("userService").getPrinterForUser(this.get("user"), printerId, "admin");
    }),

    roleError: _ember["default"].computed("noAdminAppRole", "adminRoleAccess", function () {
      return this.get("noAdminAppRole") && this.get("adminRoleAccess") !== _stockConstantsAccessTypes.ACCESS_TYPES.NO_ACCESS;
    }),

    adminRoleAccess: _ember["default"].computed("user.roles.[]", {
      get: function get() {
        if (this.get("noAdminAppRole")) {
          return _stockConstantsAccessTypes.ACCESS_TYPES.NO_ACCESS;
        } else if (this.get("roleExpiryDate")) {
          return _stockConstantsAccessTypes.ACCESS_TYPES.LIMITED_ACCESS;
        } else {
          return _stockConstantsAccessTypes.ACCESS_TYPES.UNLIMITED_ACCESS;
        }
      },
      set: function set(_, value) {
        if (value === _stockConstantsAccessTypes.ACCESS_TYPES.LIMITED_ACCESS) {
          this.set("roleExpiryDate", moment().format("DD/MMM/YYYY"));
        } else {
          this.set("roleExpiryDate", "");
        }

        return value;
      }
    }),

    observeExpiryDate: _ember["default"].observer("roleExpiryDate", function () {
      if (this.get("roleExpiryDate") && this.get("adminRoleAccess") !== _stockConstantsAccessTypes.ACCESS_TYPES.LIMITED_ACCESS) {
        this.set("adminRoleAccess", _stockConstantsAccessTypes.ACCESS_TYPES.LIMITED_ACCESS);
      }
    }),

    roleExpiryDate: _ember["default"].computed("activeRoles.[]", {
      get: function get() {
        var date = this.get("userService").getRoleExpiryDate(this.get("user"), "admin");
        return date ? moment(date).format("DD/MMM/YYYY") : "";
      },
      set: function set(_, value) {
        return value;
      }
    }),

    hasReviewerRole: _ember["default"].computed("activeRoles.[]", {
      get: function get() {
        return this.get("userService").hasRole(this.get("user"), _stockConstantsRoles.ROLES.ADMIN_APP_ROLES.REVIEWER);
      },
      set: function set(_, value) {
        return value;
      }
    }),

    hasSupervisorRole: _ember["default"].computed("activeRoles.[]", {
      get: function get() {
        return this.get("userService").hasRole(this.get("user"), _stockConstantsRoles.ROLES.ADMIN_APP_ROLES.SUPERVISOR);
      },
      set: function set(_, value) {
        return value;
      }
    }),

    noAdminAppRole: _ember["default"].computed("hasReviewerRole", "hasSupervisorRole", function () {
      return !this.get("hasReviewerRole") && !this.get("hasSupervisorRole");
    }),

    savePrinter: function savePrinter() {
      var printerId = this.get("selectedPrinterId");
      this.get("printerService").addDefaultPrinter(printerId, this.get("user.id"), "admin");
    },

    updateUserRole: function updateUserRole(role, selectedRole, roleExpiryDate) {
      var roleId = this.get("userService").getRoleId(role);
      var userId = this.get("user.id");

      if (selectedRole) {
        this.get("userService").assignRole(userId, roleId, roleExpiryDate);
      } else {
        this.get("userService").deleteUserRole(userId, roleId);
      }
    },

    actions: {
      setPrinterValue: function setPrinterValue(value) {
        var printerId = value.id;
        this.set("selectedPrinterId", printerId);
      },

      cancelForm: function cancelForm() {
        this.set("selectedPrinterId", "");
        this.transitionToRoute("users.details", this.get("user.id"));
      },

      saveUserRoles: function saveUserRoles() {
        var roleExpiryDate = undefined;
        var adminRoleAccess = this.get("adminRoleAccess");

        if (adminRoleAccess === _stockConstantsAccessTypes.ACCESS_TYPES.NO_ACCESS) {
          this.get("userService").deleteAdminRoles(this.get("user"));
        } else {
          if (this.get("canUpdateRole")) {
            if (adminRoleAccess === _stockConstantsAccessTypes.ACCESS_TYPES.LIMITED_ACCESS) {
              roleExpiryDate = this.get("roleExpiryDate");
            }

            this.updateUserRole(_stockConstantsRoles.ROLES.ADMIN_APP_ROLES.REVIEWER, this.get("hasReviewerRole"), roleExpiryDate);
            this.updateUserRole(_stockConstantsRoles.ROLES.ADMIN_APP_ROLES.SUPERVISOR, this.get("hasSupervisorRole"), roleExpiryDate);
          }

          this.savePrinter();
        }
        this.transitionToRoute("users.details", this.get("user.id"));
      }
    }
  });
});