define("stock/controllers/orders/items", ["exports", "stock/config/environment", "ember", "lodash", "stock/mixins/search_resource"], function (exports, _stockConfigEnvironment, _ember, _lodash, _stockMixinsSearch_resource) {
  exports["default"] = _ember["default"].Controller.extend(_stockMixinsSearch_resource["default"], {
    hideDetailsLink: true,
    settings: _ember["default"].inject.service(),

    orderId: _ember["default"].computed.alias("model.id"),
    isMobileApp: _stockConfigEnvironment["default"].cordova.enabled,
    autoDisplayOverlay: false,

    autoLoad: false,
    perPage: 25,

    getFilterQuery: function getFilterQuery() {
      return {
        stockRequest: true,
        restrictMultiQuantity: this.get("settings.onlyDesignateSingletons")
      };
    },

    triggerDisplayDesignateOverlay: function triggerDisplayDesignateOverlay() {
      this.set("autoDisplayOverlay", true);
    },

    actions: {
      loadMoreItems: function loadMoreItems(pageNo) {
        var params = this.trimQuery(_lodash["default"].merge({}, this.getFilterQuery(), this.getSearchQuery(), this.getPaginationQuery(pageNo)));

        return this.get("store").query("item", params).then(function (results) {
          return results;
        });
      },

      setScannedSearchText: function setScannedSearchText(searchedText) {
        this.set("searchText", searchedText);
      },

      clearSearch: function clearSearch() {
        this.set("searchText", "");
      }
    }
  });
});