define("stock/routes/users/merge_user", ["exports", "stock/routes/authorize"], function (exports, _stockRoutesAuthorize) {
  exports["default"] = _stockRoutesAuthorize["default"].extend({
    queryParams: {
      otherUserId: null
    },

    beforeModel: function beforeModel(_ref) {
      var _ref$queryParams = _ref.queryParams;
      var queryParams = _ref$queryParams === undefined ? {} : _ref$queryParams;
      var _ref$params = _ref.params;
      var params = _ref$params === undefined ? {} : _ref$params;

      this._super.apply(this, arguments);

      var hasotherUserId = !!queryParams.otherUserId;
      var userId = params["users.merge_user"].user_id;

      if (!hasotherUserId || hasotherUserId && queryParams.otherUserId === userId) {
        this.replaceWith("users.details", userId);
      }
    },

    model: function model(params) {
      return Ember.RSVP.hash({
        user: this.loadIfAbsent("user", params.user_id),
        otherUser: this.loadIfAbsent("user", params.otherUserId)
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
    }
  });
});