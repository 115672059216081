define("stock/helpers/item-action-icon", ["exports", "ember", "stock/constants/action-icons", "lodash"], function (exports, _ember, _stockConstantsActionIcons, _lodash) {
  exports["default"] = _ember["default"].Helper.helper(function (state) {
    var iconIndex = _lodash["default"].findIndex(_stockConstantsActionIcons.ACTIONS_ICONS, ["name", state[0]]);

    if (iconIndex > -1) {
      return _stockConstantsActionIcons.ACTIONS_ICONS[iconIndex].icon;
    } else {
      return "";
    }
  });
});