define('stock/routes/session', ['exports', 'ember'], function (exports, _ember) {
  var getOwner = _ember['default'].getOwner;
  exports['default'] = _ember['default'].Route.extend({
    cordova: _ember['default'].inject.service(),

    loadOrRedirectUser: function loadOrRedirectUser() {
      if (!this.get('session.currentUser')) {
        getOwner(this).lookup("route:application")._loadDataStore();
      } else {
        if (this.session.get('isLoggedIn')) {
          this.get("cordova").appLoad();
        }
        this.transitionTo('/');
      }
    },

    beforeModel: function beforeModel() {
      if (window.localStorage.getItem("authToken") && this.session.get('isLoggedIn')) {
        this.loadOrRedirectUser();
      } else {
        this.transitionTo('login');
      }
    }
  });
});