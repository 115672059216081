define("stock/controllers/orders/detail", ["exports", "ember", "stock/config/environment", "stock/controllers/goodcity_controller", "stock/mixins/search_resource", "lodash", "stock/mixins/async"], function (exports, _ember, _stockConfigEnvironment, _stockControllersGoodcity_controller, _stockMixinsSearch_resource, _lodash, _stockMixinsAsync) {
  var getOwner = _ember["default"].getOwner;
  exports["default"] = _stockControllersGoodcity_controller["default"].extend(_stockMixinsAsync["default"], _stockMixinsSearch_resource["default"], {
    backLinkPath: "",
    displayAllItems: false,
    cancelReasonLength: 180,
    otherCancellationReason: "",
    isMobileApp: _stockConfigEnvironment["default"].cordova.enabled,
    hkTimeZone: _stockConfigEnvironment["default"].APP.HK_TIME_ZONE,
    order: _ember["default"].computed.alias("model"),
    orderId: _ember["default"].computed.alias("model.id"),
    showCancellationReason: false,

    hasUnreadMessages: _ember["default"].computed("order", function () {
      return this.get("order.hasUnreadMessages");
    }),

    unreadMessagesCount: _ember["default"].computed("order", function () {
      return this.get("order.unreadMessagesCount");
    }),

    store: _ember["default"].inject.service(),
    messageBox: _ember["default"].inject.service(),
    i18n: _ember["default"].inject.service(),
    placeHolderDate: null,
    appReview: _ember["default"].inject.service(),
    application: _ember["default"].inject.controller(),
    isOrderProcessRestarted: false,
    scheduleChangePopupVisible: false,
    filterService: _ember["default"].inject.service(),
    processingChecklist: _ember["default"].inject.service(),
    orderService: _ember["default"].inject.service(),
    currentRoute: _ember["default"].computed.alias("application.currentPath"),

    tabName: _ember["default"].computed("currentRoute", function () {
      return this.get("currentRoute").split(".").get("lastObject");
    }),

    highlightSelectedTabSummary: _ember["default"].computed("tabName", function () {
      return ["client_summary", "contact_summary"].indexOf(this.get("tabName")) >= 0;
    }),

    highlightSelectedTabGoods: _ember["default"].computed("tabName", function () {
      return ["active_items", "requested_items"].indexOf(this.get("tabName")) >= 0;
    }),

    scheduleTimeSlots: _ember["default"].computed(function () {
      var _this2 = this;

      var buildSlot = function buildSlot(hours, minutes) {
        var key = _this2.formatTimeSlot(hours, minutes);
        return {
          name: key,
          id: key,
          hours: hours,
          minutes: minutes
        };
      };
      var slots = _lodash["default"].range(0, 23).map(function (h) {
        return [0, 30].map(function (m) {
          return buildSlot(h, m);
        });
      });
      return _lodash["default"].flatten(slots);
    }),

    displayOrderOptions: _ember["default"].computed({
      get: function get() {
        return false;
      },
      set: function set(key, value) {
        return value;
      }
    }),

    cancelReason: _ember["default"].computed.oneWay("model.cancelReason"),

    cancelText: _ember["default"].computed("model.cancellationReason", function () {
      var cancelTextKey = this.get("model.cancellationReason") ? "update_reason" : "cancel_order";
      return this.get("i18n").t("order_details." + cancelTextKey);
    }),

    cancellationReasons: _ember["default"].computed(function () {
      return this.store.peekAll("cancellation_reason");
    }),

    cancellationReasonId: _ember["default"].computed("model.cancellationReason", function () {
      return this.get("model.cancellationReason.id") || this.get("cancellationReasons.firstObject.id");
    }),

    remainingReasonChars: _ember["default"].computed("cancelReason", function () {
      return this.get("cancelReasonLength") - (this.get("cancelReason") && this.get("cancelReason").length || 0);
    }),

    ordersPackagesLengthMoreThenThree: _ember["default"].observer("model.ordersPackages", function () {
      var ordersPackages = this.get("model.ordersPackages");
      ordersPackages.canonicalState.forEach(function (record) {
        if (record && record._data.state === "cancelled") {
          ordersPackages.canonicalState.removeObject(record);
        }
      });
      return ordersPackages.canonicalState.length >= 3 ? this.set("displayAllItems", false) : this.set("displayAllItems", true);
    }),

    organisationsUser: _ember["default"].computed("model.{createdBy,isGoodCityOrder}", "model.createdBy.organisationsUsers.@each.{status,organisationId}", "model.createdBy.organisationsUsers.[]", "model.{organisation,isGoodCityOrder}", function () {
      if (!this.get("model.isGoodCityOrder")) {
        return null;
      }

      var organisationsUsers = this.getWithDefault("model.createdBy.organisationsUsers", []);

      return organisationsUsers.findBy("organisationId", this.get("model.organisationId"));
    }),

    itemsList: _ember["default"].computed("model.items", "displayAllItems", "model.ordersPackages", "model.ordersPackages.@each.quantity", "model.ordersPackages.@each.state", function () {
      var ordersPackages = this.get("model.ordersPackages").rejectBy("state", "requested").rejectBy("state", "cancelled").rejectBy("state", null);
      return this.get("displayAllItems") ? ordersPackages : ordersPackages.slice(0, 3);
    }),

    canceledItemsList: _ember["default"].computed("model.items", "displayAllItems", "model.ordersPackages", "model.ordersPackages.@each.quantity", "model.ordersPackages.@each.state", function () {
      var ordersPackages = this.get("model.ordersPackages").filterBy("state", "cancelled").rejectBy("state", null);
      return this.get("displayAllItems") ? ordersPackages : ordersPackages.slice(0, 3);
    }),

    ordersPkgLength: _ember["default"].computed("model.items", "displayAllItems", "model.ordersPackages", "model.ordersPackages.@each.quantity", "model.ordersPackages.@each.state", function () {
      return this.get("model.ordersPackages").rejectBy("state", "requested").rejectBy("state", null).length;
    }),

    formatTimeSlot: function formatTimeSlot(hours, minutes) {
      return moment().set("hour", hours).set("minute", minutes).format("hh:mmA");
    },

    genericCustomPopUp: function genericCustomPopUp(message, button1text, button2text, btn1Callback) {
      var _this3 = this;

      var _this = this;
      _this.get("messageBox").custom(_this.get("i18n").t(message), _this.get("i18n").t(button1text), function () {
        btn1Callback();
      }, _this.get("i18n").t(button2text), function () {
        _this3.send("toggleDisplayOptions");
      });
    },

    genericAlertPopUp: function genericAlertPopUp(message) {
      var btn1Callback = arguments.length <= 1 || arguments[1] === undefined ? _lodash["default"].noop : arguments[1];

      var _this = this;
      this.get("messageBox").alert(_this.get("i18n").t(message), function () {
        btn1Callback();
      });
    },

    //Should only be able to close if at least 1 item is dispatched and 0 is designated
    canCloseOrder: function canCloseOrder(order) {
      var ordersPackages = order.get("ordersPackages");
      return ordersPackages.filterBy("state", "dispatched").length > 0 && !ordersPackages.filterBy("state", "designated").length;
    },

    //Should only be able to cancel if 0 items are dispatched
    canCancelOrder: function canCancelOrder(order) {
      return !order.get("ordersPackages").filterBy("state", "dispatched").length;
    },

    verifyOrgApproval: function verifyOrgApproval(cb) {
      return regeneratorRuntime.async(function verifyOrgApproval$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            if (!(!this.get("organisationsUser") || this.get("organisationsUser.isApproved"))) {
              context$1$0.next = 2;
              break;
            }

            return context$1$0.abrupt("return", cb());

          case 2:
            context$1$0.next = 4;
            return regeneratorRuntime.awrap(this.modalAlert("order.cannot_process_unless_approved"));

          case 4:
            this.replaceRoute("orders.contact_summary", this.get("model.id"));

          case 5:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    actions: {
      cancelOrder: function cancelOrder() {
        var _this4 = this;

        var reason = {
          cancellation_reason_id: this.get("cancellationReasonId"),
          cancel_reason: this.get("cancelReason")
        };
        this.runTask(this.get("orderService").cancelOrder(this.get("order"), reason).then(function () {
          _this4.send("toggleDisplayOptions");
          _this4.setProperties({
            otherCancellationReason: ""
          });
        }), _stockMixinsAsync.ERROR_STRATEGIES.MODAL);
      },

      openSchedulePopup: function openSchedulePopup() {
        var scheduledAt = this.get("model.orderTransport.scheduledAt");
        try {
          var scheduledDate = moment.tz(scheduledAt, this.get("hkTimeZone"));
          var timeString = scheduledDate.format("hh:mmA");

          var currentTimeSlot = _lodash["default"].find(this.get("scheduleTimeSlots"), ["id", timeString]);

          this.set("selectedTimeslot", currentTimeSlot || this.get("scheduleTimeSlots")[0]);

          var formattedScheduledDate = moment(scheduledDate).format("D MMMM, YYYY");

          this.set("selectedScheduleDate", scheduledDate.format());
          this.set("scheduledAtStringPlaceholder", formattedScheduledDate);
          this.set("placeHolderDate", formattedScheduledDate);
        } catch (e) {
          this.set("selectedTimeslot", this.get("scheduleTimeSlots")[0]);
          this.set("selectedScheduleDate", null);
        }
        this.set("scheduleChangePopupVisible", true);
      },

      closeSchedulePopup: function closeSchedulePopup() {
        this.set("scheduleChangePopupVisible", false);
      },

      saveNewSchedule: function saveNewSchedule() {
        var ts = this.get("selectedTimeslot");
        var date = this.get("selectedScheduleDate");
        if (!date || !ts) {
          return this.showError("Please select a valid date and timeslot");
        }

        date = typeof date === "string" ? new Date(date) : date;
        var tzDate = moment.tz(date.toDateString(), this.get("hkTimeZone"));
        tzDate.set({
          hour: ts.hours,
          minute: ts.minutes
        });

        this.updateRecord(this.get("model.orderTransport"), {
          timeslot: ts.id,
          scheduledAt: tzDate
        });
      },

      selectTimeslot: function selectTimeslot(ts) {
        this.set("selectedTimeslot", ts);
      },

      toggleOrderOptions: function toggleOrderOptions() {
        this.toggleProperty("displayOrderOptions");
      },

      resetCancellationReason: function resetCancellationReason() {
        this.set("cancelReason", "");
        this.set("order.cancellationReason", this.get("cancellationReasons").get("firstObject"));
      },

      updateOrder: function updateOrder(order, actionName) {
        var _this5 = this;

        switch (actionName) {
          case "messagePopUp":
            this.send("changeOrderState", order, "cancel");
            break;
          case "start_processing":
            this.verifyOrgApproval(function () {
              _this5.send("changeOrderState", order, actionName);
            });
            break;
          case "resubmit":
            this.send("promptResubmitModel", order, actionName);

            //clear cancel reason from ember data.
            this.send("resetCancellationReason");
            break;
          case "reopen":
            this.send("promptReopenModel", order, actionName);
            break;
          case "restart_process":
            this.send("promptRestartProcessModel", order, actionName);
            break;
          case "dispatch_later":
            this.send("dispatchLaterModel", order, actionName);
            break;
          case "cancel":
            this.set("showCancellationReason", true);
            break;
          case "close":
            this.send("promptCloseOrderModel", order, actionName);
            break;
          case "finish_processing":
            this.verifyOrgApproval(function () {
              _this5.send("verifyChecklistAndChangeState", order, actionName);
            });
            break;
          case "start_dispatching":
            this.send("verifyChecklistAndChangeState", order, actionName);
            break;
          default:
            this.send("changeOrderState", order, actionName);
        }
      },

      verifyChecklistAndChangeState: function verifyChecklistAndChangeState(order, actionName) {
        if (this.get("processingChecklist").checklistCompleted(order)) {
          this.send("changeOrderState", order, actionName);
        } else {
          this.genericAlertPopUp("order_details.logistics.checklist_incomplete");
        }
      },

      dispatchLaterModel: function dispatchLaterModel(order, actionName) {
        var _this = this;
        if (order.get("dispatchedOrdersPackages").length) {
          this.genericAlertPopUp("order_details.dispatch_later_undispatch_warning", function () {
            _this.send("toggleDisplayOptions");
          });
        } else {
          this.genericCustomPopUp("order_details.dispatch_later_warning", "order.dispatch_later", "not_now", function () {
            _this.send("changeOrderState", order, actionName);
          });
        }
      },

      promptResubmitModel: function promptResubmitModel(order, actionName) {
        var _this = this;
        this.genericCustomPopUp("order_details.resubmit_order_warning", "order.resubmit", "not_now", function () {
          _this.send("changeOrderState", order, actionName);
        });
      },

      promptReopenModel: function promptReopenModel(order, actionName) {
        var _this = this;
        this.genericCustomPopUp("order_details.reopen_warning", "order.reopen_order", "not_now", function () {
          _this.send("changeOrderState", order, actionName);
        });
      },

      toggleDisplayOptions: function toggleDisplayOptions() {
        if (this.get("displayOrderOptions")) {
          this.set("displayOrderOptions", false);
        }
      },

      promptRestartProcessModel: function promptRestartProcessModel(order, actionName) {
        var _this = this;
        if (order.get("dispatchedOrdersPackages").length) {
          this.genericAlertPopUp("order_details.restart_undispatch_warning", function () {
            _this.send("toggleDisplayOptions");
          });
        } else {
          this.genericCustomPopUp("order_details.restart_warning", "order.restart_process", "not_now", function () {
            _this.set("isOrderProcessRestarted", true);
            _this.send("changeOrderState", order, actionName);
          });
        }
      },

      promptCancelOrderModel: function promptCancelOrderModel(order, actionName) {
        var _this = this;
        if (this.canCancelOrder(order)) {
          this.genericCustomPopUp("order_details.cancel_warning", "order.cancel_order", "not_now", function () {
            _this.send("changeOrderState", order, actionName);
          });
        } else {
          this.genericAlertPopUp("order_details.cancel_order_alert", function () {});
        }
      },

      promptCloseOrderModel: function promptCloseOrderModel(order, actionName) {
        var _this = this;
        if (this.canCloseOrder(order)) {
          this.genericCustomPopUp("order_details.close_warning", "order.close_order", "not_now", function () {
            _this.send("changeOrderState", order, actionName);
          });
        } else {
          this.genericAlertPopUp("order_details.close_order_dispatch_alert", function () {});
        }
      },

      changeOrderState: function changeOrderState(order, transition) {
        var _this6 = this;

        this.runTask(this.get("orderService").changeOrderState(order, transition).then(function (data) {
          if (transition === "restart_process") {
            _this6.set("isOrderProcessRestarted", false);
          }
          _this6.send("toggleDisplayOptions");
        })["finally"](function () {
          if (transition === "close") {
            _this6.get("appReview").promptReviewModal(true);
          }
        }));
      }
    }
  });
});