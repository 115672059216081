define("stock/routes/order/search_users", ["exports", "ember", "stock/routes/authorize", "stock/utils/ajax-promise"], function (exports, _ember, _stockRoutesAuthorize, _stockUtilsAjaxPromise) {
  exports["default"] = _stockRoutesAuthorize["default"].extend({
    model: function model(params) {
      var orderId = params.order_id;
      var order = this.store.peekRecord("designation", orderId) || this.store.findRecord("designation", orderId);
      return _ember["default"].RSVP.hash({
        order: order,
        recentUsers: new _stockUtilsAjaxPromise["default"]("/recent_users", "GET", this.session.get("authToken"))
      });
    },

    setupController: function setupController(controller, model) {
      var _this = this;

      this._super(controller, model);
      this.get("store").pushPayload(model.recentUsers);
      var users = [];

      model.recentUsers.users.forEach(function (user) {
        var userObject = _this.store.peekRecord("user", user.id);
        user.organisations_users_ids.forEach(function (org_user_id) {
          var organisationsUser = controller.findOrganisationsUser(org_user_id);
          _this.store.pushPayload(organisationsUser);
        });
        users.push(userObject);
      });

      controller.set("filteredResults", users.uniq());
    },

    resetController: function resetController(controller) {
      controller.set("searchText", "");
    }
  });
});