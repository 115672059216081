define("stock/components/goodcity/orders-search-overlay", ["exports", "ember", "lodash", "stock/mixins/search_resource"], function (exports, _ember, _lodash, _stockMixinsSearch_resource) {

  /**
   * An overlay that pops up from the bottom of the screen, allowing the user
   * to search and select an order.
   *
   * The popup *does not* do anythinng to the order apart from returning it
   *
   * @property {boolean} open whether the popup is visible or not
   * @property {function} onSelect callback triggered when an order is selected
   */
  exports["default"] = _ember["default"].Component.extend(_stockMixinsSearch_resource["default"], {
    searchProps: {},
    autoLoad: true,
    store: _ember["default"].inject.service(),
    perPage: 10,

    init: function init() {
      this._super.apply(this, arguments);
      this.set("uuid", _lodash["default"].uniqueId("orders_search_overlay_"));
    },

    noInput: _ember["default"].computed.not("searchText"),

    showRecentlyUsed: _ember["default"].computed.and("noInput", "open"),

    recentlyUsedOrders: _ember["default"].computed("open", function () {
      return this.get("store").peekAll("designation").sortBy("recentlyUsedAt").slice(0, 10);
    }),

    actions: {
      cancel: function cancel() {
        this.send("selectOrder", null);
      },

      selectOrder: function selectOrder(order) {
        this.getWithDefault("onSelect", _lodash["default"].noop)(order);
        this.set("open", false);
      },

      loadMoreOrders: function loadMoreOrders(pageNo) {
        var params = this.trimQuery(_lodash["default"].merge({}, this.getSearchQuery(), this.getPaginationQuery(pageNo)));

        return this.get("store").query("designation", params);
      }
    }
  });
});