define("stock/models/package_type", ["exports", "ember", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (exports, _ember, _emberDataModel, _emberDataAttr, _emberDataRelationships) {
  exports["default"] = _emberDataModel["default"].extend({
    name: (0, _emberDataAttr["default"])("string"),
    code: (0, _emberDataAttr["default"])("string"),
    items: (0, _emberDataRelationships.hasMany)("item", { async: false }),
    packages: (0, _emberDataRelationships.hasMany)("package", { async: false }),
    allowBox: (0, _emberDataAttr["default"])("boolean"),
    allowPallet: (0, _emberDataAttr["default"])("boolean"),
    allowPackage: (0, _emberDataAttr["default"])("boolean"),

    getItemPackageList: _ember["default"].computed("packages.@each.allowWebPublish", "_packages.@each.packageType", "packages.@each.hasSiblingPackages", "packages.@each.isAvailable", function () {
      var packages = this.get("packages").filterBy("isAvailable");
      var items = [];

      if (packages.length) {
        var singlePackages = packages.rejectBy("hasSiblingPackages") || [];
        items = items.concat(singlePackages.toArray());

        var multiPackages = packages.filterBy("hasSiblingPackages") || [];
        items = items.concat(multiPackages.map(function (pkg) {
          return pkg.get("item");
        }).uniq());
      }
      return items.uniq();
    }),

    _packages: _ember["default"].computed("packages.[]", function () {
      return this.get("store").peekAll("package");
    }),

    _packageCategories: _ember["default"].computed(function () {
      return this.store.peekAll("package_category");
    }),

    packageCategories: _ember["default"].computed("code", "_packageCategories.[]", function () {
      var code = this.get("code");
      return this.get("_packageCategories").filter(function (p) {
        return (p.get("packageTypeCodes") || []).indexOf(code) > -1;
      });
    }),

    allPackageCategories: _ember["default"].computed("code", "_packageCategories.[]", function () {
      var categories = this.get("packageCategories").toArray();
      this.get("packageCategories").forEach(function (pkg) {
        var parentCategory = pkg.get("parentCategory");
        if (parentCategory) {
          categories = categories.concat(parentCategory);
        }
      });
      return categories.uniq();
    })
  });
});