define("stock/utils/cache", ["exports", "ember", "lodash"], function (exports, _ember, _lodash) {
  exports.cached = cached;
  exports.exec = exec;

  var CACHE_IDS = [];

  /**
   * @description Wraps the function, allowing only the first call to go through. Any subsequent call will use the cached result
   *
   * @param {function} func the source method
   * @returns {function} a wrapper function which will only ever execute `func` once
   */

  function cached(func) {
    var result = null;
    var called = false;
    return function () {
      if (!called) {
        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        result = func.apply(this, args);
        called = true;
      }
      return result;
    };
  }

  /**
   * @description
   *  <br> Will call func only if it hasn't been exectuted before
   *  <br> Warning: This method does not return the value of the exectured method
   *
   *  @param {string} cacheId the cache key
   *  @param {string} func the method to resolve missing value
   *  @param {any} [scope] scope to bind the method to (default=null)
   *  @param {...any} [args] arguments passed to the method
   *  @returns {any}
   */

  function exec(cacheId, func) {
    var scope = arguments.length <= 2 || arguments[2] === undefined ? null : arguments[2];

    if (_lodash["default"].includes(CACHE_IDS, cacheId)) {
      return _ember["default"].RSVP.resolve();
    }

    CACHE_IDS.push(cacheId);

    for (var _len2 = arguments.length, args = Array(_len2 > 3 ? _len2 - 3 : 0), _key2 = 3; _key2 < _len2; _key2++) {
      args[_key2 - 3] = arguments[_key2];
    }

    return _ember["default"].RSVP.resolve(func.apply(scope, args)).then(_lodash["default"].noop)["catch"](function (e) {
      _lodash["default"].pull(CACHE_IDS, cacheId);
      return _ember["default"].RSVP.reject(e);
    });
  }

  exports["default"] = { cached: cached, exec: exec };
});