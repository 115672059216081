define("stock/services/offer-service", ["exports", "ember", "stock/services/api-base-service", "stock/mixins/navigation_aware", "lodash"], function (exports, _ember, _stockServicesApiBaseService, _stockMixinsNavigation_aware, _lodash) {
  exports["default"] = _stockServicesApiBaseService["default"].extend(_stockMixinsNavigation_aware["default"], {
    init: function init() {
      this._super.apply(this, arguments);
      this.set("openOfferSearch", false);
    },

    getOffer: function getOffer() {
      var _this = this;

      var deferred = _ember["default"].RSVP.defer();

      this.set("openOfferSearch", true);
      this.set("displayResults", true);
      this.set("onOfferSelected", function (offer) {
        _this.set("onOfferSelected", _lodash["default"].noop);
        _this.set("openOfferSearch", false);
        deferred.resolve(offer || null);
      });

      return deferred.promise;
    }
  });
});