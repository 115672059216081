define("stock/components/barcode-scanner", ["exports", "ember", "stock/mixins/async"], function (exports, _ember, _stockMixinsAsync) {
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  exports["default"] = _ember["default"].Component.extend(_stockMixinsAsync["default"], {
    barcodeService: _ember["default"].inject.service(),
    paramName: null,

    redirect: function redirect(scannedText) {
      if (scannedText) {
        var key = this.get("paramName") || "searchInput";
        this.get("router").transitionTo(this.get("route"), {
          queryParams: _defineProperty({}, key, scannedText)
        });
      }
    },

    actions: {
      scanBarcode: function scanBarcode() {
        var scanner, allowed;
        return regeneratorRuntime.async(function scanBarcode$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              scanner = this.get("barcodeService");
              context$1$0.prev = 1;
              context$1$0.next = 4;
              return regeneratorRuntime.awrap(scanner.requestPermission());

            case 4:
              allowed = context$1$0.sent;

              if (allowed) {
                context$1$0.next = 7;
                break;
              }

              return context$1$0.abrupt("return");

            case 7:
              context$1$0.next = 12;
              break;

            case 9:
              context$1$0.prev = 9;
              context$1$0.t0 = context$1$0["catch"](1);
              return context$1$0.abrupt("return", this.modalAlert("camera_scan.permission_error"));

            case 12:
              context$1$0.prev = 12;
              context$1$0.t1 = this;
              context$1$0.next = 16;
              return regeneratorRuntime.awrap(scanner.scanOne());

            case 16:
              context$1$0.t2 = context$1$0.sent;
              context$1$0.t1.redirect.call(context$1$0.t1, context$1$0.t2);
              context$1$0.next = 23;
              break;

            case 20:
              context$1$0.prev = 20;
              context$1$0.t3 = context$1$0["catch"](12);

              this.modalAlert("Scanning failed: " + context$1$0.t3);

            case 23:
            case "end":
              return context$1$0.stop();
          }
        }, null, this, [[1, 9], [12, 20]]);
      }
    }
  });
});