define("stock/controllers/orders/index", ["exports", "ember", "lodash", "stock/utils/mem-cache", "stock/services/filter-service", "stock/mixins/search_resource"], function (exports, _ember, _lodash, _stockUtilsMemCache, _stockServicesFilterService, _stockMixinsSearch_resource) {

  /**
   * @module Controllers/orders/OrdersSearchController
   * @augments ember/Controller
   */
  exports["default"] = _ember["default"].Controller.extend(_stockMixinsSearch_resource["default"], {
    session: _ember["default"].inject.service(),
    /**
     * @property {Boolean} SearchMixin configuration
     **/
    autoLoad: true,
    /**
     * @property {Number} SearchMixin configuration, perPage in response
     **/
    perPage: 25,

    init: function init() {
      this._super.apply(this, arguments);
      this.cache = new _stockUtilsMemCache["default"]();
      this.get("session").memCache.push(this.cache);
    },

    searchProps: {
      exclude_message_sender: true,
      include_messages: true,
      shallow: true
    },

    afterSearch: function afterSearch(designations) {
      if (designations && designations.get("length") > 0) {
        this.get("store").query("order_transport", {
          order_ids: designations.mapBy("id").join(",")
        });
      }
    },

    hasSearchText: _ember["default"].computed("searchText", function () {
      return _ember["default"].$.trim(this.get("searchText")).length;
    }),

    getFilterQuery: function getFilterQuery() {
      var filterService = this.get("filterService");
      var utils = this.get("utilityMethods");

      var _filterService$get = filterService.get("orderTimeRange");

      var after = _filterService$get.after;
      var before = _filterService$get.before;

      var isPriority = filterService.isPriority();
      var typesFilters = filterService.get("orderTypeFilters");
      var stateFilters = _lodash["default"].without(filterService.get("orderStateFilters"), _stockServicesFilterService.STATE_FILTERS.PRIORITY);

      return {
        state: utils.stringifyArray(stateFilters),
        type: utils.stringifyArray(typesFilters),
        priority: isPriority,
        after: after && after.getTime(),
        before: before && before.getTime()
      };
    },

    reloadResults: function reloadResults() {
      this.get("cache").clear();
      this._super();
    },

    createCacheKey: function createCacheKey(data) {
      return JSON.stringify(data);
    },

    actions: {
      /**
       * Load the next page of the list
       *
       * @memberof OrdersSearchController
       * @param {number} pageNo the page to load
       * @returns {Promise<Order[]>}
       */
      loadMoreOrders: function loadMoreOrders(pageNo) {
        var _this = this;

        var cache = this.get("cache");
        var params = this.trimQuery(_lodash["default"].merge({}, this.getFilterQuery(), this.getSearchQuery(), this.getPaginationQuery(pageNo)));
        var cacheKey = this.createCacheKey(params);
        if (cache.has(cacheKey)) {
          return cache.get(cacheKey);
        }
        return this.get("store").query("designation", params).then(function (results) {
          _this.afterSearch(results);
          cache.set(cacheKey, results);
          return results;
        });
      },

      clearSearch: function clearSearch() {
        this.set("searchText", "");
      }
    }
  });
});