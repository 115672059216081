define("stock/routes/orders/staff_conversation", ["exports", "stock/routes/orders/conversation", "ember"], function (exports, _stockRoutesOrdersConversation, _ember) {
  exports["default"] = _stockRoutesOrdersConversation["default"].extend({
    templateName: "orders/conversation",

    model: function model(params) {
      return _ember["default"].RSVP.hash({
        designation: this.loadIfAbsent("designation", params.order_id),
        messages: this.store.query("message", {
          order_id: params.order_id,
          is_private: true
        })
      });
    }
  });
});