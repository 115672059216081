define("stock/controllers/authenticate", ["exports", "ember", "stock/utils/ajax-promise", "stock/config/environment", "stock/mixins/preload_data", "stock/controllers/goodcity_controller", "lodash"], function (exports, _ember, _stockUtilsAjaxPromise, _stockConfigEnvironment, _stockMixinsPreload_data, _stockControllersGoodcity_controller, _lodash) {
  var timeout = undefined;

  exports["default"] = _stockControllersGoodcity_controller["default"].extend(_stockMixinsPreload_data["default"], {
    messageBox: _ember["default"].inject.service(),
    authService: _ember["default"].inject.service(),
    subscription: _ember["default"].inject.service(),
    attemptedTransition: null,
    pin: "",
    timer: _stockConfigEnvironment["default"].APP.OTP_RESEND_TIME,
    pinAlreadySent: false,
    isMobileApp: _stockConfigEnvironment["default"].cordova.enabled,
    mobilePhone: "",
    donorAppUrl: _stockConfigEnvironment["default"].APP.DONOR_APP_URL,
    charityAppUrl: _stockConfigEnvironment["default"].APP.CHARITY_APP_URL,

    mobile: _ember["default"].computed("mobilePhone", function () {
      return _stockConfigEnvironment["default"].APP.HK_COUNTRY_CODE + this.get("mobilePhone");
    }),

    timerFunction: function timerFunction() {
      var _this = this;

      var waitTime = this.get("timer");
      if (waitTime === 0) {
        this.resetTimerParameters();
        return false;
      }
      this.set("timer", waitTime - 1);
      timeout = setTimeout(function () {
        _this.timerFunction();
      }, 1000);
    },

    resetTimerParameters: function resetTimerParameters() {
      this.set("pinAlreadySent", false);
      this.set("timer", _stockConfigEnvironment["default"].APP.OTP_RESEND_TIME);
    },

    actions: {
      authenticateUser: function authenticateUser() {
        var _this2 = this;

        var pin = this.get("pin");

        if (!pin) {
          _ember["default"].$("#pin").closest("div").addClass("error");
          return;
        }

        var otpAuthKey = this.get("session.otpAuthKey");
        _ember["default"].$(".auth_error").hide();
        this.showLoadingSpinner();
        this.get("authService").verify(pin, otpAuthKey).then(function (_ref) {
          var jwt_token = _ref.jwt_token;
          var user = _ref.user;

          clearTimeout(timeout);
          _this2.resetTimerParameters();
          _this2.set("pin", null);
          _this2.set("session.authToken", jwt_token);
          _this2.set("session.otpAuthKey", null);
          _this2.store.pushPayload(user);
          _this2.get("subscription").wire();

          var userProfile = _this2.get("store").peekRecord("user_profile", user.user_profile.id);
          if (userProfile && userProfile.get("activeRoles").length > 0) {
            return _this2.preloadData();
          }
        }).then(function () {
          var currentUser = _this2.get("store").peekRecord("user_profile", _this2.get("session.currentUser.id"));

          if (!currentUser.get("activeRoles") || currentUser.get("activeRoles").length === 0) {
            return _this2.transitionToRoute("guest_user_details");
          }

          var attemptedTransition = _this2.get("attemptedTransition");
          if (!attemptedTransition) {
            return _this2.transitionToRoute("/");
          }
          attemptedTransition.retry();
          _this2.set("attemptedTransition", null);
        })["catch"](function (jqXHR) {
          _ember["default"].$("#pin").closest("div").addClass("error");
          if (jqXHR.status === 422) {
            _this2.get("messageBox").alert(_lodash["default"].get(jqXHR, "responseJSON.errors.pin"));
          }
        })["finally"](function () {
          return _this2.hideLoadingSpinner();
        });
      },

      resendPin: function resendPin() {
        var _this3 = this;

        if (!this.get("mobile") || this.get("mobile").length !== 12) {
          _ember["default"].$("#mobile").closest(".mobile").addClass("error");
          return;
        }

        this.set("pinAlreadySent", true);
        this.showLoadingSpinner();
        this.get("authService").sendPin(this.get("mobile")).then(function (data) {
          _this3.set("session.otpAuthKey", data.otp_auth_key);
          _this3.set("pin", null);
          _this3.transitionToRoute("/authenticate");
          _this3.timerFunction();
        })["catch"](function (error) {
          _this3.set("pinAlreadySent", false);
          if ([401].includes(error.status)) {
            _this3.get("messageBox").alert("You are not authorized.", function () {
              _this3.transitionToRoute("/");
            });
          } else if ([422, 403].includes(error.status)) {
            _ember["default"].$("#mobile").closest(".mobile").addClass("error");
            return;
          }
          throw error;
        })["finally"](function () {
          return _this3.hideLoadingSpinner();
        });
      }
    }
  });
});