define("stock/mixins/preload_data", ["exports", "ember", "stock/config/environment", "stock/utils/ajax-promise"], function (exports, _ember, _stockConfigEnvironment, _stockUtilsAjaxPromise) {
  exports["default"] = _ember["default"].Mixin.create({
    messages: _ember["default"].inject.service(),
    i18n: _ember["default"].inject.service(),
    userService: _ember["default"].inject.service(),

    preloadData: function preloadData() {
      var _this = this;

      var promises = [];
      var retrieve = function retrieve(types) {
        return types.map(function (type) {
          return _this.store.findAll(type);
        });
      };

      if (this.get("session.authToken")) {
        promises.push(this.get("userService").currentUser().then(function (data) {
          _this.store.pushPayload(data);
          _this.store.pushPayload({ user: data.user_profile });
          _this.notifyPropertyChange("session.currentUser");
        }));
        promises = promises.concat(retrieve(_stockConfigEnvironment["default"].APP.PRELOAD_TYPES));
        promises.push(this.get("messages").fetchUnreadMessageCount());
        promises.push(this.store.query("code", { stock: true }));
      }

      return _ember["default"].RSVP.all(promises);
    }
  });
});