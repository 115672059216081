define("stock/routes/orders/contact_summary", ["exports", "stock/routes/orders/detail", "stock/utils/ajax-promise"], function (exports, _stockRoutesOrdersDetail, _stockUtilsAjaxPromise) {
  //jshint ignore:line

  exports["default"] = _stockRoutesOrdersDetail["default"].extend({
    orderService: Ember.inject.service(),

    model: function model() {
      var order,
          userId,
          args$1$0 = arguments;
      return regeneratorRuntime.async(function model$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return regeneratorRuntime.awrap(this._super.apply(this, args$1$0));

          case 2:
            order = context$1$0.sent;
            userId = order.get("createdBy.id");
            return context$1$0.abrupt("return", Ember.RSVP.hash({
              order: order,
              usersOrdersCount: this.get("orderService").ordersCountFor(userId)
            }));

          case 5:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    setupController: function setupController(controller, model) {
      if (controller) {
        var countryId = model && model.order.get("countryId");
        var countryName = model && model.order.get("countryName");

        controller.set("model", model.order);
        controller.set("codeWithoutPrefix", model.order.get("code").slice(1));
        controller.set("selectedCountry", { id: countryId, nameEn: countryName });
        controller.set("codeValidationError", false);
        controller.set("ordersCount", model.usersOrdersCount);
      }
    }
  });
});