define("stock/services/session", ["exports", "ember", "stock/computed/local-storage", "stock/config/environment"], function (exports, _ember, _stockComputedLocalStorage, _stockConfigEnvironment) {
  exports["default"] = _ember["default"].Service.extend({
    authToken: _ember["default"].computed.localStorage(),
    otpAuthKey: _ember["default"].computed.localStorage(),
    language: _ember["default"].computed.localStorage(),
    memCache: [],
    isLoggedIn: _ember["default"].computed.notEmpty("authToken"),
    store: _ember["default"].inject.service(),
    deviceId: Math.random().toString().substring(2),

    currentUser: _ember["default"].computed(function () {
      var store = this.get("store");
      return store.peekAll("user_profile").get("firstObject") || null;
    }).volatile(),

    clear: function clear() {
      this.set("authToken", null);
      this.set("language", null);
      this.set("otpAuthKey", null);
    },

    clearCache: function clearCache() {
      var memCache = this.get("memCache");
      memCache.map(function (cache) {
        return cache.cache = {};
      });
      memCache.set("memCache", []);
    },

    unloadSessionData: function unloadSessionData() {
      var store = this.get("store");
      _stockConfigEnvironment["default"].APP.USER_DATA_TYPES.forEach(function (name) {
        store.unloadAll(name);
      });
    }
  });
});