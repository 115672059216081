define("stock/mixins/search_option", ["exports", "ember", "lodash"], function (exports, _ember, _lodash) {
  exports["default"] = _ember["default"].Mixin.create({
    searchText: "",
    resultArray: [],
    onSearchCountry: function onSearchCountry(field, searchText) {
      this.set("searchText", _ember["default"].$.trim(searchText));
      _ember["default"].run.debounce(this, this.applyFilter, field, 500);
    },

    applyFilter: function applyFilter(field) {
      var _this = this;

      var searchTextLength = this.get("searchText").length;
      var searchText = searchTextLength ? this.get("searchText") : "";
      this.get("store").query(field, {
        searchText: searchText
      }).then(function (data) {
        //Check the input has changed since the promise started
        if (searchText === _this.get("searchText")) {
          _this.set("resultArray", _ember["default"].A(data));
        }
      });
    }
  });
});