define("stock/controllers/access_pass", ["exports", "ember", "stock/controllers/goodcity_controller"], function (exports, _ember, _stockControllersGoodcity_controller) {
  exports["default"] = _stockControllersGoodcity_controller["default"].extend({
    i18n: _ember["default"].inject.service(),
    printerService: _ember["default"].inject.service(),

    selectedAccessOption: "today",
    accessPassRoleIds: "",
    selectedPrinterDisplay: "",

    printers: _ember["default"].computed(function () {
      return this.get("printerService").allAvailablePrinters();
    }),

    invalidPassDetails: _ember["default"].computed("accessPassRoleIds", function () {
      return this.get("accessPassRoleIds").trim().length === 0;
    }),

    accessOptions: _ember["default"].computed(function () {
      var translation = this.get("i18n");
      var today = translation.t("access_pass.today");
      var tomorrow = translation.t("access_pass.tomorrow");
      var week = translation.t("access_pass.week");

      return [{ name: today, id: "today" }, { name: tomorrow, id: "tomorrow" }, { name: week, id: "week" }];
    }),

    getSelectionDate: function getSelectionDate(option) {
      var date = undefined;

      switch (option.id) {
        case "today":
          date = moment().format("MM/DD/YYYY");
          break;
        case "tomorrow":
          date = moment().add(1, "days").format("MM/DD/YYYY");
          break;
        case "week":
          date = moment().endOf("week").format("MM/DD/YYYY");
          break;
      }

      date = moment(date).set("hour", 20).set("minutes", 0).format("LLLL");

      return date;
    },

    actions: {
      setPrinterValue: function setPrinterValue(value) {
        var printerId = value.id;
        this.set("selectedPrinterId", printerId);
      },

      generateAccessPass: function generateAccessPass() {
        var accessExpiresAt, newRecord;
        return regeneratorRuntime.async(function generateAccessPass$(context$1$0) {
          var _this = this;

          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              accessExpiresAt = this.getSelectionDate(this.get("selectedAccessOption"));
              newRecord = this.get("store").createRecord("access-pass", {
                roleIds: this.get("accessPassRoleIds"),
                printerId: "" + (this.get("selectedPrinterDisplay.id") || ""),
                accessExpiresAt: accessExpiresAt
              });

              this.showLoadingSpinner();

              return context$1$0.abrupt("return", newRecord.save()["catch"](function (r) {
                return _this.onError(r);
              }).then(function (data) {
                _this.transitionToRoute("display_access_pass", {
                  queryParams: { code: data.data.accessKey }
                });
                _this.hideLoadingSpinner();
              }));

            case 4:
            case "end":
              return context$1$0.stop();
          }
        }, null, this);
      }
    }
  });
});