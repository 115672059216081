define("stock/services/cordova", ["exports", "ember", "stock/config/environment", "stock/utils/ajax-promise"], function (exports, _ember, _stockConfigEnvironment, _stockUtilsAjaxPromise) {
  exports["default"] = _ember["default"].Service.extend({
    session: _ember["default"].inject.service(),
    store: _ember["default"].inject.service(),

    isAndroid: function isAndroid() {
      if (!_stockConfigEnvironment["default"].cordova.enabled || !window.device) {
        return;
      }
      return ["android", "Android", "amazon-fireos"].indexOf(window.device.platform) >= 0;
    },

    isIOSBrowser: function isIOSBrowser() {
      var userAgent = window.navigator.userAgent;
      return userAgent.match(/iPad/i) || userAgent.match(/iPhone/i);
    },

    isIOS: function isIOS() {
      if (!_stockConfigEnvironment["default"].cordova.enabled || !window.device) {
        return;
      }
      return window.device.platform === "iOS";
    },

    appLoad: function appLoad() {
      if (!_stockConfigEnvironment["default"].cordova.enabled || _stockConfigEnvironment["default"].disableNotifications) {
        return;
      }
      this.initiatePushNotifications();
    },

    initiatePushNotifications: function initiatePushNotifications() {
      var _this = this;

      function onDeviceReady() {
        var push = PushNotification.init({
          android: {
            icon: "notification",
            iconColor: "#000000"
          },
          ios: {
            alert: true,
            sound: true,
            badge: true
          }
        });

        push.on("registration", function (data) {
          sendToken(data.registrationId, platformCode());
        });

        push.on("notification", function (data) {
          if (!data.additionalData.foreground) {
            if (window.device.platform === "iOS") {
              processTappedNotification(data.additionalData.payload);
            } else {
              processTappedNotification(data.additionalData);
            }
          }
        });

        push.on("error", function (e) {
          console.log(e);
        });
      }

      function sendToken(handle, platform) {
        // jshint ignore:line
        return new _stockUtilsAjaxPromise["default"]("/auth/register_device", "POST", _this.get("session.authToken"), {
          handle: handle,
          platform: platform
        });
      }

      function platformCode() {
        // jshint ignore:line
        var platform;
        if (_this.isAndroid()) {
          platform = "fcm";
        } else if (window.device.platform === "iOS") {
          platform = "aps";
        }
        return platform;
      }

      function processTappedNotification(_ref) {
        var messageable_type = _ref.messageable_type;
        var is_private = _ref.is_private;
        var messageable_id = _ref.messageable_id;

        // jshint ignore:line
        var model = messageable_type == "Order" ? "designation" : "item";
        var router = _this.get("router");
        _this.get("store").findRecord(model, messageable_id, {
          reload: true
        }).then(function (data) {
          _this.get("store").pushPayload(data);
          if (messageable_type == "Order") {
            is_private == "True" ? router.transitionTo("orders.staff_conversation", messageable_id) : router.transitionTo("orders.conversation", messageable_id);
          } else {
            router.transitionTo("items.staff_conversation", messageable_id);
          }
        });
      }

      document.addEventListener("deviceready", onDeviceReady, true);
    }
  });
});