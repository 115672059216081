define("stock/services/stocktake-service", ["exports", "stock/services/api-base-service", "stock/utils/helpers"], function (exports, _stockServicesApiBaseService, _stockUtilsHelpers) {
  exports["default"] = _stockServicesApiBaseService["default"].extend({
    store: Ember.inject.service(),

    __applyAction: function __applyAction(stocktake, action) {
      var data;
      return regeneratorRuntime.async(function __applyAction$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            if (!stocktake.get("isOpen")) {
              context$1$0.next = 5;
              break;
            }

            context$1$0.next = 3;
            return regeneratorRuntime.awrap(this.PUT("/stocktakes/" + (0, _stockUtilsHelpers.toID)(stocktake) + "/" + action));

          case 3:
            data = context$1$0.sent;

            this.get("store").pushPayload(data);

          case 5:
            return context$1$0.abrupt("return", stocktake);

          case 6:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    /**
     * Will apply all the revisions of a stocktake to the inventory and mark the stocktake as "Closed"
     *
     * @param {Stocktake|string} stocktake a stocktake model or its ID
     * @returns {Promise<Stocktake>}
     */
    commitStocktake: function commitStocktake(stocktake) {
      return this.__applyAction(stocktake, "commit");
    },

    /**
     * Will cancel a stocktake and all of its revisions
     *
     * @param {Stocktake|string} stocktake a stocktake model or its ID
     * @returns {Promise<Stocktake>}
     */
    cancelStocktake: function cancelStocktake(stocktake) {
      return this.__applyAction(stocktake, "cancel");
    }
  });
});