define('stock/routes/order/confirm_booking', ['exports', 'ember', 'stock/routes/order/order_user_organisation'], function (exports, _ember, _stockRoutesOrderOrder_user_organisation) {
  exports['default'] = _stockRoutesOrderOrder_user_organisation['default'].extend({
    /* jshint ignore:start */
    model: function model() {
      var _ref,
          order,
          organisation,
          user,
          organisationsUser,
          args$1$0 = arguments;

      return regeneratorRuntime.async(function model$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return regeneratorRuntime.awrap(this._super.apply(this, args$1$0));

          case 2:
            _ref = context$1$0.sent;
            order = _ref.order;
            organisation = _ref.organisation;
            user = _ref.user;
            organisationsUser = _ref.organisationsUser;
            return context$1$0.abrupt('return', _ember['default'].RSVP.hash({
              order: order,
              organisation: organisation,
              user: user,
              organisationsUser: organisationsUser
            }));

          case 8:
          case 'end':
            return context$1$0.stop();
        }
      }, null, this);
    },
    /* jshint ignore:end */

    setupController: function setupController() {
      this._super.apply(this, arguments);
      this.controllerFor('application').set('showSidebar', false);
    },

    deactivate: function deactivate() {
      this.controllerFor('application').set('showSidebar', true);
    }
  });
});
// jshint ignore:line