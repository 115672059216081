define("stock/routes/display_access_pass", ["exports", "stock/routes/authorize", "stock/config/environment"], function (exports, _stockRoutesAuthorize, _stockConfigEnvironment) {
  exports["default"] = _stockRoutesAuthorize["default"].extend({
    queryParams: {
      code: ""
    },

    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);

      var hasCode = !!transition.queryParams.code;

      if (!hasCode) {
        transition.abort();
        this.transitionTo("access_pass");
      }
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set("counter", 1);
      controller.set("timer", _stockConfigEnvironment["default"].APP.ACCESS_PASS_VALIDITY);
      controller.timerFunction();
    }
  });
});