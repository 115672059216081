define("stock/controllers/order/international_order", ["exports", "ember", "stock/mixins/search_option", "stock/mixins/async", "stock/mixins/grades_option", "stock/constants/state-events", "stock/constants/regex"], function (exports, _ember, _stockMixinsSearch_option, _stockMixinsAsync, _stockMixinsGrades_option, _stockConstantsStateEvents, _stockConstantsRegex) {
  exports["default"] = _ember["default"].Controller.extend(_stockMixinsSearch_option["default"], _stockMixinsAsync["default"], _stockMixinsGrades_option["default"], {
    i18n: _ember["default"].inject.service(),
    showError: false,
    orderService: _ember["default"].inject.service(),
    session: _ember["default"].inject.service(),

    isInvalidDescription: _ember["default"].computed.not("orderDescription"),
    isInvalidPeopleCount: _ember["default"].computed.not("peopleCount"),

    isInvalidCode: _ember["default"].computed("shipmentOrCarryoutCode", function () {
      var codeRegEx = new RegExp(_stockConstantsRegex.regex.SHIPMENT_ORDER_REGEX);
      return !this.get("shipmentOrCarryoutCode") || !codeRegEx.test(this.get("shipmentOrCarryoutCode"));
    }),

    isInvalidCountry: _ember["default"].computed.not("country"),

    isInvalidShipmentDate: _ember["default"].computed.not("shipmentDate"),

    orderParams: function orderParams() {
      var params = {
        state_event: _stockConstantsStateEvents.STATE_EVENTS.SUBMIT,
        detail_type: this.get("selectedType.id") || _stockConstantsStateEvents.INTERNATIONAL_ORDERS.SHIPMENT,
        code: "" + this.get("prefix") + this.get("shipmentOrCarryoutCode"),
        country_id: this.get("country.id"),
        shipment_date: this.get("shipmentDate"),
        people_helped: this.get("peopleCount"),
        description: this.get("orderDescription"),
        submitted_by_id: this.get("session.currentUser.id")
      };
      return { order: params };
    },

    clearFormData: function clearFormData() {
      this.set("showError", false);
      this.set("selectedType", "");
      this.set("country", "");
      this.set("shipmentDate", "");
      this.set("peopleCount", "");
      this.set("orderDescription", "");
    },

    actions: {
      cancel: function cancel() {
        this.clearFormData();
        this.transitionToRoute("app_menu_list");
      },

      clearDescription: function clearDescription() {
        this.set("orderDescription", "");
      },

      handleTypeChange: function handleTypeChange(value) {
        return regeneratorRuntime.async(function handleTypeChange$(context$1$0) {
          var _this = this;

          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              context$1$0.next = 2;
              return regeneratorRuntime.awrap(this.runTask(function callee$1$0() {
                var _ref, code;

                return regeneratorRuntime.async(function callee$1$0$(context$2$0) {
                  while (1) switch (context$2$0.prev = context$2$0.next) {
                    case 0:
                      this.set("selectedType", value);
                      context$2$0.next = 3;
                      return regeneratorRuntime.awrap(this.get("orderService").getNextCode(value.id));

                    case 3:
                      _ref = context$2$0.sent;
                      code = _ref.code;

                      this.set("shipmentOrCarryoutCode", code.substr(1));
                      this.set("prefix", code[0]);

                    case 7:
                    case "end":
                      return context$2$0.stop();
                  }
                }, null, _this);
              }, _stockMixinsAsync.ERROR_STRATEGIES.MODAL));

            case 2:
              return context$1$0.abrupt("return", context$1$0.sent);

            case 3:
            case "end":
              return context$1$0.stop();
          }
        }, null, this);
      },

      createInternationalOrder: function createInternationalOrder() {
        return regeneratorRuntime.async(function createInternationalOrder$(context$1$0) {
          var _this2 = this;

          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              if (!(this.get("isInvalidShipmentDate") || this.get("isInvalidCode") || this.get("isInvalidCountry") || this.get("isInvalidDescription") || this.get("isInvalidPeopleCount"))) {
                context$1$0.next = 3;
                break;
              }

              this.set("showError", true);
              return context$1$0.abrupt("return");

            case 3:
              context$1$0.next = 5;
              return regeneratorRuntime.awrap(this.runTask(function callee$1$0() {
                var _ref2, designation;

                return regeneratorRuntime.async(function callee$1$0$(context$2$0) {
                  while (1) switch (context$2$0.prev = context$2$0.next) {
                    case 0:
                      context$2$0.next = 2;
                      return regeneratorRuntime.awrap(this.get("orderService").createShipmentOrCarryoutOrder(this.orderParams()));

                    case 2:
                      _ref2 = context$2$0.sent;
                      designation = _ref2.designation;

                      this.clearFormData();

                      this.replaceRoute("orders.active_items", designation.id);

                    case 6:
                    case "end":
                      return context$2$0.stop();
                  }
                }, null, _this2);
              }, _stockMixinsAsync.ERROR_STRATEGIES.MODAL));

            case 5:
              return context$1$0.abrupt("return", context$1$0.sent);

            case 6:
            case "end":
              return context$1$0.stop();
          }
        }, null, this);
      },

      onSearch: function onSearch(field, searchText) {
        this.onSearchCountry(field, searchText);
      },

      setCountryValue: function setCountryValue(value) {
        var countryName = this.get("store").peekRecord("country", value.id).get("nameEn");
        this.set("country", { id: value.id, nameEn: countryName });
      }
    }
  });
});