define("stock/routes/items/history", ["exports", "ember", "stock/routes/authorize"], function (exports, _ember, _stockRoutesAuthorize) {
  exports["default"] = _stockRoutesAuthorize["default"].extend({
    packageService: _ember["default"].inject.service(),

    model: function model(_ref) {
      var item_id = _ref.item_id;

      var queryParams = {
        package_id: item_id,
        per_page: 100
      };
      return _ember["default"].RSVP.hash({
        item: this.store.findRecord("item", item_id, { reload: true }),
        itemActions: this.store.query("item_action", queryParams),
        versions: this.get("packageService").getPackageVersions(item_id)
      });
    },

    afterModel: function afterModel(_ref2) {
      var _this = this;

      var item = _ref2.item;

      if (item.get("isPartOfSet")) {
        item.get("packageSet.items").forEach(function (item) {
          _this.store.findRecord("item", item.get("id"), { reload: true });
        });
      }
      _ember["default"].$("body").animate({ scrollTop: 0 });
    },

    setupController: function setupController(controller, _ref3) {
      var item = _ref3.item;
      var itemActions = _ref3.itemActions;
      var versions = _ref3.versions;

      controller.set("model", item);
      controller.set("versions", versions);
      controller.set("itemActions", itemActions);
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.off();
      }
    }
  });
});