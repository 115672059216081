define("stock/components/item-options-list", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    hidden: true,
    item: null,

    toggleItemClass: function toggleItemClass() {
      var item = this.get("item");
      _ember["default"].$(".receive-item-options." + item.id).toggleClass("hidden");
      _ember["default"].$(".options-link-open." + item.id).toggleClass("hidden");
    },

    actions: {
      toggle: function toggle(value) {
        this.set("hidden", value);
        var item = this.get("item");
        var itemOptionsLink = _ember["default"].$(".options-link-open." + item.id)[0];
        var optionsLink = _ember["default"].$(".options-link-open.hidden");
        if (optionsLink.length) {
          _ember["default"].$(".receive-item-options").not(".hidden").toggleClass("hidden");
          _ember["default"].$(".options-link-open.hidden").toggleClass("hidden");
          //this.toggleItemClass();
          return false;
        } else if (itemOptionsLink) {
          this.toggleItemClass();
          return false;
        } else {
          return true;
        }
      }
    }
  });
});