define("stock/utils/mem-cache", ["exports", "ember", "lodash"], function (exports, _ember, _lodash) {
  var _createClass = (function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; })();

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

  var Cache = (function () {
    function Cache() {
      var opts = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

      _classCallCheck(this, Cache);

      this.timeout = opts.timeout || 5 * 60 * 1000;
      this.cache = {};
    }

    _createClass(Cache, [{
      key: "get",
      value: function get(key) {
        var entry = this.cache[key];
        if (entry && entry.expiry >= Date.now()) {
          return entry.value;
        }
      }
    }, {
      key: "set",
      value: function set(key, value) {
        this.cache[key] = {
          value: value,
          expiry: Date.now() + this.timeout
        };
      }
    }, {
      key: "has",
      value: function has(key) {
        return !!this.get(key);
      }
    }, {
      key: "del",
      value: function del(key) {
        delete this.cache[key];
      }
    }, {
      key: "clear",
      value: function clear() {
        this.cache = {};
      }
    }]);

    return Cache;
  })();

  exports["default"] = Cache;
});