define("stock/components/cancel-order", ["exports", "ember", "stock/utils/ajax-promise"], function (exports, _ember, _stockUtilsAjaxPromise) {
  var getOwner = _ember["default"].getOwner;
  exports["default"] = _ember["default"].Component.extend({
    tagName: "a",
    isCancel: true,
    messageBox: _ember["default"].inject.service(),
    store: _ember["default"].inject.service(),
    order: null,

    actions: {
      deleteOrder: function deleteOrder() {
        var _this = this;

        var previousPath = this.get("prevPath");
        if (previousPath && previousPath === "contact_summary") {
          window.history.back();
        } else {
          this.get("messageBox").custom("Cancel this Request?", "Not Now", null, "Cancel Request", function () {
            return _this.send("removeOrder");
          });
        }
      },

      removeOrder: function removeOrder() {
        var _this2 = this;

        var loadingView = getOwner(this).lookup("component:loading").append();
        var orderId = this.get("order.id");
        var url = "/orders/" + orderId;
        var designation = this.get("store").peekRecord("designation", orderId);

        new _stockUtilsAjaxPromise["default"](url, "DELETE", this.get("session.authToken")).then(function () {
          _this2.get("router").transitionTo("app_menu_list");
          _this2.get("store").unloadRecord(designation);
        })["finally"](function () {
          return loadingView.destroy();
        });
      }
    }
  });
});