define("stock/routes/organisation", ["exports", "stock/routes/authorize"], function (exports, _stockRoutesAuthorize) {
  exports["default"] = _stockRoutesAuthorize["default"].extend({
    model: function model(params) {
      return this.loadIfAbsent("organisation", params.organisation_id);
    },

    setupController: function setupController(controller, model) {
      return regeneratorRuntime.async(function setupController$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            this._super(controller, model);
            this.setOrganisationUsers(model);
            this.setOrganisationTypes(model);
            this.setCountry(model);

          case 4:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    // set the selected country value
    setCountry: function setCountry(model) {
      var country;
      return regeneratorRuntime.async(function setCountry$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            country = model.get("country");

            this.controller.set("country", {
              id: country.get("id"),
              nameEn: country.get("nameEn")
            });

          case 2:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    // set organisation users
    setOrganisationUsers: function setOrganisationUsers(model) {
      var organisationUsers;
      return regeneratorRuntime.async(function setOrganisationUsers$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            organisationUsers = this.store.peekAll("organisationsUser").filterBy("organisationId", parseInt(model.id));

            this.controller.set("organisationUsers", organisationUsers);

          case 2:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    // fetch all organisation type if required
    // set the selected organisation type
    setOrganisationTypes: function setOrganisationTypes(model) {
      var data, selectedOrganisationType;
      return regeneratorRuntime.async(function setOrganisationTypes$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return regeneratorRuntime.awrap(this.getOrganisationTypes());

          case 2:
            data = context$1$0.sent;

            this.controller.set("organisationTypes", data);
            selectedOrganisationType = this.store.peekRecord("organisationType", model.get("organisationTypeId"));

            this.controller.set("selectedOrganisationType", selectedOrganisationType);

          case 6:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    getOrganisationTypes: function getOrganisationTypes() {
      var organisationTypes;
      return regeneratorRuntime.async(function getOrganisationTypes$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            organisationTypes = this.store.peekAll("organisationTypes");

            if (!organisationTypes.get("length")) {
              context$1$0.next = 5;
              break;
            }

            return context$1$0.abrupt("return", organisationTypes);

          case 5:
            context$1$0.next = 7;
            return regeneratorRuntime.awrap(this.store.findAll("organisationType", { reload: true }));

          case 7:
            return context$1$0.abrupt("return", context$1$0.sent);

          case 8:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    }
  });
});