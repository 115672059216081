define("stock/components/order-filter-buttons", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    i18n: _ember["default"].inject.service(),
    filterService: _ember["default"].inject.service(),

    orderStateFilters: _ember["default"].computed.alias("filterService.orderStateFilters"),
    hasStateFilters: _ember["default"].computed("orderStateFilters", function () {
      return this.get("orderStateFilters").length > 0;
    }),

    orderTypeFilters: _ember["default"].computed.alias("filterService.orderTypeFilters"),
    hasTypeFilters: _ember["default"].computed("orderTypeFilters", function () {
      return this.get("orderTypeFilters").length > 0;
    }),

    orderTimeRange: _ember["default"].computed.alias("filterService.orderTimeRange"),
    hasTimeFilters: _ember["default"].computed("orderTimeRange", function () {
      var _get = this.get("orderTimeRange");

      var preset = _get.preset;
      var after = _get.after;
      var before = _get.before;

      return preset || after || before;
    }),

    actions: {
      redirectTofilters: function redirectTofilters(queryParam) {
        var orderFilter = {};
        orderFilter[queryParam] = true;
        this.get("router").transitionTo("order_filters", {
          queryParams: orderFilter
        });
      },

      clearStateFilters: function clearStateFilters() {
        this.get("filterService").clearOrderStateFilters();
      },

      clearTypeFilters: function clearTypeFilters() {
        this.get("filterService").clearOrderTypeFilters();
      },

      clearTimeFilters: function clearTimeFilters() {
        this.get("filterService").clearOrderTimeFilters();
      }
    }
  });
});