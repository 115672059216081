define('stock/models/item', ['exports', 'ember-data/attr', 'ember-data/relationships', 'ember', 'stock/models/cloudinary_url', 'lodash', 'stock/mixins/grades_option'], function (exports, _emberDataAttr, _emberDataRelationships, _ember, _stockModelsCloudinary_url, _lodash, _stockMixinsGrades_option) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  function SUM(ordersPkgs) {
    return ordersPkgs.mapBy('quantity').reduce(function (total, qty) {
      return total + qty;
    }, 0);
  }

  /**
   * @module Models/Item
   * @description maps to the 'Package' table
   * @augments ember/Model
   *
   */
  exports['default'] = _stockModelsCloudinary_url['default'].extend(_stockMixinsGrades_option['default'], {
    messageableName: 'Package',
    notes: (0, _emberDataAttr['default'])('string'),
    grade: (0, _emberDataAttr['default'])('string'),
    inventoryNumber: (0, _emberDataAttr['default'])('string'),
    caseNumber: (0, _emberDataAttr['default'])('string'),
    receivedQuantity: (0, _emberDataAttr['default'])('number'),
    length: (0, _emberDataAttr['default'])('number'),
    width: (0, _emberDataAttr['default'])('number'),
    height: (0, _emberDataAttr['default'])('number'),
    weight: (0, _emberDataAttr['default'])('number'),
    pieces: (0, _emberDataAttr['default'])('number'),
    packageTypeId: (0, _emberDataAttr['default'])('number'),
    offerId: (0, _emberDataAttr['default'])('string'),
    expiryDate: (0, _emberDataAttr['default'])('string'),
    comment: (0, _emberDataAttr['default'])('string'),
    state: (0, _emberDataAttr['default'])('string'),
    locationId: (0, _emberDataAttr['default'])('number'),

    onHandQuantity: (0, _emberDataAttr['default'])('number'),
    availableQuantity: (0, _emberDataAttr['default'])('number'),
    designatedQuantity: (0, _emberDataAttr['default'])('number'),
    dispatchedQuantity: (0, _emberDataAttr['default'])('number'),
    onHandBoxedQuantity: (0, _emberDataAttr['default'])('number'),
    onHandPalletizedQuantity: (0, _emberDataAttr['default'])('number'),
    maxOrderQuantity: (0, _emberDataAttr['default'])('number'),
    notes: (0, _emberDataAttr['default'])('string'),
    notesZhTw: (0, _emberDataAttr['default'])('string'),
    restrictionId: (0, _emberDataAttr['default'])('number'),
    // Temporarily keep the old `quantity` field as an alias to make migration easier
    quantity: _ember['default'].computed.alias('availableQuantity'),

    sentOn: (0, _emberDataAttr['default'])('date'),
    packageSetId: (0, _emberDataAttr['default'])('number'),
    packageSet: (0, _emberDataRelationships.belongsTo)('package_set', {
      async: false
    }),
    isPartOfSet: _ember['default'].computed.bool('packageSet'),
    isBoxPallet: _ember['default'].computed('storageType', function () {
      return this.get('storageType') && (this.get('storageType.isBox') || this.get('storageType.isPallet'));
    }),
    saleableValue: _ember['default'].computed('saleable', function () {
      var saleable = this.get('saleable');
      return _lodash['default'].filter(this.get('saleableOptions'), ['value', saleable])[0].name;
    }),
    itemId: (0, _emberDataAttr['default'])('string'),
    allowWebPublish: (0, _emberDataAttr['default'])('boolean'),
    saleable: (0, _emberDataAttr['default'])('string'),
    detailId: (0, _emberDataAttr['default'])('number'),
    detailType: (0, _emberDataAttr['default'])('string'),
    valueHkDollar: (0, _emberDataAttr['default'])('string'),
    detail: (0, _emberDataRelationships.belongsTo)('detail', {
      polymorphic: true,
      async: false
    }),
    designationId: (0, _emberDataAttr['default'])('string'),
    designation: (0, _emberDataRelationships.belongsTo)('designation', {
      async: false
    }),
    location: (0, _emberDataRelationships.belongsTo)('location', {
      async: false
    }),
    code: (0, _emberDataRelationships.belongsTo)('code', {
      async: false
    }),
    packageType: (0, _emberDataRelationships.belongsTo)('packageType', {
      async: false
    }),
    donorCondition: (0, _emberDataRelationships.belongsTo)('donor_condition', {
      async: false
    }),
    restriction: (0, _emberDataRelationships.belongsTo)('restriction', {
      async: false
    }),
    packagesLocations: (0, _emberDataRelationships.hasMany)('packages_location', {
      async: false
    }),
    itemActions: (0, _emberDataRelationships.hasMany)('item_action', {
      async: false
    }),
    versions: (0, _emberDataRelationships.hasMany)('version', {
      async: false
    }),

    storageTypeId: (0, _emberDataAttr['default'])('number'),
    storageType: (0, _emberDataRelationships.belongsTo)('storage_type', {
      async: true
    }),

    ordersPackages: (0, _emberDataRelationships.hasMany)('ordersPackages', {
      async: false
    }),
    ordersPackages: (0, _emberDataRelationships.hasMany)('ordersPackages', { async: true }),
    offersPackages: (0, _emberDataRelationships.hasMany)('offersPackages', { async: false }),
    offer: (0, _emberDataRelationships.belongsTo)('offer', { async: false }),
    imageIds: (0, _emberDataAttr['default'])(),
    images: (0, _emberDataRelationships.hasMany)('image', {
      async: false
    }),

    messages: (0, _emberDataRelationships.hasMany)('message', {
      async: true
    }),

    isDispatched: _ember['default'].computed.bool('sentOn'),
    orderCode: _ember['default'].computed.alias('designation.code'),
    updatedAt: (0, _emberDataAttr['default'])('date'),

    imageUrl: _ember['default'].computed.alias('image.imageUrl'),

    storageTypeName: _ember['default'].computed.alias('storageType.name'),

    isDesignated: _ember['default'].computed('ordersPackages', 'ordersPackages.[]', 'ordersPackages.@each.state', function () {
      // Warning: this assumes all items are singletones
      return this.get('ordersPackages').filterBy('state', 'designated').get('length') > 0;
    }),

    isDispatchedForQuantity: _ember['default'].computed('ordersPackages.[]', function () {
      return this.get('ordersPackages').isAny('state', 'dispatched');
    }),

    firstDesignatedOrdersPackage: _ember['default'].computed('designatedOrdersPackages', function () {
      return this.get('designatedOrdersPackages').get('firstObject');
    }),

    lockedQty: _ember['default'].computed('ordersPackages', 'ordersPackages.[]', 'ordersPackages.@each.state', function () {
      return SUM(this.get('ordersPackages').rejectBy('state', 'cancelled'));
    }),

    isAvailable: _ember['default'].computed.bool('availableQuantity'),

    isUnavailable: _ember['default'].computed.not('isAvailable'),

    thumbImageUrl: _ember['default'].computed('favouriteImage.{angle,cloudinaryId}', function () {
      return this.get('favouriteImage.thumbImageUrl') || this.generateUrl(120, 120, true);
    }),

    validUndispatchedLocations: _ember['default'].computed('packagesLocations.@each.quantity', function () {
      var dispatchedLocation = this.store.peekAll('location').filterBy('building', 'Dispatched');
      var pkgsLocations = this.get('packagesLocations').filterBy('quantity');
      if (dispatchedLocation.length) {
        return pkgsLocations.rejectBy('locationId', parseInt(dispatchedLocation.get('firstObject.id'), 10));
      } else {
        return pkgsLocations;
      }
    }),

    validPackagesLocations: _ember['default'].computed('packagesLocations.@each.quantity', function () {
      return this.get('packagesLocations').filterBy('quantity');
    }),

    orderPackagesMoreThenZeroQty: _ember['default'].computed('ordersPackages.@each.quantity', function () {
      return this.get('ordersPackages').filterBy('quantity').filterBy('state', 'designated');
    }),

    onHandQty: _ember['default'].computed('receivedQuantity', 'dispatchedQuantity', function () {
      return this.get('receivedQuantity') - this.get('dispatchedQuantity');
    }),

    designatedItemCount: _ember['default'].computed('ordersPackages.@each.quantity', 'ordersPackages.[]', function () {
      return this.get('ordersPackages').filterBy('state', 'designated').length;
    }),

    designatedOrdersPackages: _ember['default'].computed('ordersPackages.@each.state', function () {
      return this.get('ordersPackages').filterBy('state', 'designated').filterBy('quantity');
    }),

    dispatchedOrdersPackages: _ember['default'].computed('ordersPackages.@each.state', function () {
      return this.get('ordersPackages').filterBy('state', 'dispatched').filterBy('quantity');
    }),

    cancelledItemCount: _ember['default'].computed('ordersPackages.@each.quantity', function () {
      return SUM(this.get('ordersPackages').filterBy('state', 'cancelled'));
    }),

    favouriteImage: _ember['default'].computed('images.@each.favourite', function () {
      return this.get('images').filterBy('favourite', true).get('firstObject') || this.store.peekAll('image').filterBy('itemId', parseInt(this.id, 10)).filterBy('favourite', true).get('firstObject');
    }),

    desinatedAndDisaptchedItemPackages: _ember['default'].computed('ordersPackages.[]', function () {
      return this.get('ordersPackagesWithStateDesignatedAndDispatched.length');
    }),

    hasOneDesignatedPackage: _ember['default'].computed('ordersPackages.@each.state', function () {
      var designatedOrdersPackages = this.get('ordersPackages').filterBy('state', 'designated');
      return designatedOrdersPackages.get('length') > 1 || designatedOrdersPackages.get('length') === 0 ? false : designatedOrdersPackages[0];
    }),

    hasSingleDesignation: _ember['default'].computed('orderPackages.[]', function () {
      return this.get('ordersPackages').filterBy('state', 'designated').get('length') === 1;
    }),

    hasOneDispatchedPackage: _ember['default'].computed('ordersPackages.@each.state', function () {
      var dispatchedOrdersPackages = this.get('ordersPackages').filterBy('state', 'dispatched');
      return dispatchedOrdersPackages.get('length') > 1 || dispatchedOrdersPackages.get('length') === 0 ? false : dispatchedOrdersPackages[0];
    }),

    hasAllPackagesDispatched: _ember['default'].computed('ordersPackages.@each.state', function () {
      return this.packagesByState('dispatched');
    }),

    packagesByState: function packagesByState(state) {
      var received_quantity = this.get('receivedQuantity');
      var ordersPackages = this.get('ordersPackages').filterBy('state', state);
      var totalQty = ordersPackages.reduce(function (qty, record) {
        return qty + parseInt(record.get('quantity'), 10);
      }, 0);

      return totalQty === received_quantity;
    },

    hasAllPackagesDesignated: _ember['default'].computed('ordersPackages.@each.state', function () {
      return this.packagesByState('designated');
    }),

    ordersPackagesWithStateDesignatedAndDispatched: _ember['default'].computed('ordersPackages.[]', function () {
      var orderPackages = this.get('ordersPackages').filterBy('quantity');
      orderPackages.forEach(function (record) {
        if (record && record.get('state') === 'cancelled') {
          orderPackages.removeObject(record);
        }
      });
      return orderPackages;
    }),

    isSingletonItem: _ember['default'].computed('quantity', function () {
      return this.get('receivedQuantity') === 1;
    }),

    isMultiQtyItem: _ember['default'].computed.not('isSingletonItem'),

    hasSingleLocation: _ember['default'].computed('packagesLocations.[]', function () {
      return _ember['default'].isEqual(this.get('packagesLocations').length, 1);
    }),

    hasSingleAndDispatchLocation: _ember['default'].computed('packagesLocations.[]', 'packagesLocations', function () {
      var pkgLocations = this.get('packagesLocations');
      return _ember['default'].isEqual(pkgLocations.length, 1) && !this.get('hasAllPackagesDispatched');
    }),

    hasMultiLocations: _ember['default'].computed('packagesLocations.[]', function () {
      return this.get('packagesLocations.length') > 1;
    }),

    firstAllLocationName: _ember['default'].computed('packagesLocations.@each.location', 'packagesLocations.[]', 'packagesLocations', function () {
      return this.get('packagesLocations').get('firstObject.location.name');
    }),

    firstLocationName: _ember['default'].computed('packagesLocations.[]', function () {
      return this.get('packagesLocations').getWithDefault('firstObject.location.name', '');
    }),

    firstOrdersPackage: _ember['default'].computed('ordersPackages.[]', function () {
      return this.get('ordersPackages.firstObject');
    }),

    locations: _ember['default'].computed('packagesLocations.[]', 'packagesLocations.@each.location', function () {
      return this.get('packagesLocations').mapBy('location');
    }),

    unreadMessagesCount: _ember['default'].computed('messages.@each.state', function () {
      return this.get('messages').filterBy('state', 'unread').length;
    }),

    hasUnreadMessages: _ember['default'].computed('unreadMessagesCount', function () {
      return this.get('unreadMessagesCount') > 0;
    }),

    imageUrlList: _ember['default'].computed('images', 'packageSet.items.@each.images.[]', 'packageSet.items.@each.imageUrl', 'packageSet.items.@each.thumbImageUrl', function () {
      var imageList = [];
      this.store.peekAll('image').filterBy('itemId', parseInt(this.id, 10)).forEach(function (image) {
        return imageList.pushObject(image.get('imageUrl'));
      });
      return imageList.uniq();
    }),

    setImages: _ember['default'].computed('packageSet.items.@each.imageUrlList.[]', function () {
      return this.getWithDefault('packageSet.items', []).reduce(function (all, item) {
        all.push.apply(all, _toConsumableArray(item.get('imageUrlList')));
        return all;
      }, []).uniq();
    }),

    /**
     * @instance
     * @property {Item[]} siblings the other packages that are part of the same set
     */
    siblings: _ember['default'].computed('isPartOfSet', 'packageSet.items.@each.packageSetId', 'packageSet.items.[]', function () {
      if (!this.get('isPartOfSet')) {
        return [];
      }

      return this.get('packageSet.items').rejectBy('id', this.get('id'));
    })
  });
});