define("stock/routes/orders/active_items", ["exports", "stock/routes/orders/detail"], function (exports, _stockRoutesOrdersDetail) {
  exports["default"] = _stockRoutesOrdersDetail["default"].extend({
    model: function model(_ref) {
      var order_id = _ref.order_id;
      var order,
          args$1$0 = arguments;
      return regeneratorRuntime.async(function model$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return regeneratorRuntime.awrap(this._super.apply(this, args$1$0));

          case 2:
            order = context$1$0.sent;
            return context$1$0.abrupt("return", Ember.RSVP.hash({
              order: order,
              gcRequests: this.store.query("goodcity_request", {
                order_ids: [order_id]
              })
            }));

          case 4:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    setupController: function setupController(controller) {
      var model = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];
      return regeneratorRuntime.async(function setupController$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return regeneratorRuntime.awrap(this._super(controller, model.order));

          case 2:
            controller.set("ordersPkgLength", 0);
            controller.on();

          case 4:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.off();
      }
    }
  });
});