define("stock/components/goodcity/designate-link", ["exports", "ember", "lodash"], function (exports, _ember, _lodash) {

  //
  // Small link component which holds all the designation actions
  //
  // Usage:
  //
  // {{#goodcity/designate-link order=orderToDesignateTo pkg=packageToDesignate}}
  //    <div> Click me to designate {{pkg.inventory_number}} to {{orderToDesignateTo.code}}
  // {{/goodcity/designate-link}}
  //
  exports["default"] = _ember["default"].Component.extend({
    designationService: _ember["default"].inject.service(),

    actions: {
      beginDesignation: function beginDesignation() {
        this.get("designationService").beginDesignation({
          pkg: this.get("pkg"),
          order: this.get("order"),
          allowItemChange: this.get("allowItemChange"),
          allowOrderChange: this.get("allowOrderChange")
        });
      }
    }
  });
});