define("stock/controllers/users/merge_user", ["exports", "ember", "stock/mixins/async"], function (exports, _ember, _stockMixinsAsync) {
  exports["default"] = _ember["default"].Controller.extend(_stockMixinsAsync["default"], {
    user: _ember["default"].computed.alias("model.user"),
    otherUser: _ember["default"].computed.alias("model.otherUser"),
    userService: _ember["default"].inject.service(),

    masterUserId: _ember["default"].computed("user.id", {
      get: function get() {
        return this.get("user.id");
      },
      set: function set(_, value) {
        return value;
      }
    }),

    actions: {
      mergeUser: function mergeUser() {
        var _this = this;

        var targetUserId = this.get("masterUserId");
        var sourceUserId = this.get("user.id");

        if (targetUserId === this.get("user.id")) {
          sourceUserId = this.get("otherUser.id");
        }

        this.runTask(this.get("userService").mergeUser(sourceUserId, targetUserId).then(function () {
          _this.transitionToRoute("users.details", targetUserId);
        }), _stockMixinsAsync.ERROR_STRATEGIES.MODAL);
      }
    }
  });
});