define("stock/controllers/organisations/orders", ["exports", "ember", "lodash", "stock/mixins/search_resource", "stock/mixins/organisation"], function (exports, _ember, _lodash, _stockMixinsSearch_resource, _stockMixinsOrganisation) {
  exports["default"] = _ember["default"].Controller.extend(_stockMixinsSearch_resource["default"], _stockMixinsOrganisation["default"], {
    organisationUsers: null,
    organisationService: _ember["default"].inject.service(),

    actions: {
      loadOrders: function loadOrders(pageNo) {
        var organisationId = this.get("model.id");
        var params = this.trimQuery(_lodash["default"].merge({}, this.getPaginationQuery(pageNo)));
        return this.get("organisationService").getOrganisationOrders(organisationId, params);
      }
    }
  });
});