define("stock/controllers/guest_user_details", ["exports", "ember", "stock/mixins/async", "stock/mixins/grades_option", "stock/utils/ajax-promise"], function (exports, _ember, _stockMixinsAsync, _stockMixinsGrades_option, _stockUtilsAjaxPromise) {
  var getOwner = _ember["default"].getOwner;
  exports["default"] = _ember["default"].Controller.extend(_stockMixinsAsync["default"], _stockMixinsGrades_option["default"], {
    user: _ember["default"].computed.alias("model"),
    userService: _ember["default"].inject.service(),
    printerService: _ember["default"].inject.service(),
    application: _ember["default"].inject.controller(),
    selectedAdminPrinterId: "",
    selectedStockPrinterId: "",

    getUserMobile: function getUserMobile() {
      return this.get("userService").getUserMobileWithCode(this.get("user.mobile"));
    },

    actions: {
      logMeOut: function logMeOut() {
        this.get("application").send("logMeOut");
      },

      saveDetails: function saveDetails() {
        var _this = this;

        var loadingView = getOwner(this).lookup("component:loading").append();

        new _stockUtilsAjaxPromise["default"]("/users/" + this.get("user.id"), "PUT", this.get("session.authToken"), {
          user: {
            first_name: this.get("firstName"),
            last_name: this.get("lastName"),
            email: this.get("email"),
            title: this.get("selectedTitle.id"),
            image_id: this.get("user.image.id")
          }
        }).then(function (data) {
          _this.get("store").pushPayload(data);
          _this.transitionToRoute("no-permission");
        })["finally"](function () {
          loadingView.destroy();
        });
      }
    }
  });
});