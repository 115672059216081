define("stock/routes/order/international_order", ["exports", "ember", "stock/routes/authorize", "stock/constants/state-events"], function (exports, _ember, _stockRoutesAuthorize, _stockConstantsStateEvents) {
  exports["default"] = _stockRoutesAuthorize["default"].extend({
    orderService: _ember["default"].inject.service(),

    setupController: function setupController(controller, model) {
      var _ref, code;

      return regeneratorRuntime.async(function setupController$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            this._super(controller, model);
            context$1$0.next = 3;
            return regeneratorRuntime.awrap(this.get("orderService").getNextCode(_stockConstantsStateEvents.INTERNATIONAL_ORDERS.SHIPMENT));

          case 3:
            _ref = context$1$0.sent;
            code = _ref.code;

            controller.set("prefix", code[0]);
            controller.set("shipmentOrCarryoutCode", code.substr(1));

          case 7:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    }
  });
});