define('stock/instance-initializers/cloudinary', ['exports', 'ember', 'stock/config/environment'], function (exports, _ember, _stockConfigEnvironment) {
  exports['default'] = {
    name: 'cloudinary',
    initialize: function initialize() /* container, app */{
      _ember['default'].$.cloudinary.config({
        'cloud_name': _stockConfigEnvironment['default'].APP.CLOUD_NAME,
        'api_key': _stockConfigEnvironment['default'].APP.CLOUD_API_KEY
      });
    }
  };
});