define("stock/models/code", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (exports, _emberDataModel, _emberDataAttr, _emberDataRelationships) {
  exports["default"] = _emberDataModel["default"].extend({
    name: (0, _emberDataAttr["default"])("string"),
    code: (0, _emberDataAttr["default"])("string"),
    otherTerms: (0, _emberDataAttr["default"])("string"),
    allowPieces: (0, _emberDataAttr["default"])("boolean", { defaultValue: false }),
    allowBox: (0, _emberDataAttr["default"])("boolean"),
    allowPallet: (0, _emberDataAttr["default"])("boolean"),
    allowPackage: (0, _emberDataAttr["default"])("boolean"),
    descriptionEn: (0, _emberDataAttr["default"])("string"),
    descriptionZhTw: (0, _emberDataAttr["default"])("string"),
    visibleInSelects: (0, _emberDataAttr["default"])("boolean", { defaultValue: false }),
    allowExpiryDate: (0, _emberDataAttr["default"])("boolean", { defaultValue: false }),
    location: (0, _emberDataRelationships.belongsTo)("location", { async: false }),
    goodcityRequests: (0, _emberDataRelationships.hasMany)("goodcity_request", { async: false }),

    defaultChildPackages: (0, _emberDataAttr["default"])("string"),
    subform: (0, _emberDataAttr["default"])("string"),
    otherChildPackages: (0, _emberDataAttr["default"])("string"),

    defaultChildPackagesList: function defaultChildPackagesList() {
      return this._getPackages(this, this.get("defaultChildPackages"));
    },

    otherChildPackagesList: function otherChildPackagesList() {
      return this._getPackages(this, this.get("otherChildPackages"));
    },

    allChildPackagesList: function allChildPackagesList() {
      return this.defaultChildPackagesList().concat(this.otherChildPackagesList());
    },

    _getPackages: function _getPackages(model, packageNames) {
      var array = (packageNames || "").split(",");
      var allPackageTypes = model.store.peekAll("code");
      return allPackageTypes.filter(function (pkg) {
        return array.indexOf(pkg.get("code")) > -1;
      });
    }
  });
});