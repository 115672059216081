define("stock/models/detail", ["exports", "ember-data"], function (exports, _emberData) {
  exports["default"] = _emberData["default"].Model.extend({
    item: _emberData["default"].belongsTo("item", {
      inverse: "detail"
    }),
    country: Ember.computed("countryId", function () {
      var countryId = this.get("countryId");
      if (countryId) {
        return this.store.peekRecord("country", this.get("countryId"));
      }
    })
  });
});