define("stock/helpers/back-action", ["exports", "ember", "stock/config/environment"], function (exports, _ember, _stockConfigEnvironment) {

  /**
   * Creates an action which backs to the previous page
   *
   * Example:
   *
   * <button {{action (back-action)}}>
   *  Go Back
   * </button>
   *
   * @returns {Function} the back action
   */
  exports["default"] = _ember["default"].Helper.extend({
    compute: function compute(_, _ref) {
      var _this = this;

      var _ref$fallbackRoute = _ref.fallbackRoute;
      var fallbackRoute = _ref$fallbackRoute === undefined ? "/" : _ref$fallbackRoute;

      return function () {
        var isRoot = location.pathname === "/";

        if (isRoot) {
          return;
        }

        var noHistory = [1, 2].includes(window.history.length);
        var deepLinked = document.referrer && document.referrer.indexOf(_stockConfigEnvironment["default"].APP.ORIGIN) < 0;

        if (deepLinked || noHistory) {
          return _this.get("router").replaceWith(fallbackRoute);
        }

        window.history.back();
      };
    }
  });
});