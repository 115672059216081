define("stock/components/goodcity/item-search-overlay", ["exports", "ember", "lodash", "stock/config/environment", "stock/mixins/search_resource", "stock/mixins/async", "stock/utils/async", "stock/utils/ember"], function (exports, _ember, _lodash, _stockConfigEnvironment, _stockMixinsSearch_resource, _stockMixinsAsync, _stockUtilsAsync, _stockUtilsEmber) {
  exports["default"] = _ember["default"].Component.extend(_stockMixinsSearch_resource["default"], _stockMixinsAsync["default"], {
    searchText: "",
    autoLoad: true,
    store: _ember["default"].inject.service(),
    displayResults: false,
    perPage: 10,
    isMobileApp: _stockConfigEnvironment["default"].cordova.enabled,
    packageService: _ember["default"].inject.service(),
    cordova: _ember["default"].inject.service(),
    messageBox: _ember["default"].inject.service(),
    i18n: _ember["default"].inject.service(),
    requireFocus: false,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.get("router").addObserver("currentRouteName", function () {
        return _this.closeOverlay();
      });
    },

    inputmode: _ember["default"].computed("searchMode", function () {
      if (this.get("searchMode") === "numeric") {
        return "numeric";
      }
      return "text";
    }),

    hasSearchText: _ember["default"].computed("searchText", function () {
      return !!this.get("searchText");
    }),

    openStateListener: (0, _stockUtilsEmber.callbackObserver)("open", [[true, "onOpen"], [false, "onClose"]]),

    closeOverlay: function closeOverlay() {
      this.setProperties({
        searchText: "",
        open: false,
        displayResults: false,
        onSelect: null
      });
    },

    getAllowedStates: function getAllowedStates() {
      var states = this.getWithDefault("packageStates", []);

      if (!states || !states.length) {
        return null;
      }

      return _lodash["default"].flatten([states]).join(",");
    },

    onOpen: function onOpen() {
      var platform, scrollFix;
      return regeneratorRuntime.async(function onOpen$(context$1$0) {
        var _this2 = this;

        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            platform = this.get("cordova");
            scrollFix = platform.isIOS() || platform.isIOSBrowser();
            context$1$0.next = 4;
            return regeneratorRuntime.awrap(_stockUtilsAsync.chain.stagerred([function () {
              return _this2.set("requireFocus", true);
            }, function () {
              return scrollFix && window.scrollTo(0, 0);
            }]));

          case 4:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    onClose: function onClose() {
      this.set("requireFocus", false);
    },

    actions: {
      cancel: function cancel() {
        this.closeOverlay();
      },

      setScannedSearchText: function setScannedSearchText(searchedText) {
        this.set("searchText", searchedText);
      },

      selectItem: function selectItem(item) {
        this.get("onConfirm")(item);
        this.closeOverlay();
      },

      clearSearch: function clearSearch() {
        this.set("searchText", "");
      },

      loadMoreItems: function loadMoreItems(pageNo) {
        var params;
        return regeneratorRuntime.async(function loadMoreItems$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              params = this.trimQuery(_lodash["default"].merge({}, this.getSearchQuery(true), this.getPaginationQuery(pageNo), {
                associated_package_types_for: this.get("parentCode"),
                storage_type_name: this.get("storageTypeName"),
                states: this.getAllowedStates()
              }));
              return context$1$0.abrupt("return", this.get("store").query("item", params));

            case 2:
            case "end":
              return context$1$0.stop();
          }
        }, null, this);
      }
    }
  });
});