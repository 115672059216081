define("stock/utils/helpers", ["exports", "lodash"], function (exports, _lodash) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; })();

  exports.toID = toID;
  exports.times = times;

  /**
   *
   * @export
   * @param {Model|string} modelOrId
   * @returns {string}
   */

  function toID(modelOrId) {
    if (modelOrId.get) {
      return modelOrId.get("id");
    }
    return modelOrId;
  }

  /**
   * Will the func method for each element of setA against each element of setB
   *
   * @export
   * @param {Array} setA
   * @param {Array} setB
   * @param {Function} func
   */

  function times(setA, setB, func) {
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = setA[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var it1 = _step.value;
        var _iteratorNormalCompletion2 = true;
        var _didIteratorError2 = false;
        var _iteratorError2 = undefined;

        try {
          for (var _iterator2 = setB[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
            var it2 = _step2.value;

            func(it1, it2);
          }
        } catch (err) {
          _didIteratorError2 = true;
          _iteratorError2 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion2 && _iterator2["return"]) {
              _iterator2["return"]();
            }
          } finally {
            if (_didIteratorError2) {
              throw _iteratorError2;
            }
          }
        }
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator["return"]) {
          _iterator["return"]();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }
  }

  var url = {
    /**
     * Return true if the string is a valid URL
     *
     * @param {string} str
     * @returns {boolean}
     */
    isUrl: function isUrl(str) {
      return (/^https?:\/\//.test(str)
      );
    },

    /**
     * Returns the value of a query param
     *
     * @param {string} url
     * @param {string} param
     * @returns {string}
     */
    readQueryParam: function readQueryParam(url, param) {
      var idx = url.indexOf("?");
      var params = idx >= 0 ? url.slice(idx + 1).split("&") : [];

      var pair = params.map(function (p) {
        return p.split("=");
      }).find(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2);

        var k = _ref2[0];
        var v = _ref2[1];
        return k === param;
      });

      return pair ? pair[1] : null;
    },

    /**
     * Returns the path of the url, without the domain name or query params
     *
     * @param {string} url
     * @returns {string}
     */
    pathname: function pathname(url) {
      return url.replace(/\?.*$/, "").replace(/^(https?:\/\/)?[^\/]*\//, "/");
    }
  };
  exports.url = url;
});