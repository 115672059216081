define('stock/routes/order/order_user_organisation', ['exports', 'ember', 'stock/routes/authorize'], function (exports, _ember, _stockRoutesAuthorize) {
  exports['default'] = _stockRoutesAuthorize['default'].extend({
    /* jshint ignore:start */
    model: function model(params) {
      var orderId, order, user, organisationsUser, organisation;
      return regeneratorRuntime.async(function model$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            orderId = params.order_id;
            context$1$0.t0 = this.store.peekRecord('designation', orderId);

            if (context$1$0.t0) {
              context$1$0.next = 6;
              break;
            }

            context$1$0.next = 5;
            return regeneratorRuntime.awrap(this.store.findRecord('designation', parseInt(orderId)));

          case 5:
            context$1$0.t0 = context$1$0.sent;

          case 6:
            order = context$1$0.t0;
            user = order.get('createdBy');
            organisationsUser = user.get('organisationsUsers.firstObject');
            organisation = organisationsUser.get('organisation');
            return context$1$0.abrupt('return', _ember['default'].RSVP.hash({
              user: user,
              organisation: organisation,
              order: order,
              organisationsUser: organisationsUser
            }));

          case 11:
          case 'end':
            return context$1$0.stop();
        }
      }, null, this);
    },
    /* jshint ignore:end */

    afterModel: function afterModel() {
      // Refer: for nested route issue for not scrolling at top of the page
      // https://github.com/dollarshaveclub/ember-router-scroll.
      _ember['default'].$('body').animate({ scrollTop: 0 });
    }
  });
});
// jshint ignore:line