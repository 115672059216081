define("stock/utils/barcode-ui", ["exports", "ember", "lodash"], function (exports, _ember, _lodash) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  exports.buildCameraView = buildCameraView;

  function buildOverlay() {
    var parent = arguments.length <= 0 || arguments[0] === undefined ? document.body : arguments[0];
    var style = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

    var container = _ember["default"].$(parent);
    var overlay = _ember["default"].$("<div></di>");

    overlay.css(_extends({
      width: "100%",
      height: "100%",
      position: parent === document.body ? "fixed" : "absolute",
      "z-index": "2000",
      top: "0px",
      left: "0px",
      "background-color": "black"
    }, style));

    container.prepend(overlay);

    return overlay;
  }

  /**
   *
   * @param {JQuery<HTMLElement>} parent
   */
  function resolveButtonContainer(parent) {
    var container = parent.find(".button-container");

    if (container.length === 0) {
      var el = _ember["default"].$('<div class="button-container"></div>');
      el.css({
        "z-index": "1000",
        position: "relative",
        display: "flex",
        "justify-content": "space-around",
        margin: "20px auto"
      });

      parent.append(el);
      return parent.find(".button-container");
    }

    return container;
  }

  function createButton(parent, content, callback) {
    var button = _ember["default"].$("<div></di>");

    button.html(content);
    button.css({
      "min-width": "70px",
      height: "30px",
      "background-color": "white",
      color: "black",
      padding: "5px",
      "border-radius": "50px",
      "z-index": "1000",
      position: "relative",
      "line-height": "20px",
      "text-align": "center"
    });

    button.on("click", callback);
    resolveButtonContainer(parent).append(button);

    return button;
  }

  function buildCameraView() {
    var parent = arguments.length <= 0 || arguments[0] === undefined ? null : arguments[0];
    var opts = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

    var overlay = buildOverlay(parent || document.body);
    var cameraView = _ember["default"].$("<div></di>");

    cameraView.css("position", "relative");

    if (parent) {
      cameraView.css({
        width: "100%",
        height: "100%",
        position: "absolute",
        top: "0px",
        left: "0px"
      });
    } else {
      cameraView.css({
        width: "90vw",
        "max-width": "600px",
        height: "90vw",
        "max-height": "600px",
        margin: "0 auto",
        "margin-top": "20vh"
      });
    }

    overlay.prepend(cameraView);

    return {
      element: cameraView.get(0),

      destroy: function destroy() {
        overlay.remove();
      },

      addButton: function addButton(content, callback) {
        if (parent) {
          return; // Custom user ui
        }
        createButton(overlay, content, callback);
      }
    };
  }
});