define("stock/controllers/users/add_user", ["exports", "ember", "stock/config/environment", "stock/mixins/async", "stock/mixins/image_upload", "stock/constants/regex", "stock/mixins/grades_option"], function (exports, _ember, _stockConfigEnvironment, _stockMixinsAsync, _stockMixinsImage_upload, _stockConstantsRegex, _stockMixinsGrades_option) {
  exports["default"] = _ember["default"].Controller.extend(_stockMixinsAsync["default"], _stockMixinsImage_upload["default"], _stockMixinsGrades_option["default"], {
    newUploadedImage: null,
    email: "",
    i18n: _ember["default"].inject.service(),
    userService: _ember["default"].inject.service(),

    isEmailorMobilePresent: _ember["default"].computed("email", "mobileNumber", function () {
      var emailRegEx = new RegExp(_stockConstantsRegex.regex.EMAIL_REGEX);
      var hkMobileNumberRegEx = new RegExp(_stockConstantsRegex.regex.HK_MOBILE_NUMBER_REGEX);
      return !Boolean(emailRegEx.test(this.get("email")) || hkMobileNumberRegEx.test(this.get("mobileNumber")));
    }),

    districts: _ember["default"].computed(function () {
      return this.get("store").peekAll("district").sortBy("name");
    }),

    clearFormData: function clearFormData() {
      this.set("selectedTitle", null);
      this.set("firstName", "");
      this.set("lastName", "");
      this.set("mobileNumber", "");
      this.set("email", "");
      this.set("selectedDistrict", null);
      this.set("selectedLanguage", null);
      this.set("newUploadedImage", null);
    },

    formatMobileNumber: function formatMobileNumber(number) {
      return number ? _stockConfigEnvironment["default"].APP.HK_COUNTRY_CODE + number : "";
    },

    getRequestParams: function getRequestParams() {
      var mobileNumber, title, language, district, imageId, params;
      return regeneratorRuntime.async(function getRequestParams$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            mobileNumber = this.formatMobileNumber(this.get("mobileNumber"));
            title = this.get("selectedTitle.id") || "Mr";
            language = this.get("selectedLanguage.id");
            district = this.get("selectedDistrict.id");
            context$1$0.next = 6;
            return regeneratorRuntime.awrap(this.uploadImage(this.get("newUploadedImage")));

          case 6:
            imageId = context$1$0.sent;
            params = {
              title: title,
              first_name: this.get("firstName"),
              last_name: this.get("lastName"),
              mobile: mobileNumber,
              email: this.get("email"),
              preferred_language: language,
              address_attributes: {
                district_id: district,
                addressable_type: "profile"
              },
              image_id: imageId
            };
            return context$1$0.abrupt("return", { user: params });

          case 9:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    uploadImage: function uploadImage(image) {
      var result;
      return regeneratorRuntime.async(function uploadImage$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            if (!image) {
              context$1$0.next = 5;
              break;
            }

            context$1$0.next = 3;
            return regeneratorRuntime.awrap(this.get("userService").saveImage(image));

          case 3:
            result = context$1$0.sent;
            return context$1$0.abrupt("return", result.get("id"));

          case 5:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    actions: {
      back: function back() {
        this.clearFormData();
        this.transitionToRoute("manage_users");
      },

      saveUser: function saveUser() {
        var _this = this;

        return this.runTask(function callee$1$0() {
          var data;
          return regeneratorRuntime.async(function callee$1$0$(context$2$0) {
            while (1) switch (context$2$0.prev = context$2$0.next) {
              case 0:
                context$2$0.t0 = regeneratorRuntime;
                context$2$0.t1 = this.get("userService");
                context$2$0.next = 4;
                return regeneratorRuntime.awrap(this.getRequestParams());

              case 4:
                context$2$0.t2 = context$2$0.sent;
                context$2$0.t3 = context$2$0.t1.createUser.call(context$2$0.t1, context$2$0.t2);
                context$2$0.next = 8;
                return context$2$0.t0.awrap.call(context$2$0.t0, context$2$0.t3);

              case 8:
                data = context$2$0.sent;

                this.get("store").pushPayload(data);
                this.clearFormData();
                this.transitionToRoute("users.details", data.user.id);

              case 12:
              case "end":
                return context$2$0.stop();
            }
          }, null, _this);
        }, _stockMixinsAsync.ERROR_STRATEGIES.MODAL);
      },

      cancelForm: function cancelForm() {
        var confirmed;
        return regeneratorRuntime.async(function cancelForm$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              context$1$0.next = 2;
              return regeneratorRuntime.awrap(this.modalConfirm(this.get("i18n").t("users.cancel_user_warning")));

            case 2:
              confirmed = context$1$0.sent;

              if (confirmed) {
                context$1$0.next = 5;
                break;
              }

              return context$1$0.abrupt("return");

            case 5:
              this.clearFormData();
              this.transitionToRoute("manage_users");

            case 7:
            case "end":
              return context$1$0.stop();
          }
        }, null, this);
      }
    }
  });
});