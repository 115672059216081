define('stock/models/beneficiary', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember'], function (exports, _emberDataModel, _emberDataAttr, _emberDataRelationships, _ember) {
  exports['default'] = _emberDataModel['default'].extend({
    firstName: (0, _emberDataAttr['default'])('string'),
    lastName: (0, _emberDataAttr['default'])('string'),
    identityNumber: (0, _emberDataAttr['default'])('string'),
    title: (0, _emberDataAttr['default'])('string'),
    phoneNumber: (0, _emberDataAttr['default'])('string'),
    identityTypeId: (0, _emberDataAttr['default'])('number'),
    identityType: (0, _emberDataRelationships.belongsTo)('identityType', { async: false }),

    fullName: _ember['default'].computed('firstName', 'lastName', function () {
      return this.get("title") + ' ' + this.get('firstName') + ' ' + this.get('lastName');
    }),

    mobileWithoutCountryCode: _ember['default'].computed('mobile', function () {
      var phoneNumber = this.get('phoneNumber');
      return phoneNumber ? phoneNumber.indexOf("+852") >= 0 ? phoneNumber.substring('4') : phoneNumber : '';
    }),

    fullNameWithoutTitle: _ember['default'].computed('firstName', 'lastName', function () {
      return this.get('firstName') + ' ' + this.get('lastName');
    })
  });
});