define("stock/controllers/manage_users", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Controller.extend({
    minSearchTextLength: 3,
    displayResults: false,

    onSearchTextChange: _ember["default"].observer("searchText", function () {
      this.hideResults();
      if (this.get("searchText").trim().length >= 3) {
        _ember["default"].run.debounce(this, this.showResults, 500);
      }
    }),

    hideResults: function hideResults() {
      this.set("displayResults", false);
    },

    showResults: function showResults() {
      this.set("displayResults", true);
    },

    actions: {
      cancelSearch: function cancelSearch() {
        _ember["default"].$("#searchText").blur();
        this.set("searchText", "");
        this.transitionToRoute("app_menu_list");
      },

      loadMoreUsers: function loadMoreUsers(page) {
        var params = {
          page: page,
          per_page: 25,
          searchText: this.get("searchText"),
          stockRequest: true
        };

        return this.get("store").query("user", params);
      }
    }
  });
});