define("stock/controllers/organisations/new", ["exports", "ember", "stock/mixins/search_option", "stock/controllers/goodcity_controller", "stock/mixins/async", "stock/constants/regex"], function (exports, _ember, _stockMixinsSearch_option, _stockControllersGoodcity_controller, _stockMixinsAsync, _stockConstantsRegex) {
  exports["default"] = _stockControllersGoodcity_controller["default"].extend(_stockMixinsSearch_option["default"], _stockMixinsAsync["default"], {
    organisationService: _ember["default"].inject.service(),
    messageBox: _ember["default"].inject.service(),
    i18n: _ember["default"].inject.service(),

    showError: false,
    name_en: "",
    name_zh_tw: "",
    website: "",
    validate: false,

    isInValidNameEn: _ember["default"].computed("name_en", function () {
      return !this.get("name_en").trim().length;
    }),

    isInValidCountry: _ember["default"].computed("country", function () {
      return !this.get("country");
    }),

    actions: {
      /**
       * Create new organisation if
       *      nameEn is present
       *      country is present
       *      type is present
       *      website has a valid format iff its present
       */
      createOrganisation: function createOrganisation() {
        return regeneratorRuntime.async(function createOrganisation$(context$1$0) {
          var _this2 = this;

          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              if (!(!this.get("isInValidNameEn") && !this.get("isInValidCountry"))) {
                context$1$0.next = 5;
                break;
              }

              context$1$0.next = 3;
              return regeneratorRuntime.awrap((function callee$1$0() {
                var organisation;
                return regeneratorRuntime.async(function callee$1$0$(context$2$0) {
                  var _this = this;

                  while (1) switch (context$2$0.prev = context$2$0.next) {
                    case 0:
                      organisation = {
                        name_en: this.get("name_en"),
                        name_zh_tw: this.get("name_zh_tw"),
                        description_en: this.get("description_en"),
                        description_zh_tw: this.get("description_zh_tw"),
                        registration: this.get("registration"),
                        website: this.get("website"),
                        country_id: this.get("country.id"),
                        organisation_type_id: this.get("selectedOrganisationType").id
                      };
                      context$2$0.next = 3;
                      return regeneratorRuntime.awrap(this.runTask(function callee$2$0() {
                        var data;
                        return regeneratorRuntime.async(function callee$2$0$(context$3$0) {
                          while (1) switch (context$3$0.prev = context$3$0.next) {
                            case 0:
                              context$3$0.next = 2;
                              return regeneratorRuntime.awrap(this.get("organisationService").create(organisation));

                            case 2:
                              data = context$3$0.sent;

                              this.send("clearForm");
                              this.replaceRoute("organisations.detail", data.organisation.id);

                            case 5:
                            case "end":
                              return context$3$0.stop();
                          }
                        }, null, _this);
                      }, _stockMixinsAsync.ERROR_STRATEGIES.MODAL));

                    case 3:
                    case "end":
                      return context$2$0.stop();
                  }
                }, null, _this2);
              })());

            case 3:
              context$1$0.next = 6;
              break;

            case 5:
              this.set("showError", true);

            case 6:
            case "end":
              return context$1$0.stop();
          }
        }, null, this);
      },

      clearForm: function clearForm() {
        this.setProperties({
          name_en: "",
          name_zh_tw: "",
          description_en: "",
          description_zh_tw: "",
          registration: "",
          website: "",
          country_id: "",
          organisation_type_id: ""
        });
      },

      cancel: function cancel() {
        var resetForm, confirmed;
        return regeneratorRuntime.async(function cancel$(context$1$0) {
          var _this3 = this;

          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              resetForm = function resetForm() {
                _ember["default"].run.later(_this3, function () {
                  this.send("clearForm");
                  this.replaceRoute("/");
                });
              };

              context$1$0.next = 3;
              return regeneratorRuntime.awrap(this.modalConfirm("organisation.cancel_warning"));

            case 3:
              confirmed = context$1$0.sent;

              if (confirmed) {
                context$1$0.next = 6;
                break;
              }

              return context$1$0.abrupt("return");

            case 6:
              return context$1$0.abrupt("return", resetForm());

            case 7:
            case "end":
              return context$1$0.stop();
          }
        }, null, this);
      },

      onSearch: function onSearch(field, searchText) {
        this.onSearchCountry(field, searchText);
      },

      changeOrganisationType: function changeOrganisationType(_ref) {
        var id = _ref.id;
        var name = _ref.name;

        this.set("selectedOrganisationType", { id: id, name: name });
      },

      setCountryValue: function setCountryValue(value) {
        var countryName = this.get("store").peekRecord("country", value.id).get("nameEn");
        this.set("country", { id: value.id, nameEn: countryName });
      }
    }
  });
});