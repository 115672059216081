define("stock/components/beneficiary-inline-input", ["exports", "ember", "stock/utils/ajax-promise"], function (exports, _ember, _stockUtilsAjaxPromise) {
  var getOwner = _ember["default"].getOwner;
  exports["default"] = _ember["default"].TextField.extend({
    tagName: "input",
    type: "text",
    attributeBindings: ["name", "type", "value", "maxlength", "id", "autoFocus", "placeholder", "required", "pattern"],
    store: _ember["default"].inject.service(),
    previousValue: "",

    whichKey: function whichKey(e, key) {
      var keyList = [13, 8, 9, 39, 46, 32];
      return e.ctrlKey && key === 86 || keyList.indexOf(key) >= 0 || key >= 35 && key <= 37 || key >= 65 && key <= 90 || key >= 96 && key <= 122;
    },

    keyDown: function keyDown(e) {
      var key = e.charCode || e.keyCode || 0;
      // allow ctrl+v, enter, backspace, tab, delete, numbers, keypad numbers
      // home, end only.
      var keyPressed = this.whichKey(e, key);
      return keyPressed;
    },

    focusOut: function focusOut() {
      var _this = this;

      if (this.get("disableCallbacks")) return;
      var field = this.attrs.name;
      var value = this.attrs.value.value.trim() || "";
      var beneficiary = this.get("beneficiary");
      var url = "/beneficiaries/" + beneficiary.get("id");
      var beneficiaryParams = {};

      beneficiaryParams[field] = value;

      if (!value) {
        this.set("value", this.get("previousValue"));
        _ember["default"].$(this.element).removeClass("inline-text-input");
        return false;
      }

      _ember["default"].$(this.element).removeClass("inline-text-input");
      if (value !== this.get("previousValue")) {
        var loadingView = getOwner(this).lookup("component:loading").append();
        new _stockUtilsAjaxPromise["default"](url, "PUT", this.get("session.authToken"), {
          beneficiary: beneficiaryParams
        }).then(function (data) {
          _this.get("store").pushPayload(data);
        })["finally"](function () {
          loadingView.destroy();
        });
      }
      _ember["default"].$(this.element).removeClass("inline-text-input");
    },

    focusIn: function focusIn() {
      this.addCssStyle();
    },

    addCssStyle: function addCssStyle() {
      _ember["default"].$(this.element).addClass("inline-text-input");
    },

    click: function click() {
      this.addCssStyle();
      this.set("previousValue", this.get("value") || "");
    },

    willDestroyElement: function willDestroyElement() {
      this.set("disableCallbacks", true);
    }
  });
});