define("stock/models/image", ["exports", "ember", "stock/models/cloudinary_url", "ember-data/attr", "ember-data/relationships", "stock/config/environment"], function (exports, _ember, _stockModelsCloudinary_url, _emberDataAttr, _emberDataRelationships, _stockConfigEnvironment) {
  exports["default"] = _stockModelsCloudinary_url["default"].extend({
    favourite: (0, _emberDataAttr["default"])("boolean"),
    cloudinaryId: (0, _emberDataAttr["default"])("string"),
    angle: (0, _emberDataAttr["default"])("string"),
    itemId: (0, _emberDataAttr["default"])("number"),
    item: (0, _emberDataRelationships.belongsTo)("item", { async: false }),
    imageableId: (0, _emberDataAttr["default"])("number"),
    imageableType: (0, _emberDataAttr["default"])("string"),

    imageUrl: _ember["default"].computed("cloudinaryId", "angle", function () {
      return this.generateUrl();
    }),

    faceCaptureImageUrl: _ember["default"].computed("cloudinaryId", "angle", function () {
      return this.generateUrl(300, 300, true, true);
    }),

    thumbImageUrl: _ember["default"].computed("cloudinaryId", "angle", function () {
      return this.generateUrl(120, 120, true);
    }),

    isImageOnLongTermStorage: _ember["default"].computed("cloudinaryId", function () {
      return (this.get("cloudinaryId") || "").indexOf(_stockConfigEnvironment["default"].APP.LONG_TERM_IMAGE_STORAGE_ID_PREFIX) === 0;
    })
  });
});