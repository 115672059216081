define("stock/services/organisations-user-service", ["exports", "ember", "stock/services/api-base-service", "lodash", "stock/constants/states"], function (exports, _ember, _stockServicesApiBaseService, _lodash, _stockConstantsStates) {
  exports["default"] = _stockServicesApiBaseService["default"].extend({
    store: _ember["default"].inject.service(),
    /**
     * Fetch ALL user status.
     * Converts it to title case and a key value pair mapping
     * @returns {Array.<Object>} with id as key and name as value
     */
    getAllStatus: function getAllStatus() {
      var statusList;
      return regeneratorRuntime.async(function getAllStatus$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            statusList = _stockConstantsStates.ORGANISATION_STATUS;

            statusList = Object.keys(statusList).map(function (val, index) {
              return {
                id: index,
                name: _lodash["default"].startCase(_lodash["default"].toLower(val))
              };
            });
            return context$1$0.abrupt("return", statusList);

          case 3:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    /**
     * Fetch organisations_user record for a given organisation and user
     * @param {Number} organisation_id
     * @param {Number} user_id
     * @returns {Promise}
     */
    getOrganisationUser: function getOrganisationUser(organisation_id, user_id) {
      var user = this.get("store").peekRecord("user", user_id);
      var usersOrganisations = user.get("organisationsUsers");

      return usersOrganisations.find(function (data) {
        return +data.get("organisationId") === +organisation_id;
      });
    }
  });
});