define("stock/helpers/highlight-substr", ["exports"], function (exports) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; })();

  exports["default"] = Ember.Helper.helper(function (args) {
    var _args = _slicedToArray(args, 2);

    var _args$0 = _args[0];
    var text = _args$0 === undefined ? "" : _args$0;
    var searchStr = _args[1];

    if (!searchStr) return text;
    return text.replace(new RegExp("(" + searchStr + ")", "i"), "<em>$1</em>");
  });
});