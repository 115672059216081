define("stock/controllers/stocktakes/index", ["exports", "ember", "lodash", "stock/mixins/async"], function (exports, _ember, _lodash, _stockMixinsAsync) {
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  exports["default"] = _ember["default"].Controller.extend(_stockMixinsAsync["default"], {
    // ----------------------
    // Dependencies
    // ----------------------

    locationService: _ember["default"].inject.service(),
    store: _ember["default"].inject.service(),
    i18n: _ember["default"].inject.service(),

    // ----------------------
    // Properties
    // ----------------------

    tabs: {
      open: "open",
      closed: "closed",
      cancelled: "cancelled"
    },

    stocktakes: _ember["default"].computed(function () {
      return this.get("store").peekAll("stocktake");
    }),

    closedStocktakes: _ember["default"].computed.filterBy("stocktakes", "state", "closed"),
    cancelledStocktakes: _ember["default"].computed.filterBy("stocktakes", "state", "cancelled"),
    openStocktakes: _ember["default"].computed.filterBy("stocktakes", "state", "open"),

    filteredStocktakes: _ember["default"].computed("selectedTab", "stocktakes.[]", "stocktakes.@each.state", function () {
      var _tabs$open$tabs$closed$tabs$cancelled$get;

      var tabs = this.get("tabs");

      var sorted = function sorted(list) {
        return list.sortBy("id").reverse();
      };

      var key = (_tabs$open$tabs$closed$tabs$cancelled$get = {}, _defineProperty(_tabs$open$tabs$closed$tabs$cancelled$get, tabs.open, "openStocktakes"), _defineProperty(_tabs$open$tabs$closed$tabs$cancelled$get, tabs.closed, "closedStocktakes"), _defineProperty(_tabs$open$tabs$closed$tabs$cancelled$get, tabs.cancelled, "cancelledStocktakes"), _tabs$open$tabs$closed$tabs$cancelled$get)[this.get("selectedTab")];

      return sorted(this.getWithDefault(key, []));
    }),

    showStocktakeList: _ember["default"].computed.not("createMode"),
    showCreateForm: _ember["default"].computed.alias("createMode"),

    stocktakeNameAlreadyExists: _ember["default"].computed("stocktakes.[]", "stocktakes.@each.name", "newStocktakeName", function () {
      var name = this.get("newStocktakeName");
      return !!this.get("stocktakes").findBy("name", name);
    }),

    stocktakeAtLocationAlreadyExists: _ember["default"].computed("selectedLocation", "stocktakes.[]", "stocktakes.@each.{location,state}", function () {
      return !!this.get("stocktakes").filterBy("isOpen").findBy("location", this.get("selectedLocation"));
    }),

    missingStocktakeName: _ember["default"].computed("newStocktakeName", function () {
      return (this.get("newStocktakeName") || "").trim().length === 0;
    }),
    invalidStocktakeName: _ember["default"].computed.or("stocktakeNameAlreadyExists", "missingStocktakeName"),
    invalidLocation: _ember["default"].computed.empty("selectedLocation"),

    // ----------------------
    // Lifecycle
    // ----------------------

    init: function init() {
      this._super.apply(this, arguments);
      this.set("selectedTab", this.get("tabs.open"));
    },

    on: function on() {
      this.resetState();
    },

    off: function off() {
      this.resetState();
    },

    // ----------------------
    // Helpers
    // ----------------------

    generateStocktakeName: function generateStocktakeName(location) {
      if (!location) return "";

      return "Stocktake - " + location.get("displayName") + " - " + moment().format("MMMM YYYY");
    },

    resetState: function resetState() {
      return regeneratorRuntime.async(function resetState$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            this.set("selectedLocation", null);
            this.set("newStocktakeName", "");
            this.set("newStocktakeComment", "");
            this.set("createMode", false);

          case 4:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    pickLocation: function pickLocation() {
      var headerText, location;
      return regeneratorRuntime.async(function pickLocation$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            headerText = this.get("i18n").t("stocktakes.select_location");
            context$1$0.next = 3;
            return regeneratorRuntime.awrap(this.get("locationService").userPickLocation({
              headerText: headerText
            }));

          case 3:
            location = context$1$0.sent;

            if (location) {
              this.set("selectedLocation", location);
              this.set("newStocktakeName", this.generateStocktakeName(location));
            }

            return context$1$0.abrupt("return", location);

          case 6:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    // ----------------------
    // Actions
    // ----------------------

    actions: {
      selectTab: function selectTab(tab) {
        var _this = this;

        this.runTask(function callee$1$0() {
          return regeneratorRuntime.async(function callee$1$0$(context$2$0) {
            while (1) switch (context$2$0.prev = context$2$0.next) {
              case 0:
                context$2$0.t0 = this;
                context$2$0.next = 3;
                return regeneratorRuntime.awrap(this.store.query("stocktake", {
                  include_revisions: false,
                  state: tab
                }));

              case 3:
                context$2$0.t1 = context$2$0.sent;
                context$2$0.t0.set.call(context$2$0.t0, "stocktakes", context$2$0.t1);

                this.set("selectedTab", tab);

              case 6:
              case "end":
                return context$2$0.stop();
            }
          }, null, _this);
        });
      },

      pickLocation: function pickLocation() {
        return this.pickLocation();
      },

      cancelCreate: function cancelCreate() {
        return regeneratorRuntime.async(function cancelCreate$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              this.set("selectedLocation", null);
              this.set("createMode", false);

            case 2:
            case "end":
              return context$1$0.stop();
          }
        }, null, this);
      },

      confirmNewStocktake: function confirmNewStocktake() {
        return regeneratorRuntime.async(function confirmNewStocktake$(context$1$0) {
          var _this2 = this;

          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              this.runTask(function callee$1$0() {
                var stocktake;
                return regeneratorRuntime.async(function callee$1$0$(context$2$0) {
                  while (1) switch (context$2$0.prev = context$2$0.next) {
                    case 0:
                      stocktake = this.get("store").createRecord("stocktake", {
                        name: this.get("newStocktakeName").trim(),
                        location: this.get("selectedLocation"),
                        comment: this.get("newStocktakeComment"),
                        state: "open"
                      });
                      context$2$0.next = 3;
                      return regeneratorRuntime.awrap(stocktake.save());

                    case 3:

                      this.resetState();
                      this.transitionToRoute("stocktakes.detail", stocktake.get("id"));

                    case 5:
                    case "end":
                      return context$2$0.stop();
                  }
                }, null, _this2);
              }, _stockMixinsAsync.ERROR_STRATEGIES.MODAL);

            case 1:
            case "end":
              return context$1$0.stop();
          }
        }, null, this);
      },

      initNewStocktake: function initNewStocktake() {
        var location;
        return regeneratorRuntime.async(function initNewStocktake$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              context$1$0.next = 2;
              return regeneratorRuntime.awrap(this.pickLocation());

            case 2:
              location = context$1$0.sent;

              if (location) {
                context$1$0.next = 5;
                break;
              }

              return context$1$0.abrupt("return");

            case 5:

              this.set("createMode", true);
              this.set("newStocktakeComment", "");

            case 7:
            case "end":
              return context$1$0.stop();
          }
        }, null, this);
      }
    }
  });
});