define("stock/components/base/global", ["exports", "ember"], function (exports, _ember) {

  var REFS = {};

  /**
   * Base class for creating singleton global components
   *
   * This type of component cannot be instanciated more than once
   *
   */
  exports["default"] = _ember["default"].Component.extend({
    init: function init(name) {
      this._super();
      this.set("name", name);

      if (!name) {
        throw new Error("Singleton component must be named");
      }

      if (REFS[name]) {
        throw new Error("Singleton component cannot be instanciated more than once");
      }

      this.set("elementId", name);

      REFS[name] = true;
    },

    willDestroyElement: function willDestroyElement() {
      delete REFS[this.get("name")];
    }
  });
});