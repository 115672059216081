define("stock/components/stock_roles_input", ["exports", "ember", "stock/constants/roles"], function (exports, _ember, _stockConstantsRoles) {
  exports["default"] = _ember["default"].Component.extend({
    showStockRolesPopup: false,
    accessPassRoles: "",
    accessPassRoleIds: "",
    hasOrderFulfilmentRole: false,
    hasStockFulfilmentRole: false,

    userService: _ember["default"].inject.service(),

    roleError: _ember["default"].computed("hasOrderFulfilmentRole", "hasStockFulfilmentRole", function () {
      return !this.get("hasOrderFulfilmentRole") && !this.get("hasStockFulfilmentRole");
    }),

    actions: {
      showStockRolesPopup: function showStockRolesPopup() {
        this.set("showStockRolesPopup", true);
      },

      setAccessRoles: function setAccessRoles() {
        var stockRole = undefined,
            orderRole = undefined,
            stockRoleId = undefined,
            orderRoleId = undefined;

        if (this.get("hasStockFulfilmentRole")) {
          stockRole = _stockConstantsRoles.ROLES.STOCK_APP_ROLES.STOCK_FULFILMENT;
          stockRoleId = this.get("userService").getRoleId(stockRole);
        }

        if (this.get("hasOrderFulfilmentRole")) {
          orderRole = _stockConstantsRoles.ROLES.STOCK_APP_ROLES.ORDER_FULFILMENT;
          orderRoleId = this.get("userService").getRoleId(orderRole);
        }

        this.set("accessPassRoles", [stockRole, orderRole].filter(Boolean).join(", "));
        this.set("accessPassRoleIds", [stockRoleId, orderRoleId].filter(Boolean).join(", "));
      },

      closeStockRolesPopup: function closeStockRolesPopup() {
        this.set("showStockRolesPopup", false);
        this.set("hasOrderFulfilmentRole", false);
        this.set("hasStockFulfilmentRole", false);
      }
    }
  });
});