define("stock/models/item_action", ["exports", "ember-data", "ember-data/relationships"], function (exports, _emberData, _emberDataRelationships) {

  var attr = _emberData["default"].attr;

  exports["default"] = _emberData["default"].Model.extend({
    action: attr("string"),
    source_type: attr("string"),
    description: attr("string"),

    userId: attr("number"),
    packageId: attr("number"),
    itemId: attr("number"),
    sourceId: attr("number"),
    quantity: attr("number"),
    locationId: attr("number"),
    createdAt: attr("date"),

    item: (0, _emberDataRelationships.belongsTo)("item", {
      async: false
    }),

    location: (0, _emberDataRelationships.belongsTo)("location", {
      async: false
    }),

    user: (0, _emberDataRelationships.belongsTo)("user", {
      async: false
    }),

    absoluteQuantity: Ember.computed("quantity", function () {
      return Math.abs(this.get("quantity"));
    }),

    locationSubscript: Ember.computed("quantity", function () {
      return Number(this.get("quantity")) >= 0 ? "To" : "From";
    }),

    sourceSubscript: Ember.computed("quantity", function () {
      return Number(this.get("quantity")) >= 0 ? "From" : "To";
    }),

    arrow: Ember.computed("quantity", function () {
      return Number(this.get("quantity")) >= 0 ? "arrow-up" : "arrow-down";
    }),

    sourceDisplayName: Ember.computed("sourceId", "source_type", function () {
      switch (this.get("source_type")) {
        case "OrdersPackage":
          return this.get("store").peekRecord("orders_package", this.get("sourceId")).getWithDefault("orderCode", "N/A");
        default:
          return "";
      }
    })
  });
});