define("stock/controllers/items/history", ["exports", "stock/controllers/items/detail", "ember", "lodash"], function (exports, _stockControllersItemsDetail, _ember, _lodash) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  exports["default"] = _stockControllersItemsDetail["default"].extend({
    enableFooterActions: false,

    groupedVersions: function groupedVersions() {
      var versions = this.get("versions").toArray();
      var results = _lodash["default"].map(versions, function (version) {
        return _lodash["default"].omit(version.get("objectChanges"), ["available_quantity", "designated_quantity", "on_hand_quantity", "id", "detail_type_id", "created_at", "received_at", "offer_id", "detail_id", "dispatched_quantity", "updated_by_id", "sent_on"]);
      });
      versions.forEach(function (version, index) {
        return version.set("objectChanges", results[index]);
      });
      return versions.filter(function (version) {
        return Object.keys(version.get("objectChanges")).length > 1;
      });
    },

    getGroupKey: function getGroupKey(isAction, action) {
      var createdAt = moment(action.get("createdAt")).format("LL");
      if (isAction) {
        return action.get("user.fullName") + "/" + action.get("action") + "/" + createdAt;
      }
      if (action.get("whodunnitName")) {
        return action.get("whodunnitName") + "/" + this.get("model.state") + "/" + createdAt;
      }
      return this.get("session.currentUser.fullName") + "/" + action.get("state") + "/" + createdAt;
    },

    groupingActionsAndVersions: function groupingActionsAndVersions(actionsAndVersions) {
      var _this = this;

      var groups = _lodash["default"].reduce(actionsAndVersions, function (results, action) {
        var createdAt = moment(action.get("createdAt")).format("LL");
        var isItemAction = !!action.get("user.fullName");
        var groupKey = _this.getGroupKey(isItemAction, action);

        if (action.get("itemType") == "OrdersPackage" && ["dispatched"].includes(action.get("state"))) {
          return results;
        }

        var lastGroup = _lodash["default"].last(results);
        if (lastGroup && lastGroup.key === groupKey) {
          lastGroup.actions.push(action);
          return results;
        }

        var newGroup = {
          key: groupKey,
          type: isItemAction ? action.get("action").capitalize() : action.get("state") == "designated" ? action.get("state") : "Edited",
          date: createdAt,
          user: isItemAction ? action.get("user.fullName") : action.get("whodunnitName"),
          actions: [action]
        };
        results.push(newGroup);
        return results;
      }, []);
      return groups;
    },

    groupedActionsAndVersions: _ember["default"].computed("model.detail", "model", "versions", "itemActions", function () {
      var actionsAndVersions = [].concat(_toConsumableArray(this.get("itemActions").toArray()), _toConsumableArray(this.groupedVersions().toArray())).sortBy("createdAt").reverse();
      return this.groupingActionsAndVersions(actionsAndVersions);
    })
  });
});