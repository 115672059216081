define("stock/helpers/multi-actions", ["exports", "ember"], function (exports, _ember) {

  /**
   * @module Helpers/multi-actions
   * @description Allows running multiple actions
   * @property {...functions} actions the actions to run
   * @example
   *
   * <button {{action (multi-actions
   *    (action "doThis")
   *    (action "doThat")
   *    (action "andThenThis")
   *  )
   * }}>
   *  Submit
   * </button>
   *
   */
  exports["default"] = _ember["default"].Helper.helper(function (actions) {
    return function callee$1$0() {
      var _iteratorNormalCompletion, _didIteratorError, _iteratorError, _iterator, _step, action;

      return regeneratorRuntime.async(function callee$1$0$(context$2$0) {
        while (1) switch (context$2$0.prev = context$2$0.next) {
          case 0:
            _iteratorNormalCompletion = true;
            _didIteratorError = false;
            _iteratorError = undefined;
            context$2$0.prev = 3;
            _iterator = actions[Symbol.iterator]();

          case 5:
            if (_iteratorNormalCompletion = (_step = _iterator.next()).done) {
              context$2$0.next = 12;
              break;
            }

            action = _step.value;
            context$2$0.next = 9;
            return regeneratorRuntime.awrap(action());

          case 9:
            _iteratorNormalCompletion = true;
            context$2$0.next = 5;
            break;

          case 12:
            context$2$0.next = 18;
            break;

          case 14:
            context$2$0.prev = 14;
            context$2$0.t0 = context$2$0["catch"](3);
            _didIteratorError = true;
            _iteratorError = context$2$0.t0;

          case 18:
            context$2$0.prev = 18;
            context$2$0.prev = 19;

            if (!_iteratorNormalCompletion && _iterator["return"]) {
              _iterator["return"]();
            }

          case 21:
            context$2$0.prev = 21;

            if (!_didIteratorError) {
              context$2$0.next = 24;
              break;
            }

            throw _iteratorError;

          case 24:
            return context$2$0.finish(21);

          case 25:
            return context$2$0.finish(18);

          case 26:
          case "end":
            return context$2$0.stop();
        }
      }, null, this, [[3, 14, 18, 26], [19,, 21, 25]]);
    };
  });
});