define("stock/controllers/users/new", ["exports", "stock/config/environment", "ember", "stock/utils/ajax-promise", "ember-i18n"], function (exports, _stockConfigEnvironment, _ember, _stockUtilsAjaxPromise, _emberI18n) {
  var getOwner = _ember["default"].getOwner;
  exports["default"] = _ember["default"].Controller.extend({
    i18n: _ember["default"].inject.service(),
    phoneNumberPlaceholder: (0, _emberI18n.translationMacro)("organisation.user.phone_number"),
    fNamePlaceholder: (0, _emberI18n.translationMacro)("organisation.user.john"),
    lNamePlaceholder: (0, _emberI18n.translationMacro)("organisation.user.doe"),
    emailPlaceholder: (0, _emberI18n.translationMacro)("organisation.user.email"),
    positionPlaceholder: (0, _emberI18n.translationMacro)("organisation.user.position_in_organisation"),
    mobilePhone: "",
    preferredPhone: "",
    organisationId: _ember["default"].computed.alias("model.id"),
    messageBox: _ember["default"].inject.service(),

    clearFormData: function clearFormData() {
      this.set("firstName", "");
      this.set("lastName", "");
      this.set("mobilePhone", "");
      this.set("email", "");
      this.set("position", "");
      this.set("preferredPhone", "");
    },

    formatMobileNumber: function formatMobileNumber() {
      var mobile = this.get("mobilePhone");
      if (mobile.length) {
        return _stockConfigEnvironment["default"].APP.HK_COUNTRY_CODE + mobile;
      }
    },

    getRequestParams: function getRequestParams() {
      var preferredPhoneValue = this.get("preferredPhone");
      var mobilePhone = this.formatMobileNumber();
      var preferredPhone = preferredPhoneValue.length && preferredPhoneValue;
      var params = {
        organisation_id: this.get("organisationId"),
        position: this.get("position"),
        preferred_contact_number: preferredPhone,
        user_attributes: {
          first_name: this.get("firstName"),
          last_name: this.get("lastName"),
          mobile: mobilePhone,
          email: this.get("email")
        }
      };
      return {
        organisations_user: params
      };
    },

    actions: {
      saveUser: function saveUser() {
        var _this = this;

        var loadingView = getOwner(this).lookup("component:loading").append();
        new _stockUtilsAjaxPromise["default"]("/organisations_users", "POST", this.get("session.authToken"), this.getRequestParams()).then(function (data) {
          _this.get("store").pushPayload(data);
          _this.clearFormData();
          _this.transitionToRoute("organisations.users", _this.get("organisationId"));
        })["catch"](function (xhr) {
          if (xhr.status === 422) {
            _this.get("messageBox").alert(xhr.responseJSON.errors[0].message);
          } else {
            throw xhr;
          }
        })["finally"](function () {
          return loadingView.destroy();
        });
      },

      cancelForm: function cancelForm() {
        var _this2 = this;

        this.get("messageBox").custom("You will lose all your data. Are you sure you want to cancel this item?", "Yes", function () {
          _ember["default"].run.later(_this2, function () {
            this.transitionToRoute("organisations.users", this.get("organisationId"));
            this.clearFormData();
          }, 0);
        }, "No");
      }
    }
  });
});