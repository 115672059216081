define("stock/components/goodcity/item-action-block", ["exports", "ember", "lodash"], function (exports, _ember, _lodash) {
  exports["default"] = _ember["default"].Component.extend({
    actionIconsList: {
      process: "random",
      recycle: "recycle",
      trash: "dumpster",
      loss: "folder-minus",
      gain: "folder-plus"
    },

    actionIcon: _ember["default"].computed("action.action", function () {
      return this.get("actionIconsList")[this.get("action.action")];
    }),

    actionTitle: _ember["default"].computed("action.action", function () {
      var actionName = this.get("action.action");

      switch (actionName) {
        case "trash":
          return "Trashed";
        case "recycle":
          return "Recycled";
        case "process":
          return "Processed";
        case "loss":
          return "Loss - Rolling stock take - " + this.get("action.location.name");
        case "gain":
          return "Gain - Stock take - " + this.get("action.location.name");
        default:
          return "NOT APPLICABLE";
      }
    })
  });
});