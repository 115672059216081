define("stock/services/app-review", ["exports", "ember", "stock/config/environment"], function (exports, _ember, _stockConfigEnvironment) {
  exports["default"] = _ember["default"].Service.extend({
    isMobileApp: _stockConfigEnvironment["default"].cordova.enabled,
    cordova: _ember["default"].inject.service(),

    promptReviewModal: function promptReviewModal() {
      if (this.get("isMobileApp")) {
        AppRate.preferences = { // jshint ignore:line
          displayAppName: _stockConfigEnvironment["default"].APP.REVIEW_APP_NAME,
          usesUntilPrompt: 1,
          promptAgainForEachNewVersion: true,
          inAppReview: true,
          storeAppURL: {
            ios: _stockConfigEnvironment["default"].APP.APPLE_APP_ID,
            android: _stockConfigEnvironment["default"].APP.ANDROID_APP_URL
          },
          customLocale: {
            title: "Would you mind rating %@?",
            message: "It won’t take more than a minute and helps to promote our app. Thanks for your support!",
            cancelButtonLabel: "No, Thanks",
            laterButtonLabel: "Remind Me Later",
            rateButtonLabel: "Rate It Now",
            yesButtonLabel: "Yes!",
            noButtonLabel: "Not really",
            appRatePromptTitle: 'Do you like using %@',
            feedbackPromptTitle: 'Mind giving us some feedback?'
          },
          callbacks: {
            handleNegativeFeedback: function handleNegativeFeedback() {
              console.log("negative feedback registered");
            },
            onRateDialogShow: function onRateDialogShow() {
              console.log("Rate dilogue shown");
            },
            onButtonClicked: function onButtonClicked(buttonIndex) {
              console.log("onButtonClicked -> " + buttonIndex + "clicked");
            }
          }
        };
        AppRate.promptForRating(false); // jshint ignore:line
      }
    }
  });
});