define("stock/components/dropdown-search", ["exports", "ember", "ember-inflector", "lodash", "ember-i18n"], function (exports, _ember, _emberInflector, _lodash, _emberI18n) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  var getOwner = _ember["default"].getOwner;
  exports["default"] = _ember["default"].Component.extend({
    selected: [],
    previousValue: "",
    i18n: _ember["default"].inject.service(),
    store: _ember["default"].inject.service(),
    subformDetailService: _ember["default"].inject.service(),
    resourceType: _ember["default"].computed.alias("packageDetails"),
    fixedDropdownArr: ["frequency", "voltage", "compTestStatus", "testStatus"],
    fixedDropdownArrId: ["frequency_id", "voltage_id", "compTestStatus_id", "testStatus_id"],
    addItem: (0, _emberI18n.translationMacro)("items.new.subform.add_item"),

    displayLabel: _ember["default"].computed("addAble", function () {
      return this.get("addAble") ? this.get("addItem") : "";
    }),

    selectedOptionDisplay: _ember["default"].computed("dropDownValues", function () {
      var _this = this;

      var selectedValues = _extends({}, this.get("dropDownValues"));
      var dataObj = {};
      Object.keys(selectedValues).map(function (data, index) {
        if (data != "country_id") {
          if (_this.get("fixedDropdownArrId").indexOf(data) >= 0) {
            var field = "" + data.substring(0, data.length - 3);
            if (selectedValues[data]) {
              var recordData = _this.get("store").peekRecord("lookup", selectedValues[data]).get("labelEn");
            } else {
              recordData = "";
            }
            dataObj[field] = {
              id: selectedValues[data],
              tag: recordData
            };
          } else {
            dataObj[data] = {
              id: index + 1,
              tag: selectedValues[data]
            };
          }
        }
      });
      return dataObj;
    }),

    isfixedDropdown: function isfixedDropdown(fieldName) {
      return this.get("fixedDropdownArr").indexOf(fieldName) >= 0;
    },

    returnConfig: function returnConfig(value, fieldName) {
      var isfixedDropdown = this.isfixedDropdown(fieldName);
      return {
        value: isfixedDropdown ? value.id : value.tag,
        name: isfixedDropdown ? fieldName + "_id" : fieldName,
        previousValue: this.get("previousValue")
      };
    },

    returnSnakeCaseKey: function returnSnakeCaseKey(fieldName) {
      var isfixedDropdown = this.isfixedDropdown(fieldName);
      return isfixedDropdown ? _lodash["default"].snakeCase(fieldName + "_id") : _lodash["default"].snakeCase(fieldName);
    },

    returnLabelEn: function returnLabelEn(responseKey) {
      return this.get("store").peekRecord("lookup", responseKey).get("labelEn");
    },

    returnTag: function returnTag(responseKey, fieldName) {
      var isfixedDropdown = this.isfixedDropdown(fieldName);
      return isfixedDropdown ? this.returnLabelEn(responseKey) : responseKey;
    },

    actions: {
      addNewField: function addNewField(name, text) {
        var packageDetails = this.get("packageDetails");
        var newTag = {
          id: packageDetails[name].length + 1,
          tag: text
        };
        var dropDownValues = _extends({}, this.get("dropDownValues"));
        dropDownValues[name] = text;
        this.set("dropDownValues", dropDownValues);
        this.set("selected", newTag);
        packageDetails[name].push(newTag);
        this.set("packageDetails", packageDetails);
        this.send("setSelected", name, newTag);
      },

      setSelected: function setSelected(fieldName, value) {
        var isfixedDropdown, config, snakeCaseKey, updateResponse, selectedValuesObj, subformType, responseKey;
        return regeneratorRuntime.async(function setSelected$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              if (!this.get("displayPage")) {
                context$1$0.next = 14;
                break;
              }

              isfixedDropdown = this.isfixedDropdown(fieldName);
              config = this.returnConfig(value, fieldName);
              snakeCaseKey = this.returnSnakeCaseKey(fieldName);
              context$1$0.next = 6;
              return regeneratorRuntime.awrap(this.get("onSetValue")(config));

            case 6:
              updateResponse = context$1$0.sent;
              selectedValuesObj = _extends({}, this.get("selectedValuesDisplay"));
              subformType = Object.keys(updateResponse)[0];
              responseKey = updateResponse[subformType][snakeCaseKey];

              selectedValuesObj[fieldName] = {
                id: isfixedDropdown ? responseKey : updateResponse.id,
                tag: this.returnTag(responseKey, fieldName)
              };
              this.set("selectedValuesDisplay", selectedValuesObj);
              context$1$0.next = 15;
              break;

            case 14:
              this.get("onConfirm")(fieldName, value);

            case 15:
            case "end":
              return context$1$0.stop();
          }
        }, null, this);
      },

      openDropDown: function openDropDown(fieldName) {
        if (this.get("displayPage")) {
          var params = this.isfixedDropdown(fieldName) ? "id" : "tag";
          this.set("previousValue", this.get("selectedValuesDisplay")[fieldName][params] || "");
        }
      }
    }
  });
});