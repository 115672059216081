define("stock/helpers/is-in-range", ["exports", "ember"], function (exports, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; })();

  /**
   * @module Helpers/is-in-range
   * @description Returns true if a value is within a range
   * @property {number} val the value to check
   * @property {number} min the minimum value allowed
   * @property {number} max the maximum value allowed
   * @example
   *
   * {{input type="number" value=quantity change=(clamp this 'quantity' 1 10) }}
   *
   */
  exports["default"] = _ember["default"].Helper.helper(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 3);

    var val = _ref2[0];
    var min = _ref2[1];
    var max = _ref2[2];

    return val >= min && val <= max;
  });
});