define("stock/components/item-filters", ["exports", "ember", "lodash"], function (exports, _ember, _lodash) {

  // ---Component

  exports["default"] = _ember["default"].Component.extend({
    i18n: _ember["default"].inject.service(),
    filterService: _ember["default"].inject.service(),
    publishFilter: "",
    imageFilter: "",
    availableStates: [{ state: "in_stock", enabled: false }, { state: "designated", enabled: false }],
    inventoryStates: [{ state: "dispatched", enabled: false }, { state: "process", enabled: false }, { state: "loss", enabled: false }, { state: "pack", enabled: false }, { state: "trash", enabled: false }, { state: "recycle", enabled: false }],
    publishFilters: ["published_and_private", "published", "private"],
    imageFilters: ["with_and_without_images", "has_images", "no_images"],

    //Marks filters as selected depending on pre-selected set of filters
    didInsertElement: function didInsertElement() {
      var itemFilters = this.get("filterService.itemStateFilters");
      if (this.get("applyStateFilter")) {
        var availableStates = itemFilters.availableStates;
        var inventoryStates = itemFilters.inventoryStates;
        var publishFilter = itemFilters.publishFilter;
        var imageFilter = itemFilters.imageFilter;

        this.set("stateFilters", availableStates || _lodash["default"].cloneDeep(this.get("availableStates")));
        this.set("inventoryFilters", inventoryStates || _lodash["default"].cloneDeep(this.get("inventoryStates")));
        this.set("publishFilter", publishFilter);
        this.set("imageFilter", imageFilter);
      }
    },

    // Removes applied filters (Generic for all filters)
    uncheckAll: function uncheckAll(filters) {
      return filters.forEach(function (filter) {
        filter.forEach(function (state) {
          return _ember["default"].set(state, "enabled", false);
        });
      });
    },

    // Applies filters (Generic for all given filters)
    checkAll: function checkAll(filters) {
      return filters.forEach(function (filter) {
        filter.forEach(function (state) {
          return _ember["default"].set(state, "enabled", true);
        });
      });
    },

    actions: {
      applyFilters: function applyFilters() {
        if (this.get("applyStateFilter")) {
          var filterService = this.get("filterService");
          var allStatesFilters = {
            availableStates: this.get("stateFilters"),
            inventoryStates: this.get("inventoryFilters"),
            publishFilter: this.get("publishFilter"),
            imageFilter: this.get("imageFilter")
          };
          filterService.set("itemStateFilters", allStatesFilters);
          this.get("router").transitionTo("items.index");
        }
      },

      applyOnHandFilter: function applyOnHandFilter() {
        if (this.get("applyStateFilter")) {
          this.send("clearFilters");
          this.checkAll([this.get("stateFilters")]);
          this.send("applyFilters");
        }
      },

      clearFilters: function clearFilters() {
        if (this.get("applyStateFilter")) {
          var allStatesFilters = [this.get("stateFilters"), this.get("inventoryFilters")];
          this.set("publishFilter", ""), this.set("imageFilter", ""), this.uncheckAll(allStatesFilters);
        }
      },

      toggleCheckbox: function toggleCheckbox(state) {
        _ember["default"].set(state, "enabled", !state.enabled);
      }
    }
  });
});