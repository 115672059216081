define("stock/mixins/fetch_package_detail", ["exports", "ember", "lodash", "stock/utils/unique-array"], function (exports, _ember, _lodash, _stockUtilsUniqueArray) {
  exports["default"] = _ember["default"].Mixin.create({
    subFormDetails: _ember["default"].computed("packageDetails", function () {
      var _this = this;

      if (this.get("showAdditionalFields")) {
        var _ret = (function () {
          var package_details = _this.get("packageDetails");
          if (package_details) {
            var _ret2 = (function () {
              var subFormData = {};
              var columns = Object.keys(package_details.get("firstObject").toJSON());
              columns.map(function (column) {
                var columnData = [];
                columnData = _this.get("setDropdownOption").setOptions(column, package_details);
                var uniqueArray = _lodash["default"].sortBy(_stockUtilsUniqueArray["default"].removeDuplicates(columnData, "tag"), "tag");
                subFormData[column] = uniqueArray.map(function (_column, index) {
                  return {
                    id: _column.id,
                    tag: _column.tag
                  };
                });
              });
              return {
                v: {
                  v: subFormData
                }
              };
            })();

            if (typeof _ret2 === "object") return _ret2.v;
          }
        })();

        if (typeof _ret === "object") return _ret.v;
      }
    }),

    returnDisplayFields: function returnDisplayFields(subform) {
      if (this.get("showAdditionalFields")) {
        return this.get("fields").additionalFields.filter(function (field) {
          return field.category.includes(subform);
        });
      }
    }
  });
});