define("stock/routes/orders/order_types", ["exports", "stock/routes/orders/detail", "stock/utils/cache", "ember"], function (exports, _stockRoutesOrdersDetail, _stockUtilsCache, _ember) {
  exports["default"] = _stockRoutesOrdersDetail["default"].extend({
    processingChecklist: _ember["default"].inject.service(),

    loadLookups: function loadLookups() {
      var _this = this;

      // Load dependent lookup tables
      return _ember["default"].RSVP.all(["gogovan_transport", "booking_type", "process_checklist"].map(function (model) {
        return !_this.store.peekAll(model).get("length") ? _this.store.findAll(model) : _this.store.peekAll(model);
      }));
    },

    loadDependencies: function loadDependencies(order) {
      return _ember["default"].RSVP.all([this.store.query("orders_process_checklist", {
        order_id: order.get("id")
      })]);
    },

    afterModel: function afterModel(model) {
      var args$1$0 = arguments;
      return regeneratorRuntime.async(function afterModel$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return regeneratorRuntime.awrap(this._super.apply(this, args$1$0));

          case 2:
            context$1$0.next = 4;
            return regeneratorRuntime.awrap(Promise.all([this.loadLookups(), this.loadDependencies(model)]));

          case 4:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set("stickyNote.showCallToAction", true);
      controller.set("stickyNote.showSaveButton", false);
    }
  });
});
// jshint ignore: start