define("stock/models/computer_accessory", ["exports", "ember-data/attr", "ember-data/relationships", "stock/models/detail"], function (exports, _emberDataAttr, _emberDataRelationships, _stockModelsDetail) {
  exports["default"] = _stockModelsDetail["default"].extend({
    createdAt: (0, _emberDataAttr["default"])("date"),
    updatedAt: (0, _emberDataAttr["default"])("date"),
    brand: (0, _emberDataAttr["default"])("string"),
    model: (0, _emberDataAttr["default"])("string"),
    serialNum: (0, _emberDataAttr["default"])("string"),
    countryId: (0, _emberDataAttr["default"])("number"),
    size: (0, _emberDataAttr["default"])("string"),
    "interface": (0, _emberDataAttr["default"])("string"),
    compVoltage: (0, _emberDataAttr["default"])("string"),
    compTestStatusId: (0, _emberDataAttr["default"])("number"),
    compTestStatus: (0, _emberDataRelationships.belongsTo)("lookup"),
    item: (0, _emberDataRelationships.belongsTo)("item")
  });
});