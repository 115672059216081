define("stock/services/processing-checklist", ["exports", "ember", "ember-data", "stock/utils/ajax-promise"], function (exports, _ember, _emberData, _stockUtilsAjaxPromise) {

  function ID(recordOrId) {
    if (recordOrId instanceof _emberData["default"].Model) {
      return recordOrId.get("id"); // model
    }
    return recordOrId; // id
  }

  exports["default"] = _ember["default"].Service.extend({
    store: _ember["default"].inject.service(),
    session: _ember["default"].inject.service(),

    getChecklistForOrder: function getChecklistForOrder(o) {
      return this.getChecklistForBookingType(o.get("bookingTypeId"));
    },

    getChecklistForBookingType: function getChecklistForBookingType(bt) {
      return this.get("store").peekAll("process-checklist").filterBy("bookingTypeId", ID(bt));
    },

    getJoinRecordForItem: function getJoinRecordForItem(order, item) {
      if (!item) {
        return;
      }
      return order.get("ordersProcessChecklists").find(function (rec) {
        return Number(item.get("id")) === rec.get("processChecklistId");
      });
    },

    itemChecked: function itemChecked(order, item) {
      // We check is there is a join table which points to the processingChecklist item
      return !!this.getJoinRecordForItem(order, item);
    },

    checklistCompleted: function checklistCompleted(order) {
      var itemsToCheck = this.getChecklistForOrder(order);
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = itemsToCheck[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var item = _step.value;

          if (!this.itemChecked(order, item)) {
            return false;
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator["return"]) {
            _iterator["return"]();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return true;
    },

    checkItem: function checkItem(order, item) {
      if (this.itemChecked(order, item)) {
        return _ember["default"].RSVP.resolve(true);
      }

      return this.putPayload(order, {
        orders_process_checklists_attributes: [{ order_id: order.get("id"), process_checklist_id: item.get("id") }]
      });
    },

    uncheckItem: function uncheckItem(order, item) {
      var joinRecord = this.getJoinRecordForItem(order, item);
      if (!joinRecord) {
        return;
      }

      return this.putPayload(order, {
        orders_process_checklists_attributes: [{ id: joinRecord.get("id"), _destroy: true }]
      });
    },

    putPayload: function putPayload(order, payload) {
      var _this = this;

      var url = "/orders/" + order.get("id");
      return new _stockUtilsAjaxPromise["default"](url, "PUT", this.get("session.authToken"), {
        order: payload
      }).then(function (data) {
        _this.get("store").pushPayload(data);
      });
    }
  });
});