define("stock/routes/application", ["exports", "ember", "stock/config/environment", "stock/mixins/preload_data", "stock/mixins/async", "lodash"], function (exports, _ember, _stockConfigEnvironment, _stockMixinsPreload_data, _stockMixinsAsync, _lodash) {
  var getOwner = _ember["default"].getOwner;
  exports["default"] = _ember["default"].Route.extend(_stockMixinsAsync["default"], _stockMixinsPreload_data["default"], {
    i18n: _ember["default"].inject.service(),
    isErrPopUpAlreadyShown: false,
    isItemUnavailable: false,
    isLoginPopUpAlreadyShown: false,
    logger: _ember["default"].inject.service(),
    messageBox: _ember["default"].inject.service(),
    cordova: _ember["default"].inject.service(),

    _loadDataStore: function _loadDataStore() {
      var _this2 = this;

      return this.preloadData()["catch"](function (error) {
        var isZeroStatus = error.status === 0 || error.errors && error.errors[0].status === "0";
        if (isZeroStatus) {
          _this2.transitionTo("offline");
        } else {
          _this2.handleError(error);
        }
      })["finally"](function () {
        // don't know why but placing this before preloadData on iPhone 6 causes register_device request to fail with status 0
        if (_this2.session.get("isLoggedIn")) {
          _this2.get("cordova").appLoad();
        }
      });
    },

    init: function init() {
      var _this = this;
      var storageHandler = function storageHandler(object) {
        var currentPath = window.location.href;
        var authToken = window.localStorage.getItem("authToken");
        var isLoginPath = currentPath.indexOf("login") >= 0 || currentPath.indexOf("authenticate") >= 0;

        if (!authToken && !isLoginPath) {
          object.session.clear();
          object.store.unloadAll();
          object.transitionTo("login");
        } else if (authToken && isLoginPath) {
          object.transitionTo("/");
        }
      };

      window.addEventListener("storage", function () {
        storageHandler(_this);
      }, false);
    },

    showItemIsNotAvailable: function showItemIsNotAvailable() {
      var _this3 = this;

      this.set("isItemUnavailable", true);

      this.get("messageBox").alert(this.get("i18n").t("unavailable_item"), function () {
        _this3.set("isItemUnavailable", false);
        _this3.transitionTo("items.index");
      });
    },

    showRecordIsNotAvailable: function showRecordIsNotAvailable() {
      var _this4 = this;

      this.get("messageBox").alert(this.get("i18n").t("unavailable_record"), function () {
        _this4.transitionTo("/");
      });
    },

    showOrderIsNotAvailable: function showOrderIsNotAvailable() {
      var _this5 = this;

      this.get("messageBox").alert(this.get("i18n").t("unavailable_order"), function () {
        _this5.transitionTo("orders.index");
      });
    },

    redirectToLogin: function redirectToLogin() {
      if (this.session.get("isLoggedIn")) {
        this.session.clear();
        this.session.unloadSessionData();
        this.transitionTo("login");
      }
    },

    beforeModel: function beforeModel() {
      var _this6 = this;

      var transition = arguments.length <= 0 || arguments[0] === undefined ? [] : arguments[0];

      try {
        localStorage.test = "isSafariPrivateBrowser";
      } catch (e) {
        this.get("messageBox").alert(this.get("i18n").t("QuotaExceededError"));
      }

      localStorage.removeItem("test");
      var language;

      if (transition.queryParams.ln) {
        language = transition.queryParams.ln === "zh-tw" ? "zh-tw" : "en";
      }

      language = this.session.get("language") || "en";
      this.set("session.language", language);
      moment.locale(language);
      this.set("i18n.locale", language);

      _ember["default"].onerror = window.onerror = function (error) {
        if (error.errors && error.errors[0] && error.errors[0].status === "401") {
          transition.abort();
        }
        _this6.handleError(error);
      };
      return this._loadDataStore();
    },

    renderTemplate: function renderTemplate() {
      this.render(); // default template
      this.render("notifications", {
        // the template to render
        into: "application", // the template to render into
        outlet: "notifications", // the name of the outlet in that template
        controller: "notifications" // the controller to use for the template
      });
    },

    handleError: function handleError(reason, transition) {
      try {
        var status;

        console.log(reason);

        try {
          status = parseInt(reason.errors[0].status, 10);
        } catch (err) {
          status = reason.status;
        }

        if (!window.navigator.onLine) {
          this.get("messageBox").alert(this.get("i18n").t("offline_error"));
          if (!reason.isAdapterError) {
            this.get("logger").error(reason);
          }
        } else if (reason.name === "QuotaExceededError") {
          this.get("logger").error(reason);
          this.get("messageBox").alert(this.get("i18n").t("QuotaExceededError"));
        } else if (reason.name === "NotFoundError" && reason.code === 8) {
          return true;
        } else if (status === 401) {
          this.redirectToLogin();
        } else if (status === 403) {
          this.redirectToNoPermission(transition);
        } else {
          if (reason.message && reason.message.indexOf("stockit_item") >= 0 && reason.message.indexOf("404") >= 0 && !this.get("isItemUnavailable")) {
            this.showItemIsNotAvailable();
          } else if (reason.message && reason.message.indexOf("/designations/") >= 0 && reason.message.indexOf("404") >= 0) {
            this.showOrderIsNotAvailable();
          } else if (status === 404) {
            this.showRecordIsNotAvailable();
          } else {
            this.showErrorPopup(reason);
          }
        }
      } catch (err) {
        console.log(err);
      }
    },

    redirectToNoPermission: function redirectToNoPermission(transition) {
      if (transition) {
        var key = transition.state.params[transition.targetName];
        var id = key[Object.keys(key)[0]];

        this.transitionTo("no-permission", {
          queryParams: {
            destination: transition.targetName,
            id: id
          }
        });
      } else {
        this.transitionTo("no-permission", {
          queryParams: { destination: "index" }
        });
      }
    },

    actions: {
      loading: function loading() {
        if (_stockConfigEnvironment["default"].environment !== "test") {
          _ember["default"].$(".loading-indicator").remove();
          var view = getOwner(this).lookup("component:loading").append();
          this.router.one("didTransition", view, "destroy");
        }
      },

      error: function error(reason, transition) {
        try {
          this.handleError(reason, transition);
        } catch (err) {
          console.log(err);
        }
      },

      logMeOut: function logMeOut() {
        this.session.clear();
        this.session.unloadSessionData();
        this.transitionTo("login");
      }
    }
  });
});