define("stock/mixins/grades_option", ["exports", "lodash", "ember", "stock/constants/saleable-options"], function (exports, _lodash, _ember, _stockConstantsSaleableOptions) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  exports["default"] = _ember["default"].Mixin.create({
    i18n: _ember["default"].inject.service(),

    grades: _ember["default"].computed(function () {
      var grades = ["A", "B", "C", "D"];
      return grades.map(function (key) {
        return {
          name: key,
          id: key
        };
      });
    }),

    titles: _ember["default"].computed(function () {
      var translation = this.get("i18n");
      var mr = translation.t("order.user_title.mr");
      var mrs = translation.t("order.user_title.mrs");
      var miss = translation.t("order.user_title.miss");
      var ms = translation.t("order.user_title.ms");

      return [{ name: mr, id: "Mr" }, { name: mrs, id: "Mrs" }, { name: miss, id: "Miss" }, { name: ms, id: "Ms" }];
    }),

    shipmentTypes: _ember["default"].computed(function () {
      var translation = this.get("i18n");
      var shipment = translation.t("order_transports.shipment");
      var carryout = translation.t("order_transports.carry_out");

      return [{ name: shipment, id: "Shipment" }, { name: carryout, id: "CarryOut" }];
    }),

    languages: _ember["default"].computed(function () {
      var translation = this.get("i18n");
      var english = translation.t("users.languages.english");
      var chinese = translation.t("users.languages.chinese");

      return [{ name: english, id: "en" }, { name: chinese, id: "zh-tw" }];
    }),

    saleableOptions: _ember["default"].computed(function () {
      var _this = this;

      return _lodash["default"].map(_stockConstantsSaleableOptions.SALEABLE_OPTIONS, function (obj) {
        return _extends({}, obj, { name: _this.get("i18n").t(obj.translation_key).string });
      });
    }),

    restrictionOptions: _ember["default"].computed(function () {
      return this.store.peekAll("restriction");
    })
  });
});