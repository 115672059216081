define("stock/routes/manage_account", ["exports", "stock/routes/authorize"], function (exports, _stockRoutesAuthorize) {
  exports["default"] = _stockRoutesAuthorize["default"].extend({
    model: function model() {
      return Ember.RSVP.hash({
        user: this.loadIfAbsent("user", this.session.get("currentUser.id"))
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set("selectedAdminPrinterId", controller.get("selectedAdminPrinterDisplay.id"));
      controller.set("selectedStockPrinterId", controller.get("selectedStockPrinterDisplay.id"));
      model.user.set("mobile", controller.getUserMobile());
    }
  });
});