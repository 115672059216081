define("stock/components/message-box", ["exports", "ember", "stock/templates/components/message-box"], function (exports, _ember, _stockTemplatesComponentsMessageBox) {
  exports["default"] = _ember["default"].Component.extend({
    layout: _stockTemplatesComponentsMessageBox["default"],
    message: "",
    btn1Text: "",
    btn1Callback: function btn1Callback() {},
    btn2Text: "",
    btn2Callback: function btn2Callback() {},
    displayCloseLink: false,
    isVisible: false,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.get("router").addObserver("currentRouteName", function () {
        return _this.close();
      });
    },

    close: function close() {
      if (this.get("isVisible")) {
        this.set("isVisible", false);
      } else {
        this.destroy();
      }
    },

    actions: {
      btn1Click: function btn1Click() {
        if (this.btn1Callback) {
          if (this.btn1Callback() === false) {
            return;
          }
        }
        this.close();
      },

      btn2Click: function btn2Click() {
        if (this.btn2Callback) {
          if (this.btn2Callback() === false) {
            return;
          }
        }
        this.close();
      },

      closeModal: function closeModal() {
        this.close();
      },

      //Fix: Too deeply nested component(3 levels) failing randomly(Known issue with Ember)
      //Remove when Ember is upgraded to >= 3.0
      updateErrorMessage: function updateErrorMessage() {
        return false;
      }
    }
  });
});