define("stock/routes/users/admin_roles", ["exports", "stock/routes/authorize"], function (exports, _stockRoutesAuthorize) {
  exports["default"] = _stockRoutesAuthorize["default"].extend({
    /* jshint ignore:start */
    model: function model(params) {
      var user = this.loadIfAbsent("user", params.user_id);
      var roles = this.store.query("role", {});
      this.store.pushPayload(roles);

      return Ember.RSVP.hash({
        user: user,
        roles: roles
      });
    },
    /* jshint ignore:end */

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set("selectedPrinterId", controller.get("selectedPrinterDisplay.id"));
    }
  });
});