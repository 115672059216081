define("stock/controllers/order/goods_details", ["exports", "ember", "stock/config/environment", "stock/mixins/async"], function (exports, _ember, _stockConfigEnvironment, _stockMixinsAsync) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  exports["default"] = _ember["default"].Controller.extend(_stockMixinsAsync["default"], {
    goodcityRequestService: _ember["default"].inject.service(),

    queryParams: ["typeId", "fromClientInformation"],
    order: _ember["default"].computed.alias("model.orderUserOrganisation.order"),
    typeId: null,
    fromClientInformation: false,
    qty: null,
    otherDetails: "",
    isMobileApp: _stockConfigEnvironment["default"].cordova.enabled,

    hasNoGcRequests: _ember["default"].computed("goodcityRequests.[]", "goodcityRequests.@each.code", function () {
      return !this.get("goodcityRequests").length || this.get("goodcityRequests").filter(function (gr) {
        return gr.code;
      }).length !== this.get("goodcityRequests").length;
    }),

    createGoodsDetails: function createGoodsDetails(params, index) {
      var data, goodcityRequests;
      return regeneratorRuntime.async(function createGoodsDetails$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return regeneratorRuntime.awrap(this.get("goodcityRequestService").createGcRequest(_extends({}, params)));

          case 2:
            data = context$1$0.sent;
            goodcityRequests = this.get("goodcityRequests");

            goodcityRequests[index].id = data["goodcity_request"]["id"];
            this.set("goodcityRequests", [].concat(_toConsumableArray(goodcityRequests)));

          case 6:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    updateGoodsDetails: function updateGoodsDetails(id, params) {
      return regeneratorRuntime.async(function updateGoodsDetails$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return regeneratorRuntime.awrap(this.get("goodcityRequestService").updateGcRequest(id, params));

          case 2:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    actions: {
      onRemoveRequest: function onRemoveRequest(_id, index) {
        return regeneratorRuntime.async(function onRemoveRequest$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              this.set("goodcityRequests", [].concat(_toConsumableArray(this.get("goodcityRequests").slice(0, index)), _toConsumableArray(this.get("goodcityRequests").slice(index + 1))));

            case 1:
            case "end":
              return context$1$0.stop();
          }
        }, null, this);
      },

      addRequest: function addRequest() {
        var goodcityRequest;
        return regeneratorRuntime.async(function addRequest$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              goodcityRequest = {
                description: "",
                quantity: 1,
                packageType: null
              };

              this.set("goodcityRequests", [].concat(_toConsumableArray(this.get("goodcityRequests")), [goodcityRequest]));

            case 2:
            case "end":
              return context$1$0.stop();
          }
        }, null, this);
      },

      saveGoodsDetails: function saveGoodsDetails() {
        var orderId, goodcityRequests;
        return regeneratorRuntime.async(function saveGoodsDetails$(context$1$0) {
          var _this = this;

          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              if (!this.get("hasNoGcRequests")) {
                context$1$0.next = 2;
                break;
              }

              return context$1$0.abrupt("return", false);

            case 2:
              orderId = this.get("order.id");
              goodcityRequests = this.get("goodcityRequests");
              context$1$0.next = 6;
              return regeneratorRuntime.awrap(this.runTask(Promise.all(goodcityRequests.map(function callee$1$0(gr, index) {
                var params;
                return regeneratorRuntime.async(function callee$1$0$(context$2$0) {
                  while (1) switch (context$2$0.prev = context$2$0.next) {
                    case 0:
                      params = {
                        package_type_id: gr.code.get("id"),
                        quantity: gr.quantity,
                        order_id: orderId,
                        description: gr.description
                      };

                      if (gr.id) {
                        context$2$0.next = 5;
                        break;
                      }

                      return context$2$0.abrupt("return", this.createGoodsDetails(params, index));

                    case 5:
                      return context$2$0.abrupt("return", this.updateGoodsDetails(gr.id, params));

                    case 6:
                    case "end":
                      return context$2$0.stop();
                  }
                }, null, _this);
              })), _stockMixinsAsync.ERROR_STRATEGIES.MODAL));

            case 6:

              this.transitionToRoute("order.appointment_details", this.get("order.id"));

            case 7:
            case "end":
              return context$1$0.stop();
          }
        }, null, this);
      }
    }
  });
});