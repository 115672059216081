define("stock/mixins/messages_base", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Mixin.create({
    body: "",
    messages: [],

    store: _ember["default"].inject.service(),
    subscription: _ember["default"].inject.service(),
    messagesUtil: _ember["default"].inject.service("messages"),
    messageInProgress: false,

    sortProperties: ["createdAt: asc"],
    sortedMessages: _ember["default"].computed.sort("messages", "sortProperties"),

    groupedMessages: _ember["default"].computed("sortedMessages.[]", function () {
      return this.groupBy(this.get("sortedMessages"), "createdDate");
    }),

    groupBy: function groupBy(content, key) {
      var result = [];
      var object, value;

      content.forEach(function (item) {
        value = item.get(key);
        object = result.findBy("value", value);
        if (!object) {
          object = {
            value: value,
            items: []
          };
          result.push(object);
        }
        return object.items.push(item);
      });
      return result.getEach("items");
    },

    createMessage: function createMessage(values) {
      var _this = this;

      if (!values) {
        return;
      }
      this.set("messageInProgress", true);

      var message = this.store.createRecord("message", values);
      message.save().then(function (data) {
        _this.set("body", "");
        _this.get("messages").pushObject(data._internalModel);
        _this.set("displayText", "");
      })["catch"](function (error) {
        _this.store.unloadRecord(message);
        throw error;
      })["finally"](function () {
        return _this.set("messageInProgress", false);
      });
    },

    prepareMessageObject: function prepareMessageObject(messageableType) {
      var values = {};
      values.body = this.get("body").trim();
      values.body = _ember["default"].Handlebars.Utils.escapeExpression(values.body || "");
      values.body = values.body.replace(/(\r\n|\n|\r)/gm, "<br>");
      if (!values.body) {
        return;
      }

      values.createdAt = new Date();
      values.isPrivate = this.get("isPrivate");
      values.sender = this.store.peekRecord("user", this.get("session.currentUser.id"));

      values.messageableType = messageableType;
      values.messageableId = this.get("model.id");
      return values;
    },

    markMessageAsRead: function markMessageAsRead(record) {
      var message = this.store.peekRecord("message", record.id);
      if (!message || message.get("isRead") || !(message.get("messageableId") == this.get("model.id") && message.get("messageableType") == this.get("model.messageableName") && message.get("isPrivate") == this.get("isPrivate"))) {
        return;
      }

      this.get("messages").pushObject(message._internalModel);
      this.get("messagesUtil").markRead(message);
    },

    actions: {
      markRead: function markRead() {
        var _this2 = this;

        this.get("sortedMessages").filterBy("state", "unread").forEach(function (message) {
          return _this2.get("messagesUtil").markRead(message);
        });
      },

      setMentionsActive: function setMentionsActive(val) {
        this.set("isMentionsActive", val);
      },

      setMessageContext: function setMessageContext(message) {
        this.set("body", message.parsedText);
        this.set("displayText", message.displayText);
      }
    }
  });
});