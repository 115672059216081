define("stock/models/packages_location", ["exports", "ember", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (exports, _ember, _emberDataModel, _emberDataAttr, _emberDataRelationships) {
  exports["default"] = _emberDataModel["default"].extend({
    packageId: (0, _emberDataAttr["default"])("number"),
    itemId: (0, _emberDataAttr["default"])("number"),
    quantity: (0, _emberDataAttr["default"])("number"),
    locationId: (0, _emberDataAttr["default"])("number"),

    location: (0, _emberDataRelationships.belongsTo)("location", { async: false }),
    item: (0, _emberDataRelationships.belongsTo)("item", { async: false }),

    quantityToMove: _ember["default"].computed("quantity", function () {
      return this.get("quantity");
    }),

    siblingPackagesLocations: _ember["default"].computed("itemId", function () {
      return this.get("item.packagesLocations");
    })
  });
});