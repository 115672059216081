define("stock/components/auto-resize-textarea", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].TextArea.extend({
    tagName: "textarea",

    attributeBindings: ["data-autoresize", "value", "name", "id", "placeholder", "maxlength", "required", "pattern"],

    valueChanged: _ember["default"].observer("value", function () {
      this.setTextareaHeight();
    }),

    didInsertElement: function didInsertElement() {
      this.setTextareaHeight();
    },

    setTextareaHeight: function setTextareaHeight() {
      if (!this.get("data-autoresize")) return;

      var textarea = this.element;
      var offset = textarea.offsetHeight - textarea.clientHeight;

      if (this.get("value") && this.get("value").length === 0) {
        _ember["default"].$(textarea).css("height", "auto");
      } else if (textarea.scrollHeight < 120) {
        _ember["default"].$(textarea).css("height", "auto").css("height", textarea.scrollHeight + offset).removeAttr("data-autoresize");
      } else {
        _ember["default"].$(textarea).css({ height: "auto", "overflow-y": "auto" }).height(105);
      }
    },

    callAction: function callAction(action) {
      var data = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];

      if (typeof action === "function") {
        return action(data);
      } else if (typeof action === "string") {
        this.sendAction(action, data);
      }
    },

    input: function input() {
      this.callAction(this.get("changeAction"));
    },

    click: function click() {
      this.callAction(this.get("clickAction"));
    },

    focusOut: function focusOut() {
      this.callAction(this.get("focusOutAction"));
    }
  });
});