define("stock/helpers/print-key-value-pair", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Helper.helper(function (key) {
    var message = "";
    if (key[0]) {
      var keys = Object.keys(key[0]);
      keys.forEach(function (record) {
        message += "Undesignating <b>" + key[0][record] + " </b>quantity from order <b>" + record + "</b><br/>";
      });
    }
    return new _ember["default"].String.htmlSafe(message);
  });
});