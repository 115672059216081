define('stock/routes/order/booking_success', ['exports', 'stock/routes/authorize'], function (exports, _stockRoutesAuthorize) {
  exports['default'] = _stockRoutesAuthorize['default'].extend({
    model: function model(params) {
      var orderId = params.order_id;
      return this.store.peekRecord('designation', orderId) || this.store.findRecord('designation', orderId);
    },

    setupController: function setupController() {
      this._super.apply(this, arguments);
      this.controllerFor('application').set('showSidebar', false);
    },

    deactivate: function deactivate() {
      this.controllerFor('application').set('showSidebar', true);
    }
  });
});