define("stock/mixins/storage-type", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Mixin.create({
    settings: _ember["default"].inject.service(),
    storageTypes: _ember["default"].computed(function () {
      return [{
        name: "Pallet",
        icon: "pallet",
        translation: "create_new_pallet",
        disable: this.get("settings.disableBoxPalletCreation")
      }, {
        name: "Box",
        icon: "box-open",
        translation: "create_new_box",
        disable: this.get("settings.disableBoxPalletCreation")
      }, {
        name: "Package",
        icon: "tag",
        translation: "create_new_item",
        disable: false
      }];
    })
  });
});