define("stock/components/notification-bar", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({

    animateNotification: _ember["default"].observer('currentController.model.[]', function () {
      var box = _ember["default"].$(".contain-to-grid.notification");
      var notification = this.get("currentController").retrieveNotification();

      if (!notification) {
        box.hide();return;
      }
      if (box.is(":hidden")) {
        box.slideDown();
        _ember["default"].$(".sticky_title_bar").animate({
          top: '5%'
        }, 400);
        _ember["default"].run.later(this, this.removeNotification, notification, 6000);
      }
    }).on("didInsertElement"),

    removeNotification: function removeNotification(notification) {
      var controller = this.get("currentController");
      if (controller) {
        var remove = function remove() {
          controller.get("model").removeObject(notification);
        };
        var newNotification = controller.retrieveNotification(1);
        if (newNotification) {
          remove();
          _ember["default"].run.later(this, this.removeNotification, newNotification, 6000);
        } else {
          _ember["default"].$(".contain-to-grid.notification").slideUp(400, remove);
          _ember["default"].$(".sticky_title_bar").animate({
            top: '0'
          }, 400);
        }
      }
    }
  });
});