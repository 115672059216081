define("stock/controllers/orders/add_request", ["exports", "ember", "stock/utils/ajax-promise", "stock/mixins/async"], function (exports, _ember, _stockUtilsAjaxPromise, _stockMixinsAsync) {
  var getOwner = _ember["default"].getOwner;
  exports["default"] = _ember["default"].Controller.extend(_stockMixinsAsync["default"], {
    queryParams: ["orderId", "packageTypeId"],
    packageTypeId: null,
    orderId: null,
    quantity: 1,
    messageBox: _ember["default"].inject.service(),

    description: _ember["default"].computed("parentCodeName", {
      get: function get() {
        return this.get("parentCodeName");
      },
      set: function set(key, value) {
        return value;
      }
    }),

    parentCodeName: _ember["default"].computed("packageTypeId", function () {
      var selected = "";
      var codeId = this.get("packageTypeId");
      if (codeId.length) {
        selected = this.get("store").peekRecord("code", codeId);
        return selected && selected.get("name");
      }
      return selected;
    }),

    getRequestParams: function getRequestParams() {
      var quantity = this.get("quantity");
      var description = this.get("description");
      var params = {
        quantity: quantity,
        description: description,
        package_type_id: this.get("packageTypeId"),
        order_id: this.get("orderId")
      };
      return { goodcity_request: params };
    },

    isOnline: function isOnline() {
      if (!window.navigator.onLine) {
        this.get("messageBox").alert(this.get("i18n").t("offline_error"));
        return false;
      }
      return true;
    },

    back: function back() {
      _ember["default"].run(function () {
        return window.history.back();
      });
    },

    actions: {
      clearDescription: function clearDescription() {
        this.set("description", "");
      },

      saveRequest: function saveRequest() {
        var _this = this;

        if (!this.isOnline() || this.get("quantity").toString().trim().length === 0) {
          return false;
        }

        this.runTask(function callee$1$0() {
          var data;
          return regeneratorRuntime.async(function callee$1$0$(context$2$0) {
            while (1) switch (context$2$0.prev = context$2$0.next) {
              case 0:
                context$2$0.next = 2;
                return regeneratorRuntime.awrap(new _stockUtilsAjaxPromise["default"]("/goodcity_requests", "POST", this.get("session.authToken"), this.getRequestParams()));

              case 2:
                data = context$2$0.sent;

                this.get("store").pushPayload(data);
                this.back();

              case 5:
              case "end":
                return context$2$0.stop();
            }
          }, null, _this);
        }, _stockMixinsAsync.ERROR_STRATEGIES.MODAL);
      },

      cancelRequest: function cancelRequest() {
        var _this2 = this;

        this.get("messageBox").custom("Are you sure you want to cancel this request?", "Yes", function () {
          _this2.replaceRoute("orders.requested_items", _this2.get("orderId"));
        }, "No");
      }
    }
  });
});