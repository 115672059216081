define("stock/models/organisations_user", ["exports", "ember", "ember-data/attr", "ember-data/model", "ember-data/relationships", "lodash"], function (exports, _ember, _emberDataAttr, _emberDataModel, _emberDataRelationships, _lodash) {
  exports["default"] = _emberDataModel["default"].extend({
    position: (0, _emberDataAttr["default"])("string"),
    preferredContactNumber: (0, _emberDataAttr["default"])("string"),
    organisationId: (0, _emberDataAttr["default"])("number"),
    status: (0, _emberDataAttr["default"])("string"),

    isApproved: _ember["default"].computed.equal("status", "approved"),
    isPendingApproval: _ember["default"].computed.equal("status", "pending"),
    isActive: _ember["default"].computed.or("isApproved", "isPendingApproval"),

    organisation: (0, _emberDataRelationships.belongsTo)("organisation", {
      async: false
    }),
    organisation: (0, _emberDataRelationships.belongsTo)("organisation", {
      async: false
    }),
    user_id: (0, _emberDataAttr["default"])("string"),
    status: (0, _emberDataAttr["default"])("string"),
    userStatus: _ember["default"].computed("status", function () {
      return _lodash["default"].startCase(_lodash["default"].toLower(this.get("status")));
    }),
    user: (0, _emberDataRelationships.belongsTo)("user", {
      async: false
    })
  });
});