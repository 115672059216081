define("stock/constants/item-actions", ["exports"], function (exports) {
  var ITEM_ACTIONS = [{
    name: "Process",
    icon: "random",
    loss: true
  }, {
    name: "Recycle",
    icon: "recycle",
    loss: true
  }, {
    name: "Trash",
    icon: "dumpster",
    loss: true
  }, {
    name: "Gain",
    icon: "folder-plus",
    loss: false
  }, {
    name: "Loss",
    icon: "folder-minus",
    loss: true
  }];
  exports.ITEM_ACTIONS = ITEM_ACTIONS;
});