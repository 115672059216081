define('stock/controllers/orders/client_summary', ['exports', 'stock/controllers/orders/detail', 'ember', 'lodash', 'stock/mixins/async'], function (exports, _stockControllersOrdersDetail, _ember, _lodash, _stockMixinsAsync) {
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  exports['default'] = _stockControllersOrdersDetail['default'].extend(_stockMixinsAsync['default'], {
    showBeneficiaryModal: false,
    selectedDistrict: null,
    noPurposeDescription: _ember['default'].computed.not('model.purposeDescription'),
    isInvalidPeopleCount: _ember['default'].computed('model.peopleHelped', function () {
      return isNaN(this.get('model.peopleHelped'));
    }),
    designationService: _ember['default'].inject.service(),
    orderService: _ember['default'].inject.service(),

    titles: _ember['default'].computed(function () {
      return [{ name: 'Mr', id: 'Mr' }, { name: 'Mrs', id: 'Mrs' }, { name: 'Miss', id: 'Miss' }, { name: 'Ms', id: 'Ms' }];
    }),

    identityTypes: _ember['default'].computed(function () {
      return this.get('store').peekAll('identity_type');
    }),

    districts: _ember['default'].computed(function () {
      return this.get('store').peekAll('district');
    }),

    isErrorPresent: function isErrorPresent() {
      if (this.get('isInvalidPeopleCount') || this.get('noPurposeDescription')) {
        this.get('model').rollbackAttributes();
        return true;
      }
    },

    actions: {
      removeBeneficiaryModal: function removeBeneficiaryModal() {
        this.toggleProperty('showBeneficiaryModal');
      },

      updateBeneficiary: function updateBeneficiary(field, value) {
        var beneficiary = this.get('model.beneficiary');
        var phoneNumber = field === 'phone_number' ? '+852' + value : value;
        this.runTask(this.get('designationService').updateBeneficiary(beneficiary.id, {
          beneficiary: _defineProperty({}, field, phoneNumber)
        }));
      },

      updateDistrict: function updateDistrict(district) {
        return regeneratorRuntime.async(function updateDistrict$(context$1$0) {
          var _this = this;

          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              context$1$0.next = 2;
              return regeneratorRuntime.awrap(this.runTask(function callee$1$0() {
                var order;
                return regeneratorRuntime.async(function callee$1$0$(context$2$0) {
                  while (1) switch (context$2$0.prev = context$2$0.next) {
                    case 0:
                      order = this.get('model');

                      order.set('district', district);
                      context$2$0.next = 4;
                      return regeneratorRuntime.awrap(order.save());

                    case 4:
                    case 'end':
                      return context$2$0.stop();
                  }
                }, null, _this);
              }));

            case 2:
            case 'end':
              return context$1$0.stop();
          }
        }, null, this);
      },

      updateOrder: function updateOrder(field, value) {
        var order = this.get('model');
        if (this.isErrorPresent() || !_lodash['default'].keys(order.changedAttributes()).length) {
          return;
        }
        this.updateRecord(order, _defineProperty({}, field, value));
      },

      updatePeopleHelped: function updatePeopleHelped(e) {
        var value = parseInt(e.target.value);
        this.set('order.peopleHelped', value);
        this.send('updateOrder', e.target.name, value);
      },

      updatePurposeDescription: function updatePurposeDescription(e) {
        this.send('updateOrder', e.target.name, e.target.value);
      },

      deleteBeneficiary: function deleteBeneficiary() {
        var _this2 = this;

        var order = this.get('model');

        this.runTask(function () {
          return _this2.get('orderService').deleteBeneficiaryOf(order);
        }, _stockMixinsAsync.ERROR_STRATEGIES.MODAL);
      }
    }
  });
});