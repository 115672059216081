define("stock/adapters/designation", ["exports", "stock/adapters/application", "lodash"], function (exports, _stockAdaptersApplication, _lodash) {
  exports["default"] = _stockAdaptersApplication["default"].extend({
    // Intercept the update method of designations, to send the data with the 'order' name instead
    updateRecord: function updateRecord(store, type, snapshot) {
      var data = this.serialize(snapshot, { includeId: true });
      var url = this.buildURL("order", snapshot.id, snapshot, "updateRecord");
      return this.ajax(url, "PUT", { data: { order: data } });
    },

    //For contstructing /show endpoint with queryParams for designations
    urlForFindRecord: function urlForFindRecord(id, modelName, snapshot) {
      var params = {
        include_packages: false,
        include_order: true,
        include_orders_packages: false,
        include_messages: true
      };
      var baseUrl = this.buildURL(modelName, id, snapshot);
      var paramStr = _lodash["default"].map(params, function (value, key) {
        return key + "=" + value;
      }).join("&");
      return baseUrl + "?" + paramStr;
    }
  });
});