define('stock/routes/orders/client_summary', ['exports', 'stock/routes/orders/detail'], function (exports, _stockRoutesOrdersDetail) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  exports['default'] = _stockRoutesOrdersDetail['default'].extend({
    model: function model() {
      var order,
          requestObj,
          args$1$0 = arguments;
      return regeneratorRuntime.async(function model$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return regeneratorRuntime.awrap(this._super.apply(this, args$1$0));

          case 2:
            order = context$1$0.sent;
            requestObj = { order: order };

            if (order.get('beneficiaryId')) {
              requestObj.beneficiary = this.loadIfAbsent('beneficiary', order.get('beneficiaryId'));
            }
            return context$1$0.abrupt('return', Ember.RSVP.hash(_extends({}, requestObj)));

          case 6:
          case 'end':
            return context$1$0.stop();
        }
      }, null, this);
    },

    setupController: function setupController(controller, model) {
      if (controller) {
        controller.set('model', model.order);
        controller.set('selectedDistrict', model.order.get('district'));
      }
    }
  });
});