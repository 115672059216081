define("stock/controllers/display_access_pass", ["exports", "ember", "stock/config/environment", "stock/utils/ajax-promise"], function (exports, _ember, _stockConfigEnvironment, _stockUtilsAjaxPromise) {
  exports["default"] = _ember["default"].Controller.extend({
    timer: _stockConfigEnvironment["default"].APP.ACCESS_PASS_VALIDITY,
    messageBox: _ember["default"].inject.service(),
    i18n: _ember["default"].inject.service(),
    counter: 1,

    timerFunction: function timerFunction() {
      var _this = this;

      if (this.session.router.currentPath == "access_pass" && this.get("counter") === 1 || this.session.router.currentPath == "display_access_pass") {
        var waitTime = this.get("timer");
        if (waitTime === 0) {
          if (this.get("counter") >= 60) {
            this.alertPassExipred();
          } else {
            this.resetTimerParameters();
            return false;
          }
        }
        this.set("timer", waitTime - 1);

        setTimeout(function () {
          _this.timerFunction();
        }, 1000);
      }
    },

    resetTimerParameters: function resetTimerParameters() {
      this.incrementCounter();
      this.set("timer", _stockConfigEnvironment["default"].APP.ACCESS_PASS_VALIDITY);
      this.getRefreshedAccessPass(this.get("code"));
    },

    incrementCounter: function incrementCounter() {
      var counter = this.get("counter");
      this.set("counter", counter + 1);
    },

    getRefreshedAccessPass: function getRefreshedAccessPass(code) {
      var accessPass = this.get("store").peekAll("access_pass").findBy("accessKey", code);

      if (accessPass) {
        this.requestRefreshedAccessPass(accessPass);
      } else {
        this.alertPassExipred();
      }
    },

    alertPassExipred: function alertPassExipred() {
      var _this2 = this;

      this.get("messageBox").custom(this.get("i18n").t("access_form.pass_expired"), this.get("i18n").t("access_form.not_now"), function () {
        return _this2.send("redirectToMenuPage");
      }, this.get("i18n").t("access_form.create_pass"), function () {
        return _this2.send("redirectToAccessPassForm");
      });
    },

    requestRefreshedAccessPass: function requestRefreshedAccessPass(accessPass) {
      var _this3 = this;

      new _stockUtilsAjaxPromise["default"]("/access_passes/" + accessPass.get("id") + "/refresh", "PUT", this.get("session.authToken"), {}).then(function (data) {
        _this3.get("store").pushPayload(data);
        _this3.set("code", data.access_pass.access_key);
        _this3.timerFunction();
      });
    },

    actions: {
      redirectToAccessPassForm: function redirectToAccessPassForm() {
        this.transitionToRoute("access_pass");
      },

      redirectToMenuPage: function redirectToMenuPage() {
        this.transitionToRoute("app_menu_list");
      }
    }
  });
});