define('stock/controllers/order/confirm_booking', ['exports', 'ember', 'stock/utils/ajax-promise', 'stock/config/environment'], function (exports, _ember, _stockUtilsAjaxPromise, _stockConfigEnvironment) {
  var getOwner = _ember['default'].getOwner;
  exports['default'] = _ember['default'].Controller.extend({
    order: _ember['default'].computed.alias("model.order"),
    isMobileApp: _stockConfigEnvironment['default'].cordova.enabled,

    actions: {
      confirmBooking: function confirmBooking() {
        var _this = this;

        console.log('confirmation');
        var order = this.get('order');
        var loadingView = getOwner(this).lookup('component:loading').append();
        var orderParams = {
          state_event: "submit"
        };
        new _stockUtilsAjaxPromise['default']('/orders/' + order.get('id'), "PUT", this.get('session.authToken'), { order: orderParams }).then(function (data) {
          _this.get("store").pushPayload(data);
          _this.transitionToRoute('order.booking_success', _this.get("order.id"));
        })['finally'](function () {
          return loadingView.destroy();
        });
      }
    }
  });
});