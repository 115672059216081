define("stock/models/package_set", ["exports", "lodash", "ember-data/model", "ember-data/attr", "ember", "ember-data/relationships"], function (exports, _lodash, _emberDataModel, _emberDataAttr, _ember, _emberDataRelationships) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  /**
   * @module Models/PackageSet
   * @description A collection (or 'set') of packages, locally known as 'items'
   * @augments ember/Model
   *
   */
  exports["default"] = _emberDataModel["default"].extend({
    description: (0, _emberDataAttr["default"])("string"),
    packageIds: (0, _emberDataAttr["default"])(),
    packageTypeId: (0, _emberDataAttr["default"])("number"),

    packageType: (0, _emberDataRelationships.belongsTo)("packageType", {
      async: false
    }),

    items: _ember["default"].computed("packageIds.[]", function () {
      var _this = this;

      return this.getWithDefault("packageIds", []).map(function (id) {
        return _this.get("store").peekRecord("item", id);
      });
    }),

    code: _ember["default"].computed("packageTypeId", function () {
      // Stock seems to use the term 'code' instead of package_type, this is a
      // temporary measure until we remove that name
      // @todo: unify code under the package_type name
      return this.get("store").peekRecord("code", this.get("packageTypeId"));
    }),

    activeOrdersPackages: _ember["default"].computed("items.@each.ordersPackages", function () {
      return this.get("items").reduce(function (ops, record) {
        ops.push.apply(ops, _toConsumableArray(record.get("ordersPackages").filterBy("quantity").rejectBy("state", "cancelled")));
        return ops;
      }, []);
    }),

    hasActiveOrders: _ember["default"].computed("activeOrdersPackages.length", function () {
      return this.get("activeOrdersPackages.length") > 0;
    })
  });
});