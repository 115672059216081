define("stock/helpers/state-filter-icon", ["exports", "ember", "stock/services/filter-service"], function (exports, _ember, _stockServicesFilterService) {
  var PRIORITY = _stockServicesFilterService.STATE_FILTERS.PRIORITY;
  var SUBMITTED = _stockServicesFilterService.STATE_FILTERS.SUBMITTED;
  var PROCESSING = _stockServicesFilterService.STATE_FILTERS.PROCESSING;
  var SCHEDULED = _stockServicesFilterService.STATE_FILTERS.SCHEDULED;
  var DISPATCHING = _stockServicesFilterService.STATE_FILTERS.DISPATCHING;
  var CLOSED = _stockServicesFilterService.STATE_FILTERS.CLOSED;
  var CANCELLED = _stockServicesFilterService.STATE_FILTERS.CANCELLED;
  exports["default"] = _ember["default"].Helper.helper(function (state) {
    switch (state[0]) {
      case PRIORITY:
        return "exclamation-triangle";
      case SUBMITTED:
        return "envelope";
      case PROCESSING:
        return "list";
      case SCHEDULED:
        return "clock";
      case DISPATCHING:
        return "paper-plane";
      case CLOSED:
        return "thumbs-up";
      case CANCELLED:
        return "thumbs-down";
      default:
        return "";
    }
  });
});