define("stock/routes/index", ["exports", "ember", "stock/routes/session", "stock/utils/ajax-promise"], function (exports, _ember, _stockRoutesSession, _stockUtilsAjaxPromise) {
  exports["default"] = _stockRoutesSession["default"].extend({
    model: function model() {
      var canViewDashboard, recentlyUsedDesignations, recentlyUsedLocations;
      return regeneratorRuntime.async(function model$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            canViewDashboard = this.get("session.currentUser.canManageOrders");
            context$1$0.next = 3;
            return regeneratorRuntime.awrap(this.get("store").query("designation", {
              recently_used: true,
              shallow: true
            }));

          case 3:
            recentlyUsedDesignations = context$1$0.sent;
            context$1$0.next = 6;
            return regeneratorRuntime.awrap(this.get("store").query("location", {
              recently_used: true
            }));

          case 6:
            recentlyUsedLocations = context$1$0.sent;

            this.get("store").pushPayload(recentlyUsedDesignations);
            this.get("store").pushPayload(recentlyUsedLocations);

            if (!canViewDashboard) {
              context$1$0.next = 11;
              break;
            }

            return context$1$0.abrupt("return", _ember["default"].RSVP.hash({
              ordersCount: new _stockUtilsAjaxPromise["default"]("/orders/summary", "GET", this.session.get("authToken")),
              recentlyUsedDesignations: recentlyUsedDesignations
            }));

          case 11:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    }
  });
});