define("stock/constants/roles", ["exports"], function (exports) {
  var ROLES = {
    ADMIN_APP_ROLES: {
      REVIEWER: "Reviewer",
      SUPERVISOR: "Supervisor"
    },

    STOCK_APP_ROLES: {
      STOCK_ADMINISTRATOR: "Stock administrator",
      STOCK_FULFILMENT: "Stock fulfilment",
      ORDER_ADMINISTRATOR: "Order administrator",
      ORDER_FULFILMENT: "Order fulfilment"
    }
  };
  exports.ROLES = ROLES;
});