define('stock/controllers/stocktakes/detail', ['exports', 'ember', 'lodash', 'stock/mixins/async', 'stock/mixins/embed_scanner', 'stock/utils/async'], function (exports, _ember, _lodash, _stockMixinsAsync, _stockMixinsEmbed_scanner, _stockUtilsAsync) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  /**
   * @enum {function}
   * @readonly
   * @memberof Controllers/stocktakes/StocktakeDetailController
   * @static
   */
  var SORT_METHODS = {
    updated_date: function updated_date(rev1, rev2) {
      var asc = arguments.length <= 2 || arguments[2] === undefined ? true : arguments[2];

      var alt = asc ? -1 : 1;
      if (!rev1.get('updatedAt')) {
        return -1 * alt; // Unsaved records at the top
      }
      return alt * (rev1.get('updatedAt') > rev2.get('updatedAt') ? -1 : 1);
    },
    inventory_id: function inventory_id(rev1, rev2) {
      var asc = arguments.length <= 2 || arguments[2] === undefined ? true : arguments[2];

      var alt = asc ? 1 : -1;

      var _map$map$map = [rev1, rev2].map(function (r) {
        return r.get('item.inventoryNumber') || '0';
      }).map(function (inv) {
        return inv.replace(/[^0-9]/g, '');
      }).map(Number);

      var _map$map$map2 = _slicedToArray(_map$map$map, 2);

      var n1 = _map$map$map2[0];
      var n2 = _map$map$map2[1];

      return alt * (n1 < n2 ? -1 : 1);
    },
    item_type: function item_type(rev1, rev2) {
      var asc = arguments.length <= 2 || arguments[2] === undefined ? true : arguments[2];

      var alt = asc ? 1 : -1;
      var code1 = rev1.getWithDefault('item.code.code', '');
      var code2 = rev2.getWithDefault('item.code.code', '');

      return alt * (code1 < code2 ? -1 : 1);
    }
  };

  /**
   * @module Controllers/stocktakes/StocktakeDetailController
   * @augments ember/Controller
   */
  exports['default'] = _ember['default'].Controller.extend(_stockMixinsAsync['default'], _stockMixinsEmbed_scanner['default'], {
    // ----------------------
    // Dependencies
    // ----------------------

    store: _ember['default'].inject.service(),
    subscription: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    packageService: _ember['default'].inject.service(),
    stocktakeService: _ember['default'].inject.service(),

    // ----------------------
    // Properties
    // ----------------------

    sortMethods: Object.keys(SORT_METHODS),

    selectedSortMode: {
      type: Object.keys(SORT_METHODS)[0],
      asc: true
    },

    revisions: _ember['default'].computed.alias('stocktake.revisions'),

    filterList: _ember['default'].computed('stocktake', 'revisions.length', 'revisions.@each.{quantity,createdAt,hasVariance,warning,dirty}', function () {
      var revisions = this.get('revisions') || [];
      return _lodash['default'].map([{
        name: 'all',
        predicate: _lodash['default'].identity
      }, {
        name: 'counted',
        predicate: function predicate(rev) {
          return rev.get('dirty') === false;
        }
      }, {
        name: 'variances',
        predicate: function predicate(rev) {
          return rev.get('hasVariance');
        }
      }, {
        name: 'warnings',
        predicate: function predicate(rev) {
          return rev.get('dirty') || rev.get('warning');
        }
      }], function (filter) {
        return _extends({}, filter, {
          count: revisions.filter(filter.predicate).get('length')
        });
      });
    }),

    activeFilter: _ember['default'].computed('selectedFilterName', 'stocktake', 'revisions.length', 'revisions.@each.{quantity,createdAt,hasVariance,warning,dirty}', function () {
      var name = this.get('selectedFilterName');
      var filters = this.get('filterList');
      return filters.findBy('name', name) || filters.get('firstObject');
    }),

    filteredRevisions: _ember['default'].computed('selectedFilterName', 'activeFilter', 'stocktake', 'searchTerm', 'selectedSortMode', 'mineOnly', 'revisions.length', 'revisions.@each.{quantity,createdAt,updatedAt,hasVariance,warning,dirty}', function () {
      var _this = this;

      var searchTerm = this.get('searchTerm');
      var userId = Number(this.get('session.currentUser.id'));
      var sortType = this.get('selectedSortMode.type');
      var sortAsc = this.get('selectedSortMode.asc');

      return this.getWithDefault('revisions', []).filter(this.get('activeFilter.predicate')).filter(function (rev) {
        if (userId && _this.get('mineOnly')) {
          var countedByIds = rev.getWithDefault('countedByIds', []);
          if (!countedByIds.includes(userId)) {
            return false;
          }
        }

        if (searchTerm && _lodash['default'].isString(searchTerm)) {
          var _ret = (function () {
            var rexp = new RegExp(searchTerm, 'ig');
            return {
              v: _lodash['default'].find(_this.getRevisionKeywords(rev), function (kw) {
                return rexp.test(kw);
              })
            };
          })();

          if (typeof _ret === 'object') return _ret.v;
        }
        return true;
      }).sort(function (rev1, rev2) {
        return SORT_METHODS[sortType](rev1, rev2, sortAsc);
      });
    }),

    // ----------------------
    // Lifecycle
    // ----------------------

    on: function on() {
      this.set('searchTerm', '');
      this.set('selectedFilterName', this.get('activeFilter.name'));
      this.set('scannerPreviewId', 'stocktake-scanner-preview-' + _lodash['default'].uniqueId());
    },

    off: function off() {
      this.stopScanning();
    },

    // ----------------------
    // Callbacks
    // ----------------------

    onRevisionsChange: function onRevisionsChange() {
      _ember['default'].run.debounce(this, this.saveChanges, 1000);
    },

    onRevisionSaveError: function onRevisionSaveError() {
      this.set('saveError', true);
    },

    // ----------------------
    // Methods
    // ----------------------

    getRevisionKeywords: function getRevisionKeywords(rev) {
      return [
      // Add any search terms for the filter field here
      rev.get('item.inventoryNumber'), rev.getWithDefault('item.code.name', null), rev.getWithDefault('item.code.code', null)].filter(_lodash['default'].identity);
    },

    trySave: function trySave(revision) {
      return regeneratorRuntime.async(function trySave$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.prev = 0;

            revision.set('dirty', false);
            context$1$0.next = 4;
            return regeneratorRuntime.awrap(revision.save());

          case 4:
            return context$1$0.abrupt('return', true);

          case 7:
            context$1$0.prev = 7;
            context$1$0.t0 = context$1$0['catch'](0);

            this.onRevisionSaveError();
            return context$1$0.abrupt('return', false);

          case 11:
          case 'end':
            return context$1$0.stop();
        }
      }, null, this, [[0, 7]]);
    },

    trySaveAll: function trySaveAll(revisions) {
      return regeneratorRuntime.async(function trySaveAll$(context$1$0) {
        var _this2 = this;

        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            this.set('saveError', false);
            this.set('saving', true);
            context$1$0.next = 4;
            return regeneratorRuntime.awrap(_ember['default'].RSVP.all(revisions.map(function (r) {
              return _this2.trySave(r);
            })));

          case 4:
            this.set('saving', false);

          case 5:
          case 'end':
            return context$1$0.stop();
        }
      }, null, this);
    },

    getChangedRevisions: function getChangedRevisions() {
      return this.get('revisions').filter(function (rev) {
        return rev.get('hasDirtyAttributes') && rev.get('isValid');
      });
    },

    onBarcodeScanned: function onBarcodeScanned(inventoryNumber) {
      var pkg;
      return regeneratorRuntime.async(function onBarcodeScanned$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            if (inventoryNumber) {
              context$1$0.next = 2;
              break;
            }

            return context$1$0.abrupt('return');

          case 2:
            context$1$0.next = 4;
            return regeneratorRuntime.awrap(this.get('packageService').findPackageByInventoryNumber(inventoryNumber));

          case 4:
            pkg = context$1$0.sent;

            if (pkg) {
              this.send('addItem', pkg);
            } else {
              this.showScannerError(this.get('i18n').t('stocktakes.unknown_inventory_number', {
                code: inventoryNumber
              }));
              _ember['default'].run.debounce(this, this.showScannerError, '', 3000);
            }

          case 6:
          case 'end':
            return context$1$0.stop();
        }
      }, null, this);
    },

    showScannerError: function showScannerError(text) {
      this.set('scannerError', text);
    },

    saveChanges: (0, _stockUtilsAsync.queued)(function callee$0$0() {
      var revisions;
      return regeneratorRuntime.async(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            revisions = this.getChangedRevisions();

            if (!revisions.get('length')) {
              context$1$0.next = 4;
              break;
            }

            context$1$0.next = 4;
            return regeneratorRuntime.awrap(this.trySaveAll(revisions));

          case 4:
          case 'end':
            return context$1$0.stop();
        }
      }, null, this);
    }),

    // ----------------------
    // Actions
    // ----------------------

    actions: {
      setSortMode: function setSortMode(type) {
        var asc = arguments.length <= 1 || arguments[1] === undefined ? true : arguments[1];

        if (!SORT_METHODS[type]) {
          console.error('Invalid sort mode');
          return; // Should never happen
        }

        this.set('selectedSortMode', { type: type, asc: asc });
      },

      incrementCount: function incrementCount(revision) {
        this.send('updateQuantity', revision, revision.get('quantity') + 1);
      },

      decrementCount: function decrementCount(revision) {
        this.send('updateQuantity', revision, revision.get('quantity') - 1);
      },

      updateQuantity: function updateQuantity(revision, input) {
        var num = input.target ? Number(input.target.value) : Number(input);

        revision.set('quantity', _lodash['default'].clamp(num || 0, 0, 9999));
        this.onRevisionsChange();
      },

      confirmCount: function confirmCount(revision) {
        revision.set('dirty', false);
        this.onRevisionsChange();
      },

      saveChanges: function saveChanges() {
        this.saveChanges();
      },

      /**
       * Will increment the revision for this pacakge by 1, or create it
       *
       * @param {Package} pkg
       */
      addItem: function addItem(pkg) {
        var opts = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];
        var revision;
        return regeneratorRuntime.async(function addItem$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              context$1$0.t0 = pkg;

              if (context$1$0.t0) {
                context$1$0.next = 5;
                break;
              }

              context$1$0.next = 4;
              return regeneratorRuntime.awrap(this.get('packageService').userPickPackage({
                searchMode: 'numeric'
              }));

            case 4:
              context$1$0.t0 = context$1$0.sent;

            case 5:
              pkg = context$1$0.t0;

              if (pkg) {
                context$1$0.next = 8;
                break;
              }

              return context$1$0.abrupt('return');

            case 8:
              revision = this.get('revisions').find(function (rev) {
                return Number(rev.get('itemId')) === Number(pkg.get('id'));
              }) || this.get('store').createRecord('stocktake_revision', {
                item: pkg,
                dirty: false,
                quantity: 0,
                stocktake: this.get('stocktake'),
                state: 'pending'
              });

              this.send('incrementCount', revision);

            case 10:
            case 'end':
              return context$1$0.stop();
          }
        }, null, this);
      },

      /**
       * Will try to process the Stocktake
       */
      commit: function commit() {
        var _this3 = this;

        this.stopScanning();
        this.runTask(function () {
          return _this3.get('stocktakeService').commitStocktake(_this3.get('stocktake'));
        }, _stockMixinsAsync.ERROR_STRATEGIES.MODAL);
      },

      /**
       * Cancels the stocktake
       */
      cancelStocktake: function cancelStocktake() {
        var confirmed;
        return regeneratorRuntime.async(function cancelStocktake$(context$1$0) {
          var _this4 = this;

          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              context$1$0.next = 2;
              return regeneratorRuntime.awrap(this.modalConfirm('stocktakes.confirm_cancel'));

            case 2:
              confirmed = context$1$0.sent;

              if (confirmed) {
                context$1$0.next = 5;
                break;
              }

              return context$1$0.abrupt('return');

            case 5:

              this.stopScanning();

              this.runTask(function () {
                return _this4.get('stocktakeService').cancelStocktake(_this4.get('stocktake'));
              }, _stockMixinsAsync.ERROR_STRATEGIES.MODAL);

            case 7:
            case 'end':
              return context$1$0.stop();
          }
        }, null, this);
      },

      selectRevision: function selectRevision(revisionId) {
        if (this.get('selectedRevisionId') === revisionId) {
          // toggle off
          return this.set('selectedRevisionId', null);
        }
        return this.set('selectedRevisionId', revisionId);
      },

      selectFilter: function selectFilter(filterName) {
        this.set('selectedFilterName', filterName);
      }
    }
  });
});

// Compute count