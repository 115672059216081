define("stock/services/user-service", ["exports", "ember", "stock/services/api-base-service", "stock/config/environment", "lodash", "stock/constants/roles", "stock/constants/regex"], function (exports, _ember, _stockServicesApiBaseService, _stockConfigEnvironment, _lodash, _stockConstantsRoles, _stockConstantsRegex) {
  exports["default"] = _stockServicesApiBaseService["default"].extend({
    store: _ember["default"].inject.service(),
    session: _ember["default"].inject.service(),
    printerService: _ember["default"].inject.service(),
    messageBox: _ember["default"].inject.service(),

    canUpdateRole: function canUpdateRole(userId) {
      return this.get("session.currentUser.canManageUserRoles") && +this.get("session.currentUser.id") !== +userId;
    },

    currentUser: function currentUser() {
      return this.GET("/auth/current_user_profile");
    },

    getRoleId: function getRoleId(roleName) {
      return this.get("store").peekAll("role").find(function (role) {
        return role.get("name") === roleName;
      }).get("id");
    },

    getRoleExpiryDate: function getRoleExpiryDate(user, userType) {
      var roles = userType == "admin" ? _stockConstantsRoles.ROLES.ADMIN_APP_ROLES : _stockConstantsRoles.ROLES.STOCK_APP_ROLES;
      var expiryDates = user.get("activeUserRoles").filter(function (row) {
        return _lodash["default"].includes(roles, row.get("role.name")) && !!row.get("expiresAt");
      }).map(function (row) {
        return row.get("expiresAt");
      });

      return _lodash["default"].max(expiryDates);
    },

    getRoleAccessText: function getRoleAccessText(user, userType) {
      var expiryDate = this.getRoleExpiryDate(user, userType);
      var hasNoRole = userType == "admin" ? this.hasNoAdminAppRole(user) : this.hasNoStockAppRole(user);

      if (hasNoRole) {
        return "No Access";
      } else if (expiryDate) {
        return "Access Untill " + moment(expiryDate).format("DD/MM/YY");
      } else {
        return "Access Forever";
      }
    },

    isPastDate: function isPastDate(date) {
      return moment.tz(date, "Asia/Hong_Kong").isBefore();
    },

    getPrinterForUser: function getPrinterForUser(user, printerId, userType) {
      if (printerId) {
        var printer = this.get("store").peekRecord("printer", printerId);
        return {
          name: printer.get("name"),
          id: printer.id
        };
      } else {
        var printer = this.get("printerService").getDefaultPrinterForUser(user.get("id"), userType);
        return printer;
      }
    },

    hasRole: function hasRole(user, roleName) {
      return !!user.get("activeRoles").find(function (role) {
        return role.get("name") === roleName;
      });
    },

    hasNoAdminAppRole: function hasNoAdminAppRole(user) {
      return !this.hasRole(user, _stockConstantsRoles.ROLES.ADMIN_APP_ROLES.REVIEWER) && !this.hasRole(user, _stockConstantsRoles.ROLES.ADMIN_APP_ROLES.SUPERVISOR);
    },

    hasNoStockAppRole: function hasNoStockAppRole(user) {
      return !this.hasRole(user, _stockConstantsRoles.ROLES.STOCK_APP_ROLES.STOCK_ADMINISTRATOR) && !this.hasRole(user, _stockConstantsRoles.ROLES.STOCK_APP_ROLES.STOCK_FULFILMENT) && !this.hasRole(user, _stockConstantsRoles.ROLES.STOCK_APP_ROLES.ORDER_ADMINISTRATOR) && !this.hasRole(user, _stockConstantsRoles.ROLES.STOCK_APP_ROLES.ORDER_FULFILMENT);
    },

    createUser: function createUser(userParams) {
      return this.POST("/users", userParams);
    },

    editUser: function editUser(userId, userParams) {
      return this.PUT("/users/" + userId, userParams);
    },

    deleteImage: function deleteImage(img) {
      img.deleteRecord();
      return img.save();
    },

    saveImage: function saveImage(img) {
      return img.save();
    },

    deleteUserRole: function deleteUserRole(userId, roleId) {
      var _this = this;

      var userRole = this.get("store").peekAll("user_role").find(function (userRole) {
        return userRole.get("roleId") === +roleId && userRole.get("userId") === +userId;
      });
      userRole && userRole.destroyRecord()["catch"](function (jqXHR) {
        userRole.rollbackAttributes();
        _this.get("messageBox").alert(_lodash["default"].get(jqXHR, "errors[0].detail.message.error"));
      });
    },

    assignRole: function assignRole(userId, roleId, date) {
      var _this2 = this;

      if (date) {
        date = moment(date, "DD/MMM/YYYY").set("hour", 20).set("minutes", 0).format("LLLL");
      }

      this.POST("/user_roles", {
        user_role: {
          role_id: +roleId,
          user_id: +userId,
          expires_at: date
        }
      }).then(function (data) {
        return _this2.get("store").pushPayload(data);
      })["catch"](function (jqXHR) {
        _this2.get("messageBox").alert(_lodash["default"].get(jqXHR, "responseJSON.errors[0].message.error"));
      });
    },

    deleteAdminRoles: function deleteAdminRoles(user) {
      var userId = user.get("id");

      if (this.canUpdateRole(userId)) {
        var userRoleIds = user.get("roles").map(function (role) {
          return role.id;
        });
        var reviewerRoleId = this.getRoleId(_stockConstantsRoles.ROLES.ADMIN_APP_ROLES.REVIEWER);
        var supervisorRoleId = this.getRoleId(_stockConstantsRoles.ROLES.ADMIN_APP_ROLES.SUPERVISOR);

        if (_lodash["default"].includes(userRoleIds, reviewerRoleId)) {
          this.deleteUserRole(userId, reviewerRoleId);
        }

        if (_lodash["default"].includes(userRoleIds, supervisorRoleId)) {
          this.deleteUserRole(userId, supervisorRoleId);
        }
      }
    },

    deleteStockRoles: function deleteStockRoles(user) {
      var _this3 = this;

      var userId = user.get("id");

      if (this.canUpdateRole(userId)) {
        (function () {
          var userRoleIds = user.get("roles").map(function (role) {
            return role.id;
          });
          var stockAppRoleIds = [_this3.getRoleId(_stockConstantsRoles.ROLES.STOCK_APP_ROLES.STOCK_ADMINISTRATOR), _this3.getRoleId(_stockConstantsRoles.ROLES.STOCK_APP_ROLES.STOCK_FULFILMENT), _this3.getRoleId(_stockConstantsRoles.ROLES.STOCK_APP_ROLES.ORDER_ADMINISTRATOR), _this3.getRoleId(_stockConstantsRoles.ROLES.STOCK_APP_ROLES.ORDER_FULFILMENT)];

          _lodash["default"].each(stockAppRoleIds, function (stockRoleId) {
            if (_lodash["default"].includes(userRoleIds, stockRoleId)) {
              _this3.deleteUserRole(userId, stockRoleId);
            }
          });
        })();
      }
    },

    mergeUser: function mergeUser(sourceUserId, targetUserId) {
      var _this4 = this;

      return this.PUT("/users/merge_users", {
        master_user_id: targetUserId,
        merged_user_id: sourceUserId
      }).then(function (data) {
        return _this4.get("store").pushPayload(data);
      });
    },

    isValidEmail: function isValidEmail(email) {
      return _stockConstantsRegex.regex.EMAIL_REGEX.test(email);
    },

    isValidMobile: function isValidMobile(mobile) {
      return _stockConstantsRegex.regex.HK_MOBILE_NUMBER_REGEX.test(mobile);
    },

    checkUserEmailValidity: function checkUserEmailValidity(email, mobile) {
      if (email) {
        return this.isValidEmail(email);
      } else {
        return this.get("user.disabled") || this.isValidMobile(mobile);
      }
    },

    checkUserMobileValidity: function checkUserMobileValidity(mobile, email) {
      if (mobile) {
        return this.isValidMobile(mobile);
      } else {
        return this.get("user.disabled") || this.isValidEmail(email);
      }
    },

    getUserMobileWithCode: function getUserMobileWithCode(mobile) {
      if (mobile) {
        if (mobile.startsWith("+852")) {
          return mobile;
        } else {
          return _stockConfigEnvironment["default"].APP.HK_COUNTRY_CODE + mobile;
        }
      }
    }
  });
});