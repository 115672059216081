define("stock/components/date-picker", ["exports", "ember", "lodash"], function (exports, _ember, _lodash) {

  var MIN_DATE = moment().subtract(2, "years").toDate();
  function isValidDate(d) {
    return d && d instanceof Date && !isNaN(d);
  }

  exports["default"] = _ember["default"].TextField.extend({
    tagName: "input",
    classNames: "pickadate",
    attributeBindings: ["name", "type", "value", "id", "required", "pattern", "placeholder", "minDate", "maxDate"],

    model: _ember["default"].computed({
      get: function get() {
        return this.get("_model");
      },
      set: function set(k, val) {
        if (!isValidDate(val)) {
          val = null;
        }
        this.set("_model", val);
        this.set("value", val ? moment(val).format("LL") : "");
        return val;
      }
    }),

    didInsertElement: function didInsertElement() {
      var cmp = this;
      var enablePastDate = this.get("enablePastDate");

      _ember["default"].run.scheduleOnce("afterRender", this, function () {
        _ember["default"].$(this.element).pickadate({
          selectMonths: true,
          selectYears: true,
          formatSubmit: "ddd mmm d",
          monthsFull: moment.months(),
          monthsShort: moment.monthsShort(),
          weekdaysShort: moment.weekdaysShort(),
          min: enablePastDate ? MIN_DATE : undefined,
          clear: false,
          today: false,
          close: false,
          onStart: function onStart() {
            if (this.get("value")) {
              this.set("select", moment(this.get("value")).toDate());
              cmp.set("_model", this.get("value"));
              cmp.set("value", moment(this.get("value")).format("LL"));
            }
          },

          onClose: function onClose() {
            _ember["default"].$(document.activeElement).blur();
            var onSelect = cmp.get("onSelect");
            if (onSelect) {
              var date = this.get("val") || this.get("select") && this.get("select").obj;
              if (isValidDate(date) && _lodash["default"].isFunction(onSelect)) {
                onSelect(date);
                cmp.set("value", moment(date).format("LL"));
              }
            }
          },

          onOpen: function onOpen() {
            var date = cmp.get("model");
            this.set("val", date ? date : null);
            var minDate = cmp.get("minDate");
            var maxDate = cmp.get("maxDate");

            this.set("min", minDate || "");
            this.set("max", maxDate || "");
          }
        });
      });
    }
  });
});