define("stock/components/contained-package", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    packageService: _ember["default"].inject.service(),
    store: _ember["default"].inject.service(),

    "package": _ember["default"].computed("pkg", function () {
      return this.get("store").peekRecord("item", this.get("pkg.id"));
    }),

    disableRemove: _ember["default"].computed.alias("entity.isDispatched")
  });
});