define("stock/routes/items/staff_conversation", ["exports", "stock/routes/authorize", "ember"], function (exports, _stockRoutesAuthorize, _ember) {
  exports["default"] = _stockRoutesAuthorize["default"].extend({
    i18n: _ember["default"].inject.service(),
    canManageItemMessages: _ember["default"].computed.alias("session.currentUser.canManageItemMessages"),

    model: function model(params) {
      return _ember["default"].RSVP.hash({
        item: this.loadIfAbsent("item", params.item_id),
        messages: this.get("canManageItemMessages") ? this.store.query("message", {
          package_id: params.item_id,
          is_private: true
        }) : []
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set("messages", model.messages);
      controller.set("model", model.item);
      controller.on();

      if (this.get("canManageItemMessages")) {
        controller.send("markRead");
      }
    },

    resetController: function resetController(controller) {
      controller.off();
    }
  });
});