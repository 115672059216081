define("stock/components/goodcity/organisation-search-overlay", ["exports", "ember", "lodash", "stock/mixins/search_resource"], function (exports, _ember, _lodash, _stockMixinsSearch_resource) {
  exports["default"] = _ember["default"].Component.extend(_stockMixinsSearch_resource["default"], {
    queryParams: ["redirectToPath"],
    redirectToPath: null,
    minSearchTextLength: 3,
    displayResults: false,
    store: _ember["default"].inject.service(),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.get("router").addObserver("currentRouteName", function () {
        return _this.send("cancelSearch");
      });
    },

    onSearchTextChange: _ember["default"].observer("searchText", function () {
      this.hideResults();

      if (this.get("searchText").trim().length >= 3) {
        _ember["default"].run.debounce(this, this.showResults, 500);
      }
    }),

    hasSearchText: _ember["default"].computed("searchText", function () {
      return !!this.get("searchText");
    }),

    hideResults: function hideResults() {
      this.set("displayResults", false);
    },

    showResults: function showResults() {
      this.set("displayResults", true);
    },

    actions: {
      clearSearch: function clearSearch() {
        this.set("searchText", "");
      },

      addOrganisation: function addOrganisation() {
        this.get("router").transitionTo("organisations.new");
        this.set("open", false);
      },

      cancelSearch: function cancelSearch() {
        this.set("searchText", "");
        this.set("open", false);
      },

      setOrganization: function setOrganization(organisation) {
        var onSelect = this.getWithDefault("onSelect", _lodash["default"].noop);
        onSelect(organisation);

        this.set("searchText", "");
        this.set("open", false);
      },

      loadMoreOrganisations: function loadMoreOrganisations(page) {
        var params = {
          page: page,
          per_page: 25,
          searchText: this.get("searchText"),
          stockRequest: true
        };

        return this.get("store").query("organisation", params);
      }
    }
  });
});