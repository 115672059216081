define("stock/components/goodcity/offers-search-overlay", ["exports", "ember", "stock/config/environment", "lodash", "stock/mixins/search_resource"], function (exports, _ember, _stockConfigEnvironment, _lodash, _stockMixinsSearch_resource) {

  /**
   * An overlay that pops up from the bottom of the screen, allowing the user
   * to search and select an offer.
   *
   * The popup *does not* do anythinng to the offer apart from returning it
   *
   * @property {boolean} open whether the popup is visible or not
   * @property {function} onSelect callback triggered when an offer is selected
   */
  exports["default"] = _ember["default"].Component.extend(_stockMixinsSearch_resource["default"], {
    autoLoad: true,
    store: _ember["default"].inject.service(),
    scanSearch: false,
    offerService: _ember["default"].inject.service(),
    messageBox: _ember["default"].inject.service(),
    i18n: _ember["default"].inject.service(),
    perPage: 25,
    isMobileApp: _stockConfigEnvironment["default"].cordova.enabled,
    offer_state: {
      state: "received"
    },
    searchText: "",

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.get("router").addObserver("currentRouteName", function () {
        return _this.send("closeOverlay");
      });
    },

    actions: {
      clearSearch: function clearSearch() {
        this.set("searchText", "");
      },

      loadMoreOffers: function loadMoreOffers(pageNo) {
        var _this2 = this;

        var params = this.trimQuery(_lodash["default"].merge({
          companies: true,
          slug: "search",
          is_desc: true,
          sort_column: "received_at"
        }, this.get("offer_state"), this.getSearchQuery(), this.getPaginationQuery(pageNo)));

        return this.get("store").query("offer", params).then(function (offers) {
          if (_this2.get("isMobileApp") && offers.get("length") == 1 && _this2.get("scanSearch")) {
            _this2.send("selectOffer", offers.get("firstObject"));
          }
          _this2.set("scanSearch", false);
          return offers;
        });
      },

      closeOverlay: function closeOverlay() {
        this.set("scanSearch", false);
        this.send("selectOffer", null);
      },

      selectOffer: function selectOffer(offer) {
        this.getWithDefault("onSelect", _lodash["default"].noop)(offer);
        this.set("scanSearch", false);
        this.set("open", false);
      },

      setScannedSearchText: function setScannedSearchText(searchedText) {
        this.set("searchText", searchedText);
        this.set("scanSearch", true);
      }
    }
  });
});