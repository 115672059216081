define("stock/components/upload-user-image", ["exports", "ember", "stock/config/environment", "stock/mixins/async", "stock/mixins/image_upload"], function (exports, _ember, _stockConfigEnvironment, _stockMixinsAsync, _stockMixinsImage_upload) {
  exports["default"] = _ember["default"].Component.extend(_stockMixinsAsync["default"], _stockMixinsImage_upload["default"], {
    userService: _ember["default"].inject.service(),
    store: _ember["default"].inject.service(),
    saveUser: true,

    getUserMobile: function getUserMobile() {
      var mobile = this.get("user.mobile");
      if (mobile) {
        if (mobile.startsWith("+852")) {
          return mobile;
        } else {
          return _stockConfigEnvironment["default"].APP.HK_COUNTRY_CODE + mobile;
        }
      }
    },

    actions: {
      uploadEditedImageSuccess: function uploadEditedImageSuccess(e, data) {
        var identifier = data.result.version + "/" + data.result.public_id + "." + data.result.format;
        var newUploadedImage = this.get("store").createRecord("image", {
          cloudinaryId: identifier,
          favourite: true
        });
        this.set("newUploadedImage", newUploadedImage);
        this.set("userImageKeys", identifier);

        if (this.get("newUploadedImage")) {
          this.send("saveEditedImage");
        }
      },

      saveEditedImage: function saveEditedImage() {
        var _this = this;

        return this.runTask(function callee$1$0() {
          var user, image;
          return regeneratorRuntime.async(function callee$1$0$(context$2$0) {
            while (1) switch (context$2$0.prev = context$2$0.next) {
              case 0:
                user = this.get("user");
                context$2$0.next = 3;
                return regeneratorRuntime.awrap(this.get("userService").saveImage(this.get("newUploadedImage")));

              case 3:
                image = context$2$0.sent;

                user.set("image", image);
                user.set("mobile", this.getUserMobile());
                if (this.get("saveUser")) {
                  user.save();
                }

              case 7:
              case "end":
                return context$2$0.stop();
            }
          }, null, _this);
        }, _stockMixinsAsync.ERROR_STRATEGIES.MODAL);
      },

      deleteImage: function deleteImage() {
        var _this2 = this;

        this.runTask(function callee$1$0() {
          var user, data;
          return regeneratorRuntime.async(function callee$1$0$(context$2$0) {
            while (1) switch (context$2$0.prev = context$2$0.next) {
              case 0:
                user = this.get("user");

                if (!user.get("image")) {
                  context$2$0.next = 4;
                  break;
                }

                context$2$0.next = 4;
                return regeneratorRuntime.awrap(this.get("userService").deleteImage(user.get("image")));

              case 4:
                context$2$0.next = 6;
                return regeneratorRuntime.awrap(this.get("userService").editUser(user.get("id"), {
                  user: { image_id: null }
                }));

              case 6:
                data = context$2$0.sent;

                this.get("store").pushPayload(data);

              case 8:
              case "end":
                return context$2$0.stop();
            }
          }, null, _this2);
        }, _stockMixinsAsync.ERROR_STRATEGIES.MODAL);
      }
    }
  });
});