define("stock/components/item-filter-buttons", ["exports", "ember", "lodash"], function (exports, _ember, _lodash) {
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  exports["default"] = _ember["default"].Component.extend({
    i18n: _ember["default"].inject.service(),
    filterService: _ember["default"].inject.service(),

    itemStateFilters: _ember["default"].computed.alias("filterService.itemStateFilterArray"),

    hasStateFilters: _ember["default"].computed("itemStateFilters", function () {
      return this.get("itemStateFilters").length > 0;
    }),

    itemLocationFilters: _ember["default"].computed.alias("filterService.itemLocationFilters"),
    hasLocationFilters: _ember["default"].computed("itemLocationFilters", function () {
      return !!this.get("itemLocationFilters");
    }),

    actions: {
      redirectTofilters: function redirectTofilters(param) {
        var queryParams = _defineProperty({}, param, true);
        this.get("router").transitionTo("item_filters", { queryParams: queryParams });
      },

      clearStateFilters: function clearStateFilters() {
        this.get("filterService").clearItemStateFilters();
      },

      clearLocationFilters: function clearLocationFilters() {
        this.get("filterService").clearItemLocationFilters();
      }
    }
  });
});