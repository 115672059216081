define("stock/components/composable-drop-down", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    displayDropDownItems: false,

    actions: {
      toggleDisplayItems: function toggleDisplayItems() {
        this.toggleProperty("displayDropDownItems");
      },

      selectOption: function selectOption(item) {
        this.get("setSelectedValue")(item);
        this.set("displayDropDownItems", false);
      },

      closeDropDown: function closeDropDown() {
        this.set("displayDropDownItems", false);
      }
    }
  });
});