define("stock/services/local-storage", ["exports", "ember", "stock/config/environment"], function (exports, _ember, _stockConfigEnvironment) {

  var LS = window.localStorage;
  var ENV = _stockConfigEnvironment["default"].environment;
  var isProd = /^prod/.test(ENV);

  // --- Helpers

  function serialize(data) {
    return JSON.stringify({ data: data });
  }

  function deserialize(str) {
    try {
      var obj = JSON.parse(str);
      return obj && obj.data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  function prefixKey(key) {
    if (isProd) {
      return key;
    }
    return ENV + "/" + key;
  }

  // --- Service

  exports["default"] = _ember["default"].Service.extend({
    read: function read(key, defaultValue) {
      var fullKey = prefixKey(key);
      return deserialize(LS.getItem(fullKey)) || defaultValue;
    },

    write: function write(key, val) {
      var fullKey = prefixKey(key);
      LS.setItem(fullKey, serialize(val));
      return val;
    },

    remove: function remove(key) {
      LS.removeItem(prefixKey(key));
    }
  });
});