define("stock/routes/stocktakes/index", ["exports", "stock/routes/authorize", "ember"], function (exports, _stockRoutesAuthorize, _ember) {
  exports["default"] = _stockRoutesAuthorize["default"].extend({
    model: function model() {
      return _ember["default"].RSVP.hash({
        stocktakes: this.store.query("stocktake", {
          include_revisions: false,
          state: "open"
        })
      });
    },

    setupController: function setupController(controller, model) {
      controller.set("model", model);
      controller.on();
    },

    resetController: function resetController(controller) {
      controller.off();
    }
  });
});