define("stock/models/stocktake_revision", ["exports", "ember-data/model", "ember-data/attr", "lodash", "ember-data/relationships"], function (exports, _emberDataModel, _emberDataAttr, _lodash, _emberDataRelationships) {
  exports["default"] = _emberDataModel["default"].extend({
    dirty: (0, _emberDataAttr["default"])("boolean"),
    state: (0, _emberDataAttr["default"])("string"),
    itemId: (0, _emberDataAttr["default"])("number"),
    item: (0, _emberDataRelationships.belongsTo)("item", { async: true }),
    stocktake: (0, _emberDataRelationships.belongsTo)("stocktake", { async: false }),
    updatedAt: (0, _emberDataAttr["default"])("date"),
    createdAt: (0, _emberDataAttr["default"])("date"),
    quantity: (0, _emberDataAttr["default"])("number"),
    processedDelta: (0, _emberDataAttr["default"])("number"),
    warning: (0, _emberDataAttr["default"])("string"),
    countedByIds: (0, _emberDataAttr["default"])(),

    expectedQuantity: Ember.computed("stocktake.location", "item.packagesLocations.[]", "item.packagesLocations.@each.quantity", function () {
      var pkgLoc = this.get("item.packagesLocations").findBy("location", this.get("stocktake.location"));

      return pkgLoc ? pkgLoc.get("quantity") : 0;
    }),

    isProcessed: Ember.computed.equal("state", "processed"),
    isCancelled: Ember.computed.equal("state", "cancelled"),

    computedDelta: Ember.computed("quantity", "expectedQuantity", function () {
      return this.get("quantity") - this.get("expectedQuantity");
    }),

    diff: Ember.computed("state", "processedDelta", "computedDelta", function () {
      switch (this.get("state")) {
        case "cancelled":
          return 0;
        case "processed":
          return this.get("processedDelta");
        default:
          return this.get("computedDelta");
      }
    }),

    diffAbs: Ember.computed("diff", function () {
      return Math.abs(this.get("diff"));
    }),

    isGain: Ember.computed("diff", function () {
      return this.get("diff") > 0;
    }),

    isLoss: Ember.computed("diff", function () {
      return this.get("diff") < 0;
    }),

    hasVariance: Ember.computed("diff", function () {
      return this.get("diff") !== 0;
    }),

    isValid: Ember.computed("quantity", function () {
      var qty = this.get("quantity");
      return _lodash["default"].isNumber(qty) && qty >= 0;
    }),

    editable: Ember.computed.equal("state", "pending"),
    completed: Ember.computed.not("editable")
  });
});