define("stock/models/message", ["exports", "ember", "ember-data"], function (exports, _ember, _emberData) {
  var attr = _emberData["default"].attr,
      belongsTo = _emberData["default"].belongsTo;

  exports["default"] = _emberData["default"].Model.extend({
    session: _ember["default"].inject.service(),
    body: attr("string"),
    isPrivate: attr("boolean"),
    createdAt: attr("date"),
    updatedAt: attr("date"),
    senderId: attr("number"),
    state: attr("string", {
      defaultValue: "read"
    }),

    sender: belongsTo("user", {
      async: false
    }),
    designation: belongsTo("designation", { async: false }),
    item: belongsTo("item", {
      async: false
    }),

    designationId: attr(),
    itemId: attr(),
    lookup: attr("string"),
    messageableType: attr("string"),
    messageableId: attr("string"),
    unreadCount: attr("string"),

    parsedBody: _ember["default"].computed("body", function () {
      var body = this.get("body");
      var lookup = this.get("lookup");
      if (!lookup) {
        return body;
      } else {
        lookup = JSON.parse(lookup);
        Object.keys(lookup).forEach(function (key) {
          body = body.replace(new RegExp("\\[:" + key + "\\]", "g"), "<span class='mentioned-message-display'>@" + lookup[key].display_name + "</span>");
        });
        return body;
      }
    }),

    plainBody: _ember["default"].computed("body", function () {
      var body = this.get("body");
      var lookup = this.get("lookup");

      if (Object.keys(lookup).length === 0) {
        return body;
      } else {
        lookup = JSON.parse(lookup);
        Object.keys(lookup).forEach(function (key) {
          body = body.replace(new RegExp("\\[:" + key + "\\]", "g"), lookup[key].display_name);
        });
      }
      return body;
    }),

    myMessage: _ember["default"].computed("sender", function () {
      return this.get("sender.id") === this.get("session.currentUser.id");
    }),

    isMessage: _ember["default"].computed("this", function () {
      return true;
    }),

    createdDate: _ember["default"].computed("createdAt", function () {
      return new Date(this.get("createdAt")).toDateString();
    }),

    isRead: _ember["default"].computed.equal("state", "read"),
    isUnread: _ember["default"].computed.equal("state", "unread")
  });
});