define("stock/components/focus-textfield", ["exports", "ember", "stock/utils/ember"], function (exports, _ember, _stockUtilsEmber) {
  exports["default"] = _ember["default"].TextField.extend({
    tagName: "input",
    type: "text",
    attributeBindings: ["name", "id", "value", "placeholder"],
    cordova: _ember["default"].inject.service(),
    store: _ember["default"].inject.service(),
    hasRecentDesignations: true,
    autofocus: true,
    autofocusOnEmptyValue: true,

    valueAutofocusListener: _ember["default"].observer("value", function () {
      if (this.get("autofocus") && this.get("autofocusOnEmptyValue") && this.get("value").length === 0) {
        this.applyFocus();
      }
    }),

    autofocusSettingListener: (0, _stockUtilsEmber.callbackObserver)("autofocus", [[true, "applyFocus"]]),

    hasFixedInputHeader: _ember["default"].computed(function () {
      return this.get("cordova").isIOS() && _ember["default"].$(".fixed_search_header").length > 0;
    }),

    applyFocus: function applyFocus() {
      this.$().trigger("focus");
    },

    scrollToStart: function scrollToStart() {
      _ember["default"].$(".fixed_search_header").addClass("absolute");
      _ember["default"].$(".footer").addClass("absolute_footer");
      _ember["default"].$(".search").addClass("no-padding");

      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },

    focusOut: function focusOut() {
      if (this.get("hasFixedInputHeader")) {
        _ember["default"].$(".fixed_search_header").removeClass("absolute");
        _ember["default"].$(".footer").removeClass("absolute_footer");
        _ember["default"].$(".search").removeClass("no-padding");
      }
    },

    didInsertElement: function didInsertElement() {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      if (this.get("autofocus")) {
        this.applyFocus();
      }
      if (this.get("hasFixedInputHeader")) {
        this.element.addEventListener("touchstart", this.scrollToStart);
      }
    },

    willDestroyElement: function willDestroyElement() {
      if (this.get("hasFixedInputHeader")) {
        this.element.addEventListener("touchstart", this.scrollToStart);
      }
    }
  });
});