define("stock/services/designation-service", ["exports", "ember", "stock/services/api-base-service", "stock/mixins/navigation_aware", "lodash", "stock/mixins/async", "stock/constants/states"], function (exports, _ember, _stockServicesApiBaseService, _stockMixinsNavigation_aware, _lodash, _stockMixinsAsync, _stockConstantsStates) {

  function ID(modelOrId) {
    if (modelOrId.get) {
      return modelOrId.get("id");
    }
    return modelOrId;
  }

  /**
   * Designation Service
   *
   * @module Services/DesignationService
   * @augments Services/ApiBaseService
   * @description Control of designation of packages to orders
   * <br> Notes:
   * <br> * Stock currently use the 'designation' model, which in reality is
   * <br> the 'order' model. We're trying to move away from this model name.
   * <br>
   * <br> * 'Designation' here refers to the assignment of a package to an order
   * <br> which mostly involves operations on the orders_package join table
   *
   */
  exports["default"] = _stockServicesApiBaseService["default"].extend(_stockMixinsNavigation_aware["default"], _stockMixinsAsync["default"], {
    store: _ember["default"].inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.set("openOrderSearch", false);
    },

    editableQty: _ember["default"].computed.alias("settings.allowPartialOperations"),

    /**
     * Runs a remote action on the specified orders_package
     *
     * @param {String|Model} ordersPackage
     * @param {Stirng} actionName
     * @param {Object} [params={}]
     * @returns {Promise<Model>}
     */
    execAction: function execAction(ordersPackage, actionName) {
      var params = arguments.length <= 2 || arguments[2] === undefined ? {} : arguments[2];
      var id, url, data;
      return regeneratorRuntime.async(function execAction$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            id = ID(ordersPackage);
            url = "/orders_packages/" + id + "/actions/" + actionName;
            context$1$0.next = 4;
            return regeneratorRuntime.awrap(this.PUT(url, params));

          case 4:
            data = context$1$0.sent;

            this.get("store").pushPayload(data);
            return context$1$0.abrupt("return", this.get("store").peekRecord("orders_package", id));

          case 7:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    /**
     * Cancels an orders_package
     * Shorthand for execAction(..., 'cancel')
     *
     * @param {String|Model} ordersPackage
     * @returns {Promise<Model>}
     */
    cancel: function cancel(ordersPackage) {
      return this.execAction(ordersPackage, "cancel");
    },

    /**
     * Dispatches an orders_package
     * Shorthand for execAction(..., 'dispatch')
     *
     * @param {String|Model} ordersPackage
     * @param {object} params
     * @param {string|Model} params.from_location the location to dispatch from
     * @returns {Promise<Model>}
     */
    dispatch: function dispatch(ordersPackage, _ref) {
      var from_location = _ref.from_location;
      var quantity = _ref.quantity;

      return this.execAction(ordersPackage, "dispatch", {
        location_id: ID(from_location),
        quantity: Number(quantity)
      });
    },

    /**
     * Undispatches an orders_package
     * Shorthand for execAction(..., 'undispatch')
     *
     * @param {String|Model} ordersPackage
     * @returns {Promise<Model>}
     */
    undispatch: function undispatch(ordersPackage) {
      return this.execAction(ordersPackage, "undispatch");
    },

    /**
     * Redesignates the orders_package to a different order
     * Shorthand for execAction(..., 'redesignate')
     *
     * @param {String|Model} ordersPackage The orders_package to update (or its ID)
     * @param {String|Model} newOrder The new order to designate to (or its ID)
     * @returns {Promise<Model>}
     */
    redesignate: function redesignate(ordersPackage) {
      return this.execAction(ordersPackage, "redesignate", {
        order_id: ordersPackage.get("orderId")
      });
    },

    /**
     * Modifies the quantity of packages needed for the order
     * Shorthand for execAction(..., 'edit_quantity')
     *
     * @param {String|Model} ordersPackage The orders_package to update (or its ID)
     * @param {Number} quantity The new quantity
     * @returns {Promise<Model>}
     */
    editQuantity: function editQuantity(ordersPackage, quantity) {
      return this.execAction(ordersPackage, "undispatch", { quantity: quantity });
    },

    /**
     * Designate a quantity of a package to an order
     *
     * @param {String|Model} pkg The package to designate
     * @param {String|Model} order The order to designate to
     * @param {Number} quantity The quantity to designate
     * @returns {Promise<Model>}
     */
    designate: function designate(pkg, _ref2) {
      var order = _ref2.order;
      var quantity = _ref2.quantity;
      var shippingNumber = _ref2.shippingNumber;
      var id, url, data;
      return regeneratorRuntime.async(function designate$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            id = ID(pkg);
            url = "/packages/" + id + "/designate";
            context$1$0.next = 4;
            return regeneratorRuntime.awrap(this.PUT(url, {
              order_id: ID(order),
              quantity: quantity,
              shipping_number: shippingNumber
            }));

          case 4:
            data = context$1$0.sent;

            this.get("store").pushPayload(data);
            return context$1$0.abrupt("return", this.get("store").peekRecord("package", id));

          case 7:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    /**
     * Triggers the order selection popup, and resolves the promise
     * once an order has been selected.
     *
     * null is returned if the user closes the UI
     *
     * @returns {Promise<Model>}
     */
    userPickOrder: function userPickOrder() {
      var _this = this;

      var filters = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

      var deferred = _ember["default"].RSVP.defer();

      this.set("orderSearchProps", filters);
      this.set("openOrderSearch", true);
      this.set("onOrderSelected", function (order) {
        _this.set("onOrderSelected", _lodash["default"].noop);
        _this.set("openOrderSearch", false);
        deferred.resolve(order || null);
      });

      return deferred.promise;
    },

    /**
     * Updates Beneficiary
     *
     * @param {String|Model} beneficiary
     * @param {object} params
     * @returns {Promise<Model>}
     */
    updateBeneficiary: function updateBeneficiary(beneficiary, params) {
      var id = ID(beneficiary);
      var url = "/beneficiaries/" + id;
      return this.PUT(url, params);
    },

    resolveOrder: function resolveOrder(order) {
      return regeneratorRuntime.async(function resolveOrder$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            return context$1$0.abrupt("return", order || this.userPickOrder({
              state: _stockConstantsStates.ACTIVE_ORDER_STATES.join(",")
            }));

          case 1:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    beginDesignation: function beginDesignation(options) {
      var pkg = options.pkg,
          order = options.order;

      this.set("allowItemChange", options.allowItemChange);
      this.set("allowOrderChange", options.allowOrderChange);

      if (pkg.get("availableQuantity") > 0 || options.isEditOperation) {
        this.triggerDesignateAction(pkg, order);
      } else {
        this.set("designationTargetPackage", pkg);
        this.set("displayDesignateForm", true);
        this.set("showItemAvailability", true);
      }
    },

    triggerDesignateAction: function triggerDesignateAction(pkg, order) {
      var _this2 = this;

      this.resolveOrder(order).then(function (target) {
        if (target) {
          _this2.set("designationTargetPackage", pkg);
          _this2.set("designationTargetOrder", target);
          _this2.computeDesignationQuantities();
          _this2.set("displayDesignateForm", true);
          _this2.set("readyToDesignate", true);
        }
      });
    },

    triggerOrderChange: function triggerOrderChange(pkg, order) {
      if (this.get("allowOrderChange")) {
        this.triggerDesignateAction(pkg, order);
      }
    },

    triggerItemChange: function triggerItemChange() {
      if (this.get("allowItemChange")) {
        this.set("readyToDesignate", false);
      }
    },

    computeDesignationQuantities: function computeDesignationQuantities() {
      var pkg = this.get("designationTargetPackage");
      var order = this.get("designationTargetOrder");

      if (!order || !pkg) {
        this.set("designatableQuantity", 0);
        this.set("designationQty", 0);
        return;
      }

      var maxQuantity = pkg.get("availableQuantity") + this.alreadyDesignatedQuantity(pkg, order);

      this.set("designatableQuantity", maxQuantity);
      if (!this.get("designationQty")) {
        this.set("designationQty", maxQuantity);
      }
    },

    alreadyDesignatedQuantity: function alreadyDesignatedQuantity(pkg, order) {
      var ordPkg = pkg.get("ordersPackages").find(function (op) {
        return parseInt(op.get("orderId")) === parseInt(order.get("id"));
      });

      if (ordPkg && ordPkg.get("isCancelled")) {
        this.set("designationQty", ordPkg.get("quantity")); //setting the quantity for redesignation action
      }
      return ordPkg && ordPkg.get("isDesignated") ? ordPkg.get("quantity") : 0;
    },

    resetOrderDesignation: function resetOrderDesignation() {
      this.set("readyToDesignate", false);
      this.set("designatableQuantity", 0);
      this.set("designationTargetPackage", null);
      this.set("designationTargetOrder", null);
      this.set("designationQty", 0);
      this.set("shippingNumber", "");
    },

    completeDesignation: function completeDesignation() {
      var _this3 = this;

      if (!this.get("readyToDesignate")) return;

      this.runTask(function () {
        var pkg = _this3.get("designationTargetPackage");
        var order = _this3.get("designationTargetOrder");
        var quantity = _this3.get("designationQty");
        var shippingNumber = _this3.get("shippingNumber");

        return _this3.designate(pkg, {
          order: order,
          quantity: quantity,
          shippingNumber: shippingNumber
        });
      }, _stockMixinsAsync.ERROR_STRATEGIES.MODAL)["finally"](function () {
        _this3.resetOrderDesignation();
      });
    },

    onNavigation: function onNavigation() {
      this.getWithDefault("onOrderSelected", _lodash["default"].noop)(null);
      this.set("readyToDesignate", false);
      this.set("showItemAvailability", false);
    }
  });
});