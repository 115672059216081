define("stock/services/barcode-service", ["exports", "ember", "lodash", "stock/config/environment", "stock/utils/barcode-ui", "stock/utils/cache", "stock/utils/async", "stock/utils/helpers"], function (exports, _ember, _lodash, _stockConfigEnvironment, _stockUtilsBarcodeUi, _stockUtilsCache, _stockUtilsAsync, _stockUtilsHelpers) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  /**
   * @enum {function}
   * @readonly
   * @memberof Services/Barcode
   * @static
   */
  var BARCODE_PARSERS = {
    /** Will try to extract the inventory number from the string */
    INVENTORY: function INVENTORY(code) {
      if (code && _stockUtilsHelpers.url.isUrl(code)) {
        return _stockUtilsHelpers.url.readQueryParam(code, "num") || _lodash["default"].last(_stockUtilsHelpers.url.pathname(code).split("/"));
      }
      return code;
    },

    /** Will return the string as-is */
    RAW: function RAW(code) {
      return code;
    }
  };

  exports.BARCODE_PARSERS = BARCODE_PARSERS;
  /**
   * @typedef {Object} ScanSession
   * @property {function} stop - Stops the scanner
   */

  var SCAN_DELAY = 1500;

  /**
   * Barcode Service
   *
   * @module Services/Barcode
   * @description Interface to interact with the Scandit barcode plugin
   *
   */
  exports["default"] = _ember["default"].Service.extend({
    messageBox: _ember["default"].inject.service(),
    packageService: _ember["default"].inject.service(),
    i18n: _ember["default"].inject.service(),
    isMobileApp: _stockConfigEnvironment["default"].cordova.enabled,
    paramName: null,

    // ----------------------
    // Private helpers
    // ----------------------

    __activate: function __activate() {
      var waitTime, elapsed;
      return regeneratorRuntime.async(function __activate$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            if (!this.__lastActivation) {
              context$1$0.next = 6;
              break;
            }

            waitTime = 4000;
            elapsed = Date.now() - this.__lastActivation;

            if (!(elapsed < waitTime)) {
              context$1$0.next = 6;
              break;
            }

            context$1$0.next = 6;
            return regeneratorRuntime.awrap((0, _stockUtilsAsync.wait)(waitTime - elapsed));

          case 6:

            this.__lastActivation = Date.now();

          case 7:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    __getContext: (0, _stockUtilsCache.cached)(function () {
      var licenseKey = window.device.platform == "Android" ? _stockConfigEnvironment["default"].APP.SCANDIT_LICENSE_KEY_ANDROID : _stockConfigEnvironment["default"].APP.SCANDIT_LICENSE_KEY_IOS;
      this.context = Scandit.DataCaptureContext.forLicenseKey(licenseKey);
      this.context.setFrameSource(this.__getCamera());
      return this.context;
    }),

    __getCamera: (0, _stockUtilsCache.cached)(function () {
      var cameraSettings = Scandit.BarcodeCapture.recommendedCameraSettings;
      var camera = Scandit.Camera["default"];

      if (camera) {
        camera.applySettings(cameraSettings);
      }

      return camera;
    }),

    __getCapture: (0, _stockUtilsCache.cached)(function () {
      var context = this.__getContext();
      var settings = new Scandit.BarcodeCaptureSettings();

      settings.enableSymbologies([
      /* Scandit.Symbology.Code128, */
      Scandit.Symbology.QR]);

      return Scandit.BarcodeCapture.forContext(context, settings);
    }),

    __cameraOn: function __cameraOn() {
      this.__getCamera().switchToDesiredState(Scandit.FrameSourceState.On);
    },

    __cameraOff: function __cameraOff() {
      this.__getCamera().switchToDesiredState(Scandit.FrameSourceState.Off);
    },

    __enableScan: function __enableScan() {
      this.__getCapture().isEnabled = true;
    },

    __disableScan: function __disableScan() {
      this.__getCapture().isEnabled = false;
    },

    /**
     * Starts a scan session
     *
     * @param {object} cfg
     * @param {HTMLElement} cfg.previewElement
     * @param {function} cfg.onStop
     * @param {function} cfg.onCapture
     * @returns {Promise<ScanSession>}
     */
    __newScanSession: function __newScanSession(_ref) {
      var previewElement = _ref.previewElement;
      var onCapture = _ref.onCapture;
      var onStop = _ref.onStop;
      var capture, listener, view, overlay, stop;
      return regeneratorRuntime.async(function __newScanSession$(context$1$0) {
        var _this = this;

        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            capture = this.__getCapture();
            listener = {
              didScan: function didScan(barcodeCapture, session) {
                _this.__disableScan();
                onCapture(barcodeCapture, session);
                _ember["default"].run.debounce(_this, _this.__enableScan, SCAN_DELAY);
              }
            };

            capture.addListener(listener);

            // --- Connect to the UI

            view = Scandit.DataCaptureView.forContext(this.__getContext());
            overlay = (0, _stockUtilsBarcodeUi.buildCameraView)(previewElement);
            context$1$0.next = 7;
            return regeneratorRuntime.awrap(this.__activate());

          case 7:

            view.connectToElement(overlay.element);

            Scandit.BarcodeCaptureOverlay.withBarcodeCaptureForView(capture, view);

            this.__cameraOn();
            this.__enableScan();

            // --- Create stop callback

            stop = (0, _stockUtilsCache.cached)(function () {
              document.removeEventListener("pause", stop);
              _this.__cameraOff();
              overlay.destroy();
              capture.removeListener(listener);
              _ember["default"].run.debounce(_this, _this.__disableScan, SCAN_DELAY + 100);
              _this.turnFlashlightOff();
              onStop();
            });

            document.addEventListener("pause", stop);

            overlay.addButton("Stop", function () {
              stop();
              overlay.destroy();
              _this.turnFlashlightOff();
            });

            overlay.addButton("Light", function () {
              _this.toggleFlashlight();
            });

            return context$1$0.abrupt("return", { stop: stop });

          case 16:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    // ----------------------
    // Service API
    // ----------------------

    /**
     * Turns the flashlight on (if available)
     *
     * @returns {Promise<undefined>}
     */
    turnFlashlightOn: function turnFlashlightOn() {
      if (this.enabled()) {
        this.__getCamera().desiredTorchState = "on";
        this.set("flashlightActive", true);
      }
    },

    /**
     * Turns the flashlight off (if available)
     *
     * @returns {Promise<undefined>}
     */
    turnFlashlightOff: function turnFlashlightOff() {
      if (this.enabled()) {
        this.__getCamera().desiredTorchState = "off";
        this.set("flashlightActive", false);
      }
    },

    /**
     * Toggles the flashlight status
     *
     * @returns {Promise<undefined>}
     */
    toggleFlashlight: function toggleFlashlight() {
      return this.get("flashlightActive") ? this.turnFlashlightOff() : this.turnFlashlightOn();
    },

    /**
     * Returns true if scanning is possible
     *
     * @returns {boolean}
     */
    enabled: function enabled() {
      return this.get("isMobileApp") && !!window.Scandit && this.__getCamera();
    },

    /**
     * Tries to get permission to use the camera
     *
     * @returns {Promise<boolean>} whether the user has granted permission to scan
     */
    requestPermission: function requestPermission() {
      var permissions = _lodash["default"].get(window, "cordova.plugins.permissions");

      if (!this.enabled() || !permissions) {
        return _ember["default"].RSVP.resolve(false);
      }

      var deferred = _ember["default"].RSVP.defer();

      var permissionError = function permissionError(e) {
        deferred.reject(e); // Something went wrong
      };

      var permissionSuccess = function permissionSuccess(_ref2) {
        var hasPermission = _ref2.hasPermission;

        deferred.resolve(hasPermission); // We got an answer from the user
      };

      permissions.hasPermission(permissions.CAMERA, function (status) {
        if (status.hasPermission) {
          deferred.resolve(true);
        } else {
          permissions.requestPermission(permissions.CAMERA, permissionSuccess, permissionError);
        }
      });

      return deferred.promise;
    },

    /**
     * Tries to scan a bar code and returns the scanned text
     *
     * @returns {Promise<ScanSession|null>}
     */
    scanMultiple: function scanMultiple() {
      var opts = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

      var allowed, _opts$previewElement, previewElement, _opts$onBarcode, onBarcode, _opts$onStop, onStop, _opts$parser, parser, scanner;

      return regeneratorRuntime.async(function scanMultiple$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.t0 = this.enabled();

            if (!context$1$0.t0) {
              context$1$0.next = 5;
              break;
            }

            context$1$0.next = 4;
            return regeneratorRuntime.awrap(this.requestPermission());

          case 4:
            context$1$0.t0 = context$1$0.sent;

          case 5:
            allowed = context$1$0.t0;
            _opts$previewElement = opts.previewElement;
            previewElement = _opts$previewElement === undefined ? null : _opts$previewElement;
            _opts$onBarcode = opts.onBarcode;
            onBarcode = _opts$onBarcode === undefined ? _lodash["default"].noop : _opts$onBarcode;
            _opts$onStop = opts.onStop;
            onStop = _opts$onStop === undefined ? _lodash["default"].noop : _opts$onStop;
            _opts$parser = opts.parser;
            parser = _opts$parser === undefined ? BARCODE_PARSERS.INVENTORY : _opts$parser;

            if (allowed) {
              context$1$0.next = 16;
              break;
            }

            return context$1$0.abrupt("return", null);

          case 16:
            context$1$0.next = 18;
            return regeneratorRuntime.awrap(this.__newScanSession({
              previewElement: previewElement,
              onStop: onStop,
              onCapture: function onCapture(barcodeCapture, session) {
                var newlyRecognizedBarcodes = session.newlyRecognizedBarcodes;

                _lodash["default"].flatten([newlyRecognizedBarcodes]).map(function (bc) {
                  return bc.data;
                }).map(parser).forEach(onBarcode);
              }
            }));

          case 18:
            scanner = context$1$0.sent;
            return context$1$0.abrupt("return", scanner);

          case 20:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    /**
     * Returns a promise that resolves once one code has been read
     *
     * @param {object} [opts={}]
     * @param {HTMLElement} [opts.htmlElement]
     * @returns {Promise<string|null>}
     */
    scanOne: function scanOne() {
      var opts = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];
      var deferred, scanner;
      return regeneratorRuntime.async(function scanOne$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            deferred = _ember["default"].RSVP.defer();
            context$1$0.next = 3;
            return regeneratorRuntime.awrap(this.scanMultiple(_extends({}, opts, {
              onBarcode: function onBarcode(code) {
                deferred.resolve(code);
                scanner.stop();
              },
              onStop: function onStop() {
                deferred.resolve("");
              }
            })));

          case 3:
            scanner = context$1$0.sent;

            if (!scanner) deferred.resolve(null);

            return context$1$0.abrupt("return", deferred.promise);

          case 6:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    /**
     * Tries to scan a bar code and returns the package associated with the inventory number scanned
     *
     * @returns {Promise<Package>}
     */
    scanPackage: function scanPackage() {
      var opts = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

      var _opts$previewElement2, previewElement, inventoryNumber;

      return regeneratorRuntime.async(function scanPackage$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            _opts$previewElement2 = opts.previewElement;
            previewElement = _opts$previewElement2 === undefined ? null : _opts$previewElement2;
            context$1$0.next = 4;
            return regeneratorRuntime.awrap(this.scanOne({ previewElement: previewElement }));

          case 4:
            inventoryNumber = context$1$0.sent;

            if (inventoryNumber) {
              context$1$0.next = 7;
              break;
            }

            return context$1$0.abrupt("return", null);

          case 7:
            return context$1$0.abrupt("return", this.get("packageService").findPackageByInventoryNumber(inventoryNumber));

          case 8:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    }
  });
});

// Scandit has issues when turned on and off very fast.
// We ensure a few seconds pass between each activation