define("stock/helpers/js-add", ["exports", "ember"], function (exports, _ember) {

  // Date Format used in App:
  // "12 Feb '16" => "DD MMM 'YY"

  exports["default"] = _ember["default"].Helper.helper(function (leftside, rightside) {
    if (rightside) {
      return leftside[0] + leftside[1];
    }
  });
});