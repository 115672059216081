define("stock/controllers/orders/contact_summary", ["exports", "ember", "stock/controllers/orders/detail", "stock/mixins/async", "stock/mixins/search_option", "stock/constants/state-events", "stock/constants/regex"], function (exports, _ember, _stockControllersOrdersDetail, _stockMixinsAsync, _stockMixinsSearch_option, _stockConstantsStateEvents, _stockConstantsRegex) {
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  exports["default"] = _stockControllersOrdersDetail["default"].extend(_stockMixinsSearch_option["default"], _stockMixinsAsync["default"], {
    orderService: _ember["default"].inject.service(),
    setOrgUserApproval: function setOrgUserApproval(status) {
      var _this = this;

      this.runTask(function () {
        _this.get("organisationsUser").set("status", status);
        return _this.get("organisationsUser").save();
      });
    },

    isValidCode: _ember["default"].computed("codeWithoutPrefix", function () {
      var codeRegEx = new RegExp(_stockConstantsRegex.regex.SHIPMENT_ORDER_REGEX);
      return this.get("codeWithoutPrefix") && codeRegEx.test(this.get("codeWithoutPrefix"));
    }),

    selectedCountry: _ember["default"].computed("order", function () {
      return {
        id: this.get("order.countryId"),
        nameEn: this.get("order.countryName")
      };
    }),

    formatOrderCode: function formatOrderCode(type, code) {
      return type == _stockConstantsStateEvents.INTERNATIONAL_ORDERS.CARRYOUT ? "C" + code : "S" + code;
    },

    actions: {
      updateInternationalOrderDetails: function updateInternationalOrderDetails(e) {
        var value = e.target.value.trim();
        var changedAttributes = this.get("order").changedAttributes();
        delete changedAttributes["shipmentDate"];
        if (Object.keys(changedAttributes).length === 0) {
          this.set(e.target.id + "ValidationError", false);
          return;
        }
        var isValid = undefined;
        switch (e.target.id) {
          case "people_helped":
            isValid = Boolean(value);
            break;
          case "description":
            isValid = Boolean(value);
            break;
          case "code":
            isValid = Boolean(this.get("isValidCode"));
            break;
        }

        if (isValid) {
          value = e.target.id == "code" ? this.formatOrderCode(this.get("order.detailType"), value) : value;
          this.send("updateOrderDetails", e.target.id, value);
        } else {
          this.get("order").rollbackAttributes();
          _ember["default"].$("#" + e.target.id).focus();
          this.set(e.target.id + "ValidationError", true);
        }
      },

      onSearch: function onSearch(field, searchText) {
        this.onSearchCountry(field, searchText);
      },

      updateOrderCode: function updateOrderCode(e) {
        var value = this.formatOrderCode(this.get("order.detailType"), e.target.value.trim());
        this.set("order.code", value);
        this.send("updateInternationalOrderDetails", e);
      },

      updatePeopleHelped: function updatePeopleHelped(e) {
        var value = parseInt(e.target.value.trim());
        this.set("order.peopleHelped", value);
        this.send("updateInternationalOrderDetails", e);
      },

      updateShipmentDate: function updateShipmentDate(date) {
        this.send("updateOrderDetails", "shipment_date", date);
      },

      updateCountry: function updateCountry(value) {
        var countryName = this.get("store").peekRecord("country", value.id).get("nameEn");
        this.set("selectedCountry", { id: value.id, nameEn: countryName });
        this.send("updateOrderDetails", "country_id", value.id);
      },

      updateOrderDetails: function updateOrderDetails(field, value) {
        return regeneratorRuntime.async(function updateOrderDetails$(context$1$0) {
          var _this2 = this;

          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              this.set(field + "ValidationError", false);

              this.runTask(function callee$1$0() {
                var params;
                return regeneratorRuntime.async(function callee$1$0$(context$2$0) {
                  while (1) switch (context$2$0.prev = context$2$0.next) {
                    case 0:
                      context$2$0.prev = 0;
                      params = _defineProperty({}, field, value);
                      context$2$0.next = 4;
                      return regeneratorRuntime.awrap(this.get("orderService").updateShipmentOrCarryoutOrder(this.get("order"), { order: params }));

                    case 4:
                      context$2$0.next = 11;
                      break;

                    case 6:
                      context$2$0.prev = 6;
                      context$2$0.t0 = context$2$0["catch"](0);

                      this.get("order").rollbackAttributes();
                      this.set("codeWithoutPrefix", this.get("order.code").slice(1));
                      throw context$2$0.t0;

                    case 11:
                    case "end":
                      return context$2$0.stop();
                  }
                }, null, _this2, [[0, 6]]);
              }, _stockMixinsAsync.ERROR_STRATEGIES.MODAL);

            case 2:
            case "end":
              return context$1$0.stop();
          }
        }, null, this);
      },

      approveOrganisationsUser: function approveOrganisationsUser() {
        this.setOrgUserApproval("approved");
      },
      denyOrganisationsUser: function denyOrganisationsUser() {
        this.setOrgUserApproval("denied");
      }
    }
  });
});