define("stock/serializers/application", ["exports", "active-model-adapter", "lodash", "stock/utils/helpers"], function (exports, _activeModelAdapter, _lodash, _stockUtilsHelpers) {

  var ALIASES = {
    // @TODO: rename the item model into package and remove those aliases
    "package": "item",
    packages: "items"
  };

  var KEY_MAPPINGS = {
    "package": {
      package_type_id: "code_id"
    },
    stocktake_revision: {
      package_id: "item_id"
    }
  };

  /**
   * Returns the records found in the payload for a specific type
   *
   * @param {object} payload
   * @param {string} modelName
   */
  function readRecords(payload, modelName) {
    return _lodash["default"].flatten([_lodash["default"].get(payload, modelName), _lodash["default"].get(payload, modelName + "s")]).filter(_lodash["default"].identity);
  }

  /**
   * Renames keys of the payload to match the expected model names
   *
   * @param {object} payload
   */
  function normalizeModelNames(payload) {
    _lodash["default"].each(ALIASES, function (alias, original) {
      if (_lodash["default"].has(payload, original)) {
        payload[alias] = payload[original];
      }
    });
  }

  /**
   * Normalizes messages before
   *
   * @param {object} payload
   */
  function normalizeMessages(payload) {
    var messages = readRecords(payload, "message");

    _lodash["default"].each(messages, function (m) {
      if (m.messageable_type == "Order") {
        m.designation_id = m.messageable_id;
      }

      if (m.messageable_type == "Package") {
        m.item_id = m.messageable_id;
      }

      // This is done to handle inconsistent mapping of jsonb datatype
      if (typeof m.lookup === "object") {
        m.lookup = JSON.stringify(m.lookup);
      }
    });
  }

  /**
   * Normalizes Images before
   *
   * @param {object} payload
   */
  function normalizeImages(payload) {
    var images = readRecords(payload, "image");

    _lodash["default"].each(images, function (m) {
      if (m.imageable_type == "Package") {
        m.item_id = m.imageable_id;
      }
    });
  }

  /**
   * Maps record keys, e.g transform package_id into item_id
   *
   * @param {object} payload
   */
  function normalizeKeys(payload) {
    _lodash["default"].each(KEY_MAPPINGS, function (mapping, modelName) {
      var keysToMap = _lodash["default"].keys(mapping);
      var records = readRecords(payload, modelName);

      (0, _stockUtilsHelpers.times)(keysToMap, records, function (key, record) {
        if (_lodash["default"].has(record, key)) {
          record[mapping[key]] = record[key];
          delete record[key];
        }
      });
    });
  }

  function normalize(payload) {
    if (!payload) return;

    normalizeKeys(payload);
    normalizeModelNames(payload);
    normalizeMessages(payload);
    normalizeImages(payload);
  }

  exports["default"] = _activeModelAdapter.ActiveModelSerializer.extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      normalize(payload);
      return this._super.apply(this, arguments);
    },

    pushPayload: function pushPayload(store, payload) {
      normalize(payload);
      return this._super.apply(this, arguments);
    }
  });
});