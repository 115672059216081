define("stock/controllers/organisations/detail", ["exports", "ember", "lodash", "stock/mixins/search_option", "stock/mixins/organisation", "stock/mixins/async"], function (exports, _ember, _lodash, _stockMixinsSearch_option, _stockMixinsOrganisation, _stockMixinsAsync) {
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  exports["default"] = _ember["default"].Controller.extend(_stockMixinsSearch_option["default"], _stockMixinsAsync["default"], _stockMixinsOrganisation["default"], {
    organisationService: _ember["default"].inject.service(),
    organisation: _ember["default"].computed.alias("model"),

    isInValidNameEn: _ember["default"].computed("model.nameEn", function () {
      return !this.get("model.nameEn").trim().length;
    }),

    isInValidCountry: _ember["default"].computed("country", function () {
      return !this.get("country.id");
    }),

    actions: {
      /**
       * Updates the organisation if
       *      nameEn is present
       *      country is present
       *      type is present
       */
      updateOrganisation: function updateOrganisation(e) {
        var isValid = true;
        if (e.target.name === "name_en") {
          isValid = !Boolean(this.get("isInValidNameEn"));
        }

        if (isValid) {
          if (this.get("model").changedAttributes()[_lodash["default"].camelCase(e.target.name)]) {
            this.send("update", e.target.name, e.target.value);
          }
        }
      },

      updateCountry: function updateCountry(value) {
        var countryName = this.get("store").peekRecord("country", value.id).get("nameEn");
        this.set("country", { id: value.id, nameEn: countryName });
        this.send("update", "country_id", value.id);
      },

      updateOrganisationType: function updateOrganisationType(_ref) {
        var id = _ref.id;
        var name = _ref.name;

        this.set("selectedOrganisationType", { id: id, name: name });
        this.send("update", "organisation_type_id", id);
      },

      update: function update(name, value) {
        var _this = this;

        this.runTask(function callee$1$0() {
          var organisation, data;
          return regeneratorRuntime.async(function callee$1$0$(context$2$0) {
            while (1) switch (context$2$0.prev = context$2$0.next) {
              case 0:
                context$2$0.prev = 0;
                organisation = _defineProperty({}, name, value);
                context$2$0.next = 4;
                return regeneratorRuntime.awrap(this.get("organisationService").update(this.get("model.id"), organisation));

              case 4:
                data = context$2$0.sent;

                this.get("store").pushPayload(data);
                context$2$0.next = 12;
                break;

              case 8:
                context$2$0.prev = 8;
                context$2$0.t0 = context$2$0["catch"](0);

                this.get("model").rollbackAttributes();
                throw context$2$0.t0;

              case 12:
              case "end":
                return context$2$0.stop();
            }
          }, null, _this, [[0, 8]]);
        }, _stockMixinsAsync.ERROR_STRATEGIES.MODAL);
      },

      onSearch: function onSearch(field, searchText) {
        this.onSearchCountry(field, searchText);
      }
    }
  });
});