define("stock/components/check-box", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    attributeBindings: ["type", "value"],
    tagName: "input",
    type: "checkbox",
    checked: false,
    selection: [],

    didInsertElement: function didInsertElement() {
      var isSelected = this.get("selection").indexOf(parseInt(this.get("selected"))) >= 0;
      if (isSelected) {
        this.$().prop("checked", true);
        this.sendAction("action", this.get("value"), true);
      }
    },

    _updateElementValue: (function () {
      this.set("checked", this.$().prop("checked"));
      var isChecked = this.get("checked");
      this.sendAction("action", this.get("value"), isChecked);
    }).on("didInsertElement"),

    change: function change(event) {
      this._updateElementValue();
    }
  });
});