define("stock/services/filter-service", ["exports", "ember", "lodash", "stock/utils/time-ranges"], function (exports, _ember, _lodash, _stockUtilsTimeRanges) {

  // --- Helpers

  var PERSISTENT_VAR = function PERSISTENT_VAR(propName, defaultValue) {
    var deserializeMap = arguments.length <= 2 || arguments[2] === undefined ? {} : arguments[2];

    return _ember["default"].computed({
      get: function get() {
        var data = this.get("localStorage").read(propName, defaultValue);
        for (var key in deserializeMap) {
          if (data[key]) {
            data[key] = deserializeMap[key](data[key]);
          }
        }
        return data;
      },
      set: function set(k, value) {
        this.get("localStorage").write(propName, value);
        this.trigger("change");
        return value;
      }
    });
  };

  // @TODO: priority should not be a state filter
  var STATE_FILTERS = {
    PRIORITY: "showPriority",
    SUBMITTED: "submitted",
    PROCESSING: "processing",
    SCHEDULED: "awaiting_dispatch",
    DISPATCHING: "dispatching",
    CLOSED: "closed",
    CANCELLED: "cancelled"
  };

  exports.STATE_FILTERS = STATE_FILTERS;
  var TYPE_FILTERS = {
    APPOINTMENT: "appointment",
    ONLINE_ORDER: "online_orders",
    SHIPMENT: "shipment",
    CARRY_OUT: "carry_out",
    OTHER: "other"
  };

  exports.TYPE_FILTERS = TYPE_FILTERS;
  // --- Service

  /**
   * Filter service
   *
   * @module Services/FilterService
   * @augments ember/Services
   * @description Controls local data related to list filtering
   */
  exports["default"] = _ember["default"].Service.extend(_ember["default"].Evented, {
    localStorage: _ember["default"].inject.service(),

    orderStateFilters: PERSISTENT_VAR("orderStateFilters", []),

    orderTypeFilters: PERSISTENT_VAR("orderTypeFilters", []),

    itemStateFilters: PERSISTENT_VAR("itemStateFilters", []),

    itemLocationFilters: PERSISTENT_VAR("itemLocationFilters", ""),

    isPriority: function isPriority() {
      var filters = this.get("orderStateFilters");
      return filters && filters.indexOf(STATE_FILTERS.PRIORITY) >= 0;
    },

    clearItemLocationFilters: function clearItemLocationFilters() {
      this.set("itemLocationFilters", "");
    },

    clearItemStateFilters: function clearItemStateFilters() {
      this.set("itemStateFilters", []);
    },

    clearOrderStateFilters: function clearOrderStateFilters() {
      this.set("orderStateFilters", []);
    },

    clearOrderTypeFilters: function clearOrderTypeFilters() {
      this.set("orderTypeFilters", []);
    },

    clearOrderTimeFilters: function clearOrderTimeFilters() {
      this.setOrderTimeRange(null);
    },

    clearFilters: function clearFilters() {
      this.clearOrderStateFilters();
      this.clearOrderTypeFilters();
      this.clearOrderTimeFilters();
    },

    itemStateFilterArray: _ember["default"].computed("itemStateFilters", function () {
      var itemFilters = this.get("itemStateFilters");
      return _lodash["default"].chain(itemFilters).map(function (f) {
        return _lodash["default"].isArray(f) ? _lodash["default"].filter(f, ["enabled", true]).map(function (_ref) {
          var state = _ref.state;
          return state;
        }) : f;
      }).compact().flatten().value();
    }),

    hasOrderFilters: _ember["default"].computed("orderStateFilters", "orderTypeFilters", "_orderTimeSettings", function () {
      var timeRange = this.get("orderTimeRange");
      return this.get("orderStateFilters").length > 0 || this.get("orderTypeFilters").length > 0 || timeRange.after || timeRange.before;
    }),

    // --- Order time filters

    _orderTimeSettings: PERSISTENT_VAR("orderTimeSettings", {}, {
      after: function after(raw) {
        return new Date(raw);
      },
      before: function before(raw) {
        return new Date(raw);
      }
    }),

    orderTimeRangePresets: _ember["default"].computed(function () {
      return _stockUtilsTimeRanges["default"];
    }).volatile(),

    /**
     * Saves the time range filter for order search
     *
     * @param {String|Object} range A time range OR a preset name
     * @memberof FilterService
     */
    setOrderTimeRange: function setOrderTimeRange(range) {
      if (_lodash["default"].isString(range)) {
        var preset = range;
        this.set("_orderTimeSettings", { preset: preset });
      } else {
        this.set("_orderTimeSettings", {
          preset: null,
          after: _lodash["default"].get(range, "after"),
          before: _lodash["default"].get(range, "before")
        });
      }

      this.notifyPropertyChange("orderTimeRange");
    },

    /**
     * Returns the order time range filter
     * If a preset was previously selected, it will be re-computed based
     * on the current time.
     *
     * @property {String|Object} range A time range OR a preset name
     */
    orderTimeRange: _ember["default"].computed(function () {
      var _get = this.get("_orderTimeSettings");

      var _get$preset = _get.preset;
      var preset = _get$preset === undefined ? "" : _get$preset;
      var _get$after = _get.after;
      var after = _get$after === undefined ? null : _get$after;
      var _get$before = _get.before;
      var before = _get$before === undefined ? null : _get$before;

      if (preset) {
        return _lodash["default"].extend({ preset: preset }, this.get("orderTimeRangePresets." + preset));
      }
      return { preset: preset, after: after, before: before };
    }).volatile()
  });
});