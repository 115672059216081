define('stock/mixins/move_actions', ['exports', 'ember', 'stock/config/environment', 'stock/mixins/async', 'lodash'], function (exports, _ember, _stockConfigEnvironment, _stockMixinsAsync, _lodash) {

  /**
   * Adds the following properties:
   *
   * - readyToMove (computed, true if other proerties are valid)
   * - moveQty
   * - moveTarget (the package to move)
   * - moveFrom
   * - moveTo
   * - editableQty
   *
   * Adds the following actions:
   *
   * - beginMove(package, [from], [to])
   * - completeMove()
   * - cancelMove()
   */
  exports['default'] = _ember['default'].Mixin.create(_stockMixinsAsync['default'], {
    locationService: _ember['default'].inject.service(),
    settings: _ember['default'].inject.service(),

    editableQty: _ember['default'].computed.alias('settings.allowPartialOperations'),

    moveQty: _ember['default'].computed('_moveQty', {
      get: function get(k) {
        return this.get('_moveQty');
      },
      set: function set(k, value) {
        var allowPartial = this.get('settings.allowPartialOperations');
        var total = this.quantityAtSource();
        var qty = Number(value);

        if (!allowPartial && qty > 0 && qty !== total) {
          throw new Error('Partial quantity is not permitted');
        }

        this.set('_moveQty', qty);
        return qty;
      }
    }),

    resolveLocation: function resolveLocation(loc) {
      var opts = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

      var i18n, _opts$queryText, queryText, presetLocations, text;

      return regeneratorRuntime.async(function resolveLocation$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            i18n = this.get('i18n');

            if (!loc) {
              context$1$0.next = 3;
              break;
            }

            return context$1$0.abrupt('return', loc);

          case 3:
            _opts$queryText = opts.queryText;
            queryText = _opts$queryText === undefined ? '' : _opts$queryText;
            presetLocations = opts.presetLocations;
            text = i18n.exists(queryText) ? i18n.t(queryText) : queryText;
            return context$1$0.abrupt('return', this.get('locationService').userPickLocation({
              headerText: text,
              presetLocations: presetLocations
            }));

          case 8:
          case 'end':
            return context$1$0.stop();
        }
      }, null, this);
    },

    quantityAtSource: function quantityAtSource() {
      var pkg = this.get('moveTarget');
      var source = this.get('moveFrom');

      if (!pkg) {
        return 0;
      }

      var pkgLoc = pkg.get('packagesLocations').findBy('location', source);

      return pkgLoc ? pkgLoc.get('quantity') : 0;
    },

    validMoveParams: function validMoveParams() {
      var target = this.get('moveTarget');
      var from = this.get('moveFrom');
      var to = this.get('moveTo');
      var qty = this.get('moveQty');

      return target && from && to && from !== to && qty > 0;
    },

    clearMoveParams: function clearMoveParams() {
      this.set('readyToMove', false);
      this.set('moveQty', 0);
      this.set('moveTarget', null);
      this.set('moveFrom', null);
      this.set('moveTo', null);
    },

    actions: {
      beginMove: function beginMove(pkg, from, to) {
        var callback = arguments.length <= 3 || arguments[3] === undefined ? _lodash['default'].noop : arguments[3];
        return regeneratorRuntime.async(function beginMove$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              context$1$0.next = 2;
              return regeneratorRuntime.awrap((this.onBeginMove || _lodash['default'].noop).apply(this));

            case 2:
              context$1$0.next = 4;
              return regeneratorRuntime.awrap(this.resolveLocation(from, {
                queryText: 'select_location.pick_from_location',
                presetLocations: pkg.get('locations')
              }));

            case 4:
              from = context$1$0.sent;
              context$1$0.next = 7;
              return regeneratorRuntime.awrap(this.resolveLocation(to, {
                queryText: 'select_location.pick_to_location'
              }));

            case 7:
              to = context$1$0.sent;

              if (!(!pkg || !to || !from)) {
                context$1$0.next = 10;
                break;
              }

              return context$1$0.abrupt('return', this.send('cancelMove'));

            case 10:

              this.set('moveTarget', pkg);
              this.set('moveFrom', from);
              this.set('moveTo', to);
              this.set('moveQty', this.quantityAtSource());

              if (this.validMoveParams()) {
                this.set('readyToMove', true);
              } else {
                this.send('cancelMove');
              }

              callback();

            case 16:
            case 'end':
              return context$1$0.stop();
          }
        }, null, this);
      },

      completeMove: function completeMove() {
        return regeneratorRuntime.async(function completeMove$(context$1$0) {
          var _this = this;

          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              this.runTask(function callee$1$0() {
                var results;
                return regeneratorRuntime.async(function callee$1$0$(context$2$0) {
                  while (1) switch (context$2$0.prev = context$2$0.next) {
                    case 0:
                      context$2$0.next = 2;
                      return regeneratorRuntime.awrap(this.get('locationService').movePackage(this.get('moveTarget'), {
                        from: this.get('moveFrom'),
                        to: this.get('moveTo'),
                        quantity: this.get('moveQty')
                      }));

                    case 2:
                      results = context$2$0.sent;
                      context$2$0.next = 5;
                      return regeneratorRuntime.awrap((this.afterMove || _lodash['default'].noop).call(this, this.get('moveTarget')));

                    case 5:
                      return context$2$0.abrupt('return', results);

                    case 6:
                    case 'end':
                      return context$2$0.stop();
                  }
                }, null, _this);
              }, _stockMixinsAsync.ERROR_STRATEGIES.MODAL)['finally'](function () {
                _this.clearMoveParams();
              });

            case 1:
            case 'end':
              return context$1$0.stop();
          }
        }, null, this);
      },

      cancelMove: function cancelMove() {
        return regeneratorRuntime.async(function cancelMove$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              this.clearMoveParams();

            case 1:
            case 'end':
              return context$1$0.stop();
          }
        }, null, this);
      }
    }
  });
});