define("stock/utils/appointments", ["exports", "stock/utils/cache", "lodash"], function (exports, _stockUtilsCache, _lodash) {
  exports["default"] = {
    /* Options for the select inputs [00:00, 00:30, ... 23:30]  */
    timeslots: _stockUtilsCache["default"].cached(function () {
      var _this = this;

      var make = function make(hours, minutes) {
        var time = _this.formatTime(hours, minutes);
        return { name: time, id: time, time: time, hours: hours, minutes: minutes };
      };
      var slots = _lodash["default"].range(0, 24).map(function (h) {
        return [make(h, 0), make(h, 30)];
      });
      return _lodash["default"].flatten(slots);
    }),

    /* Pairs composed of a record and it's select-able timeslot */
    makeSelectableList: function makeSelectableList(models) {
      var _this2 = this;

      return models.map(function (r) {
        return Ember.Object.create({
          record: r,
          timeslot: _this2.getTimeSlotOf(r)
        });
      });
    },

    formatTime: function formatTime(hour, minute) {
      return moment().set({ hour: hour, minute: minute }).format("HH:mm");
    },

    getTimeStringOf: function getTimeStringOf(record) {
      var modelName = record.get("constructor.modelName");
      var isPreset = modelName === "appointment-slot-preset";
      if (isPreset) {
        return this.formatTime(record.get("hours"), record.get("minutes")); // Preset
      }
      return moment.utc(record.get("timestamp")).local().format("HH:mm"); // Special day
    },

    getDateStringOfSlot: function getDateStringOfSlot(slot) {
      return moment.utc(slot.get("timestamp")).local().format("dddd Do MMM YYYY");
    },

    getTimeSlotOf: function getTimeSlotOf(record) {
      var time = this.getTimeStringOf(record);
      return this.timeslots().find(function (ts) {
        return ts.time === time;
      });
    },

    sameDay: function sameDay(d1, d2) {
      return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
    }
  };
});