define("stock/models/offer", ["exports", "ember", "ember-data"], function (exports, _ember, _emberData) {

  var attr = _emberData["default"].attr,
      hasMany = _emberData["default"].hasMany,
      belongsTo = _emberData["default"].belongsTo;

  exports["default"] = _emberData["default"].Model.extend({
    companyId: attr("number"),
    notes: attr("string"),
    createdById: attr("string"),
    company: belongsTo("company", { async: false }),
    createdBy: belongsTo("user", { async: false }),
    receivedAt: attr("date")
  });
});