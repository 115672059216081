define('stock/services/order-service', ['exports', 'stock/services/api-base-service'], function (exports, _stockServicesApiBaseService) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  exports['default'] = _stockServicesApiBaseService['default'].extend({
    store: Ember.inject.service(),

    ordersCountFor: function ordersCountFor(userId) {
      return this.GET('/users/' + userId + '/orders_count');
    },

    getNextCode: function getNextCode(detail_type) {
      var data;
      return regeneratorRuntime.async(function getNextCode$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return regeneratorRuntime.awrap(this.GET('/orders/next_code', {
              detail_type: detail_type
            }));

          case 2:
            data = context$1$0.sent;
            return context$1$0.abrupt('return', data);

          case 4:
          case 'end':
            return context$1$0.stop();
        }
      }, null, this);
    },

    createShipmentOrCarryoutOrder: function createShipmentOrCarryoutOrder(params) {
      var data;
      return regeneratorRuntime.async(function createShipmentOrCarryoutOrder$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return regeneratorRuntime.awrap(this.POST('/orders', params));

          case 2:
            data = context$1$0.sent;

            this.get('store').pushPayload(data);
            return context$1$0.abrupt('return', data);

          case 5:
          case 'end':
            return context$1$0.stop();
        }
      }, null, this);
    },

    updateShipmentOrCarryoutOrder: function updateShipmentOrCarryoutOrder(order, params) {
      var data;
      return regeneratorRuntime.async(function updateShipmentOrCarryoutOrder$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return regeneratorRuntime.awrap(this.PUT('/orders/' + order.id, params));

          case 2:
            data = context$1$0.sent;

            this.get('store').pushPayload(data);
            return context$1$0.abrupt('return', data);

          case 5:
          case 'end':
            return context$1$0.stop();
        }
      }, null, this);
    },

    changeOrderState: function changeOrderState(order, state) {
      var _this = this;

      var opts = arguments.length <= 2 || arguments[2] === undefined ? {} : arguments[2];

      return this.PUT('/orders/' + order.id + '/transition', _extends({
        transition: state
      }, opts)).then(function (data) {
        data['designation'] = data['order'];
        _this.get('store').pushPayload(data);
      });
    },

    updateAddress: function updateAddress(order, addressAttributes) {
      var addressId, identity;
      return regeneratorRuntime.async(function updateAddress$(context$1$0) {
        var _this2 = this;

        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            addressId = order.getWithDefault('addressId');
            identity = addressId ? { id: addressId } : {};
            return context$1$0.abrupt('return', this.PUT('/orders/' + order.id, {
              order: {
                address_attributes: _extends({}, addressAttributes, identity)
              }
            }).then(function (data) {
              data['designation'] = data['designation'] || data['order'];
              _this2.get('store').pushPayload(data);
            }));

          case 3:
          case 'end':
            return context$1$0.stop();
        }
      }, null, this);
    },

    updateOrder: function updateOrder(order, params) {
      var data;
      return regeneratorRuntime.async(function updateOrder$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return regeneratorRuntime.awrap(this.PUT('/orders/' + order.id, params));

          case 2:
            data = context$1$0.sent;

            this.get('store').pushPayload(data);

          case 4:
          case 'end':
            return context$1$0.stop();
        }
      }, null, this);
    },

    cancelOrder: function cancelOrder(order, reason) {
      return this.changeOrderState(order, 'cancel', reason);
    },

    /**
     * Deletes the beneficiary of an order (if it exists)
     *
     * @param {Order} order
     * @returns {Order}
     */
    deleteBeneficiaryOf: function deleteBeneficiaryOf(order) {
      return regeneratorRuntime.async(function deleteBeneficiaryOf$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            if (order.get('beneficiaryId')) {
              context$1$0.next = 2;
              break;
            }

            return context$1$0.abrupt('return', order);

          case 2:
            context$1$0.next = 4;
            return regeneratorRuntime.awrap(this.DELETE('/beneficiaries/' + order.get('beneficiaryId')));

          case 4:

            order.get('beneficiary').unloadRecord();
            order.set('beneficiary', null);
            order.set('beneficiaryId', null);

            return context$1$0.abrupt('return', order);

          case 8:
          case 'end':
            return context$1$0.stop();
        }
      }, null, this);
    }
  });
});