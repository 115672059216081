define("stock/services/settings", ["exports", "ember", "lodash"], function (exports, _ember, _lodash) {

  var NOT_EMPTY = function NOT_EMPTY(val) {
    return val && val.length > 0;
  };
  var TO_STRING = function TO_STRING(val) {
    return String(val);
  };
  var TO_ARRAY = function TO_ARRAY(val) {
    return val.trim().split(/\s*,\s*/);
  };
  var TO_NUMBER = function TO_NUMBER(val) {
    return Number(val);
  };
  var IS_NUMBER = function IS_NUMBER(val) {
    return !isNaN(TO_NUMBER(val));
  };
  var TO_BOOL = function TO_BOOL(val) {
    if (_lodash["default"].isString(val)) {
      return (/^true$/i.test(val)
      );
    }
    return Boolean(val);
  };

  /**
   * @module Services/SettingsService
   * @augments ember/Service
   * @description Settings are loaded from the API in order to configure the behaviour of the app remotely.
   */
  exports["default"] = _ember["default"].Service.extend({
    store: _ember["default"].inject.service(),

    /**
     * @property {object} defaults Local pre-configured values
     * @readonly
     * @description
     * <br>
     * <br> Any key defined on the backend will override the local values
     * <br>
     * <br> A local value *must* be defined, the service will throw an error
     * <br> otherwise.
     * <br> This behaviour is meant to ensure we have fallback values if the remote config is missing.
     * <br> Add any new configuration keys here
     */
    defaults: {
      "stock.allow_partial_operations": false,
      "stock.only_publish_singletons": true,
      "stock.only_designate_singletons": true,
      "stock.enable_box_pallet_creation": false,
      "stock.allow_item_actions": true,
      "stock.allow_box_pallet_item_addition": false,
      "stock.params_not_to_be_copied": "serialNum"
    },

    /**
     * @property {Computed<boolean>} allowPartialOperations whether partial operations are allowed
     */
    allowPartialOperations: _ember["default"].computed(function () {
      return this.readBoolean("stock.allow_partial_operations");
    }),

    /**
     * @property {Computed<boolean>} onlyDesignateSingletons prevents multi-qty items from being designated
     */
    onlyDesignateSingletons: _ember["default"].computed(function () {
      return this.readBoolean("stock.only_designate_singletons");
    }),

    /**
     * @property {Computed<boolean>} onlyPublishSingletons prevents multi-qty items from being published
     */
    onlyPublishSingletons: _ember["default"].computed(function () {
      return this.readBoolean("stock.only_publish_singletons");
    }),

    /**
     * @property {Computed<boolean>} disableBoxPalletCreation disables box pallet creation
     */
    disableBoxPalletCreation: _ember["default"].computed(function () {
      return !this.readBoolean("stock.enable_box_pallet_creation");
    }),

    /**
     * @property {Computed<boolean>} allowItemActions whether item actions are allowed
     */
    allowItemActions: _ember["default"].computed(function () {
      return this.readBoolean("stock.allow_item_actions");
    }),

    /**
     * @property {Computed<boolean>} disableBoxPalletItemAddition disables box pallet addition
     */
    disableBoxPalletItemAddition: _ember["default"].computed(function () {
      return !this.readBoolean("stock.allow_box_pallet_item_addition");
    }),

    /**
     * @property {Computed<string>} paramsNotCopied lists params not to be copied during duplicate items
     */
    paramsNotCopied: _ember["default"].computed(function () {
      return this.readArray("stock.params_not_to_be_copied");
    }),
    // ---- Access methods

    /**
     * Reads the property by its key and returns a boolean
     *
     * @param {string} key the settings key
     * @returns {boolean} whether the field is enabled or not
     */
    readBoolean: function readBoolean(key) {
      return this.__readValue(key, {
        parser: TO_BOOL
      });
    },

    /**
     * Reads the property by its key and returns a string
     *
     * @param {string} key the settings key
     * @returns {array} the string of the setting keys in array form
     */
    readArray: function readArray(key) {
      return this.__readValue(key, {
        parser: TO_ARRAY
      });
    },

    /**
     * Reads the property by its key and returns a string
     *
     * @param {string} key the settings key
     * @returns {string} the settings value
     */
    readString: function readString(key) {
      return this.__readValue(key, {
        parser: TO_STRING,
        validator: NOT_EMPTY
      });
    },

    /**
     * Reads the property by its key and returns a number
     *
     * @param {string} key the settings key
     * @returns {number} the number value
     */
    readNumber: function readNumber(key) {
      return this.__readValue(key, {
        parser: TO_NUMBER,
        validator: IS_NUMBER
      });
    },

    // ---- Helpers

    __validate: function __validate(val, validator) {
      var validators = _lodash["default"].compact(_lodash["default"].flatten([validator]));
      return _lodash["default"].every(validators, function (fn) {
        return fn(val);
      });
    },

    __assertExists: function __assertExists(key) {
      var defaults = this.get("defaults");
      if (_lodash["default"].has(defaults, key)) {
        return;
      }

      throw new Error("\n      Settings '" + key + "' has not been defined locally.\n      Please define a local default value before using it\n    ");
    },

    __readValue: function __readValue(key) {
      var options = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

      var defaults = this.get("defaults");
      var validator = options.validator;
      var _options$parser = options.parser;
      var parser = _options$parser === undefined ? _lodash["default"].identity : _options$parser;

      this.__assertExists(key);

      var record = this.get("store").peekAll("goodcity_setting").findBy("key", key);

      var val = record && record.get("value");
      if (val && this.__validate(val, validator)) {
        return parser(val);
      }
      return parser(defaults[key]);
    }
  });
});