define("stock/controllers/users/details", ["exports", "ember", "lodash", "stock/mixins/async", "stock/mixins/organisation"], function (exports, _ember, _lodash, _stockMixinsAsync, _stockMixinsOrganisation) {
  exports["default"] = _ember["default"].Controller.extend(_stockMixinsOrganisation["default"], _stockMixinsAsync["default"], {
    organisationsUserService: _ember["default"].inject.service(),
    userService: _ember["default"].inject.service(),
    session: _ember["default"].inject.service(),
    user: _ember["default"].computed.alias("model"),
    disableUserPopupVisible: false,
    enableUserPopupVisible: false,
    updateUserMessagePopupVisible: false,
    isDisabledUser: _ember["default"].computed.alias("user.disabled"),
    minSearchTextLength: 3,

    canDisableUsers: _ember["default"].computed("user.id", function () {
      return this.get("session.currentUser.canDisableUsers") && +this.get("session.currentUser.id") !== +this.get("user.id");
    }),

    canMergeUsers: _ember["default"].computed("user.id", function () {
      return this.get("session.currentUser.canMergeUsers") && +this.get("session.currentUser.id") !== +this.get("user.id");
    }),

    showEnableUserMessage: _ember["default"].computed.and("canDisableUsers", "isDisabledUser"),

    canManageCharityPosition: _ember["default"].computed(function () {
      return this.get("session").get("currentUser").get("canManageOrganisationsUsers");
    }),

    userOrganisationDetails: _ember["default"].computed("model", "model.organisationsUsers.[]", "model.organisationsUsers.@each.userStatus", function () {
      var _this = this;

      var organisationUser = [];

      this.get("model.organisationsUsers").map(function (record) {
        organisationUser.push({
          id: record.get("id"),
          status: record.get("userStatus"),
          name: _this.store.peekRecord("organisation", record.get("organisationId")).get("nameEn")
        });
      });
      return organisationUser;
    }),

    adminRoleAccess: _ember["default"].computed("user.activeRoles.@each.expiresAt", function () {
      return this.get("userService").getRoleAccessText(this.get("user"), "admin");
    }),

    stockRoleAccess: _ember["default"].computed("user.activeRoles.@each.expiresAt", function () {
      return this.get("userService").getRoleAccessText(this.get("user"), "stock");
    }),

    toggleUserAccount: function toggleUserAccount(options) {
      var _this2 = this;

      if (this.get("canDisableUsers")) {
        (function () {
          var user = _this2.get("user");

          _this2.runTask(function callee$2$0() {
            return regeneratorRuntime.async(function callee$2$0$(context$3$0) {
              while (1) switch (context$3$0.prev = context$3$0.next) {
                case 0:
                  user.set("disabled", options.disabled);
                  context$3$0.next = 3;
                  return regeneratorRuntime.awrap(user.save());

                case 3:
                case "end":
                  return context$3$0.stop();
              }
            }, null, _this2);
          }, _stockMixinsAsync.ERROR_STRATEGIES.MODAL);
        })();
      }
    },

    onSearchTextChange: _ember["default"].observer("searchText", function () {
      this.hideResults();
      if (this.get("searchText").trim().length >= this.get("minSearchTextLength")) {
        _ember["default"].run.debounce(this, this.showResults, 500);
      }
    }),

    hideResults: function hideResults() {
      this.set("displayResults", false);
    },

    showResults: function showResults() {
      this.set("displayResults", true);
    },

    actions: {
      searchUsers: function searchUsers(page) {
        var params = {
          page: page,
          per_page: 25,
          searchText: this.get("searchText"),
          stockRequest: true
        };

        return this.get("store").query("user", params);
      },

      clearSearch: function clearSearch() {
        this.set("searchText", "");
      },

      cancelMerge: function cancelMerge() {
        this.set("searchUser", false);
        this.set("searchText", "");
      },

      searchUserToMerge: function searchUserToMerge() {
        this.set("searchUser", true);
      },

      disableUser: function disableUser() {
        this.toggleUserAccount({ disabled: true });
      },

      cancelDisableUser: function cancelDisableUser() {
        this.set("disableUserPopupVisible", false);
      },

      cancelEnableUser: function cancelEnableUser() {
        this.set("enableUserPopupVisible", false);
      },

      checkUserValidity: function checkUserValidity() {
        var user = this.get("user");
        if ((user.get("mobile") || "").length && (user.get("email") || "").length) {
          this.set("updateUserMessagePopupVisible", true);
        } else {
          this.send("displayEnableUserPopup");
        }
      },

      displayDisableUserPopup: function displayDisableUserPopup() {
        this.set("disableUserPopupVisible", true);
      },

      displayEnableUserPopup: function displayEnableUserPopup() {
        this.set("enableUserPopupVisible", true);
      },

      enableUser: function enableUser() {
        this.toggleUserAccount({ disabled: false });
      },

      /**
       * Navigate to charity_position screen
       * If user is already present in selected organisation, then organisations_users record
       * already exists. In that case, it will be an edit operation
       *
       * If user is not present in the selected organisation, then it will be a create operation
       */
      addCharityPosition: function addCharityPosition() {
        var organisation, organisationUser, params;
        return regeneratorRuntime.async(function addCharityPosition$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              context$1$0.next = 2;
              return regeneratorRuntime.awrap(this.organisationLookup());

            case 2:
              organisation = context$1$0.sent;
              organisationUser = this.get("organisationsUserService").getOrganisationUser(organisation.get("id"), this.get("model.id"));
              params = organisationUser ? "id=" + organisationUser.get("id") : "organisationId=" + organisation.get("id");

              this.transitionToRoute("/users/" + this.get("model.id") + "/charity_position?" + params);

            case 6:
            case "end":
              return context$1$0.stop();
          }
        }, null, this);
      },

      viewCharityPosition: function viewCharityPosition(id) {
        this.transitionToRoute("/users/" + this.get("model.id") + "/charity_position?id=" + id);
      }
    }
  });
});