define("stock/helpers/format-display-value", ["exports", "ember", "stock/constants/saleable-options", "lodash"], function (exports, _ember, _stockConstantsSaleableOptions, _lodash) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; })();

  exports["default"] = _ember["default"].Helper.extend({
    store: _ember["default"].inject.service(),
    i18n: _ember["default"].inject.service(),

    getRecordName: function getRecordName(modelName, modelId) {
      var fieldName = arguments.length <= 2 || arguments[2] === undefined ? "name" : arguments[2];

      if (!modelId) {
        return "-";
      }

      var record = this.get("store").peekRecord(modelName, modelId);

      if (record) {
        return record.getWithDefault(fieldName, "N/A");
      } else {
        return "-";
      }
    },

    getSaleable: function getSaleable(value) {
      var saleable = _lodash["default"].find(_stockConstantsSaleableOptions.SALEABLE_OPTIONS, ["value", _lodash["default"].isNull(value) ? value : value.toString()]);
      return this.get("i18n").t(saleable.translation_key).string;
    },

    getLookUp: function getLookUp(modelId) {
      if (!modelId) {
        return "-";
      }
      var label = this.get("i18n.locale") === "en" ? "labelEn" : "labelZhTw";
      return this.getRecordName("lookup", modelId, label);
    },

    getCountry: function getCountry(modelId) {
      if (!modelId) {
        return "-";
      }
      var label = this.get("i18n.locale") === "en" ? "nameEn" : "nameZhTw";
      var record = this.get("store").peekRecord("country", modelId);
      if (!record) {
        return "N/A";
      }
      return record.getWithDefault(label, "N/A");
    },

    getSaleable: function getSaleable(value) {
      var saleable = _lodash["default"].find(_stockConstantsSaleableOptions.SALEABLE_OPTIONS, ["value", _lodash["default"].isNull(value) ? value : value.toString()]);
      return this.get("i18n").t(saleable.translation_key).string;
    },

    compute: function compute(data) {
      var _data = _slicedToArray(data, 2);

      var type = _data[0];
      var value = _data[1];

      switch (type) {
        case "expiry_date":
          if (!value) {
            return "-";
          }
          return moment(value).format("LLL");
        case "package_id":
          return this.getRecordName("item", value, "inventoryNumber");
        case "order_id":
          return this.getRecordName("designation", value, "code");
        case "location_id":
          return this.getRecordName("location", value);
        case "restriction_id":
          return this.getRecordName("restriction", value);
        case "package_type_id":
          return this.getRecordName("code", value);
        case "storage_type_id":
          return this.getRecordName("storage-type", value);
        case "donor_condition_id":
          return this.getRecordName("donor_condition", value);
        case "saleable":
          return this.getSaleable(value);
        case "frequency_id":
          return this.getLookUp(value);
        case "voltage_id":
          return this.getLookUp(value);
        case "test_status_id":
          return this.getLookUp(value);
        case "comp_test_status_id":
          return this.getLookUp(value);
        case "country_id":
          return this.getCountry(value);
        default:
          if (!value) {
            return "-";
          }
          return value;
      }
    }
  });
});