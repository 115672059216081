define("stock/controllers/no-permission", ["exports", "ember", "stock/utils/ajax-promise", "stock/config/environment", "stock/mixins/preload_data"], function (exports, _ember, _stockUtilsAjaxPromise, _stockConfigEnvironment, _stockMixinsPreload_data) {
  exports["default"] = _ember["default"].Controller.extend(_stockMixinsPreload_data["default"], {
    queryParams: ["destination", "id"],
    application: _ember["default"].inject.controller(),
    isMobileApp: _stockConfigEnvironment["default"].cordova.enabled,
    accessKey: "",

    actions: {
      logMeOut: function logMeOut() {
        this.get("application").send("logMeOut");
      },

      tryAttemptedTransition: function tryAttemptedTransition() {
        if (this.destination) {
          if (this.id) {
            this.replaceRoute(this.destination, +this.id);
          } else {
            this.replaceRoute(this.destination);
          }
        } else {
          this.replaceRoute("/");
        }
      },

      requestAccessByPass: function requestAccessByPass() {
        var _this = this;

        if (this.get("accessKey") && this.get("accessKey").length === 6) {
          new _stockUtilsAjaxPromise["default"]("/users/" + this.get("session.currentUser.id") + "/grant_access", "PUT", this.get("session.authToken"), { access_key: this.get("accessKey") }).then(function (data) {
            _this.get("store").pushPayload(data);
            _this.preloadData();
            _this.transitionToRoute("/");
          })["finally"](function () {
            _this.set("accessKey", "");
          });
        }
      },

      setScannedSearchText: function setScannedSearchText(scannedText) {
        console.log(scannedText);
        this.set("accessKey", scannedText);
        this.send("requestAccessByPass");
      }
    }
  });
});