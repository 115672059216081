define("stock/services/subscription", ["exports", "ember", "lodash", "stock/config/environment", "stock/mixins/async"], function (exports, _ember, _lodash, _stockConfigEnvironment, _stockMixinsAsync) {
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function run(func) {
    if (func) {
      func();
    }
  }

  var ALL_OPERATIONS = ["create", "update", "delete"];

  var UPDATE_STRATEGY = {
    RELOAD: function RELOAD(store, type, record) {
      return store.findRecord(type, record.id, { reload: true });
    },
    MERGE: function MERGE(store, type, record) {
      store.pushPayload(_defineProperty({}, type, record));
    },
    SAVE_SENDER: function SAVE_SENDER(store, type, record, sender) {
      store.pushPayload(sender);
    }
  };

  /**
   * Subscription service
   *
   */
  exports["default"] = _ember["default"].Service.extend(_ember["default"].Evented, _stockMixinsAsync["default"], {
    messagesUtil: _ember["default"].inject.service("messages"),
    session: _ember["default"].inject.service(),
    store: _ember["default"].inject.service(),
    socket: null,
    lastOnline: Date.now(),
    deviceTtl: 0,
    deviceId: _ember["default"].computed.alias("session.deviceId"),
    status: {
      online: false
    },
    connectUrl: _ember["default"].computed("session.authToken", "deviceId", function () {
      return _stockConfigEnvironment["default"].APP.SOCKETIO_WEBSERVICE_URL + ("?token=" + encodeURIComponent(this.get("session.authToken"))) + ("&deviceId=" + this.get("deviceId")) + ("&meta=appName:" + _stockConfigEnvironment["default"].APP.NAME);
    }),

    // -----------
    // Config
    // -----------
    unhandledTypes: [
    // those types will be ignored
    "offer", "item", "schedule", "delivery", "gogovan_order", "contact", "address"],
    importStrategies: {
      // define how we handle incoming changes
      designation: {
        operations: ["update", "delete"]
      },
      item: {
        strategy: UPDATE_STRATEGY.MERGE
      },
      message: {
        strategy: [UPDATE_STRATEGY.MERGE, UPDATE_STRATEGY.SAVE_SENDER]
      },
      defaults: {
        operations: ALL_OPERATIONS,
        strategy: UPDATE_STRATEGY.MERGE
      }
    },
    internalTypeMapping: {
      // type renaming
      "package": "item",
      order: "designation"
    },

    // -----------
    // Watch
    // -----------
    updateStatus: _ember["default"].observer("socket", function () {
      var socket = this.get("socket");
      var online = navigator.connection ? navigator.connection.type !== "none" : navigator.onLine;
      online = socket && socket.connected && online;
      this.set("status", { online: online });
    }),

    // resync if offline longer than deviceTtl
    checkdeviceTTL: _ember["default"].observer("status.online", function () {
      var online = this.get("status.online");
      var deviceTtl = this.get("deviceTtl");

      if (!online) {
        this.set("lastOnline", Date.now());
        return;
      }

      var overtime = Date.now() - this.get("lastOnline") > deviceTtl * 1000;
      if (deviceTtl && overtime) {
        this.resync();
      }
    }),

    // -----------
    // Setup
    // -----------

    init: function init() {
      var updateStatus = _ember["default"].run.bind(this, this.updateStatus);
      window.addEventListener("online", updateStatus);
      window.addEventListener("offline", updateStatus);
    },

    wire: function wire() {
      if (_stockConfigEnvironment["default"].environment === "test") {
        return;
      }

      return this.setup();
    },

    setup: function setup() {
      var updateStatus = _ember["default"].run.bind(this, this.updateStatus);
      var socket = io(this.get("connectUrl"), {
        autoConnect: false,
        forceNew: true
      });

      this.set("socket", socket);
      socket.on("connect", function () {
        updateStatus();
        socket.io.engine.on("upgrade", updateStatus);
      });
      socket.on("notification", _ember["default"].run.bind(this, this.notification));
      socket.on("disconnect", updateStatus);
      socket.on("error", _ember["default"].run.bind(this, this.error));
      socket.on("update_store", _ember["default"].run.bind(this, this.update_store));
      socket.on("_batch", _ember["default"].run.bind(this, this.batch));
      socket.on("_resync", _ember["default"].run.bind(this, this.resync));
      socket.on("_settings", _ember["default"].run.bind(this, function (settings) {
        this.set("deviceTtl", settings.device_ttl);
        this.set("lastOnline", Date.now());
      }));
      socket.connect(); // manually connect since it's not auto-connecting if you logout and then back in
    },

    unwire: function unwire() {
      var socket = this.get("socket");
      if (socket) {
        socket.close();
        this.set("socket", null);
      }
    },

    // -----------
    // Helpers
    // -----------

    getStrategy: function getStrategy(type) {
      var defaultStrat = this.importStrategies.defaults;
      var typeStrat = this.importStrategies[type] || {};
      return _lodash["default"].extend({}, defaultStrat, typeStrat);
    },

    operationIsAllowed: function operationIsAllowed(operation, type) {
      var _getStrategy = this.getStrategy(type);

      var operations = _getStrategy.operations;

      return operations.indexOf(operation) >= 0;
    },

    resolveTypeAliases: function resolveTypeAliases(type) {
      var typeMapping = this.get("internalTypeMapping");
      return typeMapping[type] || type;
    },

    parseData: function parseData(data) {
      var payload = data.item;
      var operation = data.operation;
      var sender = data.sender;
      var deviceId = data.device_id;

      var rawType = Object.keys(payload)[0].toLowerCase();
      var type = this.resolveTypeAliases(rawType);
      var record = _ember["default"].$.extend({}, payload[rawType] || payload[_lodash["default"].capitalize(rawType)]);
      return { payload: payload, record: record, operation: operation, type: type, rawType: rawType, sender: sender, deviceId: deviceId };
    },

    isUnhandled: function isUnhandled(data) {
      var _parseData = this.parseData(data);

      var operation = _parseData.operation;
      var deviceId = _parseData.deviceId;
      var rawType = _parseData.rawType;
      var type = _parseData.type;

      if (deviceId == this.get("deviceId")) {
        return true; // Change initiated by us
      }

      if (this.get("unhandledTypes").indexOf(rawType) >= 0) {
        console.warn("[Subscription] Unhandled data type '" + rawType + "'");
        return true;
      }

      if (!this.operationIsAllowed(operation, type)) {
        console.warn("[Subscription] Ignoring a '" + operation + "' operation for type '" + type + "'");
        return true;
      }
      return false;
    },

    applyUpdateStrategy: function applyUpdateStrategy(record, type, sender) {
      var store, _getStrategy2, strategy, funcs, _iteratorNormalCompletion, _didIteratorError, _iteratorError, _iterator, _step, fn;

      return regeneratorRuntime.async(function applyUpdateStrategy$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            store = this.get("store");
            _getStrategy2 = this.getStrategy(type);
            strategy = _getStrategy2.strategy;
            funcs = _lodash["default"].flatten([strategy]);
            _iteratorNormalCompletion = true;
            _didIteratorError = false;
            _iteratorError = undefined;
            context$1$0.prev = 7;
            _iterator = funcs[Symbol.iterator]();

          case 9:
            if (_iteratorNormalCompletion = (_step = _iterator.next()).done) {
              context$1$0.next = 16;
              break;
            }

            fn = _step.value;
            context$1$0.next = 13;
            return regeneratorRuntime.awrap(fn(store, type, record, sender));

          case 13:
            _iteratorNormalCompletion = true;
            context$1$0.next = 9;
            break;

          case 16:
            context$1$0.next = 22;
            break;

          case 18:
            context$1$0.prev = 18;
            context$1$0.t0 = context$1$0["catch"](7);
            _didIteratorError = true;
            _iteratorError = context$1$0.t0;

          case 22:
            context$1$0.prev = 22;
            context$1$0.prev = 23;

            if (!_iteratorNormalCompletion && _iterator["return"]) {
              _iterator["return"]();
            }

          case 25:
            context$1$0.prev = 25;

            if (!_didIteratorError) {
              context$1$0.next = 28;
              break;
            }

            throw _iteratorError;

          case 28:
            return context$1$0.finish(25);

          case 29:
            return context$1$0.finish(22);

          case 30:
          case "end":
            return context$1$0.stop();
        }
      }, null, this, [[7, 18, 22, 30], [23,, 25, 29]]);
    },

    // -----------
    // Socket hooks
    // -----------

    notification: function notification(data, success) {
      this.trigger("notification", data);
      run(success);
    },

    error: function error(reason) {
      if (typeof reason !== "object" || reason.type !== "TransportError" && reason.message !== "xhr post error") {
        console.warn("[Subscription] Socker error", reason);
      }
    },

    batch: function batch(events, success) {
      events.forEach(function (args) {
        var event = args[0];
        if (this[event]) {
          this[event].apply(this, args.slice(1));
        }
      }, this);
      run(success);
    },

    resync: function resync() {
      this.get("store").findAll("item");
    },

    update_store: function update_store(data, success) {
      var _this = this;

      this.runTask(function () {
        return _this.applyChanges(data, success);
      }, {
        errorStrategy: _stockMixinsAsync.ERROR_STRATEGIES.ROLLBAR,
        showSpinner: false
      });
    },

    applyChanges: function applyChanges(data, success) {
      var _parseData2, record, operation, deviceId, type, sender, existingItem;

      return regeneratorRuntime.async(function applyChanges$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            if (!this.isUnhandled(data)) {
              context$1$0.next = 2;
              break;
            }

            return context$1$0.abrupt("return", false);

          case 2:
            _parseData2 = this.parseData(data);
            record = _parseData2.record;
            operation = _parseData2.operation;
            deviceId = _parseData2.deviceId;
            type = _parseData2.type;
            sender = _parseData2.sender;
            context$1$0.t0 = operation;
            context$1$0.next = context$1$0.t0 === "create" ? 11 : context$1$0.t0 === "update" ? 11 : context$1$0.t0 === "delete" ? 14 : 17;
            break;

          case 11:
            context$1$0.next = 13;
            return regeneratorRuntime.awrap(this.applyUpdateStrategy(record, type, sender));

          case 13:
            return context$1$0.abrupt("break", 19);

          case 14:
            existingItem = this.get("store").peekRecord(type, record.id);

            if (existingItem) {
              existingItem.unloadRecord();
            }
            return context$1$0.abrupt("break", 19);

          case 17:
            console.error("[Subscription] Unsupported operation '" + operation + "'");
            return context$1$0.abrupt("return", false);

          case 19:
            this.trigger("change:" + type, { type: type, operation: operation, record: record });
            run(success);
            return context$1$0.abrupt("return", true);

          case 22:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    }
  });
});