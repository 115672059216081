define("stock/routes/orders/conversation", ["exports", "stock/routes/orders/detail", "ember"], function (exports, _stockRoutesOrdersDetail, _ember) {
  exports["default"] = _stockRoutesOrdersDetail["default"].extend({
    model: function model(params) {
      return _ember["default"].RSVP.hash({
        designation: this.store.peekRecord("designation", params.order_id, {
          reload: true
        }) || this.store.findRecord("designation", params.order_id),
        messages: this.store.query("message", {
          order_id: params.order_id,
          is_private: false
        })
      });
    },

    afterModel: function afterModel(model) {
      //jshint ignore:line
      //Overriding to neglect afterModel in detail
    },

    setupController: function setupController(controller, model) {
      controller.set("messages", model.messages);
      controller.set("model", model.designation);
      controller.send("markRead");
      controller.on();
    },

    resetController: function resetController(controller) {
      controller.off();
    }
  });
});