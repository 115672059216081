define("stock/routes/item_filters", ["exports", "stock/routes/authorize"], function (exports, _stockRoutesAuthorize) {
  exports["default"] = _stockRoutesAuthorize["default"].extend({
    model: function model() {
      var recentlyUsedLocations = this.store.peekAll("location").filterBy("recentlyUsedAt");
      return recentlyUsedLocations.length ? recentlyUsedLocations : this.store.query("location", { recently_used: true });
    },

    deactivate: function deactivate() {
      var controller = this.controllerFor("itemFilters");
      var queryParams = controller.get("queryParams");
      queryParams.forEach(function (queryParam) {
        return controller.set(queryParam, null);
      });
    },

    setupController: function setupController(controller, model) {
      var _this = this;

      this._super(controller, model);
      var buildingNames = model.getEach("building").uniq();
      buildingNames.forEach(function (buildingName) {
        _this.store.createRecord("location", {
          building: buildingName,
          area: "(All areas)"
        });
      });
      controller.set("model", this.store.peekAll("location"));
      controller.set("searchText", "");
    }
  });
});