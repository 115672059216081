define("stock/routes/items/detail", ["exports", "stock/routes/authorize", "ember", "lodash", "stock/utils/cache"], function (exports, _stockRoutesAuthorize, _ember, _lodash, _stockUtilsCache) {
  exports["default"] = _stockRoutesAuthorize["default"].extend({
    itemBackLinkPath: _ember["default"].computed.localStorage(),
    transition: null,
    packageService: _ember["default"].inject.service(),
    messageBox: _ember["default"].inject.service(),
    i18n: _ember["default"].inject.service(),

    queryParams: {
      showDispatchOverlay: false
    },

    model: function model(_ref) {
      var item_id = _ref.item_id;
      var model, detailType, detailId;
      return regeneratorRuntime.async(function model$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return regeneratorRuntime.awrap(this.loadItemWithImages(item_id));

          case 2:
            model = context$1$0.sent;
            detailType = model.get("detailType");
            detailId = model.get("detailId");

            if (!detailType) {
              context$1$0.next = 8;
              break;
            }

            context$1$0.next = 8;
            return regeneratorRuntime.awrap(this.loadIfAbsent(_lodash["default"].snakeCase(detailType).toLowerCase(), detailId));

          case 8:
            return context$1$0.abrupt("return", model);

          case 9:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    afterModel: function afterModel(model) {
      return regeneratorRuntime.async(function afterModel$(context$1$0) {
        var _this = this;

        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            if (!model.get("inventoryNumber") || model.get("state") === "missing") {
              this.get("transition").abort();
              this.get("messageBox").alert(this.get("i18n").t("item_details.not_inventorized_or_missing"), function () {
                _this.transitionTo("items.index");
              });
            }

            if (model.get("isBoxPallet")) {
              context$1$0.next = 6;
              break;
            }

            context$1$0.next = 4;
            return regeneratorRuntime.awrap(this.store.findAll("restriction", { reload: true }));

          case 4:
            context$1$0.next = 6;
            return regeneratorRuntime.awrap(this.store.findAll("donor_condition", { reload: true }));

          case 6:
            context$1$0.next = 8;
            return regeneratorRuntime.awrap(this.preloadProcessingDestination(model));

          case 8:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    preloadProcessingDestination: (0, _stockUtilsCache.cached)(function callee$0$0() {
      return regeneratorRuntime.async(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return regeneratorRuntime.awrap(this.store.findAll("processing_destination", {
              reload: true
            }));

          case 2:
            return context$1$0.abrupt("return", context$1$0.sent);

          case 3:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    }),

    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);
      this.set("transition", transition);
      var previousRoutes = this.router.router.currentHandlerInfos;
      var previousRoute = previousRoutes && previousRoutes.pop();
      var path = "items.index";

      if (previousRoute) {
        var routeName = previousRoute.name;
        if (routeName.indexOf("items") === 0) {
          path = this.get("itemBackLinkPath") || path;
        } else if (routeName.indexOf("items") > -1 || routeName === "orders.active_items") {
          path = routeName;
        }
      }
      this.set("itemBackLinkPath", path);
    },

    setupController: function setupController(controller, model) {
      var defaultValue, detailType, details;
      return regeneratorRuntime.async(function setupController$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            this._super(controller, model);
            controller.set("showSetList", false);
            controller.set("callOrderObserver", false);
            controller.set("backLinkPath", this.get("itemBackLinkPath"));
            controller.set("active", true);
            controller.set("showExtendedFooterMenu", false);
            controller.set("displayResults", true);
            controller.set("containerQuantity", null);
            controller.set("readyForAction", false);

            context$1$0.next = 11;
            return regeneratorRuntime.awrap(this.get("packageService").getItemValuation({
              donorConditionId: model.get("donorCondition.id"),
              grade: model.get("grade"),
              packageTypeId: model.get("code.id")
            }));

          case 11:
            defaultValue = context$1$0.sent;

            controller.set("defaultValueHkDollar", +defaultValue.value_hk_dollar);
            controller.set("valueHkDollar", +model.get("valueHkDollar"));

            detailType = model.get("detailType");

            if (!detailType) {
              context$1$0.next = 20;
              break;
            }

            context$1$0.next = 18;
            return regeneratorRuntime.awrap(this.store.query(_lodash["default"].snakeCase(detailType), {
              distinct: "brand"
            }));

          case 18:
            details = context$1$0.sent;

            controller.set("packageDetails", details);

          case 20:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set("active", false);
        controller.set("selectedDescriptionLanguage", "en");
        controller.set("showDescriptionSuggestion", false);
        controller.get("model").rollbackAttributes();
      }
    },

    // --- Helpers

    /**
     * Loads all the images of an item (cached)
     *
     * @param {Item} item the selected item
     * @private
     */
    preloadImages: function preloadImages(item) {
      var _this2 = this;

      var ids = item.getWithDefault("imageIds", []);
      return _ember["default"].RSVP.all(ids.map(function (id) {
        return _this2.loadImage(id);
      }));
    },

    preloadMessages: function preloadMessages(item) {
      return this.get("store").query("message", {
        is_private: true,
        messageable_id: item.id,
        messageable_type: "Package"
      });
    },

    /**
     * Loads an image if not available
     *
     * @private
     * @param {String} id the image id
     * @returns {Image} the image record
     */
    loadImage: function loadImage(id) {
      var cachedImg;
      return regeneratorRuntime.async(function loadImage$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            cachedImg = this.store.peekRecord("image", id);

            if (!cachedImg) {
              context$1$0.next = 3;
              break;
            }

            return context$1$0.abrupt("return", cachedImg);

          case 3:
            return context$1$0.abrupt("return", this.store.findRecord("image", id, { reload: true }));

          case 4:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    /**
     * Loads an item with its images
     * Shorthand for loadItem(id, { loadImages: true })
     *
     * @private
     * @param {String} id the item id
     * @returns {Item} the item record
     */
    loadItemWithImages: function loadItemWithImages(id) {
      return this.loadItem(id, { loadImages: true });
    },

    /**
     * Loads an item
     * Uses the reload:false option to prevent background loads
     *
     * @private
     * @param {String} id the item id
     * @param {Object} opts load options
     * @param {Boolean} opts.loadImages if set to true, will also load the images
     * @returns {Item} the item record
     */
    loadItem: function loadItem(id) {
      var opts = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

      var _opts$loadImages, loadImages, item;

      return regeneratorRuntime.async(function loadItem$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            _opts$loadImages = opts.loadImages;
            loadImages = _opts$loadImages === undefined ? false : _opts$loadImages;
            context$1$0.next = 4;
            return regeneratorRuntime.awrap(this.store.findRecord("item", id, {
              reload: true,
              adapterOptions: { include_orders_packages: false }
            }));

          case 4:
            item = context$1$0.sent;

            if (!loadImages) {
              context$1$0.next = 8;
              break;
            }

            context$1$0.next = 8;
            return regeneratorRuntime.awrap(this.preloadImages(item));

          case 8:
            context$1$0.next = 10;
            return regeneratorRuntime.awrap(this.preloadMessages(item));

          case 10:
            return context$1$0.abrupt("return", item);

          case 11:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    }
  });
});

// Load selected package