define("stock/routes/items/index", ["exports", "stock/routes/authorize", "ember", "stock/utils/ajax-promise", "lodash"], function (exports, _stockRoutesAuthorize, _ember, _stockUtilsAjaxPromise, _lodash) {
  exports["default"] = _stockRoutesAuthorize["default"].extend({
    queryParams: {
      itemSetId: "",
      searchInput: ""
    },
    userService: _ember["default"].inject.service(),
    partial_qnty: _ember["default"].computed.localStorage(),

    previousPage: function previousPage(transition) {
      var prevPage = _lodash["default"].last(_lodash["default"].get(transition, "router.currentHandlerInfos"));
      return _lodash["default"].get(prevPage, "name", "");
    },

    isBackNavigation: function isBackNavigation(transition) {
      return (/^items\..+$/.test(this.previousPage(transition))
      );
    },

    /* jshint ignore:start */
    model: function model(params, transition) {
      var data;
      return regeneratorRuntime.async(function model$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            if (!this.isBackNavigation(transition)) {
              context$1$0.next = 2;
              break;
            }

            return context$1$0.abrupt("return");

          case 2:
            if (this.session.get("currentUser")) {
              context$1$0.next = 7;
              break;
            }

            context$1$0.next = 5;
            return regeneratorRuntime.awrap(this.get("userService").currentUser());

          case 5:
            data = context$1$0.sent;

            this.store.pushPayload(data);

          case 7:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    setupController: function setupController(controller) {
      var model = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

      this._super(controller, model);
      this.set("partial_qnty", 0);
      controller.set("itemSetId", this.paramsFor("items.index").itemSetId);
      controller.set("searchText", this.paramsFor("items.index").searchInput);
      controller.on();
    },
    /* jshint ignore:end */

    afterModel: function afterModel() {
      this.set("partial_qnty", 0);
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.off();
      }
    }
  });
});
//jshint ignore:line

// When returning from the order details back to the search
// we restore the state exactly as it was before