define("stock/controllers/item_filters", ["exports", "stock/controllers/search_module", "ember"], function (exports, _stockControllersSearch_module, _ember) {
  exports["default"] = _stockControllersSearch_module["default"].extend({
    searchModelName: "location",
    queryParams: ["applyStateFilter", "applyLocationFilter"],
    applyStateFilter: null,
    applyLocationFilter: null,
    unloadAll: true,
    sortProperties: ["building", "area"],
    sortedLocations: _ember["default"].computed.sort("model", "sortProperties"),
    recentlyUsedLocations: _ember["default"].computed.sort("model", "sortProperties"),
    filterService: _ember["default"].inject.service(),
    paginationOpts: function paginationOpts() {
      return {
        perPage: 25,
        startingPage: 1,
        modelPath: "sortedLocations",
        stockRequest: true
      };
    },

    applyFilter: function applyFilter() {
      var _this = this;

      var searchText = this.get("searchText");
      if (searchText.length > 0) {
        this.set("isLoading", true);
        this.set("hasNoResults", false);
        this.store.unloadAll("location");

        this.infinityModel(this.get("searchModelName"), this.paginationOpts(), this.buildQueryParamMap()).then(function (data) {
          if (_this.get("searchText") === data.meta.search) {
            (function () {
              var area = "(All areas)";
              var buildingNames = data.getEach("building").uniq();
              buildingNames.forEach(function (building) {
                _this.store.createRecord("location", { building: building, area: area });
              });
              _this.set("model", _this.store.peekAll("location"));
              _this.set("hasNoResults", !data.get("length"));
            })();
          }
        })["finally"](function () {
          return _this.set("isLoading", false);
        });
      }
      this.set("model", []);
    },

    actions: {
      setLocation: function setLocation(location) {
        this.get("filterService").set("itemLocationFilters", location.get("name"));
        this.transitionToRoute("items.index");
      },
      clearLocationAndRedirect: function clearLocationAndRedirect() {
        this.get("filterService").set("itemLocationFilters", "");
        this.transitionToRoute("items.index");
      }
    }
  });
});