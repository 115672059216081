define("stock/controllers/notifications", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Controller.extend({
    subscription: _ember["default"].inject.service(),
    model: _ember["default"].computed({
      get: function get() {
        return [];
      },
      set: function set(key, value) {
        return value;
      }
    }),

    nextNotification: _ember["default"].computed("model.[]", function () {
      //retrieveNotification is not implemented here because it needs to call itself
      return this.retrieveNotification();
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.get("subscription").on("notification", this, this.onNewNotification);
    },

    onNewNotification: function onNewNotification(notification) {
      notification.date = new Date(notification.date);
      this.get("model").pushObject(notification);
    },

    retrieveNotification: function retrieveNotification(index) {
      // not sure why but model.firstObject is undefined when there's one notification
      var notification = this.get("model") && this.get("model")[index || 0];
      if (!notification) {
        return null;
      }

      this.setRoute(notification);

      return notification;
    },

    setRoute: function setRoute(notification) {
      var route = undefined;

      if (notification.category === "message") {
        if (notification.messageable_type === "Package") {
          route = ["items.staff_conversation", notification.package_id];
        } else if (notification.messageable_type === "Order") {
          if (notification.is_private) {
            route = ["orders.staff_conversation", notification.messageable_id];
          } else {
            route = ["orders.conversation", notification.messageable_id];
          }
        }
      } else {
        route = ["orders.active_items", notification.order_id];
      }

      notification.route = route;
    },

    redirectToOrderDetail: function redirectToOrderDetail(orderId) {
      this.transitionToRoute("orders.active_items", orderId);
    },

    actions: {
      view: function view() {
        var notification = this.get("nextNotification");
        this.get("model").removeObject(notification);
        this.transitionToRoute.apply(this, notification.route);
      },

      unloadNotifications: function unloadNotifications() {
        this.set("model", []);
      }
    }
  });
});