define("stock/components/goodcity/remove-item-from-container", ["exports", "ember", "lodash", "stock/mixins/async", "stock/mixins/item_actions"], function (exports, _ember, _lodash, _stockMixinsAsync, _stockMixinsItem_actions) {
  var getOwner = _ember["default"].getOwner;
  exports["default"] = _ember["default"].Component.extend(_stockMixinsAsync["default"], _stockMixinsItem_actions["default"], {
    packageService: _ember["default"].inject.service(),
    locationService: _ember["default"].inject.service(),

    isValidQuantity: _ember["default"].computed("maxRemovableQuantity", "removableQuantity", function () {
      var value = +this.get("removableQuantity");
      return value > 0 && value <= +this.get("maxRemovableQuantity");
    }),

    actions: {
      beginUnpack: function beginUnpack(container, item, quantity) {
        var selectedLocation;
        return regeneratorRuntime.async(function beginUnpack$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              this.set("openRemoveItemOverlay", false);

              context$1$0.next = 3;
              return regeneratorRuntime.awrap(this.get("locationService").userPickLocation());

            case 3:
              selectedLocation = context$1$0.sent;

              if (selectedLocation) {
                context$1$0.next = 8;
                break;
              }

              return context$1$0.abrupt("return");

            case 8:
              this.set("removableQuantity", quantity);
              this.set("maxRemovableQuantity", quantity);

              this.set("location", selectedLocation);
              this.set("openRemoveItemOverlay", true);

            case 12:
            case "end":
              return context$1$0.stop();
          }
        }, null, this);
      },

      performUnpack: function performUnpack() {
        return regeneratorRuntime.async(function performUnpack$(context$1$0) {
          var _this = this;

          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              context$1$0.next = 2;
              return regeneratorRuntime.awrap(this.runTask(function () {
                return _this.unpack(_this.get("container"), _this.get("item"), _this.get("location.id"), _this.get("removableQuantity"), _this.get("onUnpackCallback"));
              }, _stockMixinsAsync.ERROR_STRATEGIES.MODAL));

            case 2:

              this.set("openRemoveItemOverlay", false);

            case 3:
            case "end":
              return context$1$0.stop();
          }
        }, null, this);
      },

      cancelAction: function cancelAction() {
        this.set("openRemoveItemOverlay", false);
      }
    }
  });
});