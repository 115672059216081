define('stock/models/address', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember'], function (exports, _emberDataModel, _emberDataAttr, _emberDataRelationships, _ember) {
  exports['default'] = _emberDataModel['default'].extend({
    flat: (0, _emberDataAttr['default'])('string'),
    building: (0, _emberDataAttr['default'])('string'),
    street: (0, _emberDataAttr['default'])('string'),
    addressType: (0, _emberDataAttr['default'])('string'),
    notes: (0, _emberDataAttr['default'])('string'),

    districtId: (0, _emberDataAttr['default'])('number'),
    district: (0, _emberDataRelationships.belongsTo)('district', { async: false }),

    addressableType: (0, _emberDataAttr['default'])('string'),
    addressable: (0, _emberDataRelationships.belongsTo)('addressable', { polymorphic: true, async: false }),

    fullAddress: _ember['default'].computed('flat', 'building', 'street', function () {
      return this.get('flat') + ' ' + this.get('building') + ' ' + this.get('street');
    })
  });
});