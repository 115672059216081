define("stock/components/donation-inline-input", ["exports", "ember", "stock/utils/ajax-promise", "stock/config/environment"], function (exports, _ember, _stockUtilsAjaxPromise, _stockConfigEnvironment) {
  var getOwner = _ember["default"].getOwner;
  exports["default"] = _ember["default"].TextField.extend({
    tagName: "input",
    type: "text",
    isMobileApp: _stockConfigEnvironment["default"].cordova.enabled,
    isEditable: false,
    attributeBindings: ["name", "type", "value", "maxlength", "id", "autoFocus", "placeholder", "required", "pattern"],
    store: _ember["default"].inject.service(),
    previousValue: "",
    displayScanner: false,
    item: null,

    focusTrigger: _ember["default"].observer("value", function () {
      this.$().focus();
    }),

    removeScanner: function removeScanner() {
      this.set("displayScanner", false);
    },

    focusOut: function focusOut() {
      var _this = this;

      var item = this.get("item");
      var url = "/packages/" + item.get("id");
      var key = this.get("name");
      var packageParams = {};
      packageParams[key] = this.get("value") || "";

      _ember["default"].$(this.element).removeClass("inline-text-input");
      if (packageParams[key].toString() !== this.get("previousValue").toString()) {
        var loadingView = getOwner(this).lookup("component:loading").append();
        new _stockUtilsAjaxPromise["default"](url, "PUT", this.get("session.authToken"), {
          "package": packageParams
        }).then(function (data) {
          _this.get("store").pushPayload(data);
        })["finally"](function () {
          loadingView.destroy();
        });
      }
      _ember["default"].$(this.element).removeClass("inline-text-input");
      _ember["default"].run.debounce(this, this.removeScanner, 2000);
    },

    focusIn: function focusIn() {
      this.addCssStyle();
    },

    addCssStyle: function addCssStyle() {
      _ember["default"].$(this.element).addClass("inline-text-input");
      if (this.get("isMobileApp")) {
        this.set("displayScanner", true);
      }
    },

    click: function click() {
      this.addCssStyle();
      this.set("previousValue", this.get("value") || "");
    }
  });
});