define("stock/services/goodcity-request-service", ["exports", "stock/services/api-base-service"], function (exports, _stockServicesApiBaseService) {
  exports["default"] = _stockServicesApiBaseService["default"].extend({
    store: Ember.inject.service(),

    createGcRequest: function createGcRequest(gcRequestParams) {
      var gcRequest;
      return regeneratorRuntime.async(function createGcRequest$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return regeneratorRuntime.awrap(this.POST("/goodcity_requests", {
              goodcity_request: gcRequestParams
            }));

          case 2:
            gcRequest = context$1$0.sent;

            this.get("store").pushPayload(gcRequest);
            return context$1$0.abrupt("return", gcRequest);

          case 5:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    updateGcRequest: function updateGcRequest(reqId, gcRequestParams) {
      var gcRequest;
      return regeneratorRuntime.async(function updateGcRequest$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return regeneratorRuntime.awrap(this.PUT("/goodcity_requests/" + reqId, {
              goodcity_request: gcRequestParams
            }));

          case 2:
            gcRequest = context$1$0.sent;

            this.get("store").pushPayload(gcRequest);
            return context$1$0.abrupt("return", this.get("store").peekRecord("goodcity_request", reqId));

          case 5:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    deleteRequest: function deleteRequest(reqId) {
      var _this = this;

      return this.DELETE("/goodcity_requests/" + reqId).then(function (gcRequest) {
        _this.get("store").pushPayload(gcRequest);
      });
    }
  });
});