define('stock/routes/orders/add_request', ['exports', 'stock/routes/orders/get_order', 'ember'], function (exports, _stockRoutesOrdersGet_order, _ember) {
  exports['default'] = _stockRoutesOrdersGet_order['default'].extend({
    model: function model(params) {
      var order = this.store.peekRecord("designation", params.orderId);
      return _ember['default'].RSVP.hash({
        order: order || this.store.findRecord('designation', params.orderId),
        codes: this.store.query('code', { stock: true })
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('quantity', 1);
    }
  });
});