define("stock/utils/unique-array", ["exports"], function (exports) {
  exports["default"] = {
    removeDuplicates: function removeDuplicates(array, prop) {
      return array.filter(function (obj, pos, arr) {
        return arr.map(function (mapObj) {
          return mapObj[prop];
        }).indexOf(obj[prop]) === pos;
      });
    }
  };
});