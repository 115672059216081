define("stock/services/messages", ["exports", "ember", "stock/utils/ajax-promise"], function (exports, _ember, _stockUtilsAjaxPromise) {
  var getOwner = _ember["default"].getOwner;
  exports["default"] = _ember["default"].Service.extend({
    logger: _ember["default"].inject.service(),
    session: _ember["default"].inject.service(),
    store: _ember["default"].inject.service(),
    subscription: _ember["default"].inject.service(),

    unreadMessageCount: 0,

    init: function init() {
      this._super.apply(this, arguments);
      this.get("subscription").on("change:message", this, this.onNewNotification);
    },

    onNewNotification: function onNewNotification(notification) {
      var msg = this.get("store").peekRecord("message", notification.record.id);

      if (notification.operation === "create" && msg.get("isUnread")) {
        this._incrementCount();
      } else if (notification.operation === "update" && notification.record.state === "read") {
        this._decrementCount();
      }
    },

    fetchUnreadMessageCount: function fetchUnreadMessageCount() {
      var _this = this;

      return this._queryMessages("unread", 1, 1).then(function (data) {
        var count = data.meta && data.meta.total_count;
        _this.set("unreadMessageCount", count || 0);
      })["catch"](function (e) {
        return _this._onError(e);
      });
    },

    _queryMessages: function _queryMessages(state, page, perPage) {
      return new _stockUtilsAjaxPromise["default"]("/messages", "GET", this.get("session.authToken"), {
        state: state,
        messageable_type: ["Order", "Package"],
        page: page,
        per_page: perPage
      });
    },

    queryNotifications: function queryNotifications(page, state) {
      var params = {
        page: page,
        state: state,
        messageable_type: ["Order", "Package"]
      };

      return new _stockUtilsAjaxPromise["default"]("/messages/notifications", "GET", this.get("session.authToken"), params);
    },

    markRead: function markRead(message) {
      var _this2 = this;

      if (message.get("isUnread")) {
        var adapter = getOwner(this).lookup("adapter:application");
        var url = adapter.buildURL("message", message.id) + "/mark_read";
        adapter.ajax(url, "PUT").then(function (data) {
          delete data.message.id;
          message.setProperties(data.message);
          _this2._decrementCount();
        })["catch"](function (error) {
          return _this2.get("logger").error(error);
        });
      }
    },

    markAllRead: function markAllRead() {
      var _this3 = this;

      return new _stockUtilsAjaxPromise["default"]("/messages/mark_all_read", "PUT", this.get("session.authToken"), {
        scope: ["order", "package"]
      }).then(function () {
        _this3.get("store").peekAll("message").filterBy("state", "unread").forEach(function (message) {
          message.set("state", "read");
        });
        _this3.set("unreadMessageCount", 0);
      });
    },

    _onError: function _onError(e) {
      this.get("logger").error(e);
    },

    getMessageRoute: function getMessageRoute(messageableId, messageableType, isPrivate) {
      messageableType = messageableType === "Package" ? "item" : messageableType;

      if (isPrivate) {
        return [messageableType.toLowerCase() + "s.staff_conversation", messageableId];
      } else if (messageableType === "Order") {
        return ["orders.conversation", messageableId];
      }
    },

    getRoute: function getRoute(message) {
      var messageableId = message.get ? message.get("messageableId") : message.messageable_id;

      var messageableType = message.get ? message.get("messageableType") : message.messageable_type;

      var messageRoute = this.getMessageRoute(messageableId, messageableType, message.get("isPrivate"));
      return messageRoute;
    },

    _incrementCount: function _incrementCount() {
      var step = arguments.length <= 0 || arguments[0] === undefined ? 1 : arguments[0];

      var count = this.get("unreadMessageCount") + step;
      if (count < 0) {
        this.set("unreadMessageCount", 0);
      } else {
        this.set("unreadMessageCount", count);
      }
    },

    _decrementCount: function _decrementCount() {
      this._incrementCount(-1);
    }
  });
});