define("stock/routes/stocktakes/detail", ["exports", "stock/routes/authorize", "ember"], function (exports, _stockRoutesAuthorize, _ember) {

  var requiresReload = (function (timeout) {
    var tracker = {};
    return function (id) {
      var lastVisit = tracker[id] || 0;
      var now = Date.now();
      tracker[id] = now;
      return now - lastVisit >= timeout;
    };
  })(3 * 60 * 1000);

  exports["default"] = _stockRoutesAuthorize["default"].extend({
    model: function model(_ref) {
      var stocktake_id = _ref.stocktake_id;

      return _ember["default"].RSVP.hash({
        stocktake: this.store.findRecord("stocktake", stocktake_id, {
          reload: requiresReload(stocktake_id)
        })
      });
    },

    setupController: function setupController(controller, _ref2) {
      var stocktake = _ref2.stocktake;

      controller.set("stocktake", stocktake);
      controller.set("selectedRevisionId", null);
      controller.on();
    },

    resetController: function resetController(controller) {
      controller.off();
    }
  });
});