define("stock/services/subform-detail-service", ["exports", "ember", "lodash", "stock/services/api-base-service", "ember-inflector", "lodash/snakeCase"], function (exports, _ember, _lodash, _stockServicesApiBaseService, _emberInflector, _lodashSnakeCase) {
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var getOwner = _ember["default"].getOwner;
  exports["default"] = _stockServicesApiBaseService["default"].extend({
    session: _ember["default"].inject.service(),
    store: _ember["default"].inject.service(),
    messageBox: _ember["default"].inject.service(),

    isSubformAvailable: function isSubformAvailable(subformName) {
      return ["computer", "electrical", "computer_accessory", "medical"].indexOf(subformName) >= 0;
    },

    isValueChanged: function isValueChanged(newValue, previousValue) {
      return newValue !== previousValue;
    },

    updateRequestAction: function updateRequestAction(paramsObj) {
      var _this = this;

      var loadingView = getOwner(this).lookup("component:loading").append();
      return this.PUT(paramsObj.url, _defineProperty({}, paramsObj.detailType, paramsObj.packageDetailParams)).then(function (data) {
        _this.get("store").pushPayload(data);
        return data;
      })["catch"](function (xhr) {
        if (xhr.status === 422) {
          _this.get("messageBox").alert(xhr.responseJSON.errors[0].message);
        } else {
          throw xhr;
        }
      })["finally"](function () {
        loadingView.destroy();
      });
    },

    updateRequest: function updateRequest(paramsObj, previousValue) {
      if (this.isValueChanged(paramsObj.packageDetailParams[paramsObj.snakeCaseKey], previousValue)) {
        return this.updateRequestAction(paramsObj);
      }
    },

    deleteDetailType: function deleteDetailType(type, detailId) {
      var apiEndpoint = (0, _emberInflector.pluralize)((0, _lodashSnakeCase["default"])(type).toLowerCase());
      return this.DELETE("/" + apiEndpoint + "/" + detailId);
    }
  });
});