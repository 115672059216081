define("stock/models/location", ["exports", "ember", "ember-data/model", "ember-data/relationships", "ember-data/attr"], function (exports, _ember, _emberDataModel, _emberDataRelationships, _emberDataAttr) {
  exports["default"] = _emberDataModel["default"].extend({
    area: (0, _emberDataAttr["default"])("string"),
    building: (0, _emberDataAttr["default"])("string"),
    createdAt: (0, _emberDataAttr["default"])("date"),
    recentlyUsedAt: (0, _emberDataAttr["default"])("date"),
    items: (0, _emberDataRelationships.hasMany)("item", { async: false }),
    package_locations: (0, _emberDataRelationships.hasMany)("location", { async: false }),

    name: _ember["default"].computed("area", "building", function () {
      var area = this.get("area");
      var building = this.get("building");
      return area ? building + "-" + area : building;
    }),

    displayName: _ember["default"].computed("area", "building", function () {
      var area = this.get("area");
      var building = this.get("building");
      return area ? "" + building + area : building;
    })
  });
});