define("stock/adapters/item_action", ["exports", "stock/adapters/application", "lodash", "stock/config/environment"], function (exports, _stockAdaptersApplication, _lodash, _stockConfigEnvironment) {
  exports["default"] = _stockAdaptersApplication["default"].extend({
    urlForQuery: function urlForQuery(query, modelName) {
      var _config$APP = _stockConfigEnvironment["default"].APP;
      var NAMESPACE = _config$APP.NAMESPACE;
      var API_HOST_URL = _config$APP.API_HOST_URL;

      var paramStr = _lodash["default"].map(query, function (value, key) {
        return key + "=" + value;
      }).join("&");
      return API_HOST_URL + "/" + NAMESPACE + "/packages_inventories?" + paramStr;
    }
  });
});