define("stock/components/goodcity/locations-search-overlay", ["exports", "ember", "lodash", "stock/components/base/global", "stock/mixins/search_resource", "stock/utils/async"], function (exports, _ember, _lodash, _stockComponentsBaseGlobal, _stockMixinsSearch_resource, _stockUtilsAsync) {

  /**
   * An overlay that pops up from the bottom of the screen, allowing the user
   * to search and select a location.
   *
   * The popup *does not* do anything to the location apart from returning it
   *
   * @property {boolean} open whether the popup is visible or not
   * @property {function} onSelect callback triggered when an order is selected
   */
  exports["default"] = _stockComponentsBaseGlobal["default"].extend(_stockMixinsSearch_resource["default"], {
    searchProps: {},
    autoLoad: true,
    store: _ember["default"].inject.service(),
    perPage: 10,

    showRecentlyUsed: _ember["default"].computed.not("searchText"),
    headerText: _ember["default"].computed.alias("options.headerText"),
    presetLocations: _ember["default"].computed.alias("options.presetLocations"),

    init: function init() {
      this._super("location-search-overlay");
    },

    onSearchTextChange: _ember["default"].observer("searchText", function () {
      this.hideResults();
      if (this.get("searchText").length) {
        _ember["default"].run.debounce(this, this.showResults, 500);
      }
    }),

    recentlyUsedLocations: _ember["default"].computed("open", function () {
      return this.get("store").peekAll("location").sortBy("recentlyUsedAt").slice(0, 10);
    }),

    isLocationPresent: function isLocationPresent(searchText) {
      return function (location) {
        return location.get("name").toLowerCase().indexOf(searchText) > -1;
      };
    },

    actions: {
      cancel: function cancel() {
        this.send("selectLocation", null);
        this.set("searchText", "");
      },

      selectLocation: function selectLocation(location) {
        var callback;
        return regeneratorRuntime.async(function selectLocation$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              callback = this.getWithDefault("onSelect", _lodash["default"].noop);

              this.set("open", false);
              this.set("searchText", "");

              // Delay the return a little bit for the animation to start
              (0, _stockUtilsAsync.stagerred)(location).then(callback);

            case 4:
            case "end":
              return context$1$0.stop();
          }
        }, null, this);
      },

      loadMoreLocations: function loadMoreLocations(pageNo) {
        if (!this.isValidTextLength()) {
          return;
        }

        if (this.get("presetLocations.length")) {
          var searchText = this.getSearchQuery().searchText.toLowerCase();
          return this.get("presetLocations").filter(this.isLocationPresent(searchText));
        } else {
          var params = this.trimQuery(_lodash["default"].merge({}, this.getSearchQuery(), this.getPaginationQuery(pageNo)));

          return this.get("store").query("location", params);
        }
      }
    }
  });
});