define("stock/models/role", ["exports", "ember", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (exports, _ember, _emberDataModel, _emberDataAttr, _emberDataRelationships) {
  exports["default"] = _emberDataModel["default"].extend({
    name: (0, _emberDataAttr["default"])("string"),
    level: (0, _emberDataAttr["default"])("number"),

    rolePermissions: (0, _emberDataRelationships.hasMany)("rolePermission", { async: false }),

    permissions: _ember["default"].computed("rolePermissions.[]", function () {
      return this.get("rolePermissions").getEach("permission");
    }),

    permissionNames: _ember["default"].computed("permissions.[]", function () {
      return this.get("permissions").getEach("name");
    })
  });
});