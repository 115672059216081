define("stock/controllers/appointments", ["exports", "ember", "lodash", "stock/config/environment", "stock/utils/appointments", "stock/controllers/goodcity_controller"], function (exports, _ember, _lodash, _stockConfigEnvironment, _stockUtilsAppointments, _stockControllersGoodcity_controller) {
  exports["default"] = _stockControllersGoodcity_controller["default"].extend({
    // ---- Tab selection

    isPresetsTab: _ember["default"].computed.equal("selectedTab", "presets"),
    isSpecialTab: _ember["default"].computed.not("isPresetsTab"),
    hkTimeZone: _stockConfigEnvironment["default"].APP.HK_TIME_ZONE,

    // ---- Data structure

    timeSlots: _ember["default"].computed(function () {
      return _stockUtilsAppointments["default"].timeslots();
    }),

    presets: _ember["default"].computed("model.appointmentSlotPresets.@each.{day,hours,minutes,quota}", function () {
      return _stockUtilsAppointments["default"].makeSelectableList(this.get("model.appointmentSlotPresets"));
    }),

    specialSlots: _ember["default"].computed("model.appointmentSlots.@each.{quota,note,timestamp}", function () {
      return _stockUtilsAppointments["default"].makeSelectableList(this.get("model.appointmentSlots"));
    }),

    /* Aggregate the presets by days of the week */
    presetsByWeekDay: _ember["default"].computed("presets.@each.{record,timeslot}", function () {
      var _this = this;

      return _lodash["default"].range(1, 8).map(function (day) {
        var presets = _this.get("presets").filter(function (pre) {
          return pre.get("record.day") === day;
        });
        return _ember["default"].Object.create({
          idx: day,
          empty: presets.length === 0,
          items: presets,
          showOptionsMenu: false
        });
      });
    }),

    /* Aggregate the speical days slots by their dates */
    specialSlotsByDate: _ember["default"].computed("specialSlots.@each.{record,timeslot}", function () {
      var slotsByDates = [];
      this.get("specialSlots").forEach(function (slot) {
        var record = slot.record;
        var dateString = _stockUtilsAppointments["default"].getDateStringOfSlot(record);

        var aggregate = _lodash["default"].find(slotsByDates, ["dateString", dateString]);
        if (!aggregate) {
          aggregate = {
            dateString: dateString,
            date: new Date(record.get("timestamp")),
            note: "",
            items: [],
            showOptionsMenu: false,
            noAppointments: true
          };
          slotsByDates.push(aggregate);
        }

        aggregate.items.push(slot);
        if (record.get("quota") > 0) {
          aggregate.noAppointments = false;
        } else if (record.get("note")) {
          // 0 quota slots are just used as placeholders to lock down the dates.
          // We display the note but they will not be displayed as editable slots
          aggregate.note = record.get("note");
        }
      });
      return slotsByDates.map(function (o) {
        return _ember["default"].Object.create(o);
      });
    }),

    init: function init() {
      this._super();
      this.set("selectedTab", "presets");
      this.set("dateCreationPopupVisible", false);
      this.set("selectedDate", null);
      this.set("incompleteForm", false);
      this.set("dateDescription", "");
    },

    // ---- Helpers

    getOpenPresetTimeslot: function getOpenPresetTimeslot(dayNumber) {
      var _this2 = this;

      return _stockUtilsAppointments["default"].timeslots().find(function (ts) {
        return !_this2.get("model.appointmentSlotPresets").find(function (record) {
          return record.get("day") === dayNumber && record.get("hours") === ts.hours && record.get("minutes") === ts.minutes;
        });
      });
    },

    getOpenDateTimeslot: function getOpenDateTimeslot(date) {
      var _this3 = this;

      return _stockUtilsAppointments["default"].timeslots().find(function (ts) {
        return !_this3.get("model.appointmentSlots").find(function (record) {
          var recordDate = new Date(record.get("timestamp"));
          return _stockUtilsAppointments["default"].sameDay(date, recordDate) && record.get("quota") > 0 && _stockUtilsAppointments["default"].getTimeStringOf(record) === ts.time;
        });
      });
    },

    // ---- Controller implementation

    actions: {
      back: function back() {
        this.back();
      },

      showSpecial: function showSpecial() {
        this.set("selectedTab", "special");
        this.get("specialSlotsByDate").forEach(function (days) {
          return days.set("showOptionsMenu", false);
        });
      },

      showDefault: function showDefault() {
        this.set("selectedTab", "presets");
        this.get("presetsByWeekDay").forEach(function (days) {
          return days.set("showOptionsMenu", false);
        });
      },

      toggleOptionsMenu: function toggleOptionsMenu(obj) {
        obj.set("showOptionsMenu", !obj.get("showOptionsMenu"));
      },

      showDateCreationPopup: function showDateCreationPopup() {
        this.set("dateCreationPopupVisible", true);
      },

      cancelDateCreation: function cancelDateCreation() {
        this.set("selectedDate", null);
        this.set("dateCreationPopupVisible", false);
        this.set("incompleteForm", false);
        this.set("dateDescription", "");
      },

      createNewSpecialDate: function createNewSpecialDate() {
        if (!this.get("dateDescription") || !this.get("selectedDate")) {
          this.set("incompleteForm", true);
          return false;
        }

        var note = this.get("dateDescription");
        var timestamp = this.get("selectedDate");
        var zone_timestamp = moment.tz(timestamp.toDateString(), this.get("hkTimeZone")).toDate();
        var clear = this.send.bind(this, "cancelDateCreation");
        var dateExists = this.get("model.appointmentSlots").find(function (sl) {
          return _stockUtilsAppointments["default"].sameDay(sl.get("timestamp"), timestamp);
        });

        if (dateExists) {
          this.showError("Selected date is already scheduled", clear);
          return;
        }
        this.createRecord("appointment_slot", {
          timestamp: zone_timestamp,
          note: note,
          quota: 0
        })["finally"](clear);
      },

      newSpecialSlot: function newSpecialSlot(date) {
        var quota = arguments.length <= 1 || arguments[1] === undefined ? 1 : arguments[1];
        var note = arguments.length <= 2 || arguments[2] === undefined ? "" : arguments[2];

        var _getOpenDateTimeslot = this.getOpenDateTimeslot(date);

        var hours = _getOpenDateTimeslot.hours;
        var minutes = _getOpenDateTimeslot.minutes;

        var timestamp = moment.utc(date).local().set("hours", hours).set("minutes", minutes).toDate();
        this.createRecord("appointment_slot", { quota: quota, note: note, timestamp: timestamp });
      },

      newPreset: function newPreset(day) {
        var quota = arguments.length <= 1 || arguments[1] === undefined ? 1 : arguments[1];

        var _getOpenPresetTimeslot = this.getOpenPresetTimeslot(day);

        var hours = _getOpenPresetTimeslot.hours;
        var minutes = _getOpenPresetTimeslot.minutes;

        this.createRecord("appointment_slot_preset", {
          quota: quota,
          hours: hours,
          minutes: minutes,
          day: day
        });
      },

      increaseQuotaOf: function increaseQuotaOf(record) {
        return this.updateRecord(record, { quota: record.get("quota") + 1 });
      },

      decreaseQuotaOf: function decreaseQuotaOf(record) {
        var quota = record.get("quota");
        if (quota > 1) {
          return this.updateRecord(record, { quota: quota - 1 });
        }
      },

      updateSlotTime: function updateSlotTime(slot, day, newTimeSlot) {
        var _this4 = this;

        var record = slot.get("record");
        var currentTime = new Date(record.get("timestamp"));
        var updatedTime = new Date(record.get("timestamp"));

        updatedTime.setHours(newTimeSlot.hours);
        updatedTime.setMinutes(newTimeSlot.minutes);
        if (currentTime !== updatedTime) {
          var _ret = (function () {
            // Has been modified
            var resetInput = function resetInput() {
              return slot.set("timeslot", _stockUtilsAppointments["default"].getTimeSlotOf(record));
            };
            var timeString = newTimeSlot.time;
            var timeslotAlreadyInUse = !!day.items.find(function (it) {
              return _stockUtilsAppointments["default"].getTimeStringOf(it.get("record")) === timeString;
            });
            if (timeslotAlreadyInUse) {
              return {
                v: _this4.showError("An appointment slot already exists for this time", resetInput)
              };
            }
            _this4.updateRecord(record, { timestamp: updatedTime }, { onFailure: resetInput });
          })();

          if (typeof _ret === "object") return _ret.v;
        }
      },

      updatePresetTime: function updatePresetTime(item, day, _ref) {
        var time = _ref.time;
        var hours = _ref.hours;
        var minutes = _ref.minutes;

        var record = item.get("record");
        if (record.get("hours") !== hours || record.get("minutes") !== minutes) {
          // Has been modified
          var resetInput = function resetInput() {
            return item.set("timeslot", _stockUtilsAppointments["default"].getTimeSlotOf(record));
          };
          var timeslotAlreadyInUse = !!day.items.find(function (it) {
            return _stockUtilsAppointments["default"].getTimeStringOf(it.get("record")) === time;
          });
          if (timeslotAlreadyInUse) {
            return this.showError("An appointment slot preset already exists for this time", resetInput);
          }
          this.updateRecord(record, { hours: hours, minutes: minutes }, { onFailure: resetInput });
        }
      },

      deleteRecord: function deleteRecord(record) {
        this.deleteRecords([record]);
      },

      deleteAllItems: function deleteAllItems(items) {
        this.deleteRecords(items.map(function (it) {
          return it.record;
        }));
      }
    }
  });
});