define("stock/helpers/arr", ["exports", "ember"], function (exports, _ember) {

  /**
   * Returns an array
   *
   * Example:
   *
   * {{#my-component
   *   data=(arr 'val1' 'val2' 'val3')
   * }}
   *
   */
  exports["default"] = _ember["default"].Helper.helper(function (args) {
    return args;
  });
});