define("stock/adapters/application", ["exports", "ember", "stock/config/environment", "active-model-adapter"], function (exports, _ember, _stockConfigEnvironment, _activeModelAdapter) {
  exports["default"] = _activeModelAdapter["default"].extend({
    namespace: _stockConfigEnvironment["default"].APP.NAMESPACE,
    host: _stockConfigEnvironment["default"].APP.API_HOST_URL,
    session: _ember["default"].inject.service(),

    headers: _ember["default"].computed("session.authToken", function () {
      return {
        Authorization: "Bearer " + this.get("session.authToken"),
        "Accept-Language": this.get("session.language"),
        "X-GOODCITY-APP-NAME": _stockConfigEnvironment["default"].APP.NAME,
        "X-GOODCITY-APP-VERSION": _stockConfigEnvironment["default"].APP.VERSION,
        "X-GOODCITY-APP-SHA": _stockConfigEnvironment["default"].APP.SHA,
        "X-GOODCITY-DEVICE-ID": this.get("session.deviceId")
      };
    }),

    buildURL: function buildURL(modelName, id, snapshot, requestType) {
      if (modelName === "order" && requestType === "findAll") {
        return _stockConfigEnvironment["default"].APP.API_HOST_URL + "/" + _stockConfigEnvironment["default"].APP.NAMESPACE + "/designations";
      }
      return this._super.apply(this, arguments);
    },

    urlForFindRecord: function urlForFindRecord(id, modelName, opts) {
      if (modelName === "item") {
        _ember["default"].set(arguments, "1", "stockit_item");
      }

      var url = this._super.apply(this, arguments);

      if (opts && opts.adapterOptions) {
        var queryString = new URLSearchParams(opts.adapterOptions).toString();

        if (/\?/.test(url)) {
          url += "&" + queryString;
        } else {
          url += "?" + queryString;
        }
      }

      return url;
    },

    urlForQuery: function urlForQuery(query, modelName) {
      if (modelName === "code") {
        _ember["default"].set(arguments, "1", "package_type");
      }
      return this._super.apply(this, arguments);
    }
  });
});