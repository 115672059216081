define("stock/routes/appointments", ["exports", "ember", "stock/routes/authorize"], function (exports, _ember, _stockRoutesAuthorize) {
  exports["default"] = _stockRoutesAuthorize["default"].extend({
    templateName: "settings/appointments/manage_quotas",

    model: function model() {
      return _ember["default"].RSVP.hash({
        appointmentSlotPresets: this.store.findAll("appointment_slot_preset"),
        appointmentSlots: this.store.findAll("appointment_slot")
      });
    }
  });
});