define('stock/routes/search_code', ['exports', 'stock/routes/authorize'], function (exports, _stockRoutesAuthorize) {
  exports['default'] = _stockRoutesAuthorize['default'].extend({
    queryParams: {
      backToNewItem: false
    },

    model: function model() {
      return this.store.query('code', { stock: true });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('searchText', "");
    }
  });
});