define("stock/components/goodcity/designate-form", ["exports", "ember", "lodash"], function (exports, _ember, _lodash) {

  /**
   * Helper properties for the designation form
   *
   */
  exports["default"] = _ember["default"].Component.extend({
    showItemAvailability: false,
    settings: _ember["default"].inject.service(),
    designationService: _ember["default"].inject.service(),

    editableQty: _ember["default"].computed.alias("settings.allowPartialOperations"),

    // Check quantities for anomalies
    canComplete: _ember["default"].computed("quantity", "maxQuantity", function () {
      return this.get("maxQuantity") > 0 && this.get("quantity") > 0 && this.get("quantity") <= this.get("maxQuantity");
    }),

    onOrderOrItemChange: _ember["default"].observer("order", "pkg", function () {
      if (!this.cancelledOrderPackage(this.get("pkg"), this.get("order"))) {
        this.set("quantity", this.get("maxQuantity"));
      }
    }),

    cancelledOrderPackage: function cancelledOrderPackage(pkg, order) {
      var cancel = false;
      var ordPkg = pkg && pkg.get("ordersPackages").find(function (op) {
        return parseInt(op.get("orderId")) === parseInt(order.get("id"));
      });

      if (ordPkg && ordPkg.get("isCancelled")) {
        cancel = true;
      }
      return cancel;
    },

    // Lists other orders the package is designated to
    otherDesignations: _ember["default"].computed("order.id", "pkg", "pkg.ordersPackages.[]", "pkg.ordersPackages.@each.{state,quantity}", function () {
      if (!this.get("pkg")) {
        return false;
      }

      return this.get("pkg.ordersPackages").filterBy("state", "designated").rejectBy("id", this.get("order.id"));
    }),

    // Whether the package is designated to some other order
    packageHasOtherDesignations: _ember["default"].computed("otherDesignations.length", function () {
      return this.get("otherDesignations.length") > 0;
    }),

    // Will suggest user to undesignate if the available quantity is 0 and some
    // other order exists
    suggestUndesignation: _ember["default"].computed("maxQuantity", "packageHasOtherDesignations", function () {
      if (this.get("maxQuantity") > 0) {
        return false;
      }

      return this.get("packageHasOtherDesignations");
    }),

    actions: {
      displayItemAvailabilityOverlay: function displayItemAvailabilityOverlay() {
        this.set("showItemAvailability", true);
      },

      closeItemAvailabilityOverlay: function closeItemAvailabilityOverlay() {
        this.set("showItemAvailability", false);
      },

      gainItem: function gainItem() {
        this.set("showItemAvailability", false);
        this.set("readyToDesignate", false);
        this.router.transitionTo("items.detail.publishing", this.get("pkg.id"));
      },

      modifyDesignation: function modifyDesignation() {
        this.set("showItemAvailability", false);
        this.set("readyToDesignate", false);
        this.router.transitionTo("items.detail.publishing", this.get("pkg.id"));
      },

      modifyBoxPalletAllocation: function modifyBoxPalletAllocation() {
        this.set("showItemAvailability", false);
        this.set("readyToDesignate", false);
        this.router.transitionTo("items.detail.location", this.get("pkg.id"));
      }
    }
  });
});