define('stock/routes/order/appointment_details', ['exports', 'ember', 'stock/utils/ajax-promise', 'stock/routes/order/order_user_organisation'], function (exports, _ember, _stockUtilsAjaxPromise, _stockRoutesOrderOrder_user_organisation) {
  exports['default'] = _stockRoutesOrderOrder_user_organisation['default'].extend({
    /* jshint ignore:start */
    model: function model() {
      var orderTransport,
          orderUserOrganisation,
          orderId,
          args$1$0 = arguments;
      return regeneratorRuntime.async(function model$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            orderTransport = this.store.peekAll("orderTransport");
            context$1$0.next = 3;
            return regeneratorRuntime.awrap(this._super.apply(this, args$1$0));

          case 3:
            orderUserOrganisation = context$1$0.sent;
            orderId = orderUserOrganisation.order.id;
            return context$1$0.abrupt('return', _ember['default'].RSVP.hash({
              orderUserOrganisation: orderUserOrganisation,
              availableDatesAndtime: new _stockUtilsAjaxPromise['default']("/appointment_slots/calendar", "GET", this.get('session.authToken'), { to: moment().add(120, 'days').format('YYYY-MM-DD') }),
              orderTransport: orderTransport && orderTransport.filterBy("orderId", orderId).get("firstObject")
            }));

          case 6:
          case 'end':
            return context$1$0.stop();
        }
      }, null, this);
    },
    /* jshint ignore:end */

    setUpFormData: function setUpFormData(model, controller) {
      var selectedId = "self";
      var selectedTime = "11:00am";
      var selectedDate = null;
      var selectedSlot = null;
      var orderTransport = model.orderTransport;
      var availableDatesAndTime = model.availableDatesAndtime;
      var slots = null;

      if (orderTransport) {
        selectedId = orderTransport.get('transportType');
        selectedTime = orderTransport.get('timeslot');
        selectedDate = moment.tz(orderTransport.get("scheduledAt"), 'Asia/Hong_Kong');
        if (selectedDate) {
          slots = availableDatesAndTime.appointment_calendar_dates.filter(function (date) {
            return date.date === selectedDate.format('YYYY-MM-DD');
          })[0].slots;
          selectedSlot = slots.find(function (slot) {
            return slot.timestamp.indexOf(orderTransport.get("timeslot")) >= 0;
          });
        }
      }

      controller.set('selectedId', selectedId);
      controller.set('selectedTimeId', selectedTime);
      controller.set('available_dates', availableDatesAndTime);
      controller.set('selectedDate', selectedDate);
      if (selectedSlot) {
        controller.set("selectedTimeId", selectedSlot.timestamp);
      }
    },

    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      this.setUpFormData(model, controller);
    },

    deactivate: function deactivate() {
      this.controllerFor('application').set('showSidebar', true);
    }
  });
});
// jshint ignore:line
// jshint ignore:line