define("stock/serializers/item", ["exports", "stock/serializers/application", "lodash"], function (exports, _stockSerializersApplication, _lodash) {

  // @TODO: delete this serializer after renaming item to package
  exports["default"] = _stockSerializersApplication["default"].extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload.offers_packages) {
        payload.offers_packages.map(function (op) {
          op.item_id = op.package_id;
          delete op.package_id;
        });
      }
      return this._super.apply(this, arguments);
    }
  });
});