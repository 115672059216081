define("stock/models/electrical", ["exports", "ember-data/attr", "ember-data/relationships", "stock/models/detail"], function (exports, _emberDataAttr, _emberDataRelationships, _stockModelsDetail) {
  exports["default"] = _stockModelsDetail["default"].extend({
    brand: (0, _emberDataAttr["default"])("string"),
    model: (0, _emberDataAttr["default"])("string"),
    serialNumber: (0, _emberDataAttr["default"])("string"),
    countryId: (0, _emberDataAttr["default"])("number"),
    standard: (0, _emberDataAttr["default"])("string"),
    testStatusId: (0, _emberDataAttr["default"])("number"),
    testStatus: (0, _emberDataRelationships.belongsTo)("lookup"),
    frequencyId: (0, _emberDataAttr["default"])("number"),
    frequency: (0, _emberDataRelationships.belongsTo)("lookup"),
    voltageId: (0, _emberDataAttr["default"])("number"),
    voltage: (0, _emberDataRelationships.belongsTo)("lookup"),
    power: (0, _emberDataAttr["default"])("string"),
    systemOrRegion: (0, _emberDataAttr["default"])("string"),
    createdAt: (0, _emberDataAttr["default"])("date"),
    updatedAt: (0, _emberDataAttr["default"])("date"),
    item: (0, _emberDataRelationships.belongsTo)("item")
  });
});