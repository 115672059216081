define("stock/controllers/items/staff_conversation", ["exports", "ember", "stock/mixins/messages_base", "stock/mixins/async", "stock/constants/roles", "lodash"], function (exports, _ember, _stockMixinsMessages_base, _stockMixinsAsync, _stockConstantsRoles, _lodash) {
  exports["default"] = _ember["default"].Controller.extend(_stockMixinsMessages_base["default"], _stockMixinsAsync["default"], {
    isPrivate: true,

    canManageItemMessages: _ember["default"].computed.alias("session.currentUser.canManageItemMessages"),

    on: function on() {
      if (this.get("canManageItemMessages")) {
        this.get("subscription").on("change:message", this, this.markReadAndScroll);
      }
    },

    off: function off() {
      if (this.get("canManageItemMessages")) {
        this.get("subscription").off("change:message", this, this.markReadAndScroll);
      }
    },

    roles: _ember["default"].computed(function () {
      return _lodash["default"].values(_stockConstantsRoles.ROLES["STOCK_APP_ROLES"]);
    }),

    markReadAndScroll: function markReadAndScroll(_ref) {
      var record = _ref.record;

      this.markMessageAsRead(record);
      this.scrollToBottom();
    },

    scrollToBottom: function scrollToBottom() {
      if (!_ember["default"].$(".message-textbar").length) {
        return;
      }

      var scrollOffset = _ember["default"].$(document).height();
      var screenHeight = document.documentElement.clientHeight;
      var pageHeight = document.documentElement.scrollHeight;

      if (pageHeight > screenHeight) {
        _ember["default"].run.later(this, function () {
          window.scrollTo(0, scrollOffset);
        });
      }
    },

    actions: {
      sendMessage: function sendMessage() {
        _ember["default"].$("textarea").trigger("blur");
        var values = this.prepareMessageObject("Package");

        this.runTask(this.createMessage(values), _stockMixinsAsync.ERROR_STRATEGIES.MODAL);
      }
    }
  });
});