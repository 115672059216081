define("stock/components/image-zoom", ["exports", "ember", "stock/config/environment"], function (exports, _ember, _stockConfigEnvironment) {
  exports["default"] = _ember["default"].Component.extend({
    lightGallery: null,
    item: null,
    isMobileApp: _stockConfigEnvironment["default"].cordova.enabled,
    showSetImages: false,

    imageUrls: _ember["default"].computed("item.id", function () {
      if (this.get("showSetImages") && this.get("item.isPartOfSet")) {
        return this.get("item.setImages");
      }

      var images = this.get("item.images") || [this.get("item.image")];

      return this.get("captureFaceImage") ? images.mapBy("faceCaptureImageUrl") : images.mapBy("imageUrl");
    }),

    actions: {
      imageZoom: function imageZoom(imageUrl) {
        window.PhotoViewer.show(imageUrl, "", { share: false });
      }
    },

    didInsertElement: function didInsertElement() {
      var _this = this;

      if (!this.get("isMobileApp")) {
        this._super();
        _ember["default"].run.scheduleOnce("afterRender", this, function () {
          var lightGallery = _this.$().parent().lightGallery({
            mode: "lg-slide",
            zoom: true,
            download: false,
            scale: 1,
            hideControlOnEnd: true,
            closable: true,
            loop: true,
            counter: true,
            enableTouch: true,
            enableDrag: true,
            selector: ".imageZoom"
          });
          _this.set("lightGallery", lightGallery);
        });
      }
    },

    willDestroyElement: function willDestroyElement() {
      if (!this.get("isMobileApp")) {
        this.destroy();
      }
    }
  });
});