define("stock/initializers/session", ["exports"], function (exports) {
  exports["default"] = {
    name: "session",
    initialize: function initialize(application) {
      application.inject("controller", "session", "service:session");
      application.inject("route", "session", "service:session");
      application.inject("component", "session", "service:session");
      application.inject("component", "router", "router:main");
      application.inject("component", "filterService", "service:filterService");
      application.inject("service", "router", "router:main");
      application.inject("helper", "router", "router:main");
    }
  };
});