define("stock/services/auth-service", ["exports", "stock/services/api-base-service"], function (exports, _stockServicesApiBaseService) {
  exports["default"] = _stockServicesApiBaseService["default"].extend({
    opts: {
      authorizedRequest: false
    },

    verify: function verify(pin, otpAuthKey) {
      return this.POST("/auth/verify", {
        pin: pin,
        otp_auth_key: otpAuthKey
      }, this.get("opts"));
    },

    sendPin: function sendPin(mobile) {
      return this.POST("/auth/signup_and_send_pin", { mobile: mobile }, this.get("opts"));
    }
  });
});