define("stock/models/organisation", ["exports", "ember-data/model", "ember-data/attr", "ember", "ember-data/relationships"], function (exports, _emberDataModel, _emberDataAttr, _ember, _emberDataRelationships) {
  exports["default"] = _emberDataModel["default"].extend({
    nameEn: (0, _emberDataAttr["default"])("string"),
    nameZhTw: (0, _emberDataAttr["default"])("string"),
    descriptionEn: (0, _emberDataAttr["default"])("string"),
    descriptionZhTw: (0, _emberDataAttr["default"])("string"),
    website: (0, _emberDataAttr["default"])("string"),
    registration: (0, _emberDataAttr["default"])("string"),
    ordersCount: (0, _emberDataAttr["default"])("string"),
    countryId: (0, _emberDataAttr["default"])("string"),
    usersCount: _ember["default"].computed.alias("organisationsUsers.length"),
    country: _ember["default"].computed("countryId", function () {
      var countryId = this.get("countryId");
      if (countryId) {
        return this.store.peekRecord("country", this.get("countryId"));
      }
    }),
    organisationTypeId: (0, _emberDataAttr["default"])("string"),
    organisationsUsers: (0, _emberDataRelationships.hasMany)("organisations_user", { async: false }),
    designations: (0, _emberDataRelationships.hasMany)("designation", { async: false })
  });
});