define("stock/components/init-foundation", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    foundation: null,

    currentClassName: _ember["default"].computed("className", function () {
      return this.get("className") ? "." + this.get('className') : document;
    }),

    didInsertElement: function didInsertElement() {
      this._super();
      this.set("foundation", this.get("currentClassName"));
    }
  });
});