define("stock/controllers/items/index", ["exports", "stock/config/environment", "lodash", "stock/utils/mem-cache", "stock/mixins/search_resource"], function (exports, _stockConfigEnvironment, _lodash, _stockUtilsMemCache, _stockMixinsSearch_resource) {

  /**
   * @module Controllers/items/ItemsSearchController
   * @augments ember/Controller
   */
  exports["default"] = Ember.Controller.extend(_stockMixinsSearch_resource["default"], {
    session: Ember.inject.service(),
    queryParams: ["searchInput", "itemSetId"],
    itemSetId: null,
    isMobileApp: _stockConfigEnvironment["default"].cordova.enabled,
    displayItemOptionsList: true,
    searchModelName: "item",
    requestOptions: {
      withInventoryNumber: "true"
    },

    packageService: Ember.inject.service(),
    packageTypeService: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.cache = new _stockUtilsMemCache["default"]();
      this.get("session").memCache.push(this.cache);
    },

    /**
     * @property {Boolean} SearchMixin configuration
     **/
    autoLoad: true,
    /**
     * @property {Number} SearchMixin configuration, perPage in response
     **/
    perPage: 25,

    scannedItem: Ember.observer("searchInput", function () {
      var searchInput = this.get("searchInput") || "";
      var sanitizeString = this.sanitizeString(searchInput);
      if (sanitizeString) {
        this.set("searchText", sanitizeString);
      }
    }),

    scannedText: Ember.observer("searchText", function () {
      var searchInput = this.get("searchText") || "";
      this.set("searchInput", this.sanitizeString(searchInput));
    }),

    hasSearchText: Ember.computed("searchText", function () {
      return Ember.$.trim(this.get("searchText")).length;
    }),

    reloadResults: function reloadResults() {
      this.get("cache").clear();
      this._super();
    },

    createCacheKey: function createCacheKey(data) {
      return JSON.stringify(data);
    },

    getFilterQuery: function getFilterQuery() {
      var filterService = this.get("filterService");
      var utilities = this.get("utilityMethods");
      var itemStateFilters = filterService.get("itemStateFilterArray");
      var itemlocationFilter = filterService.get("itemLocationFilters");
      return {
        stockRequest: true,
        state: utilities.stringifyArray(itemStateFilters) || "received",
        location: itemlocationFilter
      };
    },

    onItemSetIdChange: Ember.observer("itemSetId", function () {
      // wait before applying the filter
      if (this.get("itemSetId")) {
        this.reloadResults();
      }
    }),

    actions: {
      /**
       * Load the next page of the list
       *
       * @param {number} pageNo the page to load
       * @returns {Promise<Model[]>}
       */
      loadMoreItems: function loadMoreItems(pageNo) {
        var cache = this.get("cache");
        var params = this.trimQuery(_lodash["default"].merge({}, this.getFilterQuery(), this.getSearchQuery(), this.getPaginationQuery(pageNo)));
        var cacheKey = this.createCacheKey(params);
        if (cache.has(cacheKey)) {
          return cache.get(cacheKey);
        }
        return this.get("store").query("item", params).then(function (results) {
          cache.set(cacheKey, results);
          return results;
        });
      },

      createNewPackage: function createNewPackage() {
        var type;
        return regeneratorRuntime.async(function createNewPackage$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              context$1$0.next = 2;
              return regeneratorRuntime.awrap(this.get("packageTypeService").userPickPackageType());

            case 2:
              type = context$1$0.sent;

              if (type) {
                this.transitionToRoute("items.new", {
                  queryParams: { codeId: type.id }
                });
              }

            case 4:
            case "end":
              return context$1$0.stop();
          }
        }, null, this);
      },

      clearSearch: function clearSearch() {
        this.set("searchText", "");
      }
    }
  });
});