define("stock/controllers/order/search_users", ["exports", "ember", "stock/controllers/search_module", "stock/utils/ajax-promise", "stock/constants/states"], function (exports, _ember, _stockControllersSearch_module, _stockUtilsAjaxPromise, _stockConstantsStates) {
  var pending = _stockConstantsStates.ORGANISATION_STATUS.pending;
  var approved = _stockConstantsStates.ORGANISATION_STATUS.approved;
  exports["default"] = _stockControllersSearch_module["default"].extend({
    filteredResults: "",
    queryParams: ["prevPath"],
    prevPath: null,
    messageBox: _ember["default"].inject.service(),
    i18n: _ember["default"].inject.service(),

    onSearchTextChange: _ember["default"].observer("searchText", function () {
      if (this.get("searchText").length) {
        _ember["default"].run.debounce(this, this.applyFilter, 500);
      } else {
        this.set("filteredResults", []);
      }
    }),

    applyFilter: function applyFilter() {
      var _this = this;

      this.set("isLoading", true);
      this.set("hasNoResults", false);

      this.infinityModel("user", {
        startingPage: 1,
        perPage: 25,
        modelPath: "filteredResults",
        organisation_status: approved + "," + pending
      }, { searchText: "searchText" }).then(function (data) {
        if (_this.get("searchText") === data.meta.search) {
          data.forEach(function (user) {
            user.get("organisations_users_ids").forEach(function (org_user_id) {
              _this.findOrganisationsUser(org_user_id);
            });
          });

          _this.set("filteredResults", data);
          _this.get("store").pushPayload(data);
          _this.set("hasNoResults", data.get("length") === 0);
        }
      })["finally"](function () {
        return _this.set("isLoading", false);
      });
      this.set("filteredResults", []);
    },

    findOrganisationsUser: function findOrganisationsUser(org_user_id) {
      return this.store.findRecord("organisations_user", org_user_id, {
        reload: false
      });
    },

    actions: {
      cancelSearch: function cancelSearch() {
        _ember["default"].$("#searchText").blur();
        this.send("clearSearch", true);
        this.transitionToRoute("app_menu_list");
      },

      goToRequestPurpose: function goToRequestPurpose(user) {
        var _this2 = this;

        var orderId = this.get("model.order.id");
        var userId = user.get("id");
        var organisationId = user.get("organisationsUsers.firstObject.organisation.id");

        if (!organisationId) {
          this.get("messageBox").custom(this.get("i18n").t("search_users.assign_charity_to_user"), this.get("i18n").t("search_users.edit_user"), function () {
            return _this2.transitionToRoute("users.details", userId);
          }, this.get("i18n").t("not_now"));

          return;
        }

        var orderParams = { created_by_id: userId, organisationId: organisationId };
        new _stockUtilsAjaxPromise["default"]("/orders/" + orderId, "PUT", this.get("session.authToken"), { order: orderParams }).then(function (data) {
          _this2.store.pushPayload(data);
          if (_this2.get("prevPath") === "contact_summary") {
            _this2.transitionToRoute("orders.contact_summary", orderId);
          } else {
            _this2.transitionToRoute("order.request_purpose", orderId, {
              queryParams: { userId: userId }
            });
          }
        });
      }
    }
  });
});