define("stock/components/radio-button", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    tagName: "input",
    type: "radio",
    attributeBindings: ["name", "type", "value", "checked", "labelText", "disabled"],
    disabled: false,

    click: function click() {
      this.set("selection", this.$().val());
    },

    checked: _ember["default"].computed('selection', function () {
      return this.get("value") === this.get("selection");
    }),

    onInit: _ember["default"].on('init', function () {
      if (this.get("value") === this.get("selection")) {
        this.set("checked", true);
      }
    })
  });
});