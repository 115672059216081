define("stock/components/time-ago", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    smallPrefix: true,

    time: _ember["default"].computed(function () {
      var timeValue = this.attrs.timeValue.value || new Date();
      return timeValue.toISOString();
    }),

    timeDisplay: _ember["default"].computed(function () {
      var timeValue = this.attrs.timeValue.value || new Date();
      return moment(timeValue).fromNow(this.get("smallPrefix"));
    })
  });
});